import { z } from 'zod';
import { FoxFrequencyEncryptionType, FoxFrequencyStatus } from '@/entities/source/@fox/constants';
import { responseDataSchema } from '@/shared/validationSchemas';

export const foxFrequencySchema = z.object({
  id: z.string(),
  encryptionTypes: z.array(
    z.object({
      type: z.nativeEnum(FoxFrequencyEncryptionType).or(z.unknown()),
      lastActiveAt: z.number(),
    })
  ),
  value: z.string(),
  countRecords: z.number(),
  note: z.string(),
  status: z.nativeEnum(FoxFrequencyStatus),
  lastActiveAt: z.number().nullish(),
  isDigital: z.boolean(),
});

export const updateFoxFrequenciesStatusSchema = z.object({
  frequencies: z.array(z.string()),
});

export const foxFrequenciesFiltersSchema = z.object({
  encryptionTypes: z.array(z.nativeEnum(FoxFrequencyEncryptionType)),
});

export const getFoxFrequenciesSchema = responseDataSchema(z.array(foxFrequencySchema));

export const createFoxFrequencySchema = responseDataSchema(foxFrequencySchema);

export const updateFoxFrequencySchema = responseDataSchema(foxFrequencySchema);

export const activateFoxFrequencySchema = responseDataSchema(z.object({}));

export const deactivateFoxFrequencySchema = responseDataSchema(z.object({}));

export const updateFoxFrequenciesStatusResponseSchema = responseDataSchema(updateFoxFrequenciesStatusSchema);

export const getFoxFrequenciesFiltersSchema = responseDataSchema(foxFrequenciesFiltersSchema);
