import pipe from 'lodash/fp/pipe';
import { useParams } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';
import useFoxFrequenciesQueryParams from './useFoxFrequenciesQueryParams';
import useGetFoxFrequencies from './useGetFoxFrequencies';
import useSelectSearchedFrequencies from './useSelectSearchedFrequencies';
import useSelectSortedFrequencies from './useSelectSortedFrequencies';

const useGetFilteredFoxFrequencies = () => {
  const { sourceId = '' } = useParams<SourceRouteParams>();
  const queryParams = useFoxFrequenciesQueryParams();
  const selectSortedFrequencies = useSelectSortedFrequencies();
  const selectSearchedFrequencies = useSelectSearchedFrequencies();
  const selectFilteredFrequencies = pipe(selectSortedFrequencies, selectSearchedFrequencies);

  return useGetFoxFrequencies({ sourceId, queryParams, options: { select: selectFilteredFrequencies } });
};

export default useGetFilteredFoxFrequencies;
