import { COPIES, NOTIFICATION_TYPES, STATUS_TYPES } from '@/shared/constants';
import { useNotificationContext } from '@/shared/context';

const { SUCCESS, ERROR } = NOTIFICATION_TYPES;

export const useCopyToClipboard = () => {
  const { openNotification } = useNotificationContext();

  const copyToClipboard = async (text: string) => {
    try {
      await window.navigator.clipboard.writeText(text);
      openNotification({
        type: SUCCESS,
        title: STATUS_TYPES.SUCCESS,
        description: COPIES.CLIPBOARD_COPY,
      });
    } catch (e) {
      openNotification({
        type: ERROR,
        title: STATUS_TYPES.ERROR,
        description: COPIES.COORDINATES_CLIPBOARD_COPY_ERROR,
      });
    }
  };

  return { copyToClipboard };
};
