import { z } from 'zod';
import {
  responseDataSchema,
  paginatedSchema,
  userSchema,
  baseUserSchema,
  newUserSchema,
  userRoleSchema,
} from '@/shared/validationSchemas';

export const getUserDataSchema = responseDataSchema(userSchema);
export const getUsersSchema = paginatedSchema(baseUserSchema);
export const getNewUsersSchema = paginatedSchema(newUserSchema);
export const activateUserSchema = responseDataSchema(userSchema);
export const deactivateUserSchema = responseDataSchema(userSchema);
export const updateUserSchema = responseDataSchema(userSchema);
export const getUserRolesSchema = responseDataSchema(z.array(userRoleSchema));
