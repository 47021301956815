// code taken from this repo https://github.com/jledet/waterfall
// some modifications necessary for a project added
// @ts-nocheck

/* eslint-disable */
import { Key } from 'ts-key-enum';
import { colormaps } from './constants';

export interface SpectrumInstance {
  addData: (data: number[]) => void;
  resize: () => void;
}

Spectrum.prototype.dataHistory = [];
Spectrum.prototype.maxStoreLen = 1000;
Spectrum.prototype.recordAvgFinishCb = null;
Spectrum.prototype.maxAvgRecord = 1;

Spectrum.prototype.squeeze = function (value, out_min, out_max) {
  if (value <= this.min_db)
    // this.min_db 0
    return out_min;
  if (value >= this.max_db)
    // this.max_db 80000
    return out_max;
  return Math.round(((value - this.min_db) / (this.max_db - this.min_db)) * out_max); // if value === 1000 result is 3, if value === 1500 result is 5
};

Spectrum.prototype.rowToImageData = function (bins) {
  // https://www.w3schools.com/jsref/canvas_imagedata_data.asp - explanation
  for (let i = 0; i < this.imagedata.data.length; i += 4) {
    const cindex = this.squeeze(bins[i / 4], 0, 255);
    const color = this.colormap[cindex];
    this.imagedata.data[i + 0] = color[0];
    this.imagedata.data[i + 1] = color[1];
    this.imagedata.data[i + 2] = color[2];
    this.imagedata.data[i + 3] = 255;
  }
};

Spectrum.prototype.addWaterfallRow = function (bins) {
  // Shift waterfall 1 row down
  this.ctx_wf.drawImage(this.ctx_wf.canvas, 0, 0, this.wf_size, this.wf_rows - 1, 0, 1, this.wf_size, this.wf_rows - 1);

  // Draw new line on waterfall canvas
  this.rowToImageData(bins);
  this.ctx_wf.putImageData(this.imagedata, 0, 0);
  // this.ctx_wf.putImageData(this.imagedata, 0, 20); // 20 here makes gap between spectrogram and waterfall

  const { width } = this.ctx.canvas;
  const { height } = this.ctx.canvas;

  // Copy scaled FFT canvas to screen. Only copy the number of rows that will
  // fit in waterfall area to avoid vertical scaling.
  this.ctx.imageSmoothingEnabled = false;
  const rows = Math.min(this.wf_rows, height - this.spectrumHeight);
  this.ctx.drawImage(
    this.ctx_wf.canvas,
    0,
    0,
    this.wf_size,
    rows,
    0,
    this.spectrumHeight,
    width,
    height - this.spectrumHeight
    // height - this.spectrumHeight - 20,  // -20 here adds padding to the bottom of the waterfall
  );
};

Spectrum.prototype.drawFFT = function (bins) {
  this.ctx.beginPath();
  this.ctx.moveTo(-1, this.spectrumHeight + 1);
  for (let i = 0; i < bins.length; i++) {
    let y = this.spectrumHeight - this.squeeze(bins[i], 0, this.spectrumHeight);
    if (y > this.spectrumHeight - 1) y = this.spectrumHeight + 1; // Hide underflow
    if (y < 0) y = 0;
    if (i == 0) this.ctx.lineTo(-1, y);
    this.ctx.lineTo(i, y);
    if (i == bins.length - 1) this.ctx.lineTo(this.wf_size + 1, y);
  }
  this.ctx.lineTo(this.wf_size + 1, this.spectrumHeight + 1);
  this.ctx.strokeStyle = '#fefefe';
  this.ctx.stroke();
};

Spectrum.prototype.drawSpectrum = function (bins) {
  const { width } = this.ctx.canvas;
  const { height } = this.ctx.canvas;

  this.ctx.fillStyle = this.fill_style;
  this.ctx.fillRect(0, 0, width, height);

  // FFT averaging
  if (this.averaging > 0) {
    if (!this.binsAverage || this.binsAverage.length != bins.length) {
      this.binsAverage = Array.from(bins);
    } else {
      for (var i = 0; i < bins.length; i++) {
        this.binsAverage[i] += this.alpha * (bins[i] - this.binsAverage[i]);
      }
    }
    bins = this.binsAverage;
  }

  // Max hold
  if (this.maxHold) {
    if (!this.binsMax || this.binsMax.length != bins.length) {
      this.binsMax = Array.from(bins);
    } else {
      for (var i = 0; i < bins.length; i++) {
        if (bins[i] > this.binsMax[i]) {
          this.binsMax[i] = bins[i];
        } else {
          // Decay
          this.binsMax[i] = 1.0025 * this.binsMax[i];
        }
      }
    }
  }

  // Do not draw anything if spectrum is not visible
  if (this.ctx_axes.canvas.height < 1) return;

  // Scale for FFT
  this.ctx.save();
  this.ctx.scale(width / this.wf_size, 1);

  // Draw maxhold
  if (this.maxHold) this.drawFFT(this.binsMax);

  // Draw FFT bins
  this.drawFFT(bins);

  // Restore scale
  this.ctx.restore();

  // Fill scaled path
  this.ctx.fillStyle = this.gradient;
  this.ctx.fill();

  // Copy axes from offscreen canvas
  this.ctx.drawImage(this.ctx_axes.canvas, 0, 0);
};

Spectrum.prototype.updateAxes = function () {
  return; // disable axes
  const { width } = this.ctx_axes.canvas;
  const { height } = this.ctx_axes.canvas; // height of the top part of the spectrogram

  // Clear axes canvas
  this.ctx_axes.clearRect(0, 0, width, height);

  // Draw axes
  this.ctx_axes.font = '12px sans-serif';
  this.ctx_axes.fillStyle = 'white';
  this.ctx_axes.textBaseline = 'middle';

  this.ctx_axes.textAlign = 'left';
  var step = 10;
  const step_db = Math.floor((this.max_db - this.min_db) / step);
  for (var i = this.min_db + step_db; i < this.max_db; i += step_db) {
    // Adjust the start and end conditions
    const y = height - this.squeeze(i, 0, height);
    this.ctx_axes.fillText(i, 5, y);

    this.ctx_axes.beginPath();
    this.ctx_axes.moveTo(20, y);
    this.ctx_axes.lineTo(width, y);
    this.ctx_axes.strokeStyle = 'rgba(200, 200, 200, 0.10)';
    this.ctx_axes.stroke();
  }

  // this.ctx_axes.textBaseline = "bottom";
  // for (var i = 0; i < 11; i++) {
  //     var x = Math.round(width / 10) * i;

  //     if (this.spanHz > 0) {
  //         var adjust = 0;
  //         if (i == 0) {
  //             this.ctx_axes.textAlign = "left";
  //             adjust = 3;
  //         } else if (i == 10) {
  //             this.ctx_axes.textAlign = "right";
  //             adjust = -3;
  //         } else {
  //             this.ctx_axes.textAlign = "center";
  //         }

  //         var freq = this.centerHz + this.spanHz / 10 * (i - 5);
  //         if (this.centerHz + this.spanHz > 1e6)
  //             freq = freq / 1e6 + "M";
  //         else if (this.centerHz + this.spanHz > 1e3)
  //             freq = freq / 1e3 + "k";
  //         this.ctx_axes.fillText(freq, x + adjust, height - 3);
  //     }

  //     this.ctx_axes.beginPath();
  //     this.ctx_axes.moveTo(x, 0);
  //     this.ctx_axes.lineTo(x, height);
  //     this.ctx_axes.strokeStyle = "rgba(200, 200, 200, 0.10)";
  //     this.ctx_axes.stroke();
  // }
  this.ctx_axes.textBaseline = 'bottom';

  var step = (this.end_wavelength - this.start_wavelength) / this.step_num;

  for (var i = 0; i <= this.step_num; i++) {
    // Modify this line to evenly distribute the steps based on the canvas width
    const x = Math.round(width / this.step_num) * i;

    // Adjust the text alignment based on the position
    let adjust = 0;
    if (i == 0) {
      this.ctx_axes.textAlign = 'left';
      adjust = 3;
    } else if (i == this.step_num) {
      this.ctx_axes.textAlign = 'right';
      adjust = -3;
    } else {
      this.ctx_axes.textAlign = 'center';
    }

    // Calculate the wavelength for the current step and display it
    const wavelength = this.start_wavelength + step * i;
    if (wavelength !== this.start_wavelength && wavelength !== this.end_wavelength) {
      this.ctx_axes.fillText(wavelength, x + adjust, height - 3);
    }

    // Drawing the vertical line
    this.ctx_axes.beginPath();
    this.ctx_axes.moveTo(x, 0);
    this.ctx_axes.lineTo(x, height);
    this.ctx_axes.strokeStyle = 'rgba(200, 200, 200, 0.10)';
    this.ctx_axes.stroke();
  }

  // this.ctx_wf.strokeStyle = "red";
  // this.ctx_wf.lineWidth = 2; // Adjust this value as needed
  // this.ctx_wf.beginPath();
  // this.ctx_wf.moveTo(0, 0);
  // this.ctx_wf.lineTo(this.ctx_wf.canvas.width, 0);
  // this.ctx_wf.stroke();
  // white horizontal line
  //
  // this.ctx_axes.strokeStyle = "red";
  // this.ctx_axes.lineWidth = 20; // Adjust this value as needed
  // this.ctx_axes.beginPath();
  // this.ctx_axes.moveTo(0, this.ctx_axes.canvas.height);
  // this.ctx_axes.lineTo(this.ctx_axes.canvas.width, this.ctx_axes.canvas.height);
  // this.ctx_axes.stroke();
  //
  // this.ctx_axes.fillRect(0, this.axes.height - 20, this.axes.width, this.ctx_axes.lineWidth);
};

Spectrum.prototype.setMaxStoreLen = function (maxStoreLen) {
  this.maxStoreLen = maxStoreLen;
};

Spectrum.prototype.addData = function (data) {
  try {
    if (!this.paused) {
      const dark_spectrum = localStorage.getItem('dark_spectrum')
        ? JSON.parse(localStorage.getItem('dark_spectrum'))
        : null;
      if (dark_spectrum && dark_spectrum.length > 0 && dark_spectrum.length == data.length) {
        const diff = data.map((item, index) => item - dark_spectrum[index]);
        data = diff;
      }

      if (data.length !== this.wf_size) {
        this.wf_size = data.length;
        this.ctx_wf.canvas.width = data.length;
        this.ctx_wf.fillStyle = this.fill_style;
        this.ctx_wf.fillRect(0, 0, this.wf.width, this.wf.height);
        this.imagedata = this.ctx_wf.createImageData(data.length, 1);
      }
      // find min and max value of data
      let min = data[0];
      let max = data[0];
      for (let i = 0; i < data.length; i++) {
        if (data[i] < min) min = data[i];
        if (data[i] > max) max = data[i];
      }
      min = Math.floor(min * 0.8);
      max = Math.ceil(max * 1.2);
      // this.setRange(min, max);
      this.drawSpectrum(data);
      this.addWaterfallRow(data);

      this.dataHistory.push({
        timestamp: Date.now(), // current UNIX timestamp in milliseconds
        data,
      });
      if (this.recordAvgFinishCb && this.dataHistory.length >= this.maxAvgRecord) {
        const calculateAverage = this.calculateAverage(this.dataHistory);
        localStorage.setItem('dark_spectrum', JSON.stringify(calculateAverage));
        this.recordAvgFinishCb(calculateAverage);
        this.recordAvgFinishCb = null;
        this.dataHistory = [];
        // this.dataHistory.push({
        //     timestamp: Date.now(), // current UNIX timestamp in milliseconds
        //     data: calculateAverage
        // });
      }
      if (this.dataHistory.length > this.maxStoreLen) {
        this.dataHistory.shift(); // remove the oldest entry if more than maxStoreLen
      }
    }
  } catch (error) {
    console.error('Spectrum.prototype.addData error', error);
  }
};

Spectrum.prototype.recordAvg = function (cb) {
  this.recordAvgFinishCb = cb;
};

Spectrum.prototype.calculateAverage = function (dataHistory) {
  const arrLen = dataHistory[0].data.length;
  const averageData = Array(arrLen).fill(0);

  dataHistory.forEach((item) => {
    for (let i = 0; i < arrLen; i++) {
      averageData[i] += item.data[i];
    }
  });

  for (let i = 0; i < arrLen; i++) {
    averageData[i] = averageData[i] / dataHistory.length;
  }

  return averageData;
};

Spectrum.prototype.resetAll = function () {
  localStorage.setItem('dark_spectrum', '');
  this.recordAvgFinishCb = null;
  this.dataHistory = [];
  this.ctx_wf.fillStyle = this.fill_style;
  this.ctx_wf.fillRect(0, 0, this.wf.width, this.wf.height);
  this.imagedata = this.ctx_wf.createImageData(this.wf_size, 1);
};

Spectrum.prototype.downloadCSV = function () {
  // Sort dataHistory based on timestamp
  this.dataHistory.sort((a, b) => a.timestamp - b.timestamp);

  // Convert dataHistory to CSV format
  let csvContent = 'data:text/csv;charset=utf-8,';
  csvContent += 'Timestamp,Data\n'; // headers
  const dark_spectrum = localStorage.getItem('dark_spectrum')
    ? JSON.parse(localStorage.getItem('dark_spectrum'))
    : null;
  if (dark_spectrum && dark_spectrum.length > 0) {
    csvContent += `#dark spectrum,${dark_spectrum.join(',')}\n`;
  }
  this.dataHistory.forEach((entry) => {
    csvContent += `${entry.timestamp},${entry.data.join(',')}\n`; // data rows
  });

  // Trigger CSV download
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', 'data.csv');
  document.body.appendChild(link); // Required for Firefox
  link.click();
  document.body.removeChild(link);
};

Spectrum.prototype.updateSpectrumRatio = function () {
  this.spectrumHeight = Math.round((this.canvas.height * this.spectrumPercent) / 100.0);

  this.gradient = this.ctx.createLinearGradient(0, 0, 0, this.spectrumHeight);
  for (let i = 0; i < this.colormap.length; i++) {
    const c = this.colormap[this.colormap.length - 1 - i];
    this.gradient.addColorStop(i / this.colormap.length, `rgba(${c[0]},${c[1]},${c[2]}, 1.0)`);
  }
};

Spectrum.prototype.resize = function () {
  const width = this.canvas.clientWidth;
  const height = this.canvas.clientHeight;

  if (this.canvas.width != width || this.canvas.height != height) {
    this.canvas.width = this.getNewCanvasWidth();
    this.canvas.height = height;
    this.updateSpectrumRatio();
  }

  if (this.axes.width != width || this.axes.height != this.spectrumHeight) {
    this.axes.width = width;
    this.axes.height = this.spectrumHeight;
    this.updateAxes();
  }
};

Spectrum.prototype.setSpectrumPercent = function (percent) {
  if (percent >= 0 && percent <= 100) {
    this.spectrumPercent = percent;
    this.updateSpectrumRatio();
  }
};

Spectrum.prototype.incrementSpectrumPercent = function () {
  if (this.spectrumPercent + this.spectrumPercentStep <= 100) {
    this.setSpectrumPercent(this.spectrumPercent + this.spectrumPercentStep);
  }
};

Spectrum.prototype.decrementSpectrumPercent = function () {
  if (this.spectrumPercent - this.spectrumPercentStep >= 0) {
    this.setSpectrumPercent(this.spectrumPercent - this.spectrumPercentStep);
  }
};

Spectrum.prototype.toggleColor = function () {
  this.colorindex++;
  if (this.colorindex >= colormaps.length) this.colorindex = 0;
  this.colormap = colormaps[this.colorindex];
  this.updateSpectrumRatio();
};

Spectrum.prototype.setRange = function (min_db, max_db) {
  this.min_db = min_db;
  this.max_db = max_db;
  this.updateAxes();
};

Spectrum.prototype.rangeUp = function () {
  this.setRange(this.min_db - 5, this.max_db - 5);
};

Spectrum.prototype.rangeDown = function () {
  this.setRange(this.min_db + 5, this.max_db + 5);
};

Spectrum.prototype.rangeIncrease = function () {
  this.setRange(this.min_db - 5, this.max_db + 5);
};

Spectrum.prototype.rangeDecrease = function () {
  if (this.max_db - this.min_db > 10) this.setRange(this.min_db + 5, this.max_db - 5);
};

Spectrum.prototype.setCenterHz = function (hz) {
  this.centerHz = hz;
  this.updateAxes();
};

Spectrum.prototype.setSpanHz = function (hz) {
  this.spanHz = hz;
  this.updateAxes();
};

Spectrum.prototype.setAveraging = function (num) {
  if (num >= 0) {
    this.averaging = num;
    this.alpha = 2 / (this.averaging + 1);
  }
};

Spectrum.prototype.incrementAveraging = function () {
  this.setAveraging(this.averaging + 1);
};

Spectrum.prototype.decrementAveraging = function () {
  if (this.averaging > 0) {
    this.setAveraging(this.averaging - 1);
  }
};

Spectrum.prototype.setPaused = function (paused) {
  this.paused = paused;
};

Spectrum.prototype.togglePaused = function () {
  this.setPaused(!this.paused);
};

Spectrum.prototype.setMaxHold = function (maxhold) {
  this.maxHold = maxhold;
  this.binsMax = undefined;
};

Spectrum.prototype.toggleMaxHold = function () {
  this.setMaxHold(!this.maxHold);
};

Spectrum.prototype.toggleFullscreen = function () {
  if (!this.fullscreen) {
    if (this.canvas.requestFullscreen) {
      this.canvas.requestFullscreen();
    } else if (this.canvas.mozRequestFullScreen) {
      this.canvas.mozRequestFullScreen();
    } else if (this.canvas.webkitRequestFullscreen) {
      this.canvas.webkitRequestFullscreen();
    } else if (this.canvas.msRequestFullscreen) {
      this.canvas.msRequestFullscreen();
    }
    this.fullscreen = true;
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
    this.fullscreen = false;
  }
};

// Drawing the clipping box
Spectrum.prototype.drawClippingBox = function () {
  this.ctx.strokeStyle = 'red';
  this.ctx.lineWidth = 2;
  this.ctx.strokeRect(this.clipStart, 0, this.clipEnd - this.clipStart, this.canvas.height);
};

// Initializing the clipping box
Spectrum.prototype.initClippingBox = function () {
  this.clipStart = this.canvas.width * 0.25;
  this.clipEnd = this.canvas.width * 0.75;
  this.drawClippingBox();
};

// Stretch the content within the clipping box
Spectrum.prototype.stretchContent = function () {
  const clipWidth = this.clipEnd - this.clipStart;
  const imageData = this.ctx.getImageData(this.clipStart, 0, clipWidth, this.canvas.height);

  // Create a temporary canvas
  const tempCanvas = document.createElement('canvas');
  const tempCtx = tempCanvas.getContext('2d');
  tempCanvas.width = clipWidth;
  tempCanvas.height = this.canvas.height;

  // Put the image data onto the temporary canvas
  tempCtx.putImageData(imageData, 0, 0);

  // Clear the main canvas
  this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

  // Draw the content of the temporary canvas onto the main canvas, stretching it
  this.ctx.drawImage(tempCanvas, 0, 0, clipWidth, this.canvas.height, 0, 0, this.canvas.width, this.canvas.height);
};

// Adding necessary event listeners for interaction
Spectrum.prototype.addEventListeners = function () {
  this.canvas.addEventListener('mousedown', (e) => {
    const mouseX = e.offsetX;
    if (mouseX > this.clipStart - 10 && mouseX < this.clipStart + 10) {
      this.isDragging = 'start';
    } else if (mouseX > this.clipEnd - 10 && mouseX < this.clipEnd + 10) {
      this.isDragging = 'end';
    }
  });

  this.canvas.addEventListener('mousemove', (e) => {
    if (this.isDragging) {
      const mouseX = e.offsetX;
      if (this.isDragging === 'start') {
        this.clipStart = mouseX;
      } else if (this.isDragging === 'end') {
        this.clipEnd = mouseX;
      }
      this.drawClippingBox();
    }
  });

  this.canvas.addEventListener('mouseup', () => {
    if (this.isDragging) {
      this.isDragging = false;
      this.stretchContent();
    }
  });
};

Spectrum.prototype.onKeypress = function (e) {
  if (e.key == ' ') {
    this.togglePaused();
  } else if (e.key == 'f') {
    this.toggleFullscreen();
  } else if (e.key == 'c') {
    this.toggleColor();
  } else if (e.key == Key.ArrowUp) {
    this.rangeUp();
  } else if (e.key == Key.ArrowDown) {
    this.rangeDown();
  } else if (e.key == Key.ArrowLeft) {
    this.rangeDecrease();
  } else if (e.key == Key.ArrowRight) {
    this.rangeIncrease();
  } else if (e.key == 's') {
    this.incrementSpectrumPercent();
  } else if (e.key == 'w') {
    this.decrementSpectrumPercent();
  } else if (e.key == '+') {
    this.incrementAveraging();
  } else if (e.key == '-') {
    this.decrementAveraging();
  } else if (e.key == 'm') {
    this.toggleMaxHold();
  }
};

Spectrum.prototype.getNewCanvasWidth = function () {
  if (!this.minCanvasWidthQuality) return this.canvas.clientWidth;

  return this.canvas.clientWidth < this.minCanvasWidthQuality ? this.minCanvasWidthQuality : this.canvas.clientWidth;
};

function Spectrum(id, options): SpectrumInstance {
  // Handle options
  this.centerHz = options && options.centerHz ? options.centerHz : 0;
  this.spanHz = options && options.spanHz ? options.spanHz : 0;
  this.wf_size = options && options.wf_size ? options.wf_size : 0;
  this.wf_rows = options && options.wf_rows ? options.wf_rows : 2048;
  this.spectrumPercent = options && options.spectrumPercent ? options.spectrumPercent : 25;
  this.spectrumPercentStep = options && options.spectrumPercentStep ? options.spectrumPercentStep : 5;
  this.averaging = options && options.averaging ? options.averaging : 0;
  this.maxHold = options && options.maxHold ? options.maxHold : false;
  this.start_wavelength = options && options.start_wavelength ? options.start_wavelength : 400;
  this.end_wavelength = options && options.end_wavelength ? options.end_wavelength : 1000;
  this.step_num = options && options.step_num ? options.step_num : 5;
  this.fill_style = options && options.fill_style ? options.fill_style : 'black';
  this.min_db = options && options.min_db ? options.min_db : 0;
  this.max_db = options && options.max_db ? options.max_db : 80000;
  this.colorIndex = options && options.colorIndex ? options.colorIndex : 0;
  this.minCanvasWidthQuality = options && options.max_db ? options.minCanvasWidthQuality : null;

  // Setup state
  this.paused = false;
  this.fullscreen = false;
  this.spectrumHeight = 0;

  // Colors
  this.colormap = colormaps[this.colorIndex];

  // Create main canvas and adjust dimensions to match actual
  this.canvas = document.getElementById(id);
  this.canvas.height = this.canvas.clientHeight;
  this.canvas.width = this.getNewCanvasWidth();
  this.ctx = this.canvas.getContext('2d');
  this.ctx.fillStyle = this.fill_style;
  this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

  // Create offscreen canvas for axes
  this.axes = document.createElement('canvas');
  this.axes.height = 1; // Updated later
  this.axes.width = this.canvas.width;
  this.ctx_axes = this.axes.getContext('2d');

  // Create offscreen canvas for waterfall
  this.wf = document.createElement('canvas');
  this.wf.height = this.wf_rows;
  this.wf.width = this.wf_size;
  this.ctx_wf = this.wf.getContext('2d');

  this.clipStart = null;
  this.clipEnd = null;
  this.isDragging = false;
  // this.addEventListeners();

  // Trigger first render
  this.setAveraging(this.averaging);
  this.updateSpectrumRatio();
  this.resize();
}

export default Spectrum;
