import { ValidationError } from '@/shared/api';
import { validationService } from '@/shared/services';

interface Params {
  error: unknown;
  param: string;
}

const isRadioNetworkLocked = ({ error, param }: Params) => {
  const lockedRadioNetworkMessages = ['frequency.locked', 'network.locked'];
  const isErrorCauseValidationError =
    error && typeof error === 'object' && 'cause' in error && error.cause instanceof ValidationError;

  if (!isErrorCauseValidationError) return false;

  return lockedRadioNetworkMessages.some((msg) =>
    validationService.isContainValidationError({
      err: error.cause as ValidationError,
      msg,
      param,
    })
  );
};

export default isRadioNetworkLocked;
