import { CSSProperties } from 'react';

interface Params {
  isOverflowLeft: boolean;
  isOverflowRight: boolean;
  frequencyWidth: number;
  isInputVisible: boolean;
}

const getFrequencyInputPosition = ({
  frequencyWidth,
  isOverflowLeft,
  isOverflowRight,
  isInputVisible,
}: Params): CSSProperties => {
  if (!isInputVisible) {
    return { left: -3 };
  }
  if (isOverflowLeft) {
    return { left: -2 };
  }
  if (isOverflowRight) {
    return { right: -2 };
  }
  return { left: -(frequencyWidth / 2) + 2 };
};

export default getFrequencyInputPosition;
