import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { getUnitUsersQueryKey } from '@/entities/unit/components/UnitContent/components/UnitUsersTab/utils';
import { UnitUser } from '@/entities/unit/types';
import { PaginatedWithFoffset } from '@/shared/api';

interface Params extends Partial<Omit<UnitUser, 'id'>>, Pick<UnitUser, 'id'> {}

const useUpdateUnitUserCache = (unitId: string) => {
  const queryClient = useQueryClient();
  const queryKey = getUnitUsersQueryKey(unitId);
  return ({ id, ...partialUnitUser }: Params) => {
    queryClient.setQueryData<InfiniteData<PaginatedWithFoffset<UnitUser>>>(
      queryKey,
      (staleData): undefined | InfiniteData<PaginatedWithFoffset<UnitUser>> => {
        if (!staleData) return;

        const { pages } = staleData;

        const updatedPages = pages.map((page) => {
          return {
            ...page,
            models: page.models.map((unitUser) => {
              if (unitUser.id !== id) return unitUser;
              return { ...unitUser, ...partialUnitUser };
            }),
          };
        });

        return {
          ...staleData,
          pages: updatedPages,
        };
      }
    );
  };
};

export default useUpdateUnitUserCache;
