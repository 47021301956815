import { MouseEvent } from 'react';
import { Tag as TagComponent, TagProps } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { SpinnerIndicator } from '@/shared/components/ui/Spinner';
import styles from './styles.module.css';

type Props = Omit<TagProps, 'bordered' | 'onMouseDown' | 'closeIcon'> & { isLoading?: boolean };

export const BaseTag = ({ children, className = '', isLoading, closable, ...props }: Props) => {
  const handlePreventMouseDown = (event: MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <TagComponent
      className={classNames(styles.tag, className, {
        [styles.pointerEventsNone]: isLoading,
        [styles.tagClosable]: closable,
      })}
      bordered={false}
      onMouseDown={handlePreventMouseDown}
      closable={closable}
      closeIcon={<CloseOutlined className={styles.closeIcon} />}
      {...props}
    >
      {children}
      {isLoading && <SpinnerIndicator iconFontSize="inherit" />}
    </TagComponent>
  );
};
