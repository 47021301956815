import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { createFoxKey as createFoxKeyApi } from '@/entities/source/@fox/api';
import { CreateFoxKeyPayload, FoxKey } from '@/entities/source/@fox/types';
import { ResponseData } from '@/shared/api';
import { CREATE_FOX_KEY_MUTATION_KEY } from '@/shared/constants';
import { useParams } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';

const useCreateFoxKey = (options: UseMutationOptions<ResponseData<FoxKey>, unknown, CreateFoxKeyPayload> = {}) => {
  const { sourceId = '' } = useParams<SourceRouteParams>();
  const { mutate: createFoxKey, isLoading: isFoxKeyCreating } = useMutation({
    ...options,
    mutationKey: [CREATE_FOX_KEY_MUTATION_KEY, sourceId],
    mutationFn: (payload: CreateFoxKeyPayload) => {
      return createFoxKeyApi({ sourceId, payload });
    },
  });

  return {
    createFoxKey,
    isFoxKeyCreating,
  };
};

export default useCreateFoxKey;
