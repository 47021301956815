import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { FoxFrequency } from '@/entities/source/@fox/types';
import { ResponseData } from '@/shared/api';

const useDeleteFoxFrequencyFromCache = (queryKey: QueryKey) => {
  const queryClient = useQueryClient();

  return (id: string) => {
    queryClient.setQueryData<ResponseData<FoxFrequency[]>>(queryKey, (data) => {
      if (!data) return;

      const updatedData = data.data.filter((foxFrequency) => {
        return foxFrequency.id !== id;
      });

      return {
        ...data,
        data: updatedData,
      };
    });
  };
};

export default useDeleteFoxFrequencyFromCache;
