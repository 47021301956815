import { Flex } from 'antd';
import { ELEVATION_METERS } from './constants';
import styles from './styles.module.css';

const ElevationLegend = () => {
  return (
    <Flex
      className={styles.legend}
      vertical
    >
      {ELEVATION_METERS.map(({ label, color }) => (
        <div
          key={label}
          className={styles.elevation}
          style={{ background: color }}
        >
          {`${label} m`}
        </div>
      ))}
    </Flex>
  );
};

export default ElevationLegend;
