import { useEffect, useState } from 'react';
import { usePrevious } from '@reactuses/core';
import { MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH } from '@/shared/constants';
import { ViewportType } from '@/shared/types';
import { getViewportType } from '@/shared/utils';

const useOnViewportTypeChange = ({ memoOnChange }: { memoOnChange: (type?: ViewportType) => void }) => {
  const [viewportType, setViewportType] = useState(
    getViewportType({ width: window.innerWidth, mobileMaxWidth: MOBILE_MAX_WIDTH, tabletMaxWidth: TABLET_MAX_WIDTH })
  );
  const previousViewportType = usePrevious(viewportType);

  useEffect(() => {
    const handleResize = (e: UIEvent) => {
      const w = e.target as Window;
      const currentViewportType = getViewportType({
        width: w.innerWidth,
        mobileMaxWidth: MOBILE_MAX_WIDTH,
        tabletMaxWidth: TABLET_MAX_WIDTH,
      });
      if (currentViewportType !== viewportType) {
        setViewportType(currentViewportType);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [viewportType]);

  useEffect(() => {
    if (viewportType !== previousViewportType) {
      memoOnChange(viewportType);
    }
  }, [viewportType, memoOnChange, previousViewportType]);
};

export default useOnViewportTypeChange;
