import { useMemo } from 'react';
import { SourceTab } from '@/shared/constants';
import { useParams } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';
import useFoxRecordsQueryParams from './useFoxRecordsQueryParams';
import useGetFiles from './useGetFiles';

const useFile = () => {
  const queryParams = useFoxRecordsQueryParams();
  const { activeTab, tabEntityId } = useParams<SourceRouteParams>();
  const { filesList } = useGetFiles({
    options: {
      enabled: false,
    },
    queryParams,
  });

  return useMemo(() => {
    if (activeTab !== SourceTab.Records || !tabEntityId || filesList.length === 0) return;

    return filesList.find(({ id }) => id === tabEntityId);
  }, [filesList, activeTab, tabEntityId]);
};

export default useFile;
