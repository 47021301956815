import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getFileFilters } from '@/entities/source/@fox/api';
import { FileFilters } from '@/entities/source/@fox/types';
import { ResponseData } from '@/shared/api';
import { FILE_FILTERS_QUERY_KEY } from '@/shared/constants';

interface Params {
  sourceId: string;
  options?: UseQueryOptions<unknown, unknown, ResponseData<FileFilters>, string[]>;
}

const useGetFileFilters = ({ sourceId, options }: Params) => {
  const { data: filters, isLoading } = useQuery({
    enabled: Boolean(sourceId),
    ...options,
    queryKey: [FILE_FILTERS_QUERY_KEY, sourceId],
    queryFn: () =>
      getFileFilters({
        source: [sourceId],
      }),
  });

  return { filters, isLoading };
};

export default useGetFileFilters;
