import { Checkbox, Col, Typography, Row } from 'antd';
import { Drawer, Select } from '@/shared/components';
import { COPIES } from '@/shared/constants';
import { useFiltersDrawer } from './hooks';
import styles from './styles.module.css';

const ACTIVE = 'Активовані';
const DEACTIVATED = 'Деактивовані';
const BY_FREQUENCY_STATE = 'За станом частоти';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const FiltersDrawer = ({ isOpen, onClose }: Props) => {
  const {
    isActiveFrequenciesSelected,
    isDeactivatedFrequenciesSelected,
    toggleActiveStatus,
    toggleDeactivatedStatus,
    isDigitalFrequenciesSelected,
    isAnalogFrequenciesSelected,
    toggleDigitalSignalType,
    toggleAnalogSignalType,
    memoEncryptionType,
    isFiltersLoading,
    encryptionTypeOptions,
    handleEncryptionTypeChange,
  } = useFiltersDrawer();

  return (
    <Drawer
      title={COPIES.FILTERS}
      open={isOpen}
      onClose={onClose}
    >
      <Row className={styles.wrapper}>
        <Col>
          <Typography className={styles.title}>{COPIES.ENCRYPTION_TYPE}</Typography>
          <Select
            mode="multiple"
            value={memoEncryptionType}
            options={encryptionTypeOptions}
            loading={isFiltersLoading}
            onChange={handleEncryptionTypeChange}
            optionFilterProp="value"
          />
        </Col>
        <Col>
          <Typography className={styles.title}>{COPIES.BY_FREQUENCY_TYPE}</Typography>
          <Checkbox
            className={styles.checkbox}
            checked={isAnalogFrequenciesSelected}
            onChange={toggleAnalogSignalType}
          >
            {COPIES.ANALOG}
          </Checkbox>
          <Checkbox
            className={styles.checkbox}
            checked={isDigitalFrequenciesSelected}
            onChange={toggleDigitalSignalType}
          >
            {COPIES.DIGITAL}
          </Checkbox>
        </Col>
        <Col>
          <Typography className={styles.title}>{BY_FREQUENCY_STATE}</Typography>
          <Checkbox
            className={styles.checkbox}
            checked={isActiveFrequenciesSelected}
            onChange={toggleActiveStatus}
          >
            {ACTIVE}
          </Checkbox>
          <Checkbox
            className={styles.checkbox}
            checked={isDeactivatedFrequenciesSelected}
            onChange={toggleDeactivatedStatus}
          >
            {DEACTIVATED}
          </Checkbox>
        </Col>
      </Row>
    </Drawer>
  );
};

export default FiltersDrawer;
