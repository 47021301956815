import { Checkbox, Row } from 'antd';
import Title from '../Title';
import { useDecodingType } from './hooks';
import styles from './styles.module.css';

const BY_DECODING_TYPE = 'По декодуванню';
const DECODED_TO_AUDIO = 'Декодовані в аудіо';
const NOT_DECODED_TO_AUDIO = 'Не декодовані в аудіо';

const DecodingType = () => {
  const { isDecodedSelected, toggleIsDecodedSelected, isNotDecodedSelected, toggleIsNotDecodedSelected } =
    useDecodingType();
  return (
    <Row>
      <Title value={BY_DECODING_TYPE} />
      <Checkbox
        className={styles.checkbox}
        checked={isDecodedSelected}
        onChange={toggleIsDecodedSelected}
      >
        {DECODED_TO_AUDIO}
      </Checkbox>
      <Checkbox
        className={styles.checkbox}
        checked={isNotDecodedSelected}
        onChange={toggleIsNotDecodedSelected}
      >
        {NOT_DECODED_TO_AUDIO}
      </Checkbox>
    </Row>
  );
};

export default DecodingType;
