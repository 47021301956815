import { ReactNode } from 'react';
import { Layout, Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { TechSupportFooter } from '@/shared/components';
import { OVERVIEW_PATH } from '@/shared/constants';
import { useNavigate } from '@/shared/hooks';
import styles from './styles.module.css';

const { Content } = Layout;

const BACK_TO_MAIN_BUTTON = 'На головну';

type Props = {
  title: string;
  message: string;
  icon: ReactNode;
  onButtonClick: () => void;
};

const CodeErrorPage = ({ title, message, icon, onButtonClick }: Props) => {
  const navigate = useNavigate();

  const handleClick = () => {
    onButtonClick();
    navigate(OVERVIEW_PATH);
  };

  return (
    <Layout className={styles.wrap}>
      <Content className={styles.content}>
        <div className={styles.icon}>{icon}</div>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.message}>{message}</p>
        <Button
          className={styles.button}
          type="primary"
          icon={<ArrowLeftOutlined />}
          onClick={handleClick}
        >
          {BACK_TO_MAIN_BUTTON}
        </Button>
      </Content>
      <TechSupportFooter />
    </Layout>
  );
};

export default CodeErrorPage;
