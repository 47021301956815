import { DatePicker, Form, FormItemProps } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { COPIES } from '@/shared/constants';
import { RangeValue } from '@/shared/types';
import { useDateRangePicker } from './hooks';
import styles from './styles.module.css';

type Props = {
  value: RangeValue;
  onChange: (value: RangeValue) => void;
  formItemProps?: FormItemProps;
};

const DateRangePicker = ({ value, onChange, formItemProps }: Props) => {
  const {
    isMobile,
    presets,
    showTime,
    dateFormat,
    size,
    defaultPickerValue,
    mobilePopupContainerRef,
    getPopupContainer,
    handleChange,
    handleOk,
    handleDisabledDate,
    handleCalendarChange,
  } = useDateRangePicker({ onChange });

  return (
    <Form.Item
      label={COPIES.DATE}
      {...formItemProps}
    >
      <DatePicker.RangePicker
        size={size}
        className={styles.dateRangePicker}
        popupClassName={styles.dateRangePickerPopover}
        defaultPickerValue={!value ? defaultPickerValue : undefined}
        disabledDate={handleDisabledDate}
        presets={presets}
        format={dateFormat}
        showTime={showTime}
        getPopupContainer={getPopupContainer}
        value={value}
        onChange={handleChange}
        onOk={handleOk}
        onCalendarChange={handleCalendarChange}
        allowClear={{ clearIcon: <CloseOutlined className={styles.clearIcon} /> }}
      />
      {isMobile && <div ref={mobilePopupContainerRef} />}
    </Form.Item>
  );
};

export default DateRangePicker;
