import { useMemo } from 'react';
import {
  Category,
  CategoryId,
  formatToSelectOptions,
  CategoryFormMultiSelect,
  CATEGORIES_PLACEHOLDER,
} from '@/entities/category';
import { SubscriptionItemId, SubscriptionType } from '@/entities/subscription';
import { useGetSubscriptions } from '@/widgets/SubscriptionDrawer/hooks';
import { useSubscriptionState } from '../hooks';

type Props = {
  categories: Category[];
  isLoading?: boolean;
};

const CategoryField = ({ categories, isLoading }: Props) => {
  const { subscriptions } = useGetSubscriptions();
  const { handleSubscriptionChange, getSubscriptionValues } = useSubscriptionState();

  const categoriesOptions = useMemo(() => formatToSelectOptions(categories), [categories]);

  const handleChange = (values: SubscriptionItemId[]) => {
    handleSubscriptionChange(values, SubscriptionType.Category);
  };

  const value = getSubscriptionValues(SubscriptionType.Category) as CategoryId[];

  return (
    <CategoryFormMultiSelect
      options={categoriesOptions}
      isLoading={isLoading}
      value={value}
      onChange={handleChange}
      disabled={!subscriptions?.models.length}
      placeholder={CATEGORIES_PLACEHOLDER}
    />
  );
};

export default CategoryField;
