import { featureCollection, getCluster } from '@turf/turf';
import { Feature, FeatureCollection } from 'geojson';
import { SourceSpecification } from 'maplibre-gl';
import {
  ClusterGroupedKey,
  GROUP_NO_ID,
  HAS_LOCATION_TYPE,
  HAS_POINT_TYPE,
  MAP_CLUSTER_RADIUS,
  MAP_MAX_ZOOM,
  MapSource,
} from '@/entities/map/constants';
import { MaplibreMap } from '@/entities/map/types';
import { getComplexId } from '@/entities/map/utils';

class SourceService {
  constructor(private map: MaplibreMap) {}

  private addMapSource(sourceId: string, sourceOptions: SourceSpecification) {
    // to avoid adding a duplicate source
    if (this.map.getSource(sourceId)) return;
    this.map.addSource(sourceId, sourceOptions);
  }

  addSource(sourceId: MapSource, features: Feature[] = []) {
    this.addMapSource(sourceId, {
      type: 'geojson',
      data: featureCollection(features),
    });
  }

  updateSource(sourceId: MapSource, features: FeatureCollection = featureCollection([])) {
    const source = this.map.getSource(sourceId);
    source?.setData(features);
  }

  addMarkerSource(groupedKey: ClusterGroupedKey, features: Feature[] = [], groupId?: string) {
    const sourceId = getComplexId(MapSource.Markers, groupId);
    let sourceData: FeatureCollection;

    if (groupedKey === ClusterGroupedKey.Default) {
      sourceData = featureCollection(features);
    } else {
      sourceData = getCluster(featureCollection(features), {
        [groupedKey]: groupId !== GROUP_NO_ID ? groupId : null,
      });
    }

    this.addMapSource(sourceId, {
      type: 'geojson',
      data: sourceData,
      generateId: true,
      cluster: true,
      clusterRadius: MAP_CLUSTER_RADIUS,
      clusterMaxZoom: MAP_MAX_ZOOM,
      clusterProperties: {
        location_features: ['+', ['case', HAS_LOCATION_TYPE, 1, 0]],
        point_features: ['+', ['case', HAS_POINT_TYPE, 1, 0]],
      },
    });
  }
}

export default SourceService;
