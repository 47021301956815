import { RouteLink } from '@/shared/components';
import ParsedFrequencyValue from '../ParsedFrequencyValue';
import useFrequencyLink from './useFrequencyLink';

type Props = {
  frequencyValue: string;
  parsedFrequencyValue: string[];
};

const FrequencyLink = ({ frequencyValue, parsedFrequencyValue }: Props) => {
  const { frequencyLinkHref, handleFrequencyClick } = useFrequencyLink(frequencyValue);

  return (
    <RouteLink
      href={frequencyLinkHref}
      onClick={handleFrequencyClick}
    >
      <ParsedFrequencyValue frequencyValue={parsedFrequencyValue} />
    </RouteLink>
  );
};

export default FrequencyLink;
