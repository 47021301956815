import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { RadioNetwork } from '@/shared/types';
import styles from './styles.module.css';
import useDroppableItemWrapper from './useDroppableItemWrapper';

interface Props {
  item: RadioNetwork;
}

const DroppableItemWrapper: FC<PropsWithChildren<Props>> = ({ children, item }) => {
  const { isOver, drop } = useDroppableItemWrapper(item);

  return (
    <div
      ref={drop}
      className={classNames({ [styles.wrapperIsOver]: isOver })}
    >
      {children}
    </div>
  );
};

export default DroppableItemWrapper;
