import FoxKeyActions from '@/entities/source/@fox/components/KeysTab/components/FoxKeyActions';
import { useGetFoxKeys, useNavigateToFoxKey } from '@/entities/source/@fox/components/KeysTab/hooks';
import { FoxKey } from '@/entities/source/@fox/types';
import { useSource, useSourceScroll } from '@/entities/source/hooks';
import { FoxSource } from '@/entities/source/types';
import { FOX_KEYS_QUERY_KEY } from '@/shared/constants';
import { useParams, useReFetchInfiniteListOnTop } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';
import { FoxKeyDetails, FoxKeyRow } from '../components';

const DETAILS_TITLE = 'Деталі ключа';

const useMobileFoxKeysList = () => {
  const { sourceId } = useParams<SourceRouteParams>();
  const navigateToFoxKey = useNavigateToFoxKey();
  const source = useSource<FoxSource>();
  const isSourceOnline = source?.status === 'online';

  const { foxKeysList, getFoxKeyRef, isFoxKeysLoading, isFoxKeysFetching, refetchFoxKeys } = useGetFoxKeys({
    options: {
      enabled: isSourceOnline,
    },
  });
  const isLoading = isFoxKeysLoading || isFoxKeysFetching;

  const renderRow = (foxKey: FoxKey) => <FoxKeyRow foxKey={foxKey} />;

  const renderDetailsTitle = () => DETAILS_TITLE;
  const renderDetailsComponent = () => <FoxKeyDetails />;

  const renderActions = (foxKey: FoxKey, _onEdit: VoidFunction, onDelete: VoidFunction) => (
    <FoxKeyActions
      keyId={foxKey.id}
      onDelete={onDelete}
    />
  );

  const handleItemClick = (item: FoxKey) => {
    navigateToFoxKey({
      routeParams: {
        sourceId,
        tabEntityId: item.id,
      },
      options: { withAllQueryParams: true },
    });
  };

  const { handleScroll, scrollTopPosition } = useSourceScroll();
  useReFetchInfiniteListOnTop({ queryKey: [FOX_KEYS_QUERY_KEY, sourceId], scrollTopPosition });

  return {
    isSourceOnline,
    foxKeysList,
    getFoxKeyRef,
    isLoading,
    renderRow,
    renderDetailsTitle,
    renderDetailsComponent,
    renderActions,
    handleItemClick,
    handleScroll,
    refetchFoxKeys,
  };
};

export default useMobileFoxKeysList;
