import { FoxKey } from '@/entities/source/@fox/types';
import { ActionsDropdown } from '@/shared/components';
import { useFoxKeyActions } from './hooks';

interface Props {
  keyId: FoxKey['id'];
  onDelete?: VoidFunction;
}

const FoxKeyActions = ({ keyId, onDelete }: Props) => {
  const { dangerItems, isLoading } = useFoxKeyActions({ keyId, onDelete });

  return (
    <ActionsDropdown
      dangerItems={dangerItems}
      isLoading={isLoading}
      attachDesktopDropdownToButton
    />
  );
};

export default FoxKeyActions;
