import { useMemo } from 'react';
import { SourceTab } from '@/shared/constants';
import { useParams } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';
import useGetFoxKeys from './useGetFoxKeys';

const useFoxKey = () => {
  const { activeTab, tabEntityId } = useParams<SourceRouteParams>();
  const { foxKeysList } = useGetFoxKeys({
    options: {
      enabled: false,
    },
  });

  return useMemo(() => {
    if (activeTab !== SourceTab.Keys || !tabEntityId || foxKeysList.length === 0) return;

    return foxKeysList.find(({ id }) => id === tabEntityId);
  }, [foxKeysList, activeTab, tabEntityId]);
};

export default useFoxKey;
