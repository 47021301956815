import { useRef } from 'react';
import { InputRef } from 'antd';
import { useHover, useElementBounding, useElementSize } from '@reactuses/core';
import { FrequencyProps } from '../types';

interface Params extends Pick<FrequencyProps, 'isDisabled' | 'onFrequencyClick' | 'id'> {
  setIsInputActive: (value: boolean) => void;
}

const useWrappers = ({ setIsInputActive, isDisabled, onFrequencyClick, id }: Params) => {
  const inputWrapperRef = useRef<HTMLSpanElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<InputRef>(null);

  const [inputWrapperWidth] = useElementSize(inputWrapperRef, {
    box: 'border-box',
  });
  const { left: wrapperLeftPosition, right: wrapperRightPosition } = useElementBounding(wrapperRef, {
    windowScroll: false,
    immediate: true,
  });

  const isWrapperHovered = useHover(wrapperRef);

  const handleWrapperClick = () => {
    onFrequencyClick?.(id);

    if (isDisabled) return;

    setIsInputActive(true);
    inputRef.current?.focus();
  };

  return {
    inputWrapperRef,
    wrapperRef,
    inputRef,
    inputWrapperWidth,
    wrapperLeftPosition,
    wrapperRightPosition,
    isWrapperHovered,
    handleWrapperClick,
  };
};

export default useWrappers;
