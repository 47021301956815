import { useEffect } from 'react';
import { useGetFoxKeys, useNavigateToFoxKey } from '@/entities/source/@fox/components/KeysTab/hooks';
import { SourceTab } from '@/shared/constants';
import { useParams } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';

const useAutoNavigateToFirstFoxKey = () => {
  const { sourceId, activeTab, tabEntityId } = useParams<SourceRouteParams>();
  const navigateToFoxKey = useNavigateToFoxKey();
  const { foxKeysList } = useGetFoxKeys({
    options: {
      enabled: false,
    },
  });

  useEffect(() => {
    if (!sourceId || activeTab !== SourceTab.Keys || tabEntityId || foxKeysList.length === 0) return;

    navigateToFoxKey({
      routeParams: {
        sourceId,
        tabEntityId: foxKeysList[0].id,
      },
      options: { withAllQueryParams: true },
    });
  }, [sourceId, activeTab, tabEntityId, foxKeysList]);
};

export default useAutoNavigateToFirstFoxKey;
