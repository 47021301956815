import { PropsWithChildren } from 'react';
import { RouteLink } from '@/shared/components';
import styles from './styles.module.css';
import useSourceLink from './useSourceLink';

type Props = {
  sourceId: number;
  isCluster?: boolean;
};

const SourceLink = ({ children, ...restProps }: PropsWithChildren<Props>) => {
  const { sourceLinkHref, handleSourceClick } = useSourceLink(restProps);

  return (
    <RouteLink
      className={styles.link}
      href={sourceLinkHref}
      onClick={handleSourceClick}
      hasStopPropagation={false}
    >
      {children}
    </RouteLink>
  );
};

export default SourceLink;
