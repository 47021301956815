import { memo } from 'react';
import { ActionsDropdown } from '@/shared/components';
import { useFoxFrequencyActions } from './hooks';
import styles from './styles.module.css';
import { FoxFrequencyActionsProps } from './types';

const FoxFrequencyActions = memo((props: FoxFrequencyActionsProps) => {
  const { items, dangerItems } = useFoxFrequencyActions(props);

  return (
    <ActionsDropdown
      items={items}
      dangerItems={dangerItems}
      attachDesktopDropdownToButton
      wrapperClassName={styles.actions}
    />
  );
});

FoxFrequencyActions.displayName = 'FoxFrequencyActions';

export default FoxFrequencyActions;
