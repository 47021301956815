import { CSSProperties, memo } from 'react';
import { getFrequencyByPosition } from '@/entities/source/@fox/components/ScanningTab/components/Spectrogram/utils';
import styles from './styles.module.css';
import { HoveredFrequencyProps } from './types';

const HoveredFrequency = memo(
  ({ cursorPosition, top, canvasWidth, startFrequency, endFrequency }: HoveredFrequencyProps) => {
    if (!cursorPosition) return null;

    const wrapperStyle: CSSProperties = { left: cursorPosition - 25, top };

    const frequency = getFrequencyByPosition({
      canvasWidth,
      endFrequency,
      startFrequency,
      position: cursorPosition,
    });

    return (
      <span
        className={styles.wrapper}
        style={wrapperStyle}
      >
        {frequency}
      </span>
    );
  }
);

HoveredFrequency.displayName = 'HoveredFrequency';

export default HoveredFrequency;
