import { useMemo } from 'react';
import { Typography } from 'antd';
import { useGetUnits, useUnitsDictionary } from '@/entities/unit/hooks';
import { LABEL_SEPARATOR } from '@/shared/constants';
import { getUnitParentNames } from '../utils';

const FORM_ITEM_NAME = 'unitId';
const FORM_ITEM_LABEL = 'Підрозділ';
const SELECT_PLACEHOLDER = 'Оберіть підрозділ';
const SELECT_OPTION_FILTER_PROP = 'name';
const SELECT_VALIDATION = [{ required: true, message: 'Підрозділ не обраний' }];

const useUnitSelect = () => {
  const { isUnitsLoading } = useGetUnits();
  const unitsDictionary = useUnitsDictionary();

  const options = useMemo(
    () =>
      Object.values(unitsDictionary).map((unit) => {
        const unitParentNames = getUnitParentNames({ unitsDictionary, unitId: unit.id });
        return {
          label: (
            <>
              <Typography.Text>{unit.shortName}</Typography.Text>
              {unitParentNames.length > 0 && (
                <Typography.Text type="secondary">
                  {LABEL_SEPARATOR} {unitParentNames.join(LABEL_SEPARATOR)}
                </Typography.Text>
              )}
            </>
          ),
          value: unit.id,
          name: `${unit.fullShortName},${unit.fullName}`,
        };
      }),
    [unitsDictionary]
  );

  return {
    options,
    isLoading: isUnitsLoading,
    formItemName: FORM_ITEM_NAME,
    formItemLabel: FORM_ITEM_LABEL,
    selectPlaceholder: SELECT_PLACEHOLDER,
    selectOptionFilterProp: SELECT_OPTION_FILTER_PROP,
    selectValidation: SELECT_VALIDATION,
  };
};

export default useUnitSelect;
