import { Subscription, SubscriptionItem } from '@/entities/subscription/types';
import { Paginated, request } from '@/shared/api';
import { getSubscriptionsSchema } from './validationSchemas';

export const getSubscriptions = () =>
  request<Paginated<Subscription>>('/subscriptions', {
    validationSchema: getSubscriptionsSchema,
  });

export const postSubscriptions = (data: SubscriptionItem[]) =>
  request('/subscriptions', { method: 'POST', body: JSON.stringify(data) });

export const deactivateSubscription = (id: number) => request(`/subscriptions/${id}/inactive`, { method: 'PATCH' });
export const activateSubscription = (id: number) => request(`/subscriptions/${id}/active`, { method: 'PATCH' });

export const patchSubscription = ({ id, body }: { id: number; body: SubscriptionItem[] }) =>
  request(`/subscriptions/${id}`, { method: 'PATCH', body: JSON.stringify(body) });
