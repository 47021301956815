import { useUpdateEffect } from '@reactuses/core';
import { FOX_RECORDS_FILTERS_STORAGE_KEY } from '@/entities/source/@fox/constants';
import { FoxRecordsFilterLocalStorageParams } from '@/entities/source/@fox/types';
import { SourceTab } from '@/shared/constants';
import { useLocalStorageForLimitedDays, useParams } from '@/shared/hooks';
import { RangeValue, SourceRouteParams } from '@/shared/types';

interface Params {
  memoEncryptionType: string[];
  memoFrequency: string[];
  memoSourceAddress: string[];
  memoGroupAddress: string[];
  digitalSignal: string;
  analogSignal: string;
  decoded: string;
  notDecoded: string;
  memoDateRange: RangeValue;
}

const useSaveRecordsFilter = ({
  memoEncryptionType,
  memoFrequency,
  memoGroupAddress,
  memoSourceAddress,
  digitalSignal,
  analogSignal,
  decoded,
  notDecoded,
  memoDateRange,
}: Params) => {
  const { sourceId, activeTab } = useParams<SourceRouteParams>();
  const { getStorageValue, setStorageValue } = useLocalStorageForLimitedDays<
    Record<string, FoxRecordsFilterLocalStorageParams>
  >(FOX_RECORDS_FILTERS_STORAGE_KEY);

  useUpdateEffect(() => {
    if (!sourceId || activeTab !== SourceTab.Records) return;

    const storageValue = getStorageValue();

    setStorageValue({
      ...storageValue,
      [sourceId]: {
        encryptionType: memoEncryptionType,
        frequency: memoFrequency,
        sourceAddress: memoSourceAddress,
        groupAddress: memoGroupAddress,
        digitalSignal,
        analogSignal,
        decoded,
        notDecoded,
        dateRange:
          memoDateRange?.[0] && memoDateRange?.[1] ? [memoDateRange[0].valueOf(), memoDateRange[1].valueOf()] : null,
      },
    });
  }, [
    sourceId,
    memoFrequency,
    memoSourceAddress,
    memoGroupAddress,
    memoEncryptionType,
    memoDateRange,
    getStorageValue,
    setStorageValue,
    digitalSignal,
    analogSignal,
    decoded,
    notDecoded,
    activeTab,
  ]);
};

export default useSaveRecordsFilter;
