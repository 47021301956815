import { Row, Typography } from 'antd';
import styles from './styles.module.css';

const ENCRYPTION_TYPE = 'Тип шифрування';
const DECRYPTION_POSSIBILITY = 'Можливість дешифрування';
const LAST_ACTIVE_AT = 'Остання активність';

interface Props {
  fullName: string;
  possibilityOfDecryption?: string;
  lastActiveAt?: string;
}

const EncryptionTypeTooltipContent = ({ fullName, possibilityOfDecryption, lastActiveAt }: Props) => {
  return (
    <Row className={styles.tooltip}>
      <Typography.Text>{`${ENCRYPTION_TYPE}: ${fullName}`}</Typography.Text>
      {possibilityOfDecryption && (
        <Typography.Text>{`${DECRYPTION_POSSIBILITY}: ${possibilityOfDecryption}`}</Typography.Text>
      )}
      {lastActiveAt && <Typography.Text>{`${LAST_ACTIVE_AT}: ${lastActiveAt}`}</Typography.Text>}
    </Row>
  );
};

export default EncryptionTypeTooltipContent;
