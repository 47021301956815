import { z } from 'zod';
import { responseDataSchema, authDataSchema } from '@/shared/validationSchemas';

export const loginResponseSchema = z.object({
  phone: z.string(),
  is_phone_verified: z.boolean(),
});

export const signupResponseSchema = z.object({}).merge(loginResponseSchema);

export const googleAuthQRCodeSchema = z.object({
  qrCode: z.string(),
  applicationKey: z.string(),
});

const resetPasswordResponseSchema = z.object({
  expiry_date: z.string(),
});

export const changePasswordResponseSchema = z.object({
  success: z.boolean(),
});

export const loginSchema = responseDataSchema(loginResponseSchema);
export const signupSchema = responseDataSchema(signupResponseSchema);
export const generateGoogleAuthQRCodeSchema = responseDataSchema(googleAuthQRCodeSchema);
export const verifyGoogleAuthCodeSchema = responseDataSchema(authDataSchema);
export const resetPasswordSchema = responseDataSchema(resetPasswordResponseSchema);
export const changePasswordSchema = responseDataSchema(changePasswordResponseSchema);
