import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { generateFoxWaterfallAuth } from '@/entities/source/@fox/api';
import { FoxWaterfallAuth } from '@/entities/source/@fox/types';
import { ResponseData } from '@/shared/api';
import { FOX_WATERFALL_AUTH_QUERY_KEY } from '@/shared/constants';

interface Params {
  sourceId: string;
  options: UseQueryOptions<unknown, unknown, ResponseData<FoxWaterfallAuth>, string[]>;
}

const useGenerateFoxWaterfallAuth = ({ sourceId, options }: Params) => {
  const { data, remove } = useQuery({
    ...options,
    queryKey: [FOX_WATERFALL_AUTH_QUERY_KEY, sourceId],
    queryFn: () => generateFoxWaterfallAuth(sourceId),
  });

  return {
    foxWaterfallAuth: data?.data,
    removeFoxWaterfallAuth: remove,
  };
};

export default useGenerateFoxWaterfallAuth;
