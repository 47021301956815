import { useEffect, useState } from 'react';

export interface Params {
  sidebarDefaultWidth: number;
}

const useToggleSidebar = ({ sidebarDefaultWidth }: Params) => {
  const [forceResizeSidebarToWidth, setForceResizeSidebarToWidth] = useState<number | string | null>(null);
  const toggleSidebarVisibility = (isSidebarVisible: boolean) => {
    if (isSidebarVisible && forceResizeSidebarToWidth !== '0') {
      setForceResizeSidebarToWidth('0');
    } else if (!isSidebarVisible && forceResizeSidebarToWidth !== sidebarDefaultWidth) {
      setForceResizeSidebarToWidth(sidebarDefaultWidth);
    }
  };

  useEffect(() => {
    if (forceResizeSidebarToWidth === null) return;

    setForceResizeSidebarToWidth(null);
  }, [forceResizeSidebarToWidth]);

  return {
    forceResizeSidebarToWidth,
    toggleSidebarVisibility,
  };
};

export default useToggleSidebar;
