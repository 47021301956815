import { useMutation, MutateOptions } from '@tanstack/react-query';
import { saveFoxSettings as saveFoxSettingsApi, SaveFoxSettingsParams } from '@/entities/source/@fox/api';
import { SAVE_FOX_SETTINGS_MUTATION_KEY } from '@/shared/constants';

const useSaveFoxSettings = (options: MutateOptions<{}, unknown, SaveFoxSettingsParams> = {}) => {
  const { mutateAsync: saveFoxSettings, isLoading: isFoxSettingsSaving } = useMutation({
    ...options,
    mutationKey: [SAVE_FOX_SETTINGS_MUTATION_KEY],
    mutationFn: saveFoxSettingsApi,
  });

  return { saveFoxSettings, isFoxSettingsSaving };
};

export default useSaveFoxSettings;
