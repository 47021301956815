import { useState } from 'react';

const useCreateFoxKeyModal = () => {
  const [isCreateFoxKeyModalOpened, setIsCreateFoxKeyModalOpened] = useState<boolean>(false);

  const handleOpenCreateFoxKeyModal = () => {
    setIsCreateFoxKeyModalOpened(true);
  };

  const handleCloseCreateFoxKeyModal = () => {
    setIsCreateFoxKeyModalOpened(false);
  };

  return {
    isCreateFoxKeyModalOpened,
    handleOpenCreateFoxKeyModal,
    handleCloseCreateFoxKeyModal,
  };
};

export default useCreateFoxKeyModal;
