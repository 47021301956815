import { ScrollUpToRefresh, Table } from '@/shared/components';
import { useFoxKeysTable } from './hooks';

const FoxKeysTable = () => {
  const {
    columns,
    foxKeysList,
    handleScrollUpToReFetch,
    isFoxKeysLoading,
    isSourceOnline,
    handleScroll,
    handleRow,
    rowClassName,
  } = useFoxKeysTable();

  return (
    <ScrollUpToRefresh
      isScrollUpToRefreshEnabled={foxKeysList.length > 0}
      onRefresh={handleScrollUpToReFetch}
      onScroll={handleScroll}
    >
      <Table
        tableLayout="fixed"
        rowKey="id"
        rowClassName={rowClassName}
        dataSource={foxKeysList}
        loading={isSourceOnline && isFoxKeysLoading}
        onRow={handleRow}
        columns={columns}
        pagination={false}
      />
    </ScrollUpToRefresh>
  );
};

export default FoxKeysTable;
