import { LayerSpecification } from 'maplibre-gl';
import { MapLayer, MapSource } from '@/entities/map/constants';

export const cartoDarkLayers = [
  {
    id: MapLayer.CartoBg,
    type: 'raster',
    source: MapSource.CartoDark,
    paint: {
      'raster-contrast': -0.2,
    },
  },
  {
    id: 'waterway_label',
    type: 'symbol',
    source: MapSource.Carto,
    'source-layer': 'waterway',
    filter: ['all', ['has', 'name'], ['==', 'class', 'river']],
    layout: {
      'text-field': '{name:uk}',
      'text-font': [
        'Montserrat Regular Italic',
        'Open Sans Italic',
        'Noto Sans Regular',
        'HanWangHeiLight Regular',
        'NanumBarunGothic Regular',
      ],
      'symbol-placement': 'line',
      'symbol-spacing': 300,
      'symbol-avoid-edges': false,
      'text-size': {
        stops: [
          [9, 8],
          [10, 9],
        ],
      },
      'text-padding': 2,
      'text-pitch-alignment': 'auto',
      'text-rotation-alignment': 'auto',
      'text-offset': {
        stops: [
          [6, [0, -0.2]],
          [11, [0, -0.4]],
          [12, [0, -0.6]],
        ],
      },
      'text-letter-spacing': 0,
      'text-keep-upright': true,
    },
    paint: {
      'text-color': 'rgba(164, 164, 164, 1)',
      'text-halo-color': '#181818',
      'text-halo-width': 1,
    },
  },
  {
    id: 'watername_lake',
    type: 'symbol',
    source: MapSource.Carto,
    'source-layer': 'water_name',
    minzoom: 4,
    filter: ['all', ['has', 'name'], ['==', '$type', 'Point'], ['==', 'class', 'lake']],
    layout: {
      'text-field': {
        stops: [
          [8, '{name:uk}'],
          [13, '{name}'],
        ],
      },
      'symbol-placement': 'point',
      'text-size': {
        stops: [
          [13, 9],
          [14, 10],
          [15, 11],
          [16, 12],
          [17, 13],
        ],
      },
      'text-font': [
        'Montserrat Regular Italic',
        'Open Sans Italic',
        'Noto Sans Regular',
        'HanWangHeiLight Regular',
        'NanumBarunGothic Regular',
      ],
      'text-line-height': 1.2,
      'text-padding': 2,
      'text-allow-overlap': false,
      'text-ignore-placement': false,
      'text-pitch-alignment': 'auto',
      'text-rotation-alignment': 'auto',
    },
    paint: {
      'text-color': 'rgba(155, 155, 155, 1)',
      'text-halo-color': '#181818',
      'text-halo-width': 1,
      'text-halo-blur': 1,
    },
  },
  {
    id: 'watername_lake_line',
    type: 'symbol',
    source: MapSource.Carto,
    'source-layer': 'water_name',
    filter: ['all', ['has', 'name'], ['==', '$type', 'LineString']],
    layout: {
      'text-field': {
        stops: [
          [8, '{name:uk}'],
          [13, '{name}'],
        ],
      },
      'symbol-placement': 'line',
      'text-size': {
        stops: [
          [13, 9],
          [14, 10],
          [15, 11],
          [16, 12],
          [17, 13],
        ],
      },
      'text-font': [
        'Montserrat Regular Italic',
        'Open Sans Italic',
        'Noto Sans Regular',
        'HanWangHeiLight Regular',
        'NanumBarunGothic Regular',
      ],
      'symbol-spacing': 350,
      'text-pitch-alignment': 'auto',
      'text-rotation-alignment': 'auto',
      'text-line-height': 1.2,
    },
    paint: {
      'text-color': '#444',
      'text-halo-color': '#181818',
      'text-halo-width': 1,
      'text-halo-blur': 1,
    },
  },
  {
    id: 'place_hamlet',
    type: 'symbol',
    source: MapSource.Carto,
    'source-layer': 'place',
    minzoom: 12,
    maxzoom: 16,
    filter: ['any', ['==', 'class', 'neighbourhood'], ['==', 'class', 'hamlet']],
    layout: {
      'text-field': {
        stops: [
          [8, '{name:uk}'],
          [14, '{name}'],
        ],
      },
      'text-font': [
        'Montserrat Regular',
        'Open Sans Regular',
        'Noto Sans Regular',
        'HanWangHeiLight Regular',
        'NanumBarunGothic Regular',
      ],
      'text-size': {
        stops: [
          [13, 8],
          [14, 10],
          [16, 11],
        ],
      },
      'icon-image': '',
      'icon-offset': [16, 0],
      'text-anchor': 'center',
      'icon-size': 1,
      'text-max-width': 10,
      'text-keep-upright': true,
      'text-offset': [0.2, 0.2],
      'text-transform': {
        stops: [
          [12, 'none'],
          [14, 'uppercase'],
        ],
      },
    },
    paint: {
      'text-color': 'rgba(182, 180, 180, 1)',
      'icon-color': '#666',
      'icon-translate-anchor': 'map',
      'text-halo-color': 'rgba(53, 52, 52, 1)',
      'text-halo-width': 1,
    },
  },
  {
    id: 'place_suburbs',
    type: 'symbol',
    source: MapSource.Carto,
    'source-layer': 'place',
    minzoom: 12,
    maxzoom: 16,
    filter: ['all', ['==', 'class', 'suburb']],
    layout: {
      'text-field': {
        stops: [
          [8, '{name:uk}'],
          [13, '{name}'],
        ],
      },
      'text-font': [
        'Montserrat Regular',
        'Open Sans Regular',
        'Noto Sans Regular',
        'HanWangHeiLight Regular',
        'NanumBarunGothic Regular',
      ],
      'text-size': {
        stops: [
          [12, 9],
          [13, 10],
          [14, 11],
          [15, 12],
          [16, 13],
        ],
      },
      'icon-image': '',
      'icon-offset': [16, 0],
      'text-anchor': 'center',
      'icon-size': 1,
      'text-max-width': 10,
      'text-keep-upright': true,
      'text-offset': [0.2, 0.2],
      'text-transform': {
        stops: [
          [8, 'none'],
          [12, 'uppercase'],
        ],
      },
    },
    paint: {
      'text-color': '#666',
      'icon-color': '#666',
      'icon-translate-anchor': 'map',
      'text-halo-color': '#222',
      'text-halo-width': 1,
    },
  },
  {
    id: 'place_villages',
    type: 'symbol',
    source: MapSource.Carto,
    'source-layer': 'place',
    minzoom: 10,
    maxzoom: 16,
    filter: ['all', ['==', 'class', 'village']],
    layout: {
      'text-field': {
        stops: [
          [8, '{name:uk}'],
          [13, '{name}'],
        ],
      },
      'text-font': [
        'Montserrat Medium',
        'Open Sans Bold',
        'Noto Sans Regular',
        'HanWangHeiLight Regular',
        'NanumBarunGothic Regular',
      ],
      'text-size': {
        stops: [
          [10, 9],
          [12, 10],
          [13, 11],
          [14, 12],
          [16, 13],
        ],
      },
      'icon-image': '',
      'icon-offset': [16, 0],
      'text-anchor': 'center',
      'icon-size': 1,
      'text-max-width': 10,
      'text-keep-upright': true,
      'text-offset': [0.2, 0.2],
      'text-transform': 'none',
    },
    paint: {
      'text-color': 'rgba(154, 153, 153, 1)',
      'icon-color': '#666',
      'icon-translate-anchor': 'map',
      'text-halo-color': '#222',
      'text-halo-width': 1,
    },
  },
  {
    id: 'place_town',
    type: 'symbol',
    source: MapSource.Carto,
    'source-layer': 'place',
    minzoom: 8,
    maxzoom: 14,
    filter: ['all', ['==', 'class', 'town']],
    layout: {
      'text-field': {
        stops: [
          [8, '{name:uk}'],
          [13, '{name}'],
        ],
      },
      'text-font': [
        'Montserrat Medium',
        'Open Sans Bold',
        'Noto Sans Regular',
        'HanWangHeiLight Regular',
        'NanumBarunGothic Regular',
      ],
      'text-size': {
        stops: [
          [8, 10],
          [9, 10],
          [10, 11],
          [13, 14],
          [14, 15],
        ],
      },
      'icon-image': '',
      'icon-offset': [16, 0],
      'text-anchor': 'center',
      'icon-size': 1,
      'text-max-width': 10,
      'text-keep-upright': true,
      'text-offset': [0.2, 0.2],
      'text-transform': 'none',
    },
    paint: {
      'text-color': 'rgba(204, 208, 228, 1)',
      'icon-color': '#666',
      'icon-translate-anchor': 'map',
      'text-halo-color': '#222',
      'text-halo-width': 1,
    },
  },
  {
    id: 'place_country_2',
    type: 'symbol',
    source: MapSource.Carto,
    'source-layer': 'place',
    minzoom: 3,
    maxzoom: 10,
    filter: ['all', ['==', 'class', 'country'], ['>=', 'rank', 3], ['has', 'iso_a2']],
    layout: {
      'text-field': '{name:uk}',
      'text-font': [
        'Montserrat Medium',
        'Open Sans Bold',
        'Noto Sans Regular',
        'HanWangHeiLight Regular',
        'NanumBarunGothic Regular',
      ],
      'text-size': {
        stops: [
          [3, 10],
          [5, 11],
          [6, 12],
          [7, 13],
          [8, 14],
        ],
      },
      'text-transform': 'uppercase',
    },
    paint: {
      'text-color': {
        stops: [
          [3, 'rgba(157, 157, 157, 1)'],
          [5, 'rgba(114, 114, 114, 1)'],
          [6, 'rgba(112, 112, 112, 1)'],
        ],
      },
      'text-halo-color': '#111',
      'text-halo-width': 1,
    },
  },
  {
    id: 'place_country_1',
    type: 'symbol',
    source: MapSource.Carto,
    'source-layer': 'place',
    minzoom: 2,
    maxzoom: 7,
    filter: ['all', ['==', 'class', 'country'], ['<=', 'rank', 2]],
    layout: {
      'text-field': '{name:uk}',
      'text-font': [
        'Montserrat Medium',
        'Open Sans Bold',
        'Noto Sans Regular',
        'HanWangHeiLight Regular',
        'NanumBarunGothic Regular',
      ],
      'text-size': {
        stops: [
          [3, 11],
          [4, 12],
          [5, 13],
          [6, 14],
        ],
      },
      'text-transform': 'uppercase',
      'text-max-width': {
        stops: [
          [2, 6],
          [3, 6],
          [4, 9],
          [5, 12],
        ],
      },
    },
    paint: {
      'text-color': {
        stops: [
          [3, 'rgba(158, 182, 189, 1)'],
          [5, 'rgba(118, 126, 137, 1)'],
          [6, 'rgba(120, 141, 147, 1)'],
        ],
      },
      'text-halo-color': '#111',
      'text-halo-width': 1,
    },
  },
  {
    id: 'place_state',
    type: 'symbol',
    source: MapSource.Carto,
    'source-layer': 'place',
    minzoom: 5,
    maxzoom: 10,
    filter: ['all', ['==', 'class', 'state'], ['<=', 'rank', 4]],
    layout: {
      'text-field': '{name:uk}',
      'text-font': [
        'Montserrat Medium',
        'Open Sans Bold',
        'Noto Sans Regular',
        'HanWangHeiLight Regular',
        'NanumBarunGothic Regular',
      ],
      'text-size': {
        stops: [
          [5, 12],
          [7, 14],
        ],
      },
      'text-transform': 'uppercase',
      'text-max-width': 9,
    },
    paint: {
      'text-color': 'rgba(203, 230, 230, 1)',
      'text-halo-color': '#111',
      'text-halo-width': 0,
    },
  },
  {
    id: 'place_continent',
    type: 'symbol',
    source: MapSource.Carto,
    'source-layer': 'place',
    minzoom: 0,
    maxzoom: 2,
    filter: ['all', ['==', 'class', 'continent']],
    layout: {
      'text-field': '{name:uk}',
      'text-font': [
        'Montserrat Medium',
        'Open Sans Bold',
        'Noto Sans Regular',
        'HanWangHeiLight Regular',
        'NanumBarunGothic Regular',
      ],
      'text-transform': 'uppercase',
      'text-size': 14,
      'text-letter-spacing': 0.1,
      'text-max-width': 9,
      'text-justify': 'center',
      'text-keep-upright': false,
    },
    paint: {
      'text-color': 'rgba(135, 164, 179, 1)',
      'text-halo-color': '#111',
      'text-halo-width': 1,
    },
  },
  {
    id: 'place_city_r6',
    type: 'symbol',
    source: MapSource.Carto,
    'source-layer': 'place',
    minzoom: 8,
    maxzoom: 15,
    filter: ['all', ['==', 'class', 'city'], ['>=', 'rank', 6]],
    layout: {
      'text-field': {
        stops: [
          [8, '{name:uk}'],
          [13, '{name}'],
        ],
      },
      'text-font': [
        'Montserrat Medium',
        'Open Sans Bold',
        'Noto Sans Regular',
        'HanWangHeiLight Regular',
        'NanumBarunGothic Regular',
      ],
      'text-size': {
        stops: [
          [8, 12],
          [9, 13],
          [10, 14],
          [13, 17],
          [14, 20],
        ],
      },
      'icon-image': '',
      'icon-offset': [16, 0],
      'text-anchor': 'center',
      'icon-size': 1,
      'text-max-width': 10,
      'text-keep-upright': true,
      'text-offset': [0.2, 0.2],
      'text-transform': 'uppercase',
    },
    paint: {
      'text-color': 'rgba(168, 176, 180, 1)',
      'icon-color': '#666',
      'icon-translate-anchor': 'map',
      'text-halo-color': '#222',
      'text-halo-width': 1,
    },
  },
  {
    id: 'place_city_r5',
    type: 'symbol',
    source: MapSource.Carto,
    'source-layer': 'place',
    minzoom: 8,
    maxzoom: 15,
    filter: ['all', ['==', 'class', 'city'], ['>=', 'rank', 0], ['<=', 'rank', 5]],
    layout: {
      'text-field': {
        stops: [
          [8, '{name:uk}'],
          [13, '{name}'],
        ],
      },
      'text-font': [
        'Montserrat Medium',
        'Open Sans Bold',
        'Noto Sans Regular',
        'HanWangHeiLight Regular',
        'NanumBarunGothic Regular',
      ],
      'text-size': {
        stops: [
          [8, 14],
          [10, 16],
          [13, 19],
          [14, 22],
        ],
      },
      'icon-image': '',
      'icon-offset': [16, 0],
      'text-anchor': 'center',
      'icon-size': 1,
      'text-max-width': 10,
      'text-keep-upright': true,
      'text-offset': [0.2, 0.2],
      'text-transform': 'uppercase',
    },
    paint: {
      'text-color': 'rgba(211, 228, 236, 1)',
      'icon-color': '#666',
      'icon-translate-anchor': 'map',
      'text-halo-color': '#222',
      'text-halo-width': 1,
    },
  },
  {
    id: 'place_city_dot_r7',
    type: 'symbol',
    source: MapSource.Carto,
    'source-layer': 'place',
    minzoom: 6,
    maxzoom: 7,
    filter: ['all', ['==', 'class', 'city'], ['<=', 'rank', 7]],
    layout: {
      'text-field': '{name:uk}',
      'text-font': [
        'Montserrat Medium',
        'Open Sans Bold',
        'Noto Sans Regular',
        'HanWangHeiLight Regular',
        'NanumBarunGothic Regular',
      ],
      'text-size': 12,
      'icon-image': 'carto-dark:circle-11',
      'icon-offset': [16, 5],
      'text-anchor': 'right',
      'icon-size': 0.4,
      'text-max-width': 8,
      'text-keep-upright': true,
      'text-offset': [0.2, 0.2],
    },
    paint: {
      'text-color': 'rgba(174, 191, 207, 1)',
      'icon-color': 'rgba(94, 105, 106, 1)',
      'icon-translate-anchor': 'map',
      'text-halo-color': '#222',
      'text-halo-width': 1,
    },
  },
  {
    id: 'place_city_dot_r4',
    type: 'symbol',
    source: MapSource.Carto,
    'source-layer': 'place',
    minzoom: 5,
    maxzoom: 7,
    filter: ['all', ['==', 'class', 'city'], ['<=', 'rank', 4]],
    layout: {
      'text-field': '{name:uk}',
      'text-font': [
        'Montserrat Medium',
        'Open Sans Bold',
        'Noto Sans Regular',
        'HanWangHeiLight Regular',
        'NanumBarunGothic Regular',
      ],
      'text-size': 12,
      'icon-image': 'carto-dark:circle-11',
      'icon-offset': [16, 5],
      'text-anchor': 'right',
      'icon-size': 0.4,
      'text-max-width': 8,
      'text-keep-upright': true,
      'text-offset': [0.2, 0.2],
    },
    paint: {
      'text-color': 'rgba(233, 239, 246, 1)',
      'icon-color': '#666',
      'icon-translate-anchor': 'map',
      'text-halo-color': '#222',
      'text-halo-width': 1,
    },
  },
  {
    id: 'place_city_dot_r2',
    type: 'symbol',
    source: MapSource.Carto,
    'source-layer': 'place',
    minzoom: 4,
    maxzoom: 7,
    filter: ['all', ['==', 'class', 'city'], ['<=', 'rank', 2]],
    layout: {
      'text-field': '{name:uk}',
      'text-font': [
        'Montserrat Medium',
        'Open Sans Bold',
        'Noto Sans Regular',
        'HanWangHeiLight Regular',
        'NanumBarunGothic Regular',
      ],
      'text-size': 12,
      'icon-image': 'carto-dark:circle-11',
      'icon-offset': [16, 5],
      'text-anchor': 'right',
      'icon-size': 0.4,
      'text-max-width': 8,
      'text-keep-upright': true,
      'text-offset': [0.2, 0.2],
    },
    paint: {
      'text-color': 'rgba(175, 194, 217, 1)',
      'icon-color': 'rgba(131, 164, 189, 1)',
      'icon-translate-anchor': 'map',
      'text-halo-color': '#222',
      'text-halo-width': 1,
    },
  },
  {
    id: 'place_city_dot_z7',
    type: 'symbol',
    source: MapSource.Carto,
    'source-layer': 'place',
    minzoom: 7,
    maxzoom: 8,
    filter: ['all', ['!has', 'capital'], ['!in', 'class', 'country', 'state']],
    layout: {
      'text-field': '{name:uk}',
      'text-font': [
        'Montserrat Medium',
        'Open Sans Bold',
        'Noto Sans Regular',
        'HanWangHeiLight Regular',
        'NanumBarunGothic Regular',
      ],
      'text-size': 12,
      'icon-image': 'carto-dark:circle-11',
      'icon-offset': [16, 5],
      'text-anchor': 'right',
      'icon-size': 0.4,
      'text-max-width': 8,
      'text-keep-upright': true,
      'text-offset': [0.2, 0.2],
    },
    paint: {
      'text-color': 'rgba(160, 179, 191, 1)',
      'icon-color': 'rgba(113, 128, 147, 1)',
      'icon-translate-anchor': 'map',
      'text-halo-color': '#222',
      'text-halo-width': 1,
    },
  },
  {
    id: 'place_capital_dot_z7',
    type: 'symbol',
    source: MapSource.Carto,
    'source-layer': 'place',
    minzoom: 7,
    maxzoom: 8,
    filter: ['all', ['>', 'capital', 0]],
    layout: {
      'text-field': '{name:uk}',
      'text-font': [
        'Montserrat Medium',
        'Open Sans Bold',
        'Noto Sans Regular',
        'HanWangHeiLight Regular',
        'NanumBarunGothic Regular',
      ],
      'text-size': 12,
      'icon-image': 'carto-dark:circle-11',
      'icon-offset': [16, 5],
      'text-anchor': 'right',
      'icon-size': 0.4,
      'text-max-width': 8,
      'text-keep-upright': true,
      'text-offset': [0.2, 0.2],
      'text-transform': 'uppercase',
    },
    paint: {
      'text-color': 'rgba(177, 201, 214, 1)',
      'icon-color': '#666',
      'icon-translate-anchor': 'map',
      'text-halo-color': '#222',
      'text-halo-width': 1,
    },
  },
] as LayerSpecification[]; // it seems `stops` property is not supported in type, whereas CARTO keeps using it
