import { ThemeConfig, theme } from 'antd';
import { config } from '../common';
import { COLORS } from './constants';
import './light.css';

export const light: ThemeConfig = {
  ...config,
  algorithm: theme.defaultAlgorithm,
  token: {
    ...config.token,
    colorPrimary: COLORS.primary,
    colorLink: COLORS.primary,
  },
  components: {
    ...config.components,
    Tag: {
      ...config.components?.Tag,
      colorText: COLORS.white,
      colorTextDescription: COLORS.white,
      colorBorder: COLORS.primary,
      colorFillQuaternary: COLORS.primary,
    },
    Divider: {
      ...config.components?.Divider,
      colorSplit: COLORS.gray5,
    },
    Table: {
      ...config.components?.Table,
      headerColor: COLORS.gray7,
      headerBg: 'transparent',
      headerSplitColor: 'transparent',
    },
    Slider: {
      ...config.components?.Slider,
      trackBg: COLORS.bgSlider,
      trackHoverBg: COLORS.bgSliderHover,
      handleColor: COLORS.bgSlider,
      colorPrimaryBorderHover: COLORS.bgSliderHover,
      handleActiveColor: COLORS.bgSliderHover,
      dotActiveBorderColor: COLORS.gray4,
    },
    Tabs: {
      ...config.components?.Tabs,
      itemColor: COLORS.tabColor,
      itemSelectedColor: COLORS.tabActiveColor,
    },
  },
};
