import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { Paginated } from '@/shared/api';
import { NETWORKS_QUERY_KEY, RADIO_NETWORK_QUERY_KEY } from '@/shared/constants';
import { useAppDispatch } from '@/shared/hooks';
import { RadioNetwork, RadioNetworkItem } from '@/shared/types';
import { setScrollToNetworkId } from '../slices';
import { useChooseNetwork } from './useChooseNetwork';
import { useNetworkQueryParams } from './useNetworkQueryParams';

export const useSynchronizeNetwork = () => {
  const queryClient = useQueryClient();
  const { queryParams } = useNetworkQueryParams();
  const { chooseNetwork } = useChooseNetwork();
  const dispatch = useAppDispatch();

  const synchronizeNetwork = (updatedNetwork: RadioNetwork) => {
    queryClient.setQueryData<RadioNetworkItem>([RADIO_NETWORK_QUERY_KEY, updatedNetwork.id], updatedNetwork);
    queryClient.setQueryData<InfiniteData<Paginated<RadioNetwork>>>([NETWORKS_QUERY_KEY, queryParams], (data) => {
      return { pages: data?.pages.slice(0, 1) ?? [], pageParams: data?.pageParams.slice(0, 1) ?? [] };
    });
    const { pages = [] } =
      queryClient.getQueryData<InfiniteData<Paginated<RadioNetwork>>>([NETWORKS_QUERY_KEY, queryParams]) || {};
    const isOnFirstPage = !!pages?.[0].models.find((network) => network.id === updatedNetwork.id);

    chooseNetwork(updatedNetwork);

    if (isOnFirstPage) {
      dispatch(setScrollToNetworkId(updatedNetwork.id));
    } else {
      dispatch(setScrollToNetworkId(pages?.[0].models?.[0]?.id));
    }
  };

  return { synchronizeNetwork, chooseNetwork };
};
