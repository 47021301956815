import { Input, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { useFrequency } from './hooks';
import styles from './styles.module.css';
import { FrequencyProps as Props } from './types';

const Frequency = ({
  startFrequency,
  endFrequency,
  frequency,
  id,
  isActive,
  frequencyHeight,
  memoOnSave,
  memoOnDeleteFrequency,
  isDisabled,
  isAutoFocusEnabled,
  maxLeftPosition,
  maxRightPosition,
  onFrequencyClick,
  isFrequencySelected,
  isDigital,
}: Props) => {
  const {
    inputWrapperRef,
    isInputVisible,
    wrapperStyles,
    wrapperRef,
    inputPosition,
    inputRef,
    inputValue,
    isLoading,
    inputMaxLength,
    inputStatus,
    isOverflowLeft,
    isOverflowRight,
    handleInputKeyUp,
    handleInputBlur,
    handleWrapperClick,
    handleInputChange,
    handleInputFocus,
  } = useFrequency({
    startFrequency,
    endFrequency,
    frequency,
    id,
    isActive,
    frequencyHeight,
    memoOnSave,
    memoOnDeleteFrequency,
    isDisabled,
    isAutoFocusEnabled,
    maxLeftPosition,
    maxRightPosition,
    onFrequencyClick,
    isDigital,
  });

  if (frequency < startFrequency || frequency > endFrequency) return null;

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.active]: isInputVisible,
        [styles.selected]: isFrequencySelected,
        [styles.analog]: !isDigital,
        [styles.digital]: isDigital,
      })}
      style={wrapperStyles}
      ref={wrapperRef}
      onClick={handleWrapperClick}
      onKeyDown={handleWrapperClick}
    >
      <span
        className={classNames(styles.inputWrapper, {
          [styles.inActiveInputWrapper]: !isActive,
          [styles.inputWrapperOverflowLeft]: isOverflowLeft,
          [styles.inputWrapperOverflowRight]: isOverflowRight,
        })}
        style={inputPosition}
        ref={inputWrapperRef}
      >
        {isInputVisible ? (
          <Input
            status={inputStatus}
            maxLength={inputMaxLength}
            ref={inputRef}
            value={inputValue}
            className={styles.input}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onKeyUp={handleInputKeyUp}
            onChange={handleInputChange}
          />
        ) : null}
        {isLoading && (
          <Spin
            className={styles.spin}
            indicator={<LoadingOutlined spin />}
          />
        )}
      </span>
      {isActive && <div className={styles.coloredLine} />}
    </div>
  );
};

export default Frequency;
