import { FoxFrequencyStatus } from '@/entities/source/@fox/constants';
import { FoxFrequency } from '@/entities/source/@fox/types';
import { ResponseData } from '@/shared/api';

interface Params {
  staleData: ResponseData<FoxFrequency[]>;
  foxFrequencyId: FoxFrequency['id'];
  status: FoxFrequencyStatus;
}

const getFoxFrequenciesWithUpdateStatus = ({ staleData, foxFrequencyId, status }: Params) => {
  const { data } = staleData;

  const updatedData = data.map((foxFrequency) => {
    if (foxFrequency.id !== foxFrequencyId) return foxFrequency;
    return { ...foxFrequency, status };
  });

  return {
    ...staleData,
    data: updatedData,
  };
};

export default getFoxFrequenciesWithUpdateStatus;
