import Icon from '@ant-design/icons';
import classNames from 'classnames';
import { FoxFrequencyEncryptionType } from '@/entities/source/@fox/constants';
import { Decrypted } from '@/shared/assets';
import styles from '../styles.module.css';

const convertEncryptionTypesToOptions = (encryptionTypes?: FoxFrequencyEncryptionType[]) => {
  if (!encryptionTypes) return [];

  return encryptionTypes.map((encryptionType) => {
    switch (encryptionType) {
      case FoxFrequencyEncryptionType.TYT380Enhanced:
      case FoxFrequencyEncryptionType.Hytera40:
      case FoxFrequencyEncryptionType.Hytera128:
      case FoxFrequencyEncryptionType.Hytera256:
      case FoxFrequencyEncryptionType.BASIC:
      case FoxFrequencyEncryptionType.RC4:
      case FoxFrequencyEncryptionType.UNENCRYPTED:
        return {
          value: encryptionType,
          label: (
            <>
              <Icon
                component={Decrypted}
                className={classNames(styles.icon, styles.greenIcon)}
              />
              {encryptionType}
            </>
          ),
        };
      default:
        return {
          value: encryptionType,
          label: (
            <>
              <Icon
                component={Decrypted}
                className={classNames(styles.icon, styles.redIcon)}
              />
              {encryptionType}
            </>
          ),
        };
    }
  });
};

export default convertEncryptionTypesToOptions;
