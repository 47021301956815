import { ThemeConfig, theme } from 'antd';
import { config } from '../common';
import { COLORS } from './constants';
import './dark.css';

export const dark: ThemeConfig = {
  ...config,
  algorithm: theme.darkAlgorithm,
  token: {
    ...config.token,
    colorPrimary: COLORS.primary,
    colorText: COLORS.gray5,
    colorTextPlaceholder: COLORS.gray7,
    colorLink: COLORS.primary,
    colorLinkHover: COLORS.linkHover,
    colorIcon: COLORS.gray6,
    colorBgContainer: COLORS.bgContainer,
    colorBgElevated: COLORS.bgElevated,
    colorBorder: COLORS.border,
    colorSplit: COLORS.border,
  },
  components: {
    ...config.components,
    Tag: {
      ...config.components?.Tag,
      colorText: COLORS.white,
      colorTextDescription: COLORS.white,
      colorBorder: COLORS.primary,
      colorFillQuaternary: COLORS.primary,
    },
    Divider: {
      ...config.components?.Divider,
      colorSplit: COLORS.gray5,
    },
    Table: {
      ...config.components?.Table,
      headerColor: COLORS.gray6,
      headerBg: 'transparent',
      headerSplitColor: 'transparent',
      borderColor: COLORS.border,
    },
    Button: {
      ...config.components?.Button,
      defaultBg: 'transparent',
      defaultBorderColor: COLORS.gray8,
      primaryColor: COLORS.gray5,
    },
    Input: {
      ...config.components?.Input,
      colorBgContainer: COLORS.bgPanel,
      colorIcon: COLORS.gray6,
      colorIconHover: COLORS.white,
    },
    Checkbox: {
      ...config.components?.Checkbox,
      colorBgContainer: COLORS.bgPanel,
    },
    Select: {
      ...config.components?.Select,
      colorBgContainer: COLORS.bgPanel,
      colorBgElevated: COLORS.bgPopover,
      colorIcon: COLORS.gray6,
      colorIconHover: COLORS.white,
      optionSelectedBg: COLORS.bgItemSelected,
    },
    DatePicker: {
      ...config.components?.DatePicker,
      colorBgContainer: COLORS.bgPanel,
      colorBgElevated: COLORS.bgPopover,
      colorIcon: COLORS.gray6,
      colorIconHover: COLORS.white,
      cellActiveWithRangeBg: COLORS.bgItemSelected,
    },
    Popover: {
      ...config.components?.Popover,
      colorBgElevated: COLORS.bgPopover,
    },
    Dropdown: {
      ...config.components?.Dropdown,
      colorBgElevated: COLORS.bgPopover,
    },
    Spin: {
      ...config.components?.Spin,
      colorBgContainer: 'transparent',
    },
    Tree: {
      ...config.components?.Tree,
      colorBgContainer: 'transparent',
      nodeSelectedBg: COLORS.bgItemSelected,
    },
    Slider: {
      ...config.components?.Slider,
      trackBg: COLORS.bgSlider,
      trackHoverBg: COLORS.bgSliderHover,
      handleColor: COLORS.bgSlider,
      colorPrimaryBorderHover: COLORS.bgSliderHover,
      handleActiveColor: COLORS.bgSliderHover,
      dotActiveBorderColor: COLORS.gray4,
    },
    Tabs: {
      ...config.components?.Tabs,
      itemColor: COLORS.gray6,
      itemSelectedColor: COLORS.gray4,
    },
  },
};
