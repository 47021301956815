import { ActionsDropdown } from '@/shared/components';
import { useRecordActions } from './hooks';

interface Props {
  id: string;
  fileName: string;
  onDelete?: () => void;
}

const RecordActions = ({ id, fileName, onDelete }: Props) => {
  const { items, dangerItems, isLoading } = useRecordActions(id, fileName, onDelete);

  return (
    <ActionsDropdown
      items={items}
      dangerItems={dangerItems}
      isLoading={isLoading}
    />
  );
};

export default RecordActions;
