import { CreateFoxKeyFormField } from '@/entities/source/@fox/types';

export const FOX_KEY_GROUP_ID_FIELD_NAME: CreateFoxKeyFormField = 'groupId';
export const FOX_KEY_KEY_ID_FIELD_NAME: CreateFoxKeyFormField = 'keyId';
export const FOX_KEY_TARGET_FREQUENCY_FIELD_NAME: CreateFoxKeyFormField = 'targetFrequencyId';
export const FOX_KEY_ENCRYPTION_FIELD_NAME: CreateFoxKeyFormField = 'encryption';
export const FOX_KEY_BODY_FIELD_NAME: CreateFoxKeyFormField = 'body';

export const FOX_KEY_GROUP_ID_LABEL = 'Group ID';
export const FOX_KEY_KEY_ID_LABEL = 'Key ID';
export const FOX_KEY_TARGET_FREQUENCY_LABEL = 'Використовувати на частоті';
export const FOX_KEY_ENCRYPTION_LABEL = 'Алгоритм шифрування';
export const FOX_KEY_BODY_LABEL = 'Key body';

export const FOX_KEY_TARGET_FREQUENCY_PLACEHOLDER = 'Вибрати частоту';
export const FOX_KEY_ENCRYPTION_PLACEHOLDER = 'Обрати алгоритм шифрування';

export const ADD_KEY = 'Додати ключ';

export const FOX_KEY_TARGET_FREQUENCY_OPTION_FILTER_PROP = 'label';
