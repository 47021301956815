export const LPD_SET = new Set([
  '433.0750',
  '433.1000',
  '433.1250',
  '433.1500',
  '433.1750',
  '433.2000',
  '433.2250',
  '433.2500',
  '433.2750',
  '433.3000',
  '433.3250',
  '433.3500',
  '433.3750',
  '433.4000',
  '433.4250',
  '433.4500',
  '433.4750',
  '433.5000',
  '433.5250',
  '433.5500',
  '433.5750',
  '433.6000',
  '433.6250',
  '433.6500',
  '433.6750',
  '433.7000',
  '433.7250',
  '433.7500',
  '433.7750',
  '433.8000',
  '433.8250',
  '433.8500',
  '433.8750',
  '433.9000',
  '433.9250',
  '433.9500',
  '433.9750',
  '434.0000',
  '434.0250',
  '434.0500',
  '434.0750',
  '434.1000',
  '434.1250',
  '434.1500',
  '434.1750',
  '434.2000',
  '434.2250',
  '434.2500',
  '434.2750',
  '434.3000',
  '434.3250',
  '434.3500',
  '434.3750',
  '434.4000',
  '434.4250',
  '434.4500',
  '434.4750',
  '434.5000',
  '434.5250',
  '434.5500',
  '434.5750',
  '434.6000',
  '434.6250',
  '434.6500',
  '434.6750',
  '434.7000',
  '434.7250',
  '434.7500',
  '434.7750',
]);

export const PMR_SET = new Set([
  '446.0000',
  '446.0062',
  '446.0187',
  '446.0312',
  '446.0437',
  '446.0562',
  '446.0687',
  '446.0812',
  '446.0937',
  '446.1062',
  '446.1187',
  '446.1312',
  '446.1437',
  '446.1562',
  '446.1687',
  '446.1812',
  '446.1937',
]);
