import MapContainer from '@/entities/map/components/MapContainer';
import { MapSource } from '@/entities/map/constants';
import { MapInitOptions } from '@/entities/map/types';
import { WatermarkRenderProp } from '@/shared/types';
import { MapContent } from './components';
import styles from './styles.module.css';
import useModalMap from './useModalMap';

const MAP_OPTIONS: MapInitOptions = {
  zoom: 11,
  interactive: false,
};

const ModalMap = ({ renderWatermark }: WatermarkRenderProp) => {
  const { modalPosition, pointCoordinates } = useModalMap();

  if (!modalPosition || !pointCoordinates) return null;

  return (
    <div
      className={styles.modalMapWrapper}
      style={modalPosition}
    >
      <MapContainer
        className={styles.modalMap}
        tileSource={MapSource.Google}
        mapOptions={MAP_OPTIONS}
        renderWatermark={renderWatermark}
      >
        <MapContent />
      </MapContainer>
    </div>
  );
};

export default ModalMap;
