import { ReactNode, useEffect } from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';

const APP_NAME = 'Стрибо';

type Props = {
  title: string;
  children: ReactNode;
};

export const TitleProvider = ({ title, children }: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <title>
          {title} - {APP_NAME}
        </title>
      </Helmet>
      {children}
    </HelmetProvider>
  );
};
