const validateRange = (minValue: string, maxValue: string) => (_: any, value: string) => {
  const min = parseFloat(minValue);
  const max = parseFloat(maxValue);
  const num = parseFloat(value);
  const isInRange = num >= min && num <= max;

  if (isInRange) {
    return Promise.resolve();
  }

  return Promise.reject(new Error(`Частота має бути в межах ${minValue} та ${maxValue}`));
};

export default validateRange;
