import { FrequenciesSelectedBoundaries } from '../types';
import getRoundedFloat from './getRoundedFloat';

interface Params {
  frequenciesSelectedBoundaries: FrequenciesSelectedBoundaries;
  startFrequency: number;
  endFrequency: number;
}

const getZoomData = ({ frequenciesSelectedBoundaries, startFrequency, endFrequency }: Params) => {
  const { startSelectedFrequency, endSelectedFrequency } = frequenciesSelectedBoundaries;

  const frequencyOnePercentValue = (endFrequency - startFrequency) / 100;

  const startZoomPercent = getRoundedFloat((startSelectedFrequency - startFrequency) / frequencyOnePercentValue);
  const endZoomPercent = getRoundedFloat((endSelectedFrequency - startFrequency) / frequencyOnePercentValue);

  return {
    startZoomFrequency: startSelectedFrequency,
    endZoomFrequency: endSelectedFrequency,
    startZoomPercent,
    endZoomPercent,
  };
};

export default getZoomData;
