import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/shared/types';
import { NotesState } from './types';

const initialState: NotesState = {
  radioNetworkId: '',
  scrollToNoteId: null,
};

export const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    setRadioNetworkId: (state: NotesState, action: PayloadAction<NotesState['radioNetworkId']>) => {
      state.radioNetworkId = action.payload;
    },
    setScrollToNoteId: (state: NotesState, action: PayloadAction<NotesState['scrollToNoteId']>) => {
      state.scrollToNoteId = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { setRadioNetworkId, setScrollToNoteId, resetState } = notesSlice.actions;

export const selectNotesRadioNetworkId = (state: RootState) => {
  const {
    notes: { radioNetworkId },
  } = state;
  return radioNetworkId;
};

export const selectScrollToNoteId = (state: RootState) => {
  const {
    notes: { scrollToNoteId },
  } = state;
  return scrollToNoteId;
};

export default notesSlice.reducer;
