import { useEffect } from 'react';
import { theme } from 'antd';
import { NEUTRAL_COLOR_PALETTE } from '@/shared/constants';

export const InsertRootCssVariables = () => {
  const { token } = theme.useToken();
  const elemId = 'theme';

  // makes available to use Ant Design theme token properties as CSS variables
  useEffect(() => {
    const foundElement = document.getElementById(elemId);
    const styleElement = document.createElement('style');
    const cssVariables: string[] = [];

    Object.entries({ ...NEUTRAL_COLOR_PALETTE, ...token }).forEach(([key, value]) => {
      // excludes keys responsible for components and private keys
      if (!key.includes('_') && !key.includes('-') && ['string', 'number'].includes(typeof value)) {
        cssVariables.push(`--${key}:${value}`);
      }
    });

    styleElement.id = elemId;
    styleElement.append(`:root{${cssVariables.join(';')}}`);

    if (foundElement) {
      foundElement.replaceWith(styleElement);
    } else {
      document.head.append(styleElement);
    }
  }, [token]);

  return null;
};
