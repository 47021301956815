import { useUpdateEffect } from '@reactuses/core';
import { FOX_SCANNING_FILTERS_STORAGE_KEY } from '@/entities/source/@fox/constants';
import { FoxScanningFilterLocalStorageParams } from '@/entities/source/@fox/types';
import { SourceTab } from '@/shared/constants';
import { useLocalStorageForLimitedDays, useParams } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';

interface Params {
  active: string;
  deactivated: string;
  digital: string;
  analog: string;
  memoEncryptionType: string[];
}

const useSaveScanningFilter = ({ active, deactivated, digital, analog, memoEncryptionType }: Params) => {
  const { sourceId, activeTab } = useParams<SourceRouteParams>();
  const { getStorageValue, setStorageValue } = useLocalStorageForLimitedDays<
    Record<string, FoxScanningFilterLocalStorageParams>
  >(FOX_SCANNING_FILTERS_STORAGE_KEY);

  useUpdateEffect(() => {
    if (!sourceId || activeTab !== SourceTab.Scanning) return;

    const storageValue = getStorageValue();
    const sourceScanningPersistedParams = storageValue?.[sourceId];

    setStorageValue({
      ...storageValue,
      [sourceId]: {
        ...sourceScanningPersistedParams,
        active,
        deactivated,
        digital,
        analog,
        encryptionType: memoEncryptionType,
      },
    });
  }, [active, deactivated, digital, analog, setStorageValue, sourceId, activeTab, getStorageValue, memoEncryptionType]);
};

export default useSaveScanningFilter;
