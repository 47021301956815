import { useDragLayer } from 'react-dnd';

const useIsDragging = () => {
  const { isDragging } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
  }));

  return isDragging;
};

export default useIsDragging;
