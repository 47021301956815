import { useQuery } from '@tanstack/react-query';
import { CATEGORIES_QUERY_KEY } from '@/shared/constants';
import { getCategories } from '../api';

const CACHING_DURATION = 300000;

export const useGetCategories = () => {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: [CATEGORIES_QUERY_KEY],
    queryFn: getCategories,
    cacheTime: CACHING_DURATION,
  });

  return { categories: data?.data || [], isCategoriesLoading: isLoading, isCategoriesFetching: isFetching };
};
