import { RefObject } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import type { BaseSelectRef } from 'rc-select';
import styles from './styles.module.css';

type Props = {
  selectRef: RefObject<BaseSelectRef>;
};

const ClearIcon = ({ selectRef }: Props) => {
  // huck to fix the rc-select issue on iOS devices
  // @see https://github.com/ant-design/ant-design/issues/28917
  // @see https://github.com/react-component/select/issues/945
  const handleClickCapture = () => selectRef.current?.blur();
  const handleClick = () => setTimeout(() => selectRef.current?.focus(), 300);

  return (
    <CloseOutlined
      className={styles.clearIcon}
      onClickCapture={handleClickCapture}
      onClick={handleClick}
    />
  );
};

export default ClearIcon;
