import { useState } from 'react';
import { QueryKey } from '@tanstack/react-query';
import { useClearInfiniteListAndRefetch } from './reactQueryHelperHooks';

const useScrollUpToReFetch = (queryKey: QueryKey) => {
  const [isReFetchingByScrollUp, setIsReFetchingByScrollUp] = useState(false);
  const handleClearInfiniteListAndRefetch = useClearInfiniteListAndRefetch(queryKey);
  const handleScrollUpToReFetch = async () => {
    setIsReFetchingByScrollUp(true);
    try {
      await handleClearInfiniteListAndRefetch();
    } finally {
      setIsReFetchingByScrollUp(false);
    }
  };

  return {
    isReFetchingByScrollUp,
    handleScrollUpToReFetch,
  };
};

export default useScrollUpToReFetch;
