import { ReactNode, ReactElement } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { MobileDetailsManagerState } from '@/shared/constants';
import { useAppDispatch, useIsMobile } from '@/shared/hooks';
import { setActiveMobileDetailsManagerState } from '@/shared/slices';

type Params = {
  title?: ReactNode;
  mobileIcon: ReactElement;
  onClick?: VoidFunction;
  isDetailsManagementEnabled?: boolean;
};

export const useAddListItemButton = ({ title, mobileIcon, onClick, isDetailsManagementEnabled = true }: Params) => {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();

  const handleClick = () => {
    if (isMobile && isDetailsManagementEnabled) {
      dispatch(setActiveMobileDetailsManagerState(MobileDetailsManagerState.Create));
    }
    onClick?.();
  };

  return {
    buttonText: isMobile ? null : title,
    handleClick,
    buttonIcon: isMobile ? mobileIcon : <PlusOutlined />,
    buttonType: isMobile ? 'default' : 'link',
  };
};
