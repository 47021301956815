import { useMemo } from 'react';
import { FoxScanningQueryParam } from '@/shared/constants';
import { useSearchParam } from '@/shared/hooks';
import { queryParamsService } from '@/shared/services';

const useFiltersManager = () => {
  const [activeStatus, setActiveStatus] = useSearchParam(FoxScanningQueryParam.Active);
  const [deactivatedStatus, setDeactivatedStatus] = useSearchParam(FoxScanningQueryParam.Deactivated);
  const [digitalSignalType, setDigitalSignalType] = useSearchParam(FoxScanningQueryParam.Digital);
  const [analogSignalType, setAnalogSignalType] = useSearchParam(FoxScanningQueryParam.Analog);
  const [encryptionType, setEncryptionType] = useSearchParam(FoxScanningQueryParam.EncryptionType);

  const memoEncryptionType = useMemo(
    () => queryParamsService.convertQueryParamToArray(encryptionType),
    [encryptionType]
  );

  const toggleActiveStatus = () => {
    setActiveStatus(activeStatus ? '' : 'true');
  };

  const toggleDeactivatedStatus = () => {
    setDeactivatedStatus(deactivatedStatus ? '' : 'true');
  };

  const toggleDigitalSignalType = () => {
    setDigitalSignalType(digitalSignalType ? '' : 'true');
  };

  const toggleAnalogSignalType = () => {
    setAnalogSignalType(analogSignalType ? '' : 'true');
  };

  const handleEncryptionTypeChange = (value: string[]) => {
    setEncryptionType(queryParamsService.convertArrayToQueryParam(value));
  };

  return {
    activeStatus,
    deactivatedStatus,
    toggleActiveStatus,
    toggleDeactivatedStatus,
    digitalSignalType,
    analogSignalType,
    toggleDigitalSignalType,
    toggleAnalogSignalType,
    memoEncryptionType,
    handleEncryptionTypeChange,
  };
};

export default useFiltersManager;
