import { useEffect } from 'react';
import { SourceTab } from '@/shared/constants';
import { useParams } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';

type Params = {
  navigateToTab: (tab: SourceTab) => void;
  defaultActiveTab: SourceTab;
  isFoxSource: boolean;
};

const useTabRouteGuard = ({ navigateToTab, defaultActiveTab, isFoxSource }: Params) => {
  const { activeTab } = useParams<SourceRouteParams>();

  useEffect(() => {
    if (!activeTab) return;

    if (isFoxSource ? !Object.values(SourceTab).includes(activeTab) : activeTab !== SourceTab.Settings) {
      navigateToTab(defaultActiveTab);
    }
  }, [activeTab, defaultActiveTab, isFoxSource]);
};

export default useTabRouteGuard;
