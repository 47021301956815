import { useUnmount } from '@reactuses/core';
import { useIsMutating } from '@tanstack/react-query';
import { useIsFrequencyDragging } from '@/entities/frequency';
import {
  setIsNetworkListUpdating,
  useGetRadioNetworks,
  useNetworkQueryParams,
  useSelectedRadioNetwork,
} from '@/entities/network';
import { NETWORKS_QUERY_KEY, UPDATE_NETWORK_MUTATION_KEY } from '@/shared/constants';
import { useAppDispatch, useScrollUpToReFetch } from '@/shared/hooks';
import useNetworkListScrolling from './useNetworkListScrolling';

const useNetworkList = () => {
  const { queryParams } = useNetworkQueryParams();
  const { selectedRadioNetwork } = useSelectedRadioNetwork();
  const { isReFetchingByScrollUp, handleScrollUpToReFetch } = useScrollUpToReFetch([NETWORKS_QUERY_KEY, queryParams]);
  const isMutatingNetwork = useIsMutating({ mutationKey: [UPDATE_NETWORK_MUTATION_KEY] });
  const isFrequencyDragging = useIsFrequencyDragging();
  const dispatch = useAppDispatch();

  const {
    networkListWrapperRef,
    isOnTopOfNetworkList,
    scrollToRowIndex,
    handleListScroll,
    handleMouseMove,
    stopNetworkListScrolling,
  } = useNetworkListScrolling();

  const { networks, isNetworksFetching, forceFetchNextPage } = useGetRadioNetworks({
    queryParams,
    isFrequencyDragging,
  });

  const isSpinnerVisible = (isNetworksFetching || Boolean(isMutatingNetwork)) && !isReFetchingByScrollUp;

  useUnmount(() => {
    dispatch(setIsNetworkListUpdating(false));
  });

  return {
    networks,
    isSpinnerVisible,
    networkListWrapperRef,
    isOnTopOfNetworkList,
    selectedRadioNetworkId: selectedRadioNetwork?.id,
    scrollToRowIndex,
    handleListScroll,
    forceFetchNextPage,
    handleMouseMove,
    handleScrollUpToReFetch,
    stopNetworkListScrolling,
  };
};

export default useNetworkList;
