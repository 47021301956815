import { FileAddOutlined } from '@ant-design/icons';
import { NEW_FREQUENCY } from '@/entities/source/@fox/components/ScanningTab/constants';
import { AddListItemButton } from '@/shared/components';

interface Props {
  className?: string;
  onClick?: VoidFunction;
}

const AddNewFoxFrequencyButton = ({ className, onClick }: Props) => {
  return (
    <AddListItemButton
      className={className}
      mobileIcon={<FileAddOutlined />}
      onClick={onClick}
    >
      {NEW_FREQUENCY}
    </AddListItemButton>
  );
};

export default AddNewFoxFrequencyButton;
