import { useEffect, useState } from 'react';
import { Col, Form, Input, Modal, Radio, Row, Space, Typography } from 'antd';
import { USER_NAME, USER_NAME_REQUIRED, USER_ROLE, PHONE_NUMBER } from '@/entities/user/constants';
import { PhoneField } from '@/shared/components';
import { COPIES, RoleName, ROLES_DESCRIPTION } from '@/shared/constants';
import { useRenderPropsContext } from '@/shared/context';
import { useAppSelector } from '@/shared/hooks';
import { selectCurrentUser } from '@/shared/slices';
import { AccessGroupRenderProps } from '@/shared/types';
import styles from './styles.module.css';

const GROUP_NAME = 'accessGroupId';
const GROUP_LABEL = 'Виберіть групу доступу';
const ADD_USER_TO_GROUP = 'Додати користувача';

const ROLES = [
  {
    value: RoleName.OPERATOR,
    ...ROLES_DESCRIPTION[RoleName.OPERATOR],
  },
  {
    value: RoleName.SUPERVISOR,
    ...ROLES_DESCRIPTION[RoleName.SUPERVISOR],
  },
];

interface FormData {
  name: string;
  phone: string;
  accessGroupId: string;
  role: RoleName;
}

type Props = {
  isOpen?: boolean;
  close: () => void;
  accessGroupId?: string;
};

export const AddUserToGroupModal = ({ isOpen = false, close, accessGroupId }: Props) => {
  const { renderAccessGroupSelect } = useRenderPropsContext<AccessGroupRenderProps>();
  const [form] = Form.useForm<FormData>();
  const [isDisabled, setDisabled] = useState(true);
  const currentUser = useAppSelector(selectCurrentUser);

  const handleClose = () => {
    close();
    form.resetFields();
  };

  const onFinish = async () => {
    handleClose();
  };

  const validateFormFields = () => setDisabled(form.getFieldsError().some((field) => field.errors.length > 0));

  useEffect(() => {
    if (!currentUser) return;

    // set default access group
    form.setFieldValue('accessGroupId', accessGroupId || currentUser.group?.id);
  }, [accessGroupId, currentUser, form]);

  return (
    <Modal
      className={styles.modal}
      destroyOnClose
      // prerender modal's child component. it's needed to have access to form object
      forceRender
      title={ADD_USER_TO_GROUP}
      open={isOpen}
      okButtonProps={{ disabled: isDisabled }}
      okText={COPIES.ADD}
      cancelText={COPIES.CANCEL}
      onOk={form.submit}
      onCancel={handleClose}
      centered
    >
      <Form<FormData>
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFieldsChange={validateFormFields}
      >
        <Form.Item
          name="name"
          label={USER_NAME}
          rules={[{ required: true, message: USER_NAME_REQUIRED }]}
        >
          <Input />
        </Form.Item>
        <PhoneField
          name="phone"
          label={PHONE_NUMBER}
          required
        />
        {renderAccessGroupSelect?.({
          name: GROUP_NAME,
          label: GROUP_LABEL,
        })}
        <Form.Item label={USER_ROLE}>
          <Radio.Group
            name="role"
            defaultValue={RoleName.OPERATOR}
          >
            <Space direction="vertical">
              {ROLES.map(({ value, label, description }) => (
                <div key={value}>
                  <Radio value={value}>{label}</Radio>
                  <Row className={styles.roleDescription}>
                    <Col>
                      <Typography>{description}</Typography>
                    </Col>
                  </Row>
                </div>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};
