import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/shared/types';
import { NavigationMenuState } from './types';

const NAV_MENU_COLLAPSE_STATE_LOCALSTORAGE_KEY = 'navSidebarCollapseState';

const persistedIsNavigationSidebarCollapsed = localStorage.getItem(NAV_MENU_COLLAPSE_STATE_LOCALSTORAGE_KEY);
const isNavigationSidebarCollapsed = persistedIsNavigationSidebarCollapsed
  ? JSON.parse(persistedIsNavigationSidebarCollapsed)
  : false;

const initialState: NavigationMenuState = {
  isNavigationSidebarCollapsed,
  navigationDrawer: null,
};

export const navigationMenuSlice = createSlice({
  name: 'navigationMenu',
  initialState,
  reducers: {
    setIsNavigationSidebarCollapsed: (state: NavigationMenuState, action: PayloadAction<boolean>) => {
      state.isNavigationSidebarCollapsed = action.payload;
      localStorage.setItem(NAV_MENU_COLLAPSE_STATE_LOCALSTORAGE_KEY, JSON.stringify(action.payload));
    },
    setNavigationDrawer: (
      state: NavigationMenuState,
      action: PayloadAction<NavigationMenuState['navigationDrawer']>
    ) => {
      state.navigationDrawer = action.payload;
    },
  },
});

export const { setIsNavigationSidebarCollapsed, setNavigationDrawer } = navigationMenuSlice.actions;

export const selectIsNavigationSidebarCollapsed = (state: RootState) =>
  state.navigationMenu.isNavigationSidebarCollapsed;

export const selectNavigationDrawer = (state: RootState) => state.navigationMenu.navigationDrawer;

export default navigationMenuSlice.reducer;
