import { useQueryClient } from '@tanstack/react-query';
import { useFoxFrequenciesQueryParams } from '@/entities/source/@fox/components/ScanningTab/hooks';
import { FoxFrequency } from '@/entities/source/@fox/types';
import { Source } from '@/entities/source/types';
import { ResponseData } from '@/shared/api';
import { FOX_FREQUENCIES_QUERY_KEY } from '@/shared/constants';
import { UseFoxFrequencyActionsParams } from '../types';
import { getFoxFrequenciesWithUpdateStatus } from '../utils';

interface UpdateSourceStatusParams extends Pick<UseFoxFrequencyActionsParams, 'foxFrequencyId' | 'foxFrequencyStatus'> {
  sourceId?: Source['id'];
}

const useFoxFrequenciesWithUpdateStatus = () => {
  const queryClient = useQueryClient();
  const queryParams = useFoxFrequenciesQueryParams();

  return ({ sourceId, foxFrequencyId, foxFrequencyStatus }: UpdateSourceStatusParams) => {
    queryClient.setQueryData<ResponseData<FoxFrequency[]>>(
      [FOX_FREQUENCIES_QUERY_KEY, sourceId, queryParams],
      (staleData) => {
        if (!staleData) return;

        return getFoxFrequenciesWithUpdateStatus({ staleData, foxFrequencyId, status: foxFrequencyStatus });
      }
    );
  };
};

export default useFoxFrequenciesWithUpdateStatus;
