export class HotkeysHelperService {
  getIsElementInDom = (query: string) => {
    return !!document.querySelector(query);
  };

  getIsOverlayedComponentOpen = () => {
    return (
      document.querySelector('.ant-modal-wrap:not([style*="display: none"])') ||
      document.querySelector('.ant-drawer-open')
    );
  };

  getIsInputOrTextarea = (e: KeyboardEvent) => {
    return e.target instanceof HTMLElement && (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA');
  };
}

export const hotkeysHelperService = new HotkeysHelperService();
