import { MutableRefObject, useEffect, useRef } from 'react';
import { useThrottleFn } from '@reactuses/core';

/*
This hook is used to detect if the mouse is in the target zone (+-25px from the right border of the side menu)
It came from the business requirement from the product manager described in the ticket: https://our-redmine-url/issues/234
 */
export const useTargetZone = ({
  onTarget,
}: {
  onTarget: (isInTargetZone: boolean) => void;
}): { targetZoneRef: MutableRefObject<HTMLDivElement | null> } => {
  const targetZoneRef = useRef<HTMLDivElement | null>(null);

  const { run: onChange } = useThrottleFn((e: MouseEvent) => {
    const mouseXPosition = e.clientX || 0;
    const targetZonePosition = targetZoneRef.current?.offsetWidth || 0;
    const isInTargetZone = Math.abs(mouseXPosition - targetZonePosition) < 25;

    onTarget(isInTargetZone);
  }, 300);

  useEffect(() => {
    if (targetZoneRef.current) {
      document.addEventListener('mousemove', onChange);
    }
    return () => {
      document.removeEventListener('mousemove', onChange);
    };
  }, [onChange]);

  return { targetZoneRef };
};
