import { LayerSpecification } from 'maplibre-gl';
import { MapLayer, MapSource } from '@/entities/map/constants';

export const elevationLayers: LayerSpecification[] = [
  {
    id: MapLayer.Bg,
    type: 'raster',
    source: MapSource.GoogleSatellite,
  },
  {
    id: MapLayer.Elevation,
    type: 'raster',
    source: MapSource.Elevation,
  },
  {
    id: MapLayer.LabelsOnly,
    type: 'raster',
    source: MapSource.GoogleLabels,
  },
];
