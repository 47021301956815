import { QueryKey } from '@tanstack/react-query';
import useDeleteInfinitePages from './useDeleteInfinitePages';
import useRefetchInfinitePage from './useRefetchInfinitePage';

const useClearInfiniteListAndRefetch = (queryKey: QueryKey) => {
  const handleDeleteInfinitePages = useDeleteInfinitePages(queryKey);
  const handleRefetchInfinitePage = useRefetchInfinitePage(queryKey);

  const handleClearInfiniteListAndRefetch = async () => {
    try {
      handleDeleteInfinitePages(0);
      await handleRefetchInfinitePage(0);
    } catch (e) {
      console.error('useClearInfiniteListAndRefetch error', e);
    }
  };

  return handleClearInfiniteListAndRefetch;
};

export default useClearInfiniteListAndRefetch;
