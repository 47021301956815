import { Row, Typography } from 'antd';
import RecordType from '@/entities/source/@fox/components/RecordsTab/components/RecordType';
import { File } from '@/entities/source/@fox/types';
import { timeService } from '@/shared/services';
import styles from './styles.module.css';

interface Props {
  metadata: File['metadata'];
}

const MetadataRow = ({ metadata }: Props) => {
  return (
    <Row className={styles.metadataRow}>
      <Typography>{metadata.frequency}</Typography>
      <RecordType
        signalType={metadata.signalType}
        encryptionType={metadata.encryptionType}
      />
      <Typography className={styles.date}>{timeService.getFormattedDate(metadata.interceptedAt, true)}</Typography>
    </Row>
  );
};

export default MetadataRow;
