import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/shared/types';
import { ModalMapState as MapState } from './types';

const initialState: MapState = {
  callingElementPosition: null,
  pointCoordinates: null,
};

const modalMapSlice = createSlice({
  name: 'modalMap',
  initialState,
  reducers: {
    setCallingElementPosition: (state: MapState, action: PayloadAction<MapState['callingElementPosition']>) => {
      state.callingElementPosition = action.payload;
    },
    setPointCoordinates: (state: MapState, action: PayloadAction<MapState['pointCoordinates']>) => {
      state.pointCoordinates = action.payload;
    },
    resetModalMapState: () => initialState,
  },
});

export const { setCallingElementPosition, setPointCoordinates, resetModalMapState } = modalMapSlice.actions;

export const selectCallingElementPosition = (state: RootState) => state.modalMap.callingElementPosition;
export const selectPointCoordinates = (state: RootState) => state.modalMap.pointCoordinates;

export default modalMapSlice.reducer;
