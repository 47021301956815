import { useState } from 'react';

const DIGITAL_FREQUENCY = 'Цифрова частота';
const ANALOG_FREQUENCY = 'Аналогова частота';

const useIsDigitalToggle = () => {
  const [isDigital, setIsDigital] = useState<boolean>(true);
  const label = isDigital ? DIGITAL_FREQUENCY : ANALOG_FREQUENCY;

  const handleSwitchClick = () => {
    setIsDigital((prevState) => !prevState);
  };

  return {
    isDigital,
    label,
    handleSwitchClick,
  };
};

export default useIsDigitalToggle;
