import { useEffect } from 'react';
import { selectCenterTo, setCenterTo, selectCenterToMetaData, setCenterToMetaData } from '@/entities/transcript/slices';
import { Transcription } from '@/entities/transcript/types';
import { useRenderPropsContext } from '@/shared/context';
import { useAppDispatch, useAppSelector, useCopyToClipboard } from '@/shared/hooks';
import { LatLngCoordinates, TranscriptRenderProps } from '@/shared/types';

const useLocationsCoordinatesTags = (transcript: Transcription) => {
  const { renderTranscriptLocations } = useRenderPropsContext<TranscriptRenderProps>();
  const { copyToClipboard } = useCopyToClipboard();
  const centerTo = useAppSelector(selectCenterTo);
  const centerToMetaData = useAppSelector(selectCenterToMetaData);
  const dispatch = useAppDispatch();
  const {
    frequencyId,
    metadata: { frequency },
    timestamp_created: createdAt,
  } = transcript;

  const handleSetCenterToMetaData = () => {
    dispatch(
      setCenterToMetaData({
        frequencyId,
        frequency: frequency ?? '',
        createdAt,
      })
    );
  };

  const handleCoordinatesClick = ({ lat, lng }: LatLngCoordinates) => {
    dispatch(setCenterTo({ lat, lng }));
    handleSetCenterToMetaData();
    copyToClipboard(`${lat}, ${lng}`);
  };

  useEffect(() => {
    if (centerTo !== null) {
      dispatch(setCenterTo(null));
    }
    if (centerToMetaData !== null) {
      dispatch(setCenterToMetaData(null));
    }
  }, [centerTo, centerToMetaData, dispatch]);

  return {
    handleCoordinatesClick,
    handleLocationClick: handleSetCenterToMetaData,
    renderTranscriptLocations,
  };
};

export default useLocationsCoordinatesTags;
