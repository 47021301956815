import { PropsWithChildren, ReactElement } from 'react';
import { ButtonProps } from 'antd';
import classNames from 'classnames';
import { Button } from '@/shared/components/ui/Button';
import styles from './styles.module.css';
import { useAddListItemButton } from './useAddListItemButton';

type Props = {
  className?: string;
  mobileIcon: ReactElement;
  onClick?: VoidFunction;
  isDetailsManagementEnabled?: boolean;
};

export const AddListItemButton = ({
  className,
  mobileIcon,
  onClick,
  isDetailsManagementEnabled,
  children,
}: PropsWithChildren<Props>) => {
  const { buttonText, buttonIcon, buttonType, handleClick } = useAddListItemButton({
    title: children,
    mobileIcon,
    onClick,
    isDetailsManagementEnabled,
  });

  return (
    <Button
      className={classNames(styles.button, className)}
      type={buttonType as ButtonProps['type']}
      icon={buttonIcon}
      onClick={handleClick}
    >
      {buttonText}
    </Button>
  );
};
