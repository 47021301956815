import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/shared/types';
import { CodesState } from './types';

const initialState: CodesState = {
  radioNetworkId: '',
  isAddingNewCode: false,
  isCodeListLoading: false,
};

export const codesSlice = createSlice({
  name: 'codes',
  initialState,
  reducers: {
    setIsAddingNewCode: (state: CodesState, action: PayloadAction<CodesState['isAddingNewCode']>) => {
      state.isAddingNewCode = action.payload;
    },
    setCodeRadioNetworkId: (state: CodesState, action: PayloadAction<CodesState['radioNetworkId']>) => {
      state.radioNetworkId = action.payload;
    },
    setIsCodeListLoading: (state: CodesState, action: PayloadAction<CodesState['isCodeListLoading']>) => {
      state.isCodeListLoading = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { setCodeRadioNetworkId, setIsAddingNewCode, setIsCodeListLoading, resetState } = codesSlice.actions;

export const selectIsAddingNewCode = (state: RootState) => {
  const {
    codes: { isAddingNewCode },
  } = state;
  return isAddingNewCode;
};

export const selectCodesRadioNetworkId = (state: RootState) => {
  const {
    codes: { radioNetworkId },
  } = state;
  return radioNetworkId;
};

export const selectIsCodeListLoading = (state: RootState) => {
  const {
    codes: { isCodeListLoading },
  } = state;
  return isCodeListLoading;
};

export default codesSlice.reducer;
