import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { createFoxFrequency as createFoxFrequencyApi } from '@/entities/source/@fox/api';
import { CreateFoxFrequencyPayload, FoxFrequency } from '@/entities/source/@fox/types';
import { ResponseData } from '@/shared/api';
import { CREATE_FOX_FREQUENCY_MUTATION_KEY } from '@/shared/constants';
import { useParams } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';

const useCreateFoxFrequency = (
  options: UseMutationOptions<ResponseData<FoxFrequency>, unknown, CreateFoxFrequencyPayload> = {}
) => {
  const { sourceId = '' } = useParams<SourceRouteParams>();
  const {
    mutate: createFoxFrequency,
    mutateAsync: createFoxFrequencyAsync,
    isLoading: isFoxFrequencyCreating,
  } = useMutation({
    ...options,
    mutationKey: [CREATE_FOX_FREQUENCY_MUTATION_KEY, sourceId],
    mutationFn: (payload: CreateFoxFrequencyPayload) => {
      return createFoxFrequencyApi({ sourceId, payload });
    },
  });

  return {
    createFoxFrequency,
    createFoxFrequencyAsync,
    isFoxFrequencyCreating,
  };
};

export default useCreateFoxFrequency;
