import { useEffect } from 'react';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import { CheckOutlined } from '@ant-design/icons';
import { SortingType } from '@/entities/source/@fox/components/ScanningTab/types';
import { FOX_SCANNING_FILTERS_STORAGE_KEY } from '@/entities/source/@fox/constants';
import { FoxScanningFilterLocalStorageParams } from '@/entities/source/@fox/types';
import { FoxScanningQueryParam } from '@/shared/constants';
import { useLocalStorageForLimitedDays, useParams, useSearchParam } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';

const ItemLabels: Record<SortingType, string> = {
  activity: 'За активністю',
  intensity: 'За інтенсивністю',
  frequency: 'За номіналом',
};

const useSortButton = () => {
  const { sourceId } = useParams<SourceRouteParams>();
  const [sortingType, setSortingType] = useSearchParam<SortingType>(FoxScanningQueryParam.Sort);
  const selectedKeys = sortingType ? [sortingType] : undefined;

  const { getStorageValue, setStorageValue } = useLocalStorageForLimitedDays<
    Record<string, FoxScanningFilterLocalStorageParams>
  >(FOX_SCANNING_FILTERS_STORAGE_KEY);

  const items: MenuItemType[] = [
    { key: 'activity', label: ItemLabels.activity, onClick: handleClick('activity'), icon: getItemIcon('activity') },
    {
      key: 'intensity',
      label: ItemLabels.intensity,
      onClick: handleClick('intensity'),
      icon: getItemIcon('intensity'),
    },
    {
      key: 'frequency',
      label: ItemLabels.frequency,
      onClick: handleClick('frequency'),
      icon: getItemIcon('frequency'),
    },
  ];

  function handleClick(type: SortingType) {
    return () => {
      setSortingType(type);
    };
  }

  function getItemIcon(type: SortingType) {
    return sortingType === type ? <CheckOutlined /> : null;
  }

  useEffect(() => {
    if (!sourceId || !sortingType) return;

    const storageValue = getStorageValue();
    const sourceScanningPersistedParams = storageValue?.[sourceId];

    setStorageValue({
      ...storageValue,
      [sourceId]: {
        ...sourceScanningPersistedParams,
        sort: sortingType,
      },
    });
  }, [sortingType, sourceId]);

  return {
    items,
    selectedKeys,
  };
};

export default useSortButton;
