import { useState, useCallback } from 'react';
import { SpectrogramProps } from '../types';

interface Params extends Pick<SpectrogramProps, 'onCreateFrequency'> {}

const useCreateFrequency = ({ onCreateFrequency }: Params) => {
  const [isFrequencyCreating, setIsFrequencyCreating] = useState(false);

  const memoHandleCreateFrequency = useCallback<SpectrogramProps['onCreateFrequency']>(async (data) => {
    try {
      setIsFrequencyCreating(true);
      await onCreateFrequency(data);
    } finally {
      setIsFrequencyCreating(false);
    }
  }, []);

  return {
    isFrequencyCreating,
    memoHandleCreateFrequency,
  };
};

export default useCreateFrequency;
