import { useNavigateToSource } from '@/entities/source/hooks';
import { FoxRecordsQueryParam, SourceTab } from '@/shared/constants';
import { useAppDispatch, useParams } from '@/shared/hooks';
import { resetActiveMobileDetailsManagerState } from '@/shared/slices';
import { SourceRouteParams } from '@/shared/types';
import { FoxFrequencyCountRecordsProps } from '../types';
import useHref from './useHref';

interface Params extends FoxFrequencyCountRecordsProps {}

const useFoxFrequencyCountRecords = ({ frequency, countRecords }: Params) => {
  const dispatch = useAppDispatch();
  const { sourceId } = useParams<SourceRouteParams>();
  const navigate = useNavigateToSource();
  const isClickable = countRecords > 0;
  const href = useHref(frequency);

  const handleClick = () => {
    if (!isClickable) return;

    navigate({
      routeParams: {
        sourceId,
        activeTab: SourceTab.Records,
      },
      options: {
        additionalQueryParams: [[FoxRecordsQueryParam.Frequency, frequency]],
        withAdditionalQueryParamsOnly: true,
      },
    });
    dispatch(resetActiveMobileDetailsManagerState());
  };

  return {
    href,
    isClickable,
    handleClick,
  };
};

export default useFoxFrequencyCountRecords;
