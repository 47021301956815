import { useEffect } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { SLIDER_CSS_SELECTOR } from '../constants';

const WAVESURFER_STYLES = `
  [data-resize] {
    display: none;
  }
  .cursor {
    width: 12px!important;
    height: 12px!important;
    top: calc(50% - 6px)!important;
    transform: translateX(-5px)!important;
    border-radius: 50%!important;
  }
  .wrapper:hover .cursor {
    width: 16px!important;
    height: 16px!important;
    top: 4px!important;
    transform: translateX(-7px)!important;
  }
  div {
    cursor: pointer!important;
  }
  .wrapper canvas {
    top: 3px;
  }
  [part='minimap'] {
    position: absolute!important;
    bottom: 13px;
    width: 100%;
    overflow: hidden;
    height: 0;
    z-index: -1;
  }
  [part='minimap']:hover,
  .scroll:hover + [part='minimap'] {
    height: auto;
    padding: 4px 0 10px 0;
  }
`;

const useWavesurferStyles = (wavesurfer: WaveSurfer | null) => {
  useEffect(() => {
    if (wavesurfer) {
      const shadowRoot = document.querySelector(`${SLIDER_CSS_SELECTOR} > div`)?.shadowRoot;
      const styleElement = document.createElement('style');
      styleElement.textContent = WAVESURFER_STYLES;
      shadowRoot?.appendChild(styleElement);
    }
  }, [wavesurfer]);
};

export default useWavesurferStyles;
