import { CREATE_CALL_SIGN_ID, ColumnTitle, ColumnPlaceholder, CallSignKey } from '@/entities/callSign/constants';
import { CallSign, MobileListColumnType } from '@/shared/types';
import CallSignAliases from '../CallSignAliases';

export const CREATE_CALL_SIGN_ROW_DATA: CallSign = {
  id: CREATE_CALL_SIGN_ID,
  name: '',
  aliases: [],
  factions: [],
  position: '',
  note: '',
  created_at: 0,
  last_active_date: 0,
};

export const NEW_CALL_SIGN = 'Новий позивний';

export const ALIAS_ADDITIONAL_COLUMN: MobileListColumnType<CallSign> = {
  dataIndex: CallSignKey.Aliases,
  title: ColumnTitle.Aliases,
  placeholder: ColumnPlaceholder.Aliases,
  modifyFn: (aliases) =>
    (aliases as CallSign[CallSignKey.Aliases])?.length ? (
      <CallSignAliases aliases={aliases as CallSign[CallSignKey.Aliases]} />
    ) : null,
};
