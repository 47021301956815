import { useEffect } from 'react';
import { useGetCategories } from '@/entities/category/hooks';
import { MobileViewQueryParam, SortType } from '@/shared/constants';
import { useNavigate, useParams } from '@/shared/hooks';
import { routingService } from '@/shared/services';
import { CategoryRouteParams } from '@/shared/types';
import { getFirstAvailableCategories, sortCategories } from '../utils';

const DEFAULT_ORDER = SortType.Descending;

const useNavigateToFirstCategory = () => {
  const { categoryId } = useParams<CategoryRouteParams>();
  const { categories } = useGetCategories();
  const navigate = useNavigate();

  useEffect(() => {
    if (categoryId) return;

    const sortedCategories = sortCategories(categories, DEFAULT_ORDER);
    const { category, parent } = getFirstAvailableCategories(sortedCategories);

    if (parent && category) {
      navigate({
        pathname: routingService.getCategoryUrl({ categoryId: String(category.id) }),
        options: { withSpecificQueryParam: MobileViewQueryParam.Mobile },
      });
    }
  }, [categories, categoryId]);
};

export default useNavigateToFirstCategory;
