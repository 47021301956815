import { FoxRecordsQueryParam } from '@/shared/constants';
import { useSearchParam } from '@/shared/hooks';

const useDecodingType = () => {
  const [isDecodedSelected, setIsDecodedSelected] = useSearchParam(FoxRecordsQueryParam.Decoded);
  const [isNotDecodedSelected, setIsNotDecodedSelected] = useSearchParam(FoxRecordsQueryParam.NotDecoded);

  const toggleIsDecodedSelected = () => {
    setIsDecodedSelected(isDecodedSelected ? '' : 'true');
  };

  const toggleIsNotDecodedSelected = () => {
    setIsNotDecodedSelected(isNotDecodedSelected ? '' : 'true');
  };

  return {
    isDecodedSelected: Boolean(isDecodedSelected),
    isNotDecodedSelected: Boolean(isNotDecodedSelected),
    toggleIsDecodedSelected,
    toggleIsNotDecodedSelected,
  };
};

export default useDecodingType;
