import { useQuery } from '@tanstack/react-query';
import { getLocations } from '@/entities/location/api';
import { LOCATIONS_QUERY_KEY } from '@/shared/constants';

const useGetLocations = (search: string, type?: 'state') => {
  const {
    data,
    isFetching: isLocationsFetching,
    isLoading: isLocationsLoading,
  } = useQuery({
    queryKey: [LOCATIONS_QUERY_KEY, search, type],
    queryFn: () => getLocations(search, type),
  });
  const locations = data?.models || [];
  return { locations, isLocationsFetching, isLocationsLoading };
};

export default useGetLocations;
