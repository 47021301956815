import { InfiniteData, QueryKey, useQueryClient } from '@tanstack/react-query';
import { Paginated } from '@/shared/api';

// function returned by useDeleteInfinitePages deletes all useInfiniteQuery's cached pages after pageIndex
// example: const deleteInfinitePages = useDeleteInfinitePages<ItemType>(['some-key']); deleteInfinitePages(2)

const useDeleteInfinitePages = <T>(queryKey: QueryKey) => {
  const queryClient = useQueryClient();

  return (pageIndex: number) => {
    queryClient.setQueryData<InfiniteData<Paginated<T>>>(queryKey, (data) => {
      if (data) {
        const filteredPages: InfiniteData<Paginated<T>>['pages'] = data.pages.filter((_, index) => index <= pageIndex);
        return {
          pages: filteredPages,
          pageParams: data.pageParams,
        };
      }
    });
  };
};

export default useDeleteInfinitePages;
