import { DecibelAxisProps } from '../types';

interface Params extends Pick<DecibelAxisProps, 'minDecibel' | 'maxDecibel'> {}

const getDecibels = ({ minDecibel, maxDecibel }: Params) => {
  const decibelAxesStep = Math.round((-maxDecibel + minDecibel) / 5);
  return Array(5)
    .fill(null)
    .reduce(
      (accumulator: number[]) => {
        return [...accumulator, accumulator[accumulator.length - 1] + decibelAxesStep];
      },
      [maxDecibel]
    );
};

export default getDecibels;
