import { getDetailValue } from '@/entities/source/@fox/utils';
import useFoxKey from './useFoxKey';

const GROUP_ID_TITLE = 'Group ID';
const KEY_ID_TITLE = 'Key ID';
const ENCRYPTION_TITLE = 'Тип шифрування';
const FREQUENCY_TITLE = 'Виявлено на частоті';
const TARGET_FREQUENCY_TITLE = 'Використовувати на частоті';
const KEY = 'Ключ';

const useFoxKeyDetails = () => {
  const foxKey = useFoxKey();

  return (
    foxKey && {
      [KEY_ID_TITLE]: {
        value: getDetailValue(foxKey.keyId),
      },
      [GROUP_ID_TITLE]: {
        value: getDetailValue(foxKey.groupId),
      },
      [ENCRYPTION_TITLE]: {
        value: getDetailValue(foxKey.encryption),
      },
      [FREQUENCY_TITLE]: {
        value: getDetailValue(foxKey.sourceFrequency),
      },
      [TARGET_FREQUENCY_TITLE]: {
        value: getDetailValue(foxKey.targetFrequency?.value),
      },
      [KEY]: {
        value: getDetailValue(foxKey.body),
        canBeCopied: Boolean(foxKey.body),
      },
    }
  );
};

export default useFoxKeyDetails;
