import classNames from 'classnames';
import { SortAscIcon } from '@/shared/assets';
import { ActionsDropdown } from '@/shared/components';
import { useSortButton } from './hooks';
import styles from './styles.module.css';

const SortButton = () => {
  const { items, selectedKeys } = useSortButton();

  return (
    <ActionsDropdown
      selectedKeys={selectedKeys}
      items={items}
      icon={<SortAscIcon />}
      className={classNames({
        [styles.activeButton]: !!selectedKeys,
      })}
      overlayClassName={styles.overlay}
    />
  );
};

export default SortButton;
