import { FormInstance } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import { CreateFoxFrequencyPayload } from '@/entities/source/@fox/types';
import { FOX_FREQUENCIES_QUERY_KEY } from '@/shared/constants';
import validations from '@/shared/constants/validation.json';
import { useParams } from '@/shared/hooks';
import { validationService } from '@/shared/services';
import { SourceRouteParams } from '@/shared/types';
import useCreateFoxFrequency from './useCreateFoxFrequency';
import useFoxFrequenciesQueryParams from './useFoxFrequenciesQueryParams';

interface Params {
  form: FormInstance<CreateFoxFrequencyPayload>;
  onCancelAddNewFoxFrequency: VoidFunction;
}

const useCreateFoxFrequencyFormSubmit = ({ form, onCancelAddNewFoxFrequency }: Params) => {
  const queryClient = useQueryClient();
  const queryParams = useFoxFrequenciesQueryParams();
  const { sourceId = '' } = useParams<SourceRouteParams>();

  const { createFoxFrequency, isFoxFrequencyCreating } = useCreateFoxFrequency({
    onSuccess: async () => {
      await queryClient.invalidateQueries([FOX_FREQUENCIES_QUERY_KEY, sourceId, queryParams]);
      onCancelAddNewFoxFrequency();
    },
    onError: (error) => {
      validationService.validateForm({ form, error, validations });
    },
  });

  const handleFinish = (values: CreateFoxFrequencyPayload) => {
    createFoxFrequency({
      ...values,
      note: values.note.trim(),
    });
  };

  return {
    handleFinish,
    isFoxFrequencyCreating,
  };
};

export default useCreateFoxFrequencyFormSubmit;
