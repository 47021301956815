import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getTranscript } from '@/entities/transcript/api';
import { Transcription } from '@/entities/transcript/types';
import { TRANSCRIPT_QUERY_KEY } from '@/shared/constants';
import useSyncTranscriptsDictionary from './useSyncTranscriptsDictionary';

const useGetTranscriptById = (id: Transcription['id']) => {
  const { data, isFetching } = useQuery({
    queryKey: [TRANSCRIPT_QUERY_KEY, id],
    queryFn: () => getTranscript(id),
    enabled: Boolean(id),
  });
  const memoTranscripts = useMemo(() => (data ? [data] : []), [data]);

  useSyncTranscriptsDictionary({ memoTranscripts });

  return {
    transcript: data,
    isTranscriptFetching: isFetching,
  };
};

export default useGetTranscriptById;
