import { useMemo } from 'react';
import { RequestQueryParams } from '@/shared/api';
import { FoxScanningQueryParam } from '@/shared/constants';
import { useSearchParam } from '@/shared/hooks';
import { queryParamsService } from '@/shared/services';

const useFoxFrequenciesQueryParams = () => {
  const [activeStatus] = useSearchParam(FoxScanningQueryParam.Active);
  const [deactivatedStatus] = useSearchParam(FoxScanningQueryParam.Deactivated);
  const [analogSignalType] = useSearchParam(FoxScanningQueryParam.Analog);
  const [digitalSignalType] = useSearchParam(FoxScanningQueryParam.Digital);
  const [encryptionType] = useSearchParam(FoxScanningQueryParam.EncryptionType);

  return useMemo<RequestQueryParams>(() => {
    const status: string[] = [];
    if (activeStatus) {
      status.push('active');
    }
    if (deactivatedStatus) {
      status.push('inactive');
    }

    const signalType: string[] = [];
    if (analogSignalType) {
      signalType.push('analog');
    }
    if (digitalSignalType) {
      signalType.push('digital');
    }

    return {
      status,
      signalType,
      encryptionType: queryParamsService.convertQueryParamToArray(encryptionType),
    };
  }, [activeStatus, deactivatedStatus, analogSignalType, digitalSignalType, encryptionType]);
};

export default useFoxFrequenciesQueryParams;
