import { PropsWithChildren } from 'react';
import styles from './styles.module.css';

export const LayoutContentHeader = ({ children }: PropsWithChildren) => {
  return (
    <div
      className={styles.header}
      data-testid="page-content-header"
    >
      {children}
    </div>
  );
};
