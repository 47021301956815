import { useMemo, useCallback, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery, UseInfiniteQueryOptions, GetNextPageParamFunction } from '@tanstack/react-query';
import { InfiniteQueryData } from '@/shared/api';
import { DEFAULT_PAGE_SIZE } from '@/shared/constants';
import { reactQueryHelperService } from '@/shared/services';
import { GetListRefFn } from '@/shared/types';

export type UseInfiniteListQueryOptions<T> = {
  shouldFetchNextPage?: boolean;
} & UseInfiniteQueryOptions<InfiniteQueryData<T>>;

const useInfiniteListQuery = <T>({ shouldFetchNextPage = true, ...options }: UseInfiniteListQueryOptions<T>) => {
  const { ref, inView } = useInView();
  const [isFetchNextPageEnabled, setIsFetchNextPageEnabled] = useState(false);
  const forceFetchNextPage = () => setIsFetchNextPageEnabled(true);

  const getNextPageParam: GetNextPageParamFunction<InfiniteQueryData<T>> = (lastPage) => {
    if (reactQueryHelperService.isPaginatedWithFoffset(lastPage)) {
      if (lastPage.models.length < Number(DEFAULT_PAGE_SIZE)) return;
      return lastPage.currentPage + 1;
    }
    return lastPage.currentPage < lastPage.totalPages ? lastPage.currentPage + 1 : undefined;
  };

  const { data, isFetching, hasNextPage, fetchNextPage, ...queryResult } = useInfiniteQuery({
    ...options,
    getNextPageParam,
  });
  const list = useMemo(() => reactQueryHelperService.getInfiniteData(data), [data]);

  const getListRef: GetListRefFn = useCallback(
    (index: number) => {
      if (index !== list.length - 2) return null;

      return ref;
    },
    [list.length, ref]
  );

  useEffect(() => {
    if (isFetchNextPageEnabled && hasNextPage && shouldFetchNextPage && !isFetching) {
      fetchNextPage();
    }
  }, [isFetchNextPageEnabled, hasNextPage, shouldFetchNextPage, fetchNextPage, isFetching]);

  useEffect(() => {
    // when we fetched next page, inView doesn't change from true to false immediately and as it stayed true for some time,
    // fetchNextPage invoked when it shouldn't, isFetchNextPageEnabled fixes it.
    setIsFetchNextPageEnabled(inView);
  }, [inView]);

  useEffect(() => {
    if (isFetchNextPageEnabled) {
      setIsFetchNextPageEnabled(false);
    }
  }, [isFetchNextPageEnabled]);

  return {
    list,
    getListRef,
    data,
    isFetching,
    hasNextPage,
    fetchNextPage,
    forceFetchNextPage,
    ...queryResult,
  };
};

export default useInfiniteListQuery;
