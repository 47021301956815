import { request, ResponseData, PaginatedWithFoffset } from '@/shared/api';
import { V2_PREFIX } from '@/shared/config';
import { DEFAULT_PAGE_SIZE } from '@/shared/constants';
import { Network, NetworkFilters, RadioNetwork, RadioNetworkItem } from '@/shared/types';
import { CreateNetworkPayload, UpdateNetworkPayload, AddToNetworkPayload } from './types';
import {
  getNetworksSchema,
  createNetworkSchema,
  updateNetworkSchema,
  addToNetworkSchema,
  getRadioNetworksSchema,
  getRadioNetworksFilterSchema,
  getRadioNetworkSchema,
} from './validationSchemas';

export const getNetworks = (name?: string) => {
  const params = new URLSearchParams();
  params.set('s', name || '');
  return request<ResponseData<Network[]>>(`/radio-networks/network?${name ? `${params.toString()}` : ''}`, {
    endpointPrefix: V2_PREFIX,
    validationSchema: getNetworksSchema,
  });
};

export const createNetwork = (payload: CreateNetworkPayload) =>
  request<Network>(`/radio-networks`, {
    method: 'POST',
    body: JSON.stringify(payload),
    endpointPrefix: V2_PREFIX,
    validationSchema: createNetworkSchema,
  });

export const updateNetwork = ({ networkId, ...payload }: UpdateNetworkPayload) =>
  request<Network>(`/radio-networks/${networkId}`, {
    method: 'PUT',
    body: JSON.stringify(payload),
    endpointPrefix: V2_PREFIX,
    validationSchema: updateNetworkSchema,
  });

export const addToNetwork = ({ networkId, frequency }: AddToNetworkPayload) =>
  request<Network>(`/radio-networks/${networkId}/frequency`, {
    method: 'POST',
    body: JSON.stringify({ frequency }),
    endpointPrefix: V2_PREFIX,
    validationSchema: addToNetworkSchema,
  });

export const getRadioNetworks = ({ currentPage, queryParams }: { currentPage: number; queryParams: string }) => {
  const params = new URLSearchParams([
    ['page', String(currentPage)],
    ['size', DEFAULT_PAGE_SIZE],
  ]).toString();

  let url = `/radio-networks?${params}`;
  if (queryParams) {
    url += `&${queryParams}`;
  }

  return request<PaginatedWithFoffset<RadioNetwork>>(url, {
    endpointPrefix: V2_PREFIX,
    validationSchema: getRadioNetworksSchema,
  });
};

export const getRadioNetworksFilter = () =>
  request<ResponseData<NetworkFilters>>('/radio-networks/filter', {
    endpointPrefix: V2_PREFIX,
    validationSchema: getRadioNetworksFilterSchema,
  });

export const getRadioNetwork = (id: string) =>
  request<RadioNetworkItem>(`/radio-networks/item/${id}`, {
    endpointPrefix: V2_PREFIX,
    validationSchema: getRadioNetworkSchema,
  });
