import { SelectedBoundaries } from '../types';

const getSelectedBoundaries = (mouseDown: number, mouseUp: number): SelectedBoundaries => {
  const startPoint = mouseDown < mouseUp ? mouseDown : mouseUp;
  const endPoint = mouseDown > mouseUp ? mouseDown : mouseUp;

  return {
    startPoint,
    endPoint,
  };
};

export default getSelectedBoundaries;
