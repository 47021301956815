import { Flex, Switch, Typography } from 'antd';
import { MoonIcon, SunIcon } from '@/shared/assets';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { selectIsDarkMode, setIsDarkMode } from '@/shared/slices';
import styles from './styles.module.css';

const ThemeToggleButton = () => {
  const isDarkMode = useAppSelector(selectIsDarkMode);
  const dispatch = useAppDispatch();

  const themeLabel = isDarkMode ? 'Темна тема' : 'Світла тема';
  const toggleDarkMode = () => dispatch(setIsDarkMode(!isDarkMode));

  return (
    <Flex
      className={styles.themeToggleButton}
      onClick={toggleDarkMode}
      onKeyDown={toggleDarkMode}
      role="button"
      tabIndex={0}
    >
      <Switch
        checkedChildren={<SunIcon />}
        unCheckedChildren={<MoonIcon />}
        checked={!isDarkMode}
      />
      <Typography.Text type="secondary">{themeLabel}</Typography.Text>
    </Flex>
  );
};

export default ThemeToggleButton;
