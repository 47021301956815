import { FC, useState } from 'react';
import dayjs from 'dayjs';
import { getFormattedTime } from '@/entities/source/@fox/components/RecordsTab/components/AudioPlayer/utils';
import { Button } from '@/shared/components/ui/Button';
import { DATE_FULL_TIME_FULL } from '@/shared/constants';
import styles from './styles.module.css';

interface Props {
  currentTime: number;
  duration: number;
  recordTimestamp?: number;
}

const NO_DATA = 'Дані відсутні';

const RecordInformation: FC<Props> = ({ currentTime, duration, recordTimestamp }) => {
  const [showTime, setShowTime] = useState(true);
  const time = `${getFormattedTime(currentTime)} / ${getFormattedTime(duration)}`;
  const info = recordTimestamp
    ? dayjs(recordTimestamp).add(currentTime, 'second').format(DATE_FULL_TIME_FULL)
    : NO_DATA;

  const toggleInfo = () => setShowTime((prevShowTime) => !prevShowTime);

  return (
    <Button
      type="text"
      className={styles.info}
      onClick={toggleInfo}
    >
      {showTime ? time : info}
    </Button>
  );
};

export default RecordInformation;
