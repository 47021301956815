import { PropsWithChildren, createContext, useContext } from 'react';
import once from 'lodash/once';
import { RenderProps } from './types';

const createRenderPropsContext = once(<State,>() => createContext<State | null>(null));

export const useRenderPropsContext = <Props extends RenderProps>() => {
  const RenderPropsContext = createRenderPropsContext<Props>();
  const context = useContext(RenderPropsContext);
  if (!context) {
    throw new Error('useRenderPropsContext should be used inside RenderPropsContextProvider');
  }
  return context;
};

const RenderPropsContextProvider = <Props extends RenderProps>({
  memoRenderProps,
  children,
}: PropsWithChildren<{
  memoRenderProps: Props;
}>) => {
  const RenderPropsContext = createRenderPropsContext<Props>();

  return <RenderPropsContext.Provider value={memoRenderProps}>{children}</RenderPropsContext.Provider>;
};

export default RenderPropsContextProvider;
