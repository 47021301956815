import { useMutation, MutateOptions } from '@tanstack/react-query';
import { updateSourceArea as updateSourceAreaApi } from '@/entities/source/api';
import { UpdateSourceAreaParams } from '@/entities/source/api/types';
import { UPDATE_SOURCE_AREA_MUTATION_KEY } from '@/shared/constants';

const useUpdateSourceArea = (options: MutateOptions<{}, unknown, UpdateSourceAreaParams> = {}) => {
  const { mutateAsync: updateSourceArea, isLoading: isSourceAreaUpdating } = useMutation({
    ...options,
    mutationKey: [UPDATE_SOURCE_AREA_MUTATION_KEY],
    mutationFn: updateSourceAreaApi,
  });

  return { updateSourceArea, isSourceAreaUpdating };
};

export default useUpdateSourceArea;
