import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import classNames from 'classnames';
import { useAppSelector, useIsTabletDevice } from '@/shared/hooks';
import {
  selectCurrentUserRole,
  selectIsLayoutSidebarLeftCollapsed,
  selectIsNavigationSidebarCollapsed,
} from '@/shared/slices';
import { ITEMS_WITH_COLLAPSIBLE_PANEL } from './constants';
import { useMenuLinks } from './hooks';
import styles from './styles.module.css';
import { filterItemsByRole, getSelectedKey } from './utils';

type Props = {
  onMenuItemClick?: VoidFunction;
};

export const NavigationMenu = ({ onMenuItemClick }: Props) => {
  const isTabletDevice = useIsTabletDevice();
  const role = useAppSelector(selectCurrentUserRole);
  const isNavigationSidebarCollapsed = useAppSelector(selectIsNavigationSidebarCollapsed);
  const isLayoutSidebarCollapsed = useAppSelector(selectIsLayoutSidebarLeftCollapsed);
  const { pathname } = useLocation();

  const menuLinks = useMenuLinks({ onMenuItemClick });
  const menuItems = useMemo(() => filterItemsByRole(menuLinks, role), [menuLinks, role]);
  const selectedKey = useMemo(() => getSelectedKey(menuItems, pathname), [menuItems, pathname]);

  const hasCollapsiblePanel =
    isTabletDevice && isNavigationSidebarCollapsed && ITEMS_WITH_COLLAPSIBLE_PANEL.includes(selectedKey);

  return (
    <Menu
      className={classNames(styles.menu, {
        [styles.menuWithCollapsedPanelIcon]: hasCollapsiblePanel && isLayoutSidebarCollapsed,
        [styles.menuWithUncollapsedPanelIcon]: hasCollapsiblePanel && !isLayoutSidebarCollapsed,
      })}
      mode="inline"
      items={menuItems}
      selectedKeys={[selectedKey]}
    />
  );
};
