import { SortDescIcon, SortAscIcon } from '@/shared/assets';
import { Button, ButtonProps } from '@/shared/components/ui/Button';
import { SortType } from '@/shared/constants';

type Props = ButtonProps & {
  direction: SortType;
};

export const SortButton = ({ direction, ...restProps }: Props) => {
  return (
    <Button
      {...restProps}
      icon={direction === SortType.Descending ? <SortDescIcon /> : <SortAscIcon />}
      data-testid="sort-button"
    />
  );
};
