import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getRadioNetwork } from '@/entities/network/api';
import { RADIO_NETWORK_QUERY_KEY } from '@/shared/constants';

interface Params extends UseQueryOptions {
  id: string;
}

const useGetRadioNetwork = ({ id, enabled }: Params) => {
  const { data, isLoading, isFetching, isSuccess, isError } = useQuery({
    queryKey: [RADIO_NETWORK_QUERY_KEY, id],
    queryFn: () => getRadioNetwork(id),
    enabled,
  });

  return {
    isError,
    isLoading,
    isSuccess,
    isFetching,
    radioNetwork: data || null,
  };
};

export default useGetRadioNetwork;
