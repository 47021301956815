import { NETWORKS_QUERY_PARAMS_KEY } from '@/shared/constants';
import { useLocalStorage, useLocalStorageForLimitedDays } from '@/shared/hooks';
import { FilterLocalStorageParams } from '@/shared/types';

const USER_GROUP_ID_STORAGE_KEY = 'userGroupId';

export const useStorageUpdateOnUserGroupChanged = (userGroupId?: string) => {
  const { getStorageValue: getUserGroupIdStorageValue, setStorageValue: setUserGroupIdStorageValue } =
    useLocalStorage<string>(USER_GROUP_ID_STORAGE_KEY);
  const { setStorageValue: setNetworkFiltersStorageValue } =
    useLocalStorageForLimitedDays<FilterLocalStorageParams>(NETWORKS_QUERY_PARAMS_KEY);

  const storedUserGroupId = getUserGroupIdStorageValue();

  if (userGroupId && !storedUserGroupId) {
    setUserGroupIdStorageValue(userGroupId);
    return;
  }

  if (userGroupId && userGroupId !== storedUserGroupId) {
    setNetworkFiltersStorageValue({
      location: [],
      dateRange: null,
      faction: [],
      frequency: [],
      group: [],
      category: [],
    });
    setUserGroupIdStorageValue(userGroupId);
  }
};
