import { useQuery } from '@tanstack/react-query';
import { getLocationsByIds } from '@/entities/location';
import { Subscription } from '@/entities/subscription';
import { Paginated } from '@/shared/api';
import { LOCATIONS_QUERY_KEY } from '@/shared/constants';
import { Location } from '@/shared/types';

const useGetSelectedLocations = (subscriptions: Paginated<Subscription> | undefined) => {
  const selectedLocationsIds =
    subscriptions?.models?.[0]?.value.flatMap((item) => (item.type === 'location' && item.value ? [item.value] : [])) ||
    [];

  const {
    data,
    isFetching: isSelectedLocationsFetching,
    isLoading: isSelectedLocationsLoading,
    fetchStatus,
  } = useQuery<Paginated<Location>>({
    queryKey: [LOCATIONS_QUERY_KEY, selectedLocationsIds],
    queryFn: () => getLocationsByIds(selectedLocationsIds),
    enabled: Boolean(selectedLocationsIds.length),
  });
  const selectedLocations = data?.models || [];
  return {
    selectedLocations,
    isSelectedLocationsFetching,
    /*
      this composed loading state is a workaround for the
      issue with react-query that described in this thread:
      https://github.com/TanStack/query/issues/3584
    */
    isSelectedLocationsLoading: isSelectedLocationsLoading && fetchStatus !== 'idle',
  };
};

export default useGetSelectedLocations;
