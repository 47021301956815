import { FoxFrequency } from '@/entities/source/@fox/types';
import { ResponseData } from '@/shared/api';
import { FoxScanningQueryParam } from '@/shared/constants';
import { useSearchParam } from '@/shared/hooks';
import { exhaustiveGuard } from '@/shared/utils';
import { SortingType } from '../types';

const useSelectSortedFrequencies = () => {
  const [sortingType] = useSearchParam<SortingType | ''>(FoxScanningQueryParam.Sort);

  return (data: ResponseData<FoxFrequency[]>): ResponseData<FoxFrequency[]> => {
    switch (sortingType) {
      case '':
      case 'activity':
        return data;
      case 'intensity':
        return {
          data: data.data.toSorted((frequencyA, frequencyB) => {
            if (frequencyA.countRecords === frequencyB.countRecords) return 0;
            return frequencyA.countRecords > frequencyB.countRecords ? -1 : 1;
          }),
        };
      case 'frequency':
        return {
          data: data.data.toSorted((frequencyA, frequencyB) => {
            if (frequencyA.value === frequencyB.value) return 0;
            return Number(frequencyA.value) > Number(frequencyB.value) ? 1 : -1;
          }),
        };
      default:
        exhaustiveGuard(sortingType);
        return data;
    }
  };
};

export default useSelectSortedFrequencies;
