interface Params {
  isActive: boolean;
  onClick: (value: boolean) => void;
}

const useToggleDetailsButton = ({ isActive, onClick }: Params) => {
  const handleClick = () => {
    onClick(isActive);
  };

  return {
    handleClick,
  };
};

export default useToggleDetailsButton;
