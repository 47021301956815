import { memo } from 'react';
import Frequency from '../Frequency';
import { useNewFrequency } from './hooks';
import { NewFrequencyProps } from './types';

const NewFrequency = memo(
  ({
    startFrequency,
    endFrequency,
    canvasWidth,
    memoFrequencyPosition,
    isMouseDownOnCanvas,
    memoOnCreateFrequency,
    frequencyHeight,
    memoFrequencies,
    maxLeftPosition,
    maxRightPosition,
    isAddingNewFrequencyEnabled,
  }: NewFrequencyProps) => {
    const { newFrequency, newFrequencyId, handleDeleteNewFrequency, handleCreateFrequency } = useNewFrequency({
      startFrequency,
      endFrequency,
      canvasWidth,
      memoFrequencyPosition,
      isMouseDownOnCanvas,
      memoOnCreateFrequency,
      memoFrequencies,
      isAddingNewFrequencyEnabled,
    });

    if (!newFrequency) return null;

    return (
      <Frequency
        isDigital
        isDisabled={false}
        id={newFrequencyId}
        frequency={newFrequency}
        isActive
        isAutoFocusEnabled
        startFrequency={startFrequency}
        endFrequency={endFrequency}
        memoOnSave={handleCreateFrequency}
        memoOnDeleteFrequency={handleDeleteNewFrequency}
        frequencyHeight={frequencyHeight}
        maxLeftPosition={maxLeftPosition}
        maxRightPosition={maxRightPosition}
      />
    );
  }
);

NewFrequency.displayName = 'NewFrequency';

export default NewFrequency;
