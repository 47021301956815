import { useEffect } from 'react';
import { useNotificationContext } from '@/shared/context';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { selectIsDataPartiallyVisible, setIsDataPartiallyVisible } from '@/shared/slices';

const DATA_PARTIALLY_VISIBLE_DESCRIPTION = 'Дані з введеної адреси URL відображено частково';

const usePartiallyVisibleDataNotification = () => {
  const dispatch = useAppDispatch();
  const isDataPartiallyVisible = useAppSelector(selectIsDataPartiallyVisible);
  const { openNotification } = useNotificationContext();

  useEffect(() => {
    if (isDataPartiallyVisible) {
      dispatch(setIsDataPartiallyVisible(false));
      openNotification({
        type: 'warning',
        title: null,
        description: DATA_PARTIALLY_VISIBLE_DESCRIPTION,
      });
    }
  }, [dispatch, isDataPartiallyVisible, openNotification]);
};

export default usePartiallyVisibleDataNotification;
