const syncServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    const registrations = await navigator.serviceWorker.getRegistrations();
    for (const registration of registrations) {
      if (registration.waiting) {
        try {
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        } catch (error) {
          console.error('Error sending message to service worker:', error);
        }
      }
    }
  }
};

export default syncServiceWorker;
