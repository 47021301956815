import { Button } from '@/shared/components';
import { COPIES } from '@/shared/constants';
import styles from './styles.module.css';

interface Props {
  isSubmitting?: boolean;
  onCancelClick: VoidFunction;
  onSubmitClick: VoidFunction;
}

const FoxFrequencyCreateButtons = ({ isSubmitting, onCancelClick, onSubmitClick }: Props) => {
  return (
    <div className={styles.wrapper}>
      <Button onClick={onCancelClick}>{COPIES.CANCEL}</Button>
      <Button
        type="primary"
        onClick={onSubmitClick}
        disabled={isSubmitting}
        loading={isSubmitting}
        danger
      >
        {COPIES.ADD}
      </Button>
    </div>
  );
};

export default FoxFrequencyCreateButtons;
