import { useQueryClient } from '@tanstack/react-query';
import { useFoxFrequenciesQueryParams } from '@/entities/source/@fox/components/ScanningTab/hooks';
import { FoxFrequency } from '@/entities/source/@fox/types';
import { Source } from '@/entities/source/types';
import { ResponseData } from '@/shared/api';
import { FOX_FREQUENCIES_QUERY_KEY } from '@/shared/constants';
import { FoxFrequencyNoteProps } from '../types';
import { getFoxFrequenciesCacheWithUpdatedNote } from '../utils';

interface Params extends Pick<FoxFrequencyNoteProps, 'foxFrequencyId' | 'note'> {
  sourceId?: Source['id'];
}

const useUpdateFoxFrequencyNoteCache = () => {
  const queryClient = useQueryClient();
  const queryParams = useFoxFrequenciesQueryParams();

  return ({ sourceId, foxFrequencyId, note }: Params) => {
    queryClient.setQueryData<ResponseData<FoxFrequency[]>>(
      [FOX_FREQUENCIES_QUERY_KEY, sourceId, queryParams],
      (staleData) => {
        if (!staleData) return;

        return getFoxFrequenciesCacheWithUpdatedNote({ staleData, foxFrequencyId, note });
      }
    );
  };
};

export default useUpdateFoxFrequencyNoteCache;
