import { ExpressionSpecification, MapGeoJSONFeature, Offset } from 'maplibre-gl';
import { DEFAULT_CLUSTER, DEFAULT_POINT, FREQUENCY_CLUSTER, FREQUENCY_POINT } from '@/entities/map/config';
import { ClusterGroupedKey, GROUP_NO_ID, MapLayer, MarkerType, SYMBOL_TEXT_SIZE } from '@/entities/map/constants';
import { CustomMarker, MaplibreMap } from '@/entities/map/types';
import { getComplexId, getMarkerLayerPrefixes } from '@/entities/map/utils';
import { SharedTranscriptsDictionary } from '@/shared/types';

type UnitType = 'em' | 'px';

type MoveMarkerWithOffsetParams = {
  type: MarkerType;
  markerIndex: number;
  groupedKey: ClusterGroupedKey;
  groupId?: string;
};

class MarkerService {
  private baseOffset = 4.5;

  private zoomDelta: Record<number, number> = {
    11: 0,
    12: this.baseOffset,
    13: this.baseOffset * 2,
    14: this.baseOffset * 3,
    15: this.baseOffset * 4,
    16: this.baseOffset * 5,
    17: this.baseOffset * 6,
    18: this.baseOffset * 7,
    19: this.baseOffset * 8,
  };

  constructor(private map: MaplibreMap) {}

  private getFrequencyValue(transcriptsDictionary?: SharedTranscriptsDictionary, frequencyId?: string) {
    if (!transcriptsDictionary) return;
    const transcriptDetails = Object.values(transcriptsDictionary).find(({ frequencyId: id }) => id === frequencyId);
    return transcriptDetails?.frequencyValue ?? '';
  }

  getMarkers(groupedKey: ClusterGroupedKey, transcriptsDictionary?: SharedTranscriptsDictionary, frequencyId?: string) {
    let clusterMarker: CustomMarker;
    let pointMarker: CustomMarker;

    if (groupedKey === ClusterGroupedKey.Frequency) {
      // Temporary solution. When BE will add frequency value directly into geoJson feature, remove this logic
      const frequencyValue = this.getFrequencyValue(transcriptsDictionary, frequencyId);
      const offset: Offset = [-30, 10];
      const extraPaintProps = frequencyValue ? {} : { 'icon-translate': offset };
      const extraLayoutProps = frequencyValue ? { 'text-field': frequencyValue } : { 'icon-size': 1.25 };

      clusterMarker = {
        ...FREQUENCY_CLUSTER,
        markerInner: {
          ...FREQUENCY_CLUSTER.markerInner,
          paint: {
            ...FREQUENCY_CLUSTER.markerInner.paint,
            ...extraPaintProps,
          },
          layout: {
            ...FREQUENCY_CLUSTER.markerInner.layout,
            ...extraLayoutProps,
          },
        },
      };
      pointMarker = {
        ...FREQUENCY_POINT,
        markerInner: {
          ...FREQUENCY_POINT.markerInner,
          paint: {
            ...FREQUENCY_POINT.markerInner.paint,
            ...extraPaintProps,
          },
          layout: {
            ...FREQUENCY_POINT.markerInner.layout,
            ...extraLayoutProps,
          },
        },
      };
    } else {
      clusterMarker = DEFAULT_CLUSTER;
      pointMarker = DEFAULT_POINT;
    }

    return { clusterMarker, pointMarker };
  }

  private getLayersRange(layerIds: string[], filterFn: (layerId: string) => boolean) {
    const range = layerIds.filter(filterFn);
    const lastRangeId = [...range].pop();
    const nextId = lastRangeId ? layerIds[layerIds.indexOf(lastRangeId) + 1] : undefined;

    return { range, next: nextId };
  }

  moveMarkerToTopLevel(marker: MapGeoJSONFeature) {
    const orderedLayerIds = this.map.getLayersOrder();
    const { range: markerLayers, next: nextNotMarkerLayer } = this.getLayersRange(orderedLayerIds, (layerId) => {
      return [MapLayer.Cluster, MapLayer.Point].some((prefix) => layerId.startsWith(prefix));
    });
    const { range: movedLayers, next: nextMarkerLayer } = this.getLayersRange(markerLayers, (layerId) => {
      const groupId = marker.layer.id.split('_').pop();
      return groupId ? layerId.endsWith(groupId) : false;
    });

    // move the marker to the highest level before next not marker layer
    movedLayers.forEach((layer) => this.map.moveLayer(layer, nextNotMarkerLayer));

    return () => {
      // return the marker position to the previous level
      movedLayers.forEach((layer) => this.map.moveLayer(layer, nextMarkerLayer));
    };
  }

  getMarkerOffset(init: number, zoom: number, index = 0, unitType: UnitType = 'px') {
    const delta = this.zoomDelta[zoom];
    const offset = init + index * delta;

    return unitType === 'em' ? offset / SYMBOL_TEXT_SIZE : offset;
  }

  /*
   * Unfortunately, maplibre doesn't support to use the GeoJSON feature data parameters for *-translate properties.
   * @see - https://github.com/mapbox/mapbox-gl-js/issues/6155
   * @see - https://github.com/mapbox/mapbox-gl-js/issues/2731
   *
   * In this case, we cannot declare these props depending on some GeoJSON feature data using just LayerSpecification,
   * and, as alternative, we have to set it programmatically.
   * IMPORTANT NOTE - this approach affects the whole layer not just a single feature
   */
  moveMarkerWithOffset({ type, markerIndex, groupedKey, groupId }: MoveMarkerWithOffsetParams) {
    const { markerLayer, markerInnerLayer, markerCountLayer } = getMarkerLayerPrefixes(type);

    const getZoomSteps = (
      initialOffset: [number, number],
      unitType?: UnitType
    ): [number, ExpressionSpecification][] => {
      return Object.keys(this.zoomDelta)
        .map(Number)
        .map((zoom) => {
          return [
            zoom,
            ['literal', initialOffset.map((axis) => this.getMarkerOffset(axis, zoom, markerIndex, unitType))],
          ];
        });
    };

    const getPaintValue = (initialOffset: [number, number], unitType?: UnitType): ExpressionSpecification => [
      'interpolate',
      ['linear'],
      ['zoom'],
      ...getZoomSteps(initialOffset, unitType).flat(),
    ];

    if (!this.map.getLayer(getComplexId(markerLayer, groupId))) return;

    if (groupedKey === ClusterGroupedKey.Frequency) {
      this.map.setPaintProperty(getComplexId(markerLayer, groupId), 'circle-translate', getPaintValue([-35, 15]));
      if (groupId === GROUP_NO_ID) {
        this.map.setPaintProperty(getComplexId(markerInnerLayer, groupId), 'icon-translate', getPaintValue([-30, 10]));
      } else {
        this.map.setPaintProperty(getComplexId(markerInnerLayer, groupId), 'icon-translate', getPaintValue([-35, 15]));
        this.map.setPaintProperty(getComplexId(markerInnerLayer, groupId), 'text-translate', getPaintValue([-35, 15]));
      }
      this.map.setPaintProperty(getComplexId(markerCountLayer, groupId), 'icon-translate', getPaintValue([0, 0]));
      this.map.setPaintProperty(getComplexId(markerCountLayer, groupId), 'text-translate', getPaintValue([0, 0]));
    }
  }
}

export default MarkerService;
