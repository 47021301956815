import { useRef, useMemo, useCallback } from 'react';
import { useElementBounding } from '@reactuses/core';
import { SpectrogramProps } from '../types';
import { getCanvasZoomStyle } from '../utils';
import { UseSpectrogramInstanceParams, UseMouseEventsParams } from './types';
import useDetectionsVisibility from './useDetectionsVisibility';
import useDrawSpectrogram from './useDrawSpectrogram';
import useFullScreen from './useFullScreen';
import useMouseEvents from './useMouseEvents';
import useSelectedFrequenciesDictionary from './useSelectedFrequenciesDictionary';
import useSpectrogramInstance from './useSpectrogramInstance';
import useSpectrogramResize from './useSpectrogramResize';
import useSpectrogramStatus from './useSpectrogramStatus';
import useZoomData from './useZoomData';

interface Params
  extends UseSpectrogramInstanceParams,
    Pick<UseMouseEventsParams, 'onCreateFrequency'>,
    Pick<
      SpectrogramProps,
      | 'memoFrequencies'
      | 'decibelPower'
      | 'status'
      | 'memoOnFrequencyClick'
      | 'memoSelectedFrequencies'
      | 'memoOnFrequenciesSelect'
      | 'isLoading'
    > {}

const useSpectrogram = ({
  startFrequency,
  endFrequency,
  onCreateFrequency,
  canvasId,
  memoFrequencies,
  decibelPower,
  status,
  memoOnFrequencyClick,
  memoSelectedFrequencies,
  memoOnFrequenciesSelect,
  isLoading,
  ...restSpectrogramInstanceParams
}: Params) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasRect = useElementBounding(canvasRef, { windowScroll: false, immediate: true });

  const spectrogram = useSpectrogramInstance({
    startFrequency,
    endFrequency,
    canvasId,
    ...restSpectrogramInstanceParams,
  });

  useSpectrogramResize({
    canvasWidth: canvasRect.width,
    canvasHeight: canvasRect.height,
    onResize: () => spectrogram?.resize(),
  });

  const { isSpectrogramDrawing } = useDrawSpectrogram({
    decibelPower,
    addData: (data: number[]) => spectrogram?.addData(data),
  });

  const { zoomData, updateZoomData, memoHandleResetZoomData } = useZoomData();

  const currentStartFrequency = zoomData?.startZoomFrequency || startFrequency;
  const currentEndFrequency = zoomData?.endZoomFrequency || endFrequency;

  const { isAnyFrequencySelected, memoResetSelectedFrequenciesDictionary, memoSetSeveralSelectedFrequencies } =
    useSelectedFrequenciesDictionary({
      memoSelectedFrequencies,
      memoOnFrequenciesSelect,
    });

  const { isFrequencyCreating, ...mouseEventsData } = useMouseEvents({
    currentStartFrequency,
    currentEndFrequency,
    onCreateFrequency,
    zoomData,
    updateZoomData,
    areMouseEventsEnabled: isSpectrogramDrawing,
    memoOnCanvasClick: memoResetSelectedFrequenciesDictionary,
    memoFrequencies,
    memoOnSpectrumSelect: memoSetSeveralSelectedFrequencies,
  });

  const isSpinnerVisible = isFrequencyCreating || !!isLoading;

  const spectrogramStatus = useSpectrogramStatus({ status, isSpectrogramDrawing });

  const fullScreenData = useFullScreen({
    ref: mouseEventsData.canvasWrapperRef,
  });

  const detectionsVisibilityData = useDetectionsVisibility();

  const canvasZoomStyle = useMemo(
    () =>
      getCanvasZoomStyle({
        startZoomPercent: zoomData?.startZoomPercent,
        endZoomPercent: zoomData?.endZoomPercent,
        canvasWidth: mouseEventsData.canvasWidth,
      }),
    [zoomData, mouseEventsData.canvasWidth]
  );

  const memoDetectionFrequencies = useMemo(() => memoFrequencies.map(({ frequency }) => frequency), [memoFrequencies]);

  const memoHandleFrequencyClick = useCallback(
    (id: string) => {
      memoOnFrequencyClick?.(id);
      memoOnFrequenciesSelect?.({
        [id]: true,
      });
    },
    [memoOnFrequencyClick, memoOnFrequenciesSelect]
  );

  return {
    isSpectrogramDrawing,
    isSpinnerVisible,
    spectrogramStatus,
    canvasRef,
    canvasRect,
    canvasZoomStyle,
    zoomData,
    memoHandleResetZoomData,
    currentStartFrequency,
    currentEndFrequency,
    memoDetectionFrequencies,
    isAnyFrequencySelected,
    memoHandleFrequencyClick,
    ...mouseEventsData,
    ...fullScreenData,
    ...detectionsVisibilityData,
  };
};

export default useSpectrogram;
