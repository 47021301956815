import { Row, Typography } from 'antd';
import { DetailsItem } from '@/entities/source/@fox/types';
import { CopyIcon } from '@/shared/assets';
import { Button, Tooltip } from '@/shared/components';
import { useDetailItem } from './hooks';
import styles from './styles.module.css';

const { Text, Paragraph } = Typography;

const COPY_TOOLTIP_TITLE = 'Скопіювати';

interface Props extends DetailsItem {}

const DetailItem = ({ name, value, canBeCopied, icon }: Props) => {
  const { handleCopyToClipboardClick } = useDetailItem();

  return (
    <div className={styles.detailRow}>
      <Text>{name}</Text>
      <Row
        align="middle"
        wrap={false}
      >
        {icon}
        <Paragraph
          className={styles.detailValue}
          strong
          ellipsis={{ rows: 3, tooltip: value }}
        >
          {value}
        </Paragraph>
        {canBeCopied && (
          <Tooltip
            title={COPY_TOOLTIP_TITLE}
            className={styles.tooltip}
          >
            <Button
              onClick={handleCopyToClipboardClick(value)}
              icon={<CopyIcon />}
            />
          </Tooltip>
        )}
      </Row>
    </div>
  );
};

export default DetailItem;
