import { LoadingWrapper, MobileListManager, ScrollUpToRefresh } from '@/shared/components';
import { AddNewFoxKeyButton } from './components';
import { useMobileFoxKeysList } from './hooks';

const MobileFoxKeysList = () => {
  const {
    isSourceOnline,
    foxKeysList,
    getFoxKeyRef,
    isLoading,
    renderRow,
    renderDetailsTitle,
    renderDetailsComponent,
    renderActions,
    handleItemClick,
    handleScroll,
    refetchFoxKeys,
  } = useMobileFoxKeysList();

  return (
    <>
      <ScrollUpToRefresh
        isScrollUpToRefreshEnabled={foxKeysList.length > 0}
        onRefresh={refetchFoxKeys}
        onScroll={handleScroll}
      >
        <LoadingWrapper
          isLoading={isLoading}
          isFixedOverlay
        >
          <MobileListManager
            listData={foxKeysList}
            getItemRef={getFoxKeyRef}
            renderRow={renderRow}
            onItemClick={handleItemClick}
            itemDetailsManager={{
              renderDetailsTitle,
              renderDetailsComponent,
              renderActions,
            }}
          />
        </LoadingWrapper>
      </ScrollUpToRefresh>
      {isSourceOnline && <AddNewFoxKeyButton />}
    </>
  );
};

export default MobileFoxKeysList;
