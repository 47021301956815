import { memo } from 'react';
import { Typography } from 'antd';
import classNames from 'classnames';
import styles from './styles.module.css';
import { DetectionProps as Props } from './types';
import useDetection from './useDetection';

const { Text } = Typography;

const Detection = memo((props: Props) => {
  const { isDetectionHidden, handleDetectionClick, detectionPosition, isDigital, adjustedDetectionFrequency } =
    useDetection(props);

  if (isDetectionHidden) return null;

  return (
    <div
      className={styles.wrapper}
      style={detectionPosition}
      onClick={handleDetectionClick}
      onKeyDown={handleDetectionClick}
    >
      <div className={styles.detectionValueWrapper}>
        <Text className={styles.detectionValue}>{adjustedDetectionFrequency}</Text>
      </div>
      <div
        className={classNames(styles.detectionLine, {
          [styles.detectionLineBursting]: isDigital,
        })}
      />
    </div>
  );
});

Detection.displayName = 'Detection';

export default Detection;
