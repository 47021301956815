import { useSearchParams } from 'react-router-dom';
import { getSourceSearchParams } from '@/entities/source/utils';
import { FoxRecordsQueryParam, SourceTab } from '@/shared/constants';
import { useIsMobile, useParams } from '@/shared/hooks';
import { routingService } from '@/shared/services';
import { SourceRouteParams } from '@/shared/types';

const useHref = (frequency: string) => {
  const isMobile = useIsMobile();
  const [searchParams] = useSearchParams();
  const { sourceId } = useParams<SourceRouteParams>();

  const recordsSearchParams = getSourceSearchParams({
    sourceId,
    activeTab: SourceTab.Records,
    searchParams,
    withMobileView: isMobile,
    additionalQueryParams: [[FoxRecordsQueryParam.Frequency, frequency]],
  });
  const path = routingService.getFoxRecordUrl({ sourceId });

  return `${path}?${recordsSearchParams.toString()}`;
};

export default useHref;
