import {
  HAS_LOCATION_TYPE,
  IS_LOCATION_CLUSTER,
  MAP_COLORS,
  SYMBOL_TEXT_FONTS,
  SYMBOL_TEXT_SIZE,
} from '@/entities/map/constants';
import { FrequencyMarker } from '@/entities/map/types';

export const FREQUENCY_CLUSTER: FrequencyMarker = {
  marker: {
    type: 'circle',
    paint: {
      'circle-translate': [-35, 15],
      'circle-radius': 22,
      'circle-opacity': 0,
    },
  },
  markerInner: {
    type: 'symbol',
    paint: {
      'icon-translate': [-35, 15],
      'icon-color': ['case', IS_LOCATION_CLUSTER, MAP_COLORS.location, MAP_COLORS.primary],
      'icon-opacity': 0.8,
      'text-translate': [-35, 15],
      'text-color': MAP_COLORS.base,
    },
    layout: {
      'icon-image': 'clusterIcon',
      'icon-text-fit': 'both',
      'icon-text-fit-padding': [8, 10, 8, 10],
      'icon-overlap': 'always',
      'text-font': SYMBOL_TEXT_FONTS,
      'text-size': SYMBOL_TEXT_SIZE,
      'text-overlap': 'always',
    },
  },
  markerCount: {
    type: 'symbol',
    paint: {
      'text-color': ['case', IS_LOCATION_CLUSTER, MAP_COLORS.locationCounterText, MAP_COLORS.base],
    },
    layout: {
      'icon-image': ['case', IS_LOCATION_CLUSTER, 'clusterLocationCountIcon', 'clusterBearingCountIcon'],
      'icon-overlap': 'always',
      'text-field': '{point_count}',
      'text-font': SYMBOL_TEXT_FONTS,
      'text-size': SYMBOL_TEXT_SIZE,
      'text-overlap': 'always',
    },
  },
};

export const FREQUENCY_POINT: FrequencyMarker = {
  marker: {
    ...FREQUENCY_CLUSTER.marker,
  },
  markerInner: {
    ...FREQUENCY_CLUSTER.markerInner,
    paint: {
      ...FREQUENCY_CLUSTER.markerInner.paint,
      'icon-color': ['case', HAS_LOCATION_TYPE, MAP_COLORS.location, MAP_COLORS.primary],
    },
  },
  markerCount: {
    ...FREQUENCY_CLUSTER.markerCount,
    paint: {
      ...FREQUENCY_CLUSTER.markerCount.paint,
      'text-color': ['case', HAS_LOCATION_TYPE, MAP_COLORS.locationCounterText, MAP_COLORS.base],
    },
    layout: {
      ...FREQUENCY_CLUSTER.markerCount.layout,
      'icon-image': ['case', HAS_LOCATION_TYPE, 'clusterLocationCountIcon', 'clusterBearingCountIcon'],
      'text-field': '1',
    },
  },
};
