import CallSignAliases from '@/entities/callSign/components/CallSignAliases';
import { CallSign, MobileListColumnType } from '@/shared/types';

export const CREATE_CALL_SIGN_ID = 'create-call-sign';

export enum ColumnTitle {
  Aliases = 'Aліаси',
  CallSignTitle = 'Позивний',
  Faction = 'Угруповання',
  Position = 'Посада',
  Notes = 'Нотатки',
}

export enum ColumnPlaceholder {
  Aliases = 'Немає аліасів',
  Name = 'Немає позивного',
  Faction = 'Немає угруповання',
  Position = 'Немає посади',
  Notes = 'Немає нотаток',
}

export enum CallSignKey {
  Id = 'id',
  Name = 'name',
  Aliases = 'aliases',
  Position = 'position',
  Factions = 'factions',
  Note = 'note',
}

export const ALIAS_DETAIL_ITEM: MobileListColumnType<CallSign> = {
  dataIndex: CallSignKey.Aliases,
  title: ColumnTitle.Aliases,
  placeholder: ColumnPlaceholder.Aliases,
  modifyFn: (aliases) =>
    (aliases as CallSign[CallSignKey.Aliases])?.length ? (
      <CallSignAliases aliases={aliases as CallSign[CallSignKey.Aliases]} />
    ) : null,
};

export const DELETE_MODAL_TITLE = 'Видалити позивний?';

export const DELETE_MODAL_CONTENT =
  'Вибраний позивний буде видалений назавжди, а всі існуючі прив’язки цього позивного будуть видалені з усіх перехоплень.';
