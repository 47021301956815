import { useEffect } from 'react';
import { useAppDispatch } from '@/shared/hooks';
import { setShouldRedirectToPageNotFound } from '@/shared/slices';

interface Params<T> {
  list: T[];
  listItemPropertyName: keyof T;
  compareValue?: T[keyof T];
}

const useRedirectToFoxKeyNotFound = <T>({ list, listItemPropertyName, compareValue }: Params<T>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!compareValue || list.length === 0) return;

    if (!list.some((listItem) => listItem[listItemPropertyName] === compareValue)) {
      dispatch(setShouldRedirectToPageNotFound(true));
    }
  }, [dispatch, compareValue, list, listItemPropertyName]);
};

export default useRedirectToFoxKeyNotFound;
