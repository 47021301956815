import { useMemo } from 'react';
import { DecibelAxisProps } from './types';
import { getDecibels } from './utils';

interface Params extends DecibelAxisProps {}

const useDecibelAxis = ({ bottom, minDecibel, maxDecibel }: Params) => {
  const wrapperStyle = { bottom };

  const decibels = useMemo(() => getDecibels({ minDecibel, maxDecibel }), [minDecibel, maxDecibel]);

  return {
    wrapperStyle,
    decibels,
  };
};

export default useDecibelAxis;
