import { memo } from 'react';
import styles from './styles.module.css';
import { VerticalDashedElementProps as Props } from './types';
import { getDashedElementStyle } from './utils';

const VerticalDashedElement = memo(
  ({
    cursorDownXPosition,
    cursorPosition,
    canvasWidth,
    isElementExpandable,
    spectrumPercent,
    isMouseDownOnWaterfall,
  }: Props) => {
    const dashedElementStyle = getDashedElementStyle({
      cursorDownXPosition,
      cursorPosition,
      canvasWidth,
      isElementExpandable,
      spectrumPercent,
      isMouseDownOnWaterfall,
    });

    return (
      <div
        className={styles.dashedElement}
        style={dashedElementStyle}
      />
    );
  }
);

VerticalDashedElement.displayName = 'VerticalDashedElement';

export default VerticalDashedElement;
