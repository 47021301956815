import { useLocation } from 'react-router-dom';
import { useMount } from '@reactuses/core';
import { authService } from '@/shared/api';
import { PATH_BEFORE_LOGOUT_LOCAL_STORAGE_KEY } from '@/shared/constants';
import { useAppDispatch } from '@/shared/hooks';
import { setRedirectAfterLogInPath } from '@/shared/slices';

interface Params {
  isAuthRequired: boolean;
}

const useRedirectAfterLogIn = ({ isAuthRequired }: Params) => {
  const { pathname, search } = useLocation();
  const dispatch = useAppDispatch();

  useMount(() => {
    const hasAuthData = Boolean(authService.getAuthDataFromLocalStorage());

    if (hasAuthData) return;

    if (isAuthRequired) {
      dispatch(setRedirectAfterLogInPath(`${pathname}${search}`));
      return;
    }

    // This scenario covers cases when local storage data was deleted after logout.
    // We get the path that was saved before logout, as this happens when the refresh token expires.
    const pathBeforeLogout = localStorage.getItem(PATH_BEFORE_LOGOUT_LOCAL_STORAGE_KEY);

    if (pathBeforeLogout) {
      dispatch(setRedirectAfterLogInPath(pathBeforeLogout));
      localStorage.removeItem(PATH_BEFORE_LOGOUT_LOCAL_STORAGE_KEY);
    }
  });
};

export default useRedirectAfterLogIn;
