import { UnitsDictionary } from '@/entities/unit/types';

interface Params {
  unitsDictionary: UnitsDictionary;
  unitId: string;
}

const getUnitParentNames = ({ unitsDictionary, unitId }: Params) => {
  const parentNames: string[] = [];

  const addParentName = ({
    dictionary,
    id,
    namesList,
  }: {
    dictionary: UnitsDictionary;
    id: string;
    namesList: string[];
  }) => {
    const unit = dictionary[id];
    const parentUnit = unit?.parentId ? dictionary[unit.parentId] : null;
    if (parentUnit) {
      namesList.push(parentUnit.shortName);
      addParentName({ dictionary, id: parentUnit.id, namesList });
    }
  };

  addParentName({ dictionary: unitsDictionary, id: unitId, namesList: parentNames });

  return parentNames;
};

export default getUnitParentNames;
