import { Drawer as AntdDrawer, DrawerProps, Typography } from 'antd';
import { MobileContainer } from '@/shared/components/mobile/MobileContainer';
import { MobileView } from '@/shared/constants';
import { useIsTablet, useIsMobile } from '@/shared/hooks';
import styles from './styles.module.css';

export const Drawer = ({ title, onClose, open = false, destroyOnClose = true, footer, children }: DrawerProps) => {
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  return isMobile ? (
    <MobileContainer
      viewType={MobileView.SecondaryScreen}
      isOpen={Boolean(open)}
      onClose={onClose as VoidFunction}
      title={title}
      footer={footer}
      shouldDestroyOnClose={destroyOnClose}
      shouldSynchronizeWithModalQueryParam
    >
      {children}
    </MobileContainer>
  ) : (
    <AntdDrawer
      placement="right"
      open={open}
      onClose={onClose}
      classNames={{ header: styles.header, footer: styles.footer }}
      title={<Typography.Text className={styles.desktopTitle}>{title}</Typography.Text>}
      width={isTablet ? '400px' : '500px'}
      footer={footer}
      destroyOnClose={destroyOnClose}
    >
      {children}
    </AntdDrawer>
  );
};
