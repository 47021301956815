import { useCallback, useMemo, useState } from 'react';
import {
  ALL_INTERCEPTIONS_PATH,
  ALL_TRANSCRIPTS_QUERY_PARAMS_KEY,
  NetworkQueryParam,
  NETWORKS_QUERY_PARAMS_KEY,
  RADIO_NETWORKS_PATH_BASE,
  SourceQueryParam,
  SOURCES_FILTERS_STORAGE_KEY,
  SOURCES_PATH_BASE,
  TranscriptQueryParam,
} from '@/shared/constants';
import { useLocalStorageForLimitedDays, useLocalStorageListener } from '@/shared/hooks';
import { queryParamsService } from '@/shared/services';
import { ITEMS } from '../constants';

const useHrefsDictionary = () => {
  const [recalculateDictionaryTrigger, setRecalculateDictionaryTrigger] = useState(0);
  const triggerDictionaryRecalculation = useCallback(() => {
    setRecalculateDictionaryTrigger((prevState) => prevState + 1);
  }, []);

  const { getStorageValue: getTranscriptsStorageValue } = useLocalStorageForLimitedDays(
    ALL_TRANSCRIPTS_QUERY_PARAMS_KEY
  );
  const { getStorageValue: getNetworksStorageValue } = useLocalStorageForLimitedDays(NETWORKS_QUERY_PARAMS_KEY);
  const { getStorageValue: getSourcesStorageValue } = useLocalStorageForLimitedDays(SOURCES_FILTERS_STORAGE_KEY);

  useLocalStorageListener({
    key: ALL_TRANSCRIPTS_QUERY_PARAMS_KEY,
    memoOnLocalStorageChange: triggerDictionaryRecalculation,
  });
  useLocalStorageListener({
    key: NETWORKS_QUERY_PARAMS_KEY,
    memoOnLocalStorageChange: triggerDictionaryRecalculation,
  });
  useLocalStorageListener({
    key: SOURCES_FILTERS_STORAGE_KEY,
    memoOnLocalStorageChange: triggerDictionaryRecalculation,
  });

  const getQueryParamByPath = (pathname: string) => {
    if (pathname === ALL_INTERCEPTIONS_PATH) return TranscriptQueryParam;
    if (pathname === RADIO_NETWORKS_PATH_BASE) return NetworkQueryParam;
    if (pathname === SOURCES_PATH_BASE) return SourceQueryParam;
  };

  const getStorageByPath = (pathname: string) => {
    if (pathname === ALL_INTERCEPTIONS_PATH) return getTranscriptsStorageValue();
    if (pathname === RADIO_NETWORKS_PATH_BASE) return getNetworksStorageValue();
    if (pathname === SOURCES_PATH_BASE) return getSourcesStorageValue();
  };

  const getPathnameWithQueryParams = (pathname: string) => {
    const storage = getStorageByPath(pathname);
    const queryParam = getQueryParamByPath(pathname);
    const queryParamsString = queryParamsService.getQueryStringFromStorage(storage, queryParam);
    return queryParamsString ? `${pathname}?${queryParamsString}` : pathname;
  };

  return useMemo(
    () =>
      ITEMS.reduce(
        (accumulator, currentValue) => {
          if ([ALL_INTERCEPTIONS_PATH, RADIO_NETWORKS_PATH_BASE, SOURCES_PATH_BASE].includes(currentValue.key)) {
            accumulator[currentValue.key] = getPathnameWithQueryParams(currentValue.key);
          } else {
            accumulator[currentValue.key] = currentValue.key;
          }

          return accumulator;
        },
        {} as Record<string, string>
      ),
    [recalculateDictionaryTrigger]
  );
};

export default useHrefsDictionary;
