import { useEffect, useState, useCallback } from 'react';
import { Buffer } from 'buffer';
import zstd from 'zstandard-wasm';

const useSpectrumDecoder = () => {
  const [isDecoderReady, setIsDecoderReady] = useState(false);

  useEffect(() => {
    const loadZstdWasm = async () => {
      await zstd.loadWASM();
    };
    loadZstdWasm().then(() => {
      setIsDecoderReady(true);
    });
  }, []);

  const decodeSpectrumBase64 = useCallback(
    (spectrumBase64: string) => {
      if (!isDecoderReady) {
        console.error('decoder is not ready');
        return;
      }

      const spectrumDataUint8Array = zstd.decompress(Buffer.from(spectrumBase64, 'base64'));
      const spectrumData = Array.from(spectrumDataUint8Array);
      const convertedSpectrumData = spectrumData.map((num: number) => (255 * (num - 50)) / 110);
      return convertedSpectrumData;
    },
    [isDecoderReady]
  );

  return {
    isDecoderReady,
    decodeSpectrumBase64,
  };
};

export default useSpectrumDecoder;
