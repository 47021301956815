import { FC } from 'react';
import { Button, Dropdown } from 'antd';
import type { MenuInfo } from 'rc-menu/lib/interface';
import { RATE_OPTIONS } from '@/entities/source/@fox/components/RecordsTab/components/AudioPlayer/constants';
import { Tooltip } from '@/shared/components';
import styles from './styles.module.css';

interface Props {
  audioRate: number;
  onRateSelect: (e: MenuInfo) => void;
}

const TOOLTIP_TITLE = 'Змінити швидкість відтворення (Shift + ↓/↑)';

const RateMenu: FC<Props> = ({ audioRate, onRateSelect }) => {
  const menuOptions = {
    items: RATE_OPTIONS,
    onClick: onRateSelect,
    selectable: true,
    selectedKeys: [audioRate.toString()],
  };

  return (
    <Dropdown
      menu={menuOptions}
      trigger={['click']}
      overlayClassName={styles.rate}
    >
      <Button className={styles.btn}>
        <Tooltip
          placement="topRight"
          isDesktopOnly
          isOneLine
          title={TOOLTIP_TITLE}
        >
          {audioRate}x
        </Tooltip>
      </Button>
    </Dropdown>
  );
};

export default RateMenu;
