import { NOTIFICATION_TYPES } from '@/shared/constants';
import { NotificationProps, useNotificationContext } from '@/shared/context';
import { validationService } from '@/shared/services';
import { GetFormItemValidationParams } from '@/shared/types';

interface OpenFormItemValidationNotificationParams extends GetFormItemValidationParams {
  notificationParams?: NotificationProps;
}

const useOpenFormItemValidationNotification = () => {
  const { openNotification } = useNotificationContext();

  return ({ notificationParams, ...validationParams }: OpenFormItemValidationNotificationParams) => {
    const formItemValidation = validationService.getFormItemValidation(validationParams);

    if (!formItemValidation.validateStatus || !formItemValidation.help) return;

    openNotification({ type: NOTIFICATION_TYPES.ERROR, ...notificationParams, title: formItemValidation.help });
  };
};

export default useOpenFormItemValidationNotification;
