import { Flex, Form } from 'antd';
import FoxFrequencyCountRecords from '@/entities/source/@fox/components/ScanningTab/components/FoxFrequencyCountRecords';
import FoxFrequencyEncryptionType from '@/entities/source/@fox/components/ScanningTab/components/FoxFrequencyEncryptionType';
import FoxFrequencyNote from '@/entities/source/@fox/components/ScanningTab/components/FoxFrequencyNote';
import FoxFrequencyValue from '@/entities/source/@fox/components/ScanningTab/components/FoxFrequencyValue';
import SignalTypeIcon from '@/entities/source/@fox/components/SignalTypeIcon';
import { FoxFrequency } from '@/entities/source/@fox/types';
import styles from './styles.module.css';

const FREQUENCY_LABEL = 'Частота';
const RECORDS_LABEL = 'Записів';
const NOTES_LABEL = 'Нотатки';

type Props = {
  frequency: FoxFrequency;
};

const FoxFrequencyDetails = ({ frequency }: Props) => {
  return (
    <Form className={styles.form}>
      <Form.Item label={FREQUENCY_LABEL}>
        <Flex className={styles.frequencyValueRow}>
          <Flex className={styles.frequencyValueCol}>
            <FoxFrequencyValue value={frequency.value} />
            <SignalTypeIcon isDigital={frequency.isDigital} />
          </Flex>
          <FoxFrequencyEncryptionType encryptionTypes={frequency.encryptionTypes} />
        </Flex>
      </Form.Item>
      <Form.Item label={RECORDS_LABEL}>
        <FoxFrequencyCountRecords
          countRecords={frequency.countRecords}
          frequency={frequency.value}
        />
      </Form.Item>
      <FoxFrequencyNote
        label={NOTES_LABEL}
        note={frequency.note}
        foxFrequencyId={frequency.id}
      />
    </Form>
  );
};

export default FoxFrequencyDetails;
