import FoxKeyEncryption from '@/entities/source/@fox/components/KeysTab/components/FoxKeyEncryption';
import FoxKeyFrequency from '@/entities/source/@fox/components/KeysTab/components/FoxKeyFrequency';
import FoxKeyKeyId from '@/entities/source/@fox/components/KeysTab/components/FoxKeyKeyId';
import SourceActivity from '@/entities/source/@fox/components/SourceActivity';
import { FoxKey } from '@/entities/source/@fox/types';
import styles from './styles.module.css';

type Props = {
  foxKey: FoxKey;
};

const FoxKeyRow = ({ foxKey }: Props) => {
  return (
    <div className={styles.row}>
      <FoxKeyKeyId keyId={foxKey.keyId} />
      <FoxKeyFrequency frequency={foxKey.targetFrequency?.value} />
      <FoxKeyEncryption encryption={foxKey.encryption} />
      <SourceActivity
        className={styles.small}
        activity={foxKey.lastActiveAt}
      />
    </div>
  );
};

export default FoxKeyRow;
