import { Flex, Typography } from 'antd';
import { UnlockFilled } from '@ant-design/icons';
import { FoxKey } from '@/entities/source/@fox/types';
import styles from './styles.module.css';

interface Props {
  encryption: FoxKey['encryption'];
}

const FoxKeyEncryption = ({ encryption }: Props) => {
  const normalizedValue = encryption.toUpperCase();

  return (
    <Flex
      gap={8}
      align="center"
    >
      <UnlockFilled className={styles.unlock} />
      <Typography.Text ellipsis={{ tooltip: normalizedValue }}>{normalizedValue}</Typography.Text>
    </Flex>
  );
};

export default FoxKeyEncryption;
