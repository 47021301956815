import { useEffect, useCallback } from 'react';
import {
  OVERVIEW_PATH,
  ALL_INTERCEPTIONS_PATH,
  RADIO_NETWORKS_PATH_BASE,
  FACTIONS_PATH_BASE,
  SOURCES_PATH_BASE,
  ACCESS_GROUPS_PATH_BASE,
  CATEGORIES_PATH_BASE,
  UNITS_PATH_BASE,
  ALLOWED_PAGES,
  ALL_TRANSCRIPTS_QUERY_PARAMS_KEY,
  NETWORKS_QUERY_PARAMS_KEY,
  TranscriptQueryParam,
  NetworkQueryParam,
  SourceQueryParam,
  SOURCES_FILTERS_STORAGE_KEY,
} from '@/shared/constants';
import { useNavigate, useAppSelector } from '@/shared/hooks';
import { hotkeysHelperService } from '@/shared/services';
import { selectCurrentUserRole } from '@/shared/slices';
import { useNavigateWithStorageParams } from './useNavigateWithStorageParams';

let isGoKeyPressed = false;

const SEQUENCE_MAX_INTERVAL = 2000;

export const useNavigationHotkeys = () => {
  const role = useAppSelector(selectCurrentUserRole);
  const navigate = useNavigate();
  const navigateToAllInterceptions = useNavigateWithStorageParams(
    TranscriptQueryParam,
    ALL_TRANSCRIPTS_QUERY_PARAMS_KEY
  );
  const navigateToRadioNetworks = useNavigateWithStorageParams(NetworkQueryParam, NETWORKS_QUERY_PARAMS_KEY);
  const navigateToSources = useNavigateWithStorageParams(SourceQueryParam, SOURCES_FILTERS_STORAGE_KEY);

  const allowedPathes = ALLOWED_PAGES[role];

  const handleKeydown = useCallback(
    (e: KeyboardEvent) => {
      if (hotkeysHelperService.getIsInputOrTextarea(e)) return;

      const lowerCaseKey = e.key.toLowerCase();

      if (lowerCaseKey === 'g') {
        isGoKeyPressed = true;
        setTimeout(() => {
          isGoKeyPressed = false;
        }, SEQUENCE_MAX_INTERVAL);
      }

      if (!isGoKeyPressed) return;

      if (lowerCaseKey === 'o' && allowedPathes.includes(OVERVIEW_PATH)) navigate(OVERVIEW_PATH);
      if (lowerCaseKey === 'i' && allowedPathes.includes(ALL_INTERCEPTIONS_PATH))
        navigateToAllInterceptions(ALL_INTERCEPTIONS_PATH);
      if (lowerCaseKey === 'n' && allowedPathes.includes(RADIO_NETWORKS_PATH_BASE))
        navigateToRadioNetworks(RADIO_NETWORKS_PATH_BASE);
      if (lowerCaseKey === 's' && allowedPathes.includes(SOURCES_PATH_BASE)) navigateToSources(SOURCES_PATH_BASE);
      if (lowerCaseKey === 'f' && allowedPathes.includes(FACTIONS_PATH_BASE)) navigate(FACTIONS_PATH_BASE);
      if (lowerCaseKey === 'c' && allowedPathes.includes(CATEGORIES_PATH_BASE)) navigate(CATEGORIES_PATH_BASE);
      if (lowerCaseKey === 'a' && allowedPathes.includes(ACCESS_GROUPS_PATH_BASE)) navigate(ACCESS_GROUPS_PATH_BASE);
      if (lowerCaseKey === 'u' && allowedPathes.includes(UNITS_PATH_BASE)) navigate(UNITS_PATH_BASE);
    },
    [navigateToAllInterceptions, navigateToRadioNetworks, navigateToSources]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);
    return () => document.removeEventListener('keydown', handleKeydown);
  }, [handleKeydown]);
};
