type WebGlStatus = 'supported' | 'disabled' | 'not supported';

const getWebglStatus = (): WebGlStatus => {
  if (window.WebGLRenderingContext) {
    const canvas = document.createElement('canvas');
    try {
      const context = canvas.getContext('webgl2') ?? canvas.getContext('webgl');
      if (context && typeof context.getParameter === 'function') {
        return 'supported';
      }
    } catch (e) {
      return 'disabled';
    }
  }
  return 'not supported';
};

export default getWebglStatus;
