import maplibregl from 'maplibre-gl';
import styles from './styles.module.css';

const tooltip = new maplibregl.Popup({
  className: styles.tooltip,
  maxWidth: 'auto',
  offset: 10,
  closeButton: false,
});

export default tooltip;
