import { ReactElement } from 'react';
import { SearchField } from '@/shared/components';
import { useRecordsSearchField } from './hooks';

interface Props {
  onFilterClick: () => void;
  additionalControlsRight?: ReactElement;
}

const RecordsSearchField = ({ onFilterClick, additionalControlsRight }: Props) => {
  const { count, search, handleSearch } = useRecordsSearchField();

  return (
    <SearchField
      value={search}
      onSearch={handleSearch}
      filterButtonOptions={{ selectedCount: count, onClick: onFilterClick }}
      additionalControlsRight={additionalControlsRight}
      isAsync
    />
  );
};

export default RecordsSearchField;
