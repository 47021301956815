import { useState, useCallback, useEffect } from 'react';
import maplibregl from 'maplibre-gl';
import { styleSchema } from '@/entities/map/config';
import { LayerService } from '@/entities/map/services';
import { MapInitOptions, MaplibreMap, TileSource } from '@/entities/map/types';
import { LatLngCoordinates } from '@/shared/types';
import useIsBrowserTabActive from './useIsBrowserTabActive';
import useWebGlStatus from './useWebGlStatus';

const useInitializeMap = (mapOptions: MapInitOptions, tileSource: TileSource) => {
  const [map, setMap] = useState<MaplibreMap | null>(null);
  const [mapContainer, setMapContainer] = useState<HTMLDivElement | null>(null);
  const [center, setCenter] = useState<LatLngCoordinates | null>(null);
  const [zoom, setZoom] = useState<number | null>(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const webGlStatus = useWebGlStatus();
  const isBrowserTabActive = useIsBrowserTabActive();

  const mapContainerRefCallback = useCallback((node: HTMLDivElement | null) => {
    if (!node) return;

    setMapContainer(node);
    setCenter(null);
    setZoom(null);
  }, []);

  const initMap = useCallback(() => {
    if (webGlStatus !== 'supported' || !isBrowserTabActive || !mapContainer) return;

    const { bounds, fitBoundsOptions, ...restOptions } = mapOptions;
    const mapViewport = center && zoom ? { center, zoom } : { bounds, fitBoundsOptions };

    const mapInstance = new maplibregl.Map({
      ...restOptions,
      ...mapViewport,
      container: mapContainer,
      style: { ...styleSchema, layers: LayerService.getTileLayers(tileSource) },
      attributionControl: false,
    }) as MaplibreMap;

    // some Mapbox plugins are not yet adapted to Maplibre and require this classname
    mapInstance.getCanvas().classList.add('mapboxgl-canvas');

    if (mapOptions.dragRotate === false) {
      // disable map rotation using touch rotation gesture
      mapInstance.touchZoomRotate.disableRotation();
    }

    setMap(mapInstance);
  }, [webGlStatus, isBrowserTabActive, mapContainer, mapOptions]);

  useEffect(() => {
    if (!map) return;
    const handleLoad = () => setIsMapLoaded(true);

    map.on('load', handleLoad);
    return () => {
      map.off('load', handleLoad);
    };
  }, [map]);

  useEffect(() => {
    if (!isBrowserTabActive) {
      const mapCenter = map?.getCenter() || null;
      const mapZoom = map?.getZoom() || null;

      setIsMapLoaded(false);
      setCenter(mapCenter);
      setZoom(mapZoom);
      map?.remove();
      setMap(null);
    } else {
      initMap();
    }
  }, [isBrowserTabActive, initMap]);

  return { mapContainerRefCallback, map, isMapLoaded };
};

export default useInitializeMap;
