import { useCallback } from 'react';
import type { MenuInfo } from 'rc-menu/lib/interface';
import { OnSettingsUpdate, PlayerSettings } from '../type';

interface Params {
  memoSettings: PlayerSettings;
  memoOnSettingsUpdate?: OnSettingsUpdate;
}

const useRateControls = ({ memoSettings, memoOnSettingsUpdate }: Params) => {
  const memoUpdateRate = useCallback(
    (rate: number) => {
      memoOnSettingsUpdate?.({ audioRate: rate });
    },
    [memoOnSettingsUpdate]
  );

  const handleRateSelect = ({ key }: MenuInfo) => {
    const rate = Number(key);
    memoUpdateRate(rate);
  };

  return { audioRate: memoSettings.audioRate ?? 1, handleRateSelect, memoUpdateRate };
};

export default useRateControls;
