import { Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useCollapseSidebar } from './hooks';
import styles from './styles.module.css';

type Props = {
  isButtonVisible: boolean;
};

export const MenuToggleButton = ({ isButtonVisible }: Props) => {
  const { isNavigationSidebarCollapsed, handleToggleNavigationSidebar } = useCollapseSidebar();

  return (
    <div className={styles.menuToggleButtonWrapper}>
      <Button
        shape="circle"
        className={styles.menuToggleButton}
        style={{
          opacity: isButtonVisible ? '1' : '0',
          rotate: isNavigationSidebarCollapsed ? '' : '180deg',
        }}
        icon={<RightOutlined className={styles.menuToggleIcon} />}
        onClick={handleToggleNavigationSidebar}
      />
    </div>
  );
};
