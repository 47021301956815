import { ReactNode } from 'react';
import { TagProps as AntdTagProps } from 'antd';
import type { RenderFunction } from 'antd/es/_util/getRenderPropValue';
import { Tooltip, TooltipProps } from '@/shared/components/ui/Tooltip';
import { BaseTag } from './components/BaseTag';
import styles from './styles.module.css';

export { BaseTag } from './components/BaseTag';

export type TagProps = Pick<AntdTagProps, 'icon' | 'className' | 'onClose' | 'children'> &
  TooltipProps & {
    isLoading?: boolean;
    tooltipContent?: ReactNode | RenderFunction;
  };

export const Tag = ({
  children,
  icon,
  isLoading = false,
  tooltipContent = '',
  onClose = undefined,
  className = '',
  ...tooltipProps
}: TagProps) => (
  <Tooltip
    title={tooltipContent}
    {...tooltipProps}
  >
    {/* need to wrap BaseTag with plain DOM element to avoid findDOMNode warning caused by antd component */}
    <span className={styles.tagWrapper}>
      <BaseTag
        className={className}
        icon={icon}
        onClose={onClose}
        closable={!!onClose}
        isLoading={isLoading}
      >
        {children}
      </BaseTag>
    </span>
  </Tooltip>
);
