import { usePreview } from 'react-dnd-preview';
import { createPortal } from 'react-dom';
import { Typography } from 'antd';
import { FrequencyIcon } from '@/shared/assets';
import { Frequency } from '@/shared/types';
import styles from './styles.module.css';

const NetworkPreview = () => {
  const preview = usePreview<{ item: Frequency }>();
  if (!preview.display) {
    return null;
  }
  const {
    item: { item: frequency },
    style,
  } = preview;

  return createPortal(
    <div
      className={styles.networkPreviewWrapper}
      style={style}
    >
      <FrequencyIcon className={styles.networkPreviewIcon} />
      <Typography.Paragraph
        ellipsis={{ rows: 2 }}
        strong
        className={styles.networkPreviewText}
      >
        {frequency.value}
      </Typography.Paragraph>
    </div>,
    document.body
  );
};

export default NetworkPreview;
