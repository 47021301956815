import classNames from 'classnames';
import styles from './styles.module.css';
import { HorizontalAxisProps as Props } from './types';
import useHorizontalAxis from './useHorizontalAxis';
import { getMarkStyle } from './utils';

const HorizontalAxis = ({ startPoint, endPoint, bottom, wrapperId }: Props) => {
  const { wrapperStyle, marks, isShortAxis } = useHorizontalAxis({
    startPoint,
    endPoint,
    bottom,
  });

  return (
    <div
      id={wrapperId}
      className={styles.wrapper}
      style={wrapperStyle}
    >
      <div className={styles.borderLeft} />
      {marks.map((markPoint) => (
        <div
          key={markPoint}
          className={classNames(styles.markWrapper, {
            [styles.shortAxisMarkWrapper]: isShortAxis,
          })}
          style={getMarkStyle({ startPoint, endPoint, markPoint })}
        >
          <span className={styles.verticalLine} />
          <span className={styles.mark}>{markPoint}</span>
        </div>
      ))}
      <div className={styles.borderRight} />
    </div>
  );
};

export default HorizontalAxis;
