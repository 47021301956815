import { QueryClientConfig } from '@tanstack/react-query';

export const config: QueryClientConfig['defaultOptions'] = {
  queries: {
    retry: false,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true, // when we refetch data of an active useQuery/useInfiniteQuery instance, while keepPreviousData === true, old data will be displayed
    cacheTime: 0, // disable react-query caching globally
    networkMode: 'always', // proper detecting of online/offline status of the application
  },
  mutations: {
    networkMode: 'always', // proper detecting of online/offline status of the application
  },
};
