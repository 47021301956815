import { Space, Typography } from 'antd';
import { EMPTY_ENCRYPTION_TYPE } from '@/entities/source/@fox/components/RecordsTab/constants';
import SignalTypeIcon from '@/entities/source/@fox/components/SignalTypeIcon';
import { FoxRecordSignalType } from '@/entities/source/@fox/constants';
import { File } from '@/entities/source/@fox/types';
import styles from './styles.module.css';

const NFM_SIGNAL = 'NFM';

interface Props {
  signalType: File['metadata']['signalType'];
  encryptionType: File['metadata']['encryptionType'];
}

const RecordType = ({ signalType, encryptionType }: Props) => {
  const type = signalType === FoxRecordSignalType.Digital ? encryptionType ?? EMPTY_ENCRYPTION_TYPE : NFM_SIGNAL;

  return (
    <Space className={styles.wrapper}>
      <SignalTypeIcon isDigital={signalType === FoxRecordSignalType.Digital} />
      <Typography.Text ellipsis={{ tooltip: type }}>{type}</Typography.Text>
    </Space>
  );
};

export default RecordType;
