import { MaskedInput as RawMaskedInput } from 'antd-mask-input';
import { MaskedInputProps } from 'antd-mask-input/build/main/lib/MaskedInput';
import classNames from 'classnames';
import { useIsTabletOrMobile } from '@/shared/hooks';
import styles from './styles.module.css';

export const MaskedInput = ({ 'aria-invalid': isError, ...props }: MaskedInputProps) => {
  const isTabletOrMobile = useIsTabletOrMobile();
  return (
    <RawMaskedInput
      size={isTabletOrMobile ? 'large' : 'middle'}
      {...props}
      className={classNames(styles.rawMaskedInput, {
        [styles.error]: isError,
      })}
    />
  );
};
