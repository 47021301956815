import { FC } from 'react';
import { Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useBlurButtonOnClick } from '@/entities/source/@fox/components/RecordsTab/components/AudioPlayer/hooks';
import { PlayIcon, PauseIcon } from '@/shared/assets';
import { Tooltip } from '@/shared/components';
import styles from './styles.module.css';

interface Props {
  isLoading: boolean;
  isPlaying: boolean;
  playPause: VoidFunction;
}

const TOOLTIP_TITLE = 'Відтворити аудіо (Пробіл)';

const ControlCheckbox: FC<Props> = ({ isLoading, isPlaying, playPause }) => {
  const { buttonRef, blurButtonOnClick: handleClick } = useBlurButtonOnClick({ onClick: playPause });

  return (
    <Tooltip
      isDesktopOnly
      isOneLine
      title={TOOLTIP_TITLE}
    >
      {isLoading ? (
        <Button
          className={styles.btn}
          icon={<LoadingOutlined />}
        />
      ) : (
        <div className={styles.btn}>
          <Button
            ref={buttonRef}
            icon={isPlaying ? <PauseIcon className={styles.icon} /> : <PlayIcon className={styles.icon} />}
            onKeyDown={(e) => e.stopPropagation()}
            onClick={handleClick}
          />
        </div>
      )}
    </Tooltip>
  );
};

export default ControlCheckbox;
