import { useQuery } from '@tanstack/react-query';
import { RADIO_NETWORKS_FILTER_QUERY_KEY } from '@/shared/constants';
import { NetworkFilters } from '@/shared/types';
import { getRadioNetworksFilter } from '../api';

const DEFAULT_FILTERS: NetworkFilters = {
  locations: [],
  factions: [],
  categories: [],
  groups: [],
};

const useRadioNetworksFilter = () => {
  const { data, isLoading } = useQuery({
    queryKey: [RADIO_NETWORKS_FILTER_QUERY_KEY],
    queryFn: getRadioNetworksFilter,
  });

  return {
    networkFilters: data?.data || DEFAULT_FILTERS,
    isNetworkFiltersLoading: isLoading,
  };
};

export default useRadioNetworksFilter;
