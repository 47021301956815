import { useState, useCallback } from 'react';
import { PlayerSettings, AudioPlayerProps } from '../type';

interface Params extends Pick<AudioPlayerProps, 'memoOnSettingsUpdate'> {
  defaultIsSkipSilenceEnabled: PlayerSettings['isSkipSilenceEnabled'];
}

const useSilenceSkipManager = ({ defaultIsSkipSilenceEnabled, memoOnSettingsUpdate }: Params) => {
  const [isSkipSilenceEnabled, setIsSkipSilenceEnabled] = useState(!!defaultIsSkipSilenceEnabled);

  const memoHandleSilenceSkipChange = useCallback(() => {
    setIsSkipSilenceEnabled((prevState) => {
      memoOnSettingsUpdate({ isSkipSilenceEnabled: !prevState });
      return !prevState;
    });
  }, [memoOnSettingsUpdate]);

  return {
    isSkipSilenceEnabled,
    memoHandleSilenceSkipChange,
  };
};

export default useSilenceSkipManager;
