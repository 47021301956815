import { StyleSpecification } from 'maplibre-gl';
import { MapSource } from '@/entities/map/constants';
import {
  CARTO_DARK_MATTER_SPRITE_URL,
  CARTO_DARK_MATTER_URL_PATTERN,
  CARTO_GLYPH_URL_PATTERN,
  CARTO_POSITRON_SPRITE_URL,
  CARTO_POSITRON_URL_PATTERN,
  CARTO_VECTOR_URL,
  ELEVATION_NORTH_EAST_POINT,
  ELEVATION_SOUTH_WEST_POINT,
  ELEVATION_URL_PATTERN,
  GOOGLE_HYBRID_URL_PATTERN,
  GOOGLE_LABELS_URL_PATTERN,
  GOOGLE_SATELLITE_URL_PATTERN,
  GOOGLE_SUBDOMAINS,
  OPENSTREET_URL_PATTERN,
} from './constants';
import { getTileUrls } from './utils';

const styleSchema: StyleSpecification = {
  version: 8,
  sprite: [
    {
      id: 'carto-light',
      url: CARTO_POSITRON_SPRITE_URL,
    },
    {
      id: 'carto-dark',
      url: CARTO_DARK_MATTER_SPRITE_URL,
    },
  ],
  glyphs: CARTO_GLYPH_URL_PATTERN,
  sources: {
    [MapSource.Google]: {
      type: 'raster',
      tiles: getTileUrls(GOOGLE_HYBRID_URL_PATTERN, GOOGLE_SUBDOMAINS),
      tileSize: 256,
      maxzoom: 20,
    },
    [MapSource.GoogleSatellite]: {
      type: 'raster',
      tiles: getTileUrls(GOOGLE_SATELLITE_URL_PATTERN, GOOGLE_SUBDOMAINS),
      tileSize: 256,
      maxzoom: 20,
    },
    [MapSource.GoogleLabels]: {
      type: 'raster',
      tiles: getTileUrls(GOOGLE_LABELS_URL_PATTERN, GOOGLE_SUBDOMAINS),
      tileSize: 256,
      maxzoom: 20,
    },
    [MapSource.OpenStreet]: {
      type: 'raster',
      tiles: [OPENSTREET_URL_PATTERN],
      tileSize: 256,
      maxzoom: 18,
    },
    [MapSource.Carto]: {
      type: 'vector',
      url: CARTO_VECTOR_URL,
    },
    [MapSource.CartoLight]: {
      type: 'raster',
      tiles: [CARTO_POSITRON_URL_PATTERN],
      tileSize: 256,
      maxzoom: 20,
    },
    [MapSource.CartoDark]: {
      type: 'raster',
      tiles: [CARTO_DARK_MATTER_URL_PATTERN],
      tileSize: 256,
      maxzoom: 20,
    },
    [MapSource.Elevation]: {
      type: 'raster',
      tiles: [ELEVATION_URL_PATTERN],
      tileSize: 256,
      maxzoom: 10,
      scheme: 'tms',
      bounds: [
        ELEVATION_SOUTH_WEST_POINT.lng,
        ELEVATION_SOUTH_WEST_POINT.lat,
        ELEVATION_NORTH_EAST_POINT.lng,
        ELEVATION_NORTH_EAST_POINT.lat,
      ],
    },
  },
  layers: [],
};

export default styleSchema;
