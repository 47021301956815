import { forwardRef } from 'react';
import { Button as AntdButton } from 'antd';
import classNames from 'classnames';
import { useButton } from './hooks';
import styles from './styles.module.css';
import { ButtonProps } from './types';

export type { ButtonProps } from './types';

export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>((props, ref) => {
  const {
    className,
    isIconButton,
    size,
    handleTouchStart,
    handleTouchEnd,
    handleMouseDown,
    handleMouseUp,
    handleClick,
    restButtonProps,
  } = useButton(props);

  return (
    <AntdButton
      {...restButtonProps}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onClick={handleClick}
      ref={ref}
      size={size}
      className={classNames(
        {
          [styles.iconButton]: isIconButton,
        },
        className
      )}
    />
  );
});

Button.displayName = 'Button';
