import { ReactElement } from 'react';
import styles from './style.module.css';
import useContentSize from './useContentSize';

interface Props {
  renderChildren: ({ contentWidth, contentHeight }: { contentWidth: number; contentHeight: number }) => ReactElement;
}

const ContentSize = ({ renderChildren }: Props) => {
  const { wrapperRef, contentWidth, contentHeight } = useContentSize();

  return (
    <div
      ref={wrapperRef}
      className={styles.wrapper}
    >
      {renderChildren({ contentHeight, contentWidth })}
    </div>
  );
};

export default ContentSize;
