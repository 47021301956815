import { useLocation } from 'react-router-dom';
import RouteLink from '@/shared/components/RouteLink';
import { useNavigateWithStorageParams } from '@/shared/components/layouts/LayoutApp/hooks/useNavigateWithStorageParams';
import {
  ALL_INTERCEPTIONS_PATH,
  ALL_TRANSCRIPTS_QUERY_PARAMS_KEY,
  COPIES,
  NetworkQueryParam,
  NETWORKS_QUERY_PARAMS_KEY,
  NOTIFICATION_TYPES,
  RADIO_NETWORKS_PATH_BASE,
  SourceQueryParam,
  SOURCES_FILTERS_STORAGE_KEY,
  SOURCES_PATH_BASE,
  TranscriptQueryParam,
} from '@/shared/constants';
import { useNotificationContext } from '@/shared/context';
import { useAppDispatch, useAppSelector, useIsTabletDevice, useNavigate } from '@/shared/hooks';
import {
  selectIsLayoutSidebarLeftCollapsed,
  selectIsNavigationSidebarCollapsed,
  setIsLayoutSidebarLeftCollapsed,
} from '@/shared/slices';
import { ITEMS, ITEMS_WITH_COLLAPSIBLE_PANEL } from '../constants';
import { MenuItem } from '../types';
import useHrefsDictionary from './useHrefsDictionary';

type Params = {
  onMenuItemClick?: VoidFunction;
};

const useMenuLinks = ({ onMenuItemClick }: Params) => {
  const isTabletDevice = useIsTabletDevice();
  const { openNotification } = useNotificationContext();
  const isNavigationSidebarCollapsed = useAppSelector(selectIsNavigationSidebarCollapsed);
  const isLayoutSidebarCollapsed = useAppSelector(selectIsLayoutSidebarLeftCollapsed);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const hrefsDictionary = useHrefsDictionary();

  const navigateToAllInterceptions = useNavigateWithStorageParams(
    TranscriptQueryParam,
    ALL_TRANSCRIPTS_QUERY_PARAMS_KEY
  );
  const navigateToRadioNetworks = useNavigateWithStorageParams(NetworkQueryParam, NETWORKS_QUERY_PARAMS_KEY);
  const navigateToSources = useNavigateWithStorageParams(SourceQueryParam, SOURCES_FILTERS_STORAGE_KEY);

  const handleClick = (key: MenuItem['key']) => {
    onMenuItemClick?.();

    // locks the navigation if user is offline
    if (!window.navigator.onLine) {
      openNotification({ type: NOTIFICATION_TYPES.ERROR, title: COPIES.CONNECTION_FAILED });
      return;
    }

    // handle repeated navigation
    if (pathname.startsWith(key)) {
      if (isTabletDevice && isNavigationSidebarCollapsed && ITEMS_WITH_COLLAPSIBLE_PANEL.includes(key)) {
        dispatch(setIsLayoutSidebarLeftCollapsed(!isLayoutSidebarCollapsed));
      }
      return;
    }

    dispatch(setIsLayoutSidebarLeftCollapsed(true));

    if (key === ALL_INTERCEPTIONS_PATH) {
      return navigateToAllInterceptions(key);
    }

    if (key === RADIO_NETWORKS_PATH_BASE) {
      return navigateToRadioNetworks(key);
    }

    if (key === SOURCES_PATH_BASE) {
      return navigateToSources(key);
    }

    navigate(key);
  };

  return ITEMS.map((item) => ({
    ...item,
    label: (
      <RouteLink
        href={hrefsDictionary[item.key]}
        onClick={() => handleClick(item.key)}
      >
        {item.label}
      </RouteLink>
    ),
  }));
};

export default useMenuLinks;
