import { useAppDispatch } from '@/shared/hooks';
import { resetActiveMobileDetailsManagerState } from '@/shared/slices';
import { MobileDetailsManagerProps } from '../types';
import { MobileDetailsContainer, MobileDetailsContainerHeader, MobileDetailsView } from './components';
import { useMobileDetailsManager } from './useMobileDetailsManager';

export { ResponsiveDetailActions } from './components/ResponsiveDetailActions';

export const MobileDetailsManager = <T,>({
  headerClassName,
  createTitlePrefix,
  detailsTitle,
  items,
  detailItemsOrder,
  selectedItem,
  detailsComponent,
  editComponent,
  createComponent,
  actions,
  onDetailContainerClose,
  isOpen = false,
  isLoading,
}: MobileDetailsManagerProps<T>) => {
  const dispatch = useAppDispatch();
  const { titlePrefix, title, detailItems, isDetails, isEdit, isCreate, viewType } = useMobileDetailsManager({
    createTitlePrefix,
    items,
    detailItemsOrder,
    selectedItem,
    isOpen,
  });

  const handleDetailContainerClose = () => {
    onDetailContainerClose?.();
    dispatch(resetActiveMobileDetailsManagerState());
  };

  const detailItemsView = detailItems && <MobileDetailsView items={detailItems} />;

  return (
    <MobileDetailsContainer
      title={
        <MobileDetailsContainerHeader
          className={headerClassName}
          prefix={titlePrefix}
          title={isDetails ? detailsTitle || title : title}
          actions={isDetails ? actions : undefined}
        />
      }
      isOpen={isOpen}
      viewType={viewType}
      isLoading={isLoading}
      onClose={isDetails ? handleDetailContainerClose : undefined}
    >
      {isDetails && (detailsComponent || detailItemsView)}
      {isEdit && editComponent}
      {isCreate && createComponent}
    </MobileDetailsContainer>
  );
};
