import { useMutation, MutateOptions } from '@tanstack/react-query';
import { deleteFile as deleteFileApi } from '@/entities/source/@fox/api';
import { DELETE_RECORD_MUTATION_KEY } from '@/shared/constants';

const useDeleteRecord = (options: MutateOptions<unknown, unknown, string> = {}) => {
  const { mutate: deleteRecord, isLoading: isRecordDeleting } = useMutation({
    ...options,
    mutationKey: [DELETE_RECORD_MUTATION_KEY],
    mutationFn: deleteFileApi,
  });

  return {
    deleteRecord,
    isRecordDeleting,
  };
};

export default useDeleteRecord;
