import { useDragLayer } from 'react-dnd';
import { Frequency } from '@/shared/types';

const useDraggingFrequencyId = () => {
  const { draggingItem } = useDragLayer((monitor) => ({
    draggingItem: monitor.getItem<{ item: Frequency }>(),
  }));

  return draggingItem?.item.id;
};

export default useDraggingFrequencyId;
