import { DetailItem } from '@/shared/components/mobile/mobileManagers/MobileDetailsManager/types';
import { COPIES } from '@/shared/constants';
import { MobileDetailItem } from './components';

type Props = {
  items: DetailItem[];
};

export const MobileDetailsView = ({ items }: Props) => {
  return items.map((item) => {
    const { id, title, placeholder, value } = item;
    return (
      <MobileDetailItem
        title={title}
        isNoData={!value}
        key={id}
      >
        {value ?? placeholder ?? COPIES.NO_DATA}
      </MobileDetailItem>
    );
  });
};
