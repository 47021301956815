import { ScrollUpToRefresh, Table } from '@/shared/components';
import { useRecordsTable } from './hooks';
import styles from './styles.module.css';

const RecordsTable = () => {
  const { filesList, columns, isFilesLoading, refetchFiles, handleScroll, handleRow, rowClassName } = useRecordsTable();

  return (
    <ScrollUpToRefresh
      onRefresh={refetchFiles}
      isScrollUpToRefreshEnabled={filesList.length > 0}
      onScroll={handleScroll}
    >
      <Table
        rowKey="id"
        className={styles.table}
        dataSource={filesList}
        columns={columns}
        pagination={false}
        loading={isFilesLoading}
        onRow={handleRow}
        rowClassName={rowClassName}
      />
    </ScrollUpToRefresh>
  );
};

export default RecordsTable;
