import { useState, useEffect, useCallback, useMemo } from 'react';
import { usePrevious, useUpdateEffect } from '@reactuses/core';
import { selectIsRecordPlaying, setIsRecordPlaying, setSelectedRecordId } from '@/entities/source/slices';
import { useAppDispatch, useParams, useAppSelector } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';
import useFile from './useFile';
import useFoxRecordsQueryParams from './useFoxRecordsQueryParams';
import useGetFiles from './useGetFiles';
import useGetRecord from './useGetRecord';
import useGetSiblingRecordId from './useGetSiblingRecordId';
import useNavigateToSiblingRecord from './useNavigateToSiblingRecord';

interface Params {
  isSkipSilenceEnabled: boolean;
  isAutoplayEnabled: boolean;
  isCurrentRecordFetched: boolean;
}

const useAutoplay = ({ isAutoplayEnabled, isSkipSilenceEnabled, isCurrentRecordFetched }: Params) => {
  const dispatch = useAppDispatch();
  const isRecordPlaying = useAppSelector(selectIsRecordPlaying);
  const { tabEntityId } = useParams<SourceRouteParams>();
  const queryParams = useFoxRecordsQueryParams();
  const { filesList } = useGetFiles({
    options: {
      enabled: false,
    },
    queryParams,
  });
  const [isWaitingNewRecords, setIsWaitingNewRecords] = useState(false);
  const { memoNavigateToNextRecord } = useNavigateToSiblingRecord();
  const { memoGetNextRecordId } = useGetSiblingRecordId();
  const [prefetchRecordId, setPrefetchRecordId] = useState<string | null>(null);
  const file = useFile();
  const previousFile = usePrevious(file);
  const nextRecordId = useMemo(() => {
    if (!tabEntityId) return null;

    return memoGetNextRecordId({
      currentRecordId: tabEntityId,
      isSkipAudioWithoutVoiceEnabled: isSkipSilenceEnabled,
      isSkipNotDecodedFileEnabled: true,
    });
  }, [tabEntityId, isSkipSilenceEnabled, memoGetNextRecordId]);

  useGetRecord(prefetchRecordId ?? '');

  const memoPlayNextRecord = useCallback(() => {
    memoNavigateToNextRecord({
      isSkipAudioWithoutVoiceEnabled: isSkipSilenceEnabled,
      isSkipNotDecodedFileEnabled: true,
    });

    if (!tabEntityId) return;

    if (nextRecordId) {
      dispatch(setSelectedRecordId(nextRecordId));
      dispatch(setIsRecordPlaying(true));
    } else {
      dispatch(setIsRecordPlaying(false));
      setIsWaitingNewRecords(true);
    }
  }, [dispatch, isSkipSilenceEnabled, memoNavigateToNextRecord, nextRecordId, tabEntityId]);

  useEffect(() => {
    if (isAutoplayEnabled && isCurrentRecordFetched && tabEntityId) {
      setPrefetchRecordId(nextRecordId);
    }
  }, [isAutoplayEnabled, isCurrentRecordFetched, nextRecordId, tabEntityId]);

  useEffect(() => {
    if (!isWaitingNewRecords || !filesList.length || !tabEntityId) return;

    if (isRecordPlaying) {
      setIsWaitingNewRecords(false);
    } else if (nextRecordId) {
      memoPlayNextRecord();
      setIsWaitingNewRecords(false);
    }
  }, [filesList, isWaitingNewRecords, memoPlayNextRecord, nextRecordId, tabEntityId, isRecordPlaying]);

  useUpdateEffect(() => {
    if (isAutoplayEnabled && file && previousFile && file.id !== previousFile?.id) {
      dispatch(setSelectedRecordId(file.id));
      dispatch(setIsRecordPlaying(!!file?.metadata.isDecoded));
    }
  }, [isAutoplayEnabled, previousFile, file]);

  return { memoPlayNextRecord };
};

export default useAutoplay;
