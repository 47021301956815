import { FC } from 'react';
import {
  AirIcon,
  AntiAircraftDefenseIcon,
  ArmyCorpsIcon,
  ArmyIcon,
  ArtilleryIcon,
  BattalionIcon,
  BrigadeIcon,
  CompanyIcon,
  DepartmentIcon,
  DivisionIcon,
  EngineeringIcon,
  ERWIcon,
  IntelligenceIcon,
  MechanizedIcon,
  MotorRifleIcon,
  NavalIcon,
  PlatoonIcon,
  PMCIcon,
  RegimentIcon,
  ShootersIcon,
  SquadIcon,
  TankIcon,
  UAVIcon,
} from '@/shared/assets';

export const FACTIONS = 'Угруповання';
export const ALL_FACTIONS = 'Всі угруповання';
export const CREATE_FACTION = 'Створити угруповання';
export const EDIT_FACTION = 'Редагувати угруповання';

export enum OrderBy {
  Id = 'id',
  Name = 'name',
}

export enum FactionKey {
  ParentId = 'parent_id',
  Country = 'country',
  LevelId = 'level_id',
  TypeId = 'type_id',
  Name = 'name',
  NameRU = 'name_ru',
  ShortName = 'short_name',
  ShortNameRU = 'short_name_ru',
  FullName = 'full_name',
  FullShortName = 'full_short_name',
  MilitaryBase = 'military_base',
  RegistryNumber = 'state_registry_number',
  BaseLocation = 'base_location',
  Info = 'info',
}

export const LEVELS_ICONS: Record<number, FC> = {
  1: SquadIcon,
  2: DepartmentIcon,
  3: PlatoonIcon,
  4: CompanyIcon,
  5: BattalionIcon,
  6: RegimentIcon,
  7: BrigadeIcon,
  8: DivisionIcon,
  9: ArmyCorpsIcon,
  10: ArmyIcon,
};

export const TYPES_ICONS: Record<number, FC> = {
  2: MotorRifleIcon,
  3: ArtilleryIcon,
  4: ERWIcon,
  5: IntelligenceIcon,
  6: UAVIcon,
  7: TankIcon,
  8: EngineeringIcon,
  9: AntiAircraftDefenseIcon,
  11: NavalIcon,
  13: AirIcon,
  15: ShootersIcon,
  16: MechanizedIcon,
  17: PMCIcon,
};
