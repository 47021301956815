import { useQuery } from '@tanstack/react-query';
import { getSubscriptions } from '@/entities/subscription';
import { SUBSCRIPTIONS_QUERY_KEY } from '@/shared/constants';

const useGetSubscriptions = () => {
  const {
    data: subscriptions,
    isLoading: isSubscriptionsLoading,
    isFetching: isSubscriptionFetching,
  } = useQuery({
    queryKey: [SUBSCRIPTIONS_QUERY_KEY],
    queryFn: getSubscriptions,
    staleTime: 30000,
  });
  return { subscriptions, isSubscriptionsLoading, isSubscriptionFetching };
};

export default useGetSubscriptions;
