import { request, ResponseData } from '@/shared/api';
import { V2_PREFIX } from '@/shared/config';
import { DEFAULT_PAGE_SIZE } from '@/shared/constants';
import { TranscriptionFilters } from '@/shared/types';
import { Transcription } from '../types';
import { PaginatedTranscripts } from './types';
import { getTranscriptsFilterSchema, getTranscriptsListSchema, getTranscriptSchema } from './validationSchemas';

export const getTranscriptsFilter = () =>
  request<ResponseData<TranscriptionFilters>>(`/transcripts/filter`, {
    validationSchema: getTranscriptsFilterSchema,
  });

export const getTranscriptsList = ({ currentPage, queryParams }: { currentPage: number; queryParams: string }) => {
  const pageParams = new URLSearchParams([
    ['page', String(currentPage)],
    ['size', DEFAULT_PAGE_SIZE],
  ]).toString();

  return request<PaginatedTranscripts>(`/transcripts?${pageParams}&${queryParams}`, {
    endpointPrefix: V2_PREFIX,
    validationSchema: getTranscriptsListSchema,
  });
};

export const getTranscript = (transcriptId: string) =>
  request<ResponseData<Transcription>>(`/transcripts/${transcriptId}`, {
    validationSchema: getTranscriptSchema,
  }).then(({ data }) => data);
