import { MobileView } from '@/shared/constants';
import { useNavigate, NavigateParams } from '@/shared/hooks/routingHooks/useNavigate';
import { useIsMobile } from './useMediaQuery';
import { useMobileActiveViewQueryParam } from './useMobileActiveViewQueryParam';

type Params = NavigateParams & {
  mobileView?: MobileView;
};

export const useNavigateWithMobileView = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { setMobileActiveView } = useMobileActiveViewQueryParam();

  return ({ mobileView, ...navParams }: Params) => {
    navigate({ ...navParams });
    if (isMobile && mobileView) {
      setMobileActiveView?.(mobileView);
    }
  };
};
