import { useState } from 'react';

const useTableItemModalManager = <T>() => {
  const [itemId, setItemId] = useState<T | null>(null);

  const handleOpenModal = (id: T) => setItemId(id);

  const handleCloseModal = () => setItemId(null);

  return {
    itemId,
    handleOpenModal,
    handleCloseModal,
  };
};

export default useTableItemModalManager;
