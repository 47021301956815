import { ReactNode } from 'react';
import { Col, Flex, Row, DrawerProps } from 'antd';
import { LayoutSidebar, Props as LayoutSidebarProps } from '@/shared/components/layouts/LayoutSidebar';
import { LayoutSidebarDrawer } from '@/shared/components/layouts/LayoutSidebarDrawer';
import { useLayoutTwoColumnsSidebarRight } from './hooks';
import styles from './styles.module.css';

export interface Props extends Omit<LayoutSidebarProps, 'resizerPosition'> {
  isSidebarRight: true;
  panel: ReactNode;
  sidebarDefaultWidth: number;
  children: ({
    isSidebarVisible,
    toggleSidebarVisibility,
  }: {
    isSidebarVisible: boolean;
    toggleSidebarVisibility: (isSidebarVisible: boolean) => void;
  }) => ReactNode;
  drawerProps?: DrawerProps;
}

const LayoutTwoColumnsSidebarRight = (props: Props) => {
  const { isSidebarRight, children, panel, drawerProps, ...sidebarProps } = props;

  const {
    layoutRef,
    sidebarWrapperRef,
    isSidebarVisible,
    forceResizeSidebarToWidth,
    toggleSidebarVisibility,
    shouldDrawerRender,
    handleOverlayClick,
  } = useLayoutTwoColumnsSidebarRight({ sidebarDefaultWidth: sidebarProps.sidebarDefaultWidth });

  return (
    <Row
      ref={layoutRef}
      className={styles.layout}
      wrap={false}
    >
      <Col
        className={styles.contentCol}
        flex="auto"
      >
        <Flex
          className={styles.content}
          vertical
        >
          {children({ isSidebarVisible, toggleSidebarVisibility })}
        </Flex>
      </Col>
      <Col
        ref={sidebarWrapperRef}
        flex="none"
      >
        {shouldDrawerRender ? (
          <LayoutSidebarDrawer
            {...drawerProps}
            placement="right"
            defaultWidth={sidebarProps.sidebarDefaultWidth}
            isOpen
            onClose={handleOverlayClick}
          >
            {panel}
          </LayoutSidebarDrawer>
        ) : (
          <LayoutSidebar
            {...sidebarProps}
            layoutRef={layoutRef}
            forceResizeSidebarToWidth={forceResizeSidebarToWidth}
            resizerPosition="left"
          >
            {panel}
          </LayoutSidebar>
        )}
      </Col>
    </Row>
  );
};

export default LayoutTwoColumnsSidebarRight;
