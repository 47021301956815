import { z } from 'zod';
import { paginatedSchema, responseDataSchema } from '@/shared/validationSchemas';

export const noteSchema = z.object({
  id: z.string(),
  text: z.string(),
  applied_at: z.number(),
});

export const getNotesSchema = paginatedSchema(noteSchema);
export const createNoteSchema = responseDataSchema(noteSchema);
export const updateNoteSchema = responseDataSchema(noteSchema);
