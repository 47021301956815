import { useState, useCallback } from 'react';
import { ZoomData, UpdateZoomDataParams } from '../types';
import { getSelectedBoundaries, getZoomData, getFrequenciesSelectedBoundaries } from '../utils';

const useZoomData = () => {
  const [zoomData, setZoomData] = useState<ZoomData>(null);

  const updateZoomData = ({
    canvasWidth,
    cursorClickXPosition,
    startFrequency,
    endFrequency,
  }: UpdateZoomDataParams) => {
    const selectedBoundaries = getSelectedBoundaries(cursorClickXPosition.down, cursorClickXPosition.up);
    const frequenciesSelectedBoundaries = getFrequenciesSelectedBoundaries({
      canvasWidth,
      startFrequency,
      endFrequency,
      selectedBoundaries,
    });

    setZoomData(
      getZoomData({
        frequenciesSelectedBoundaries,
        startFrequency,
        endFrequency,
      })
    );

    window.getSelection()?.removeAllRanges();
  };

  const memoHandleResetZoomData = useCallback(() => {
    setZoomData(null);
  }, []);

  return {
    zoomData,
    updateZoomData,
    memoHandleResetZoomData,
  };
};

export default useZoomData;
