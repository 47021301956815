import { useQuery } from '@tanstack/react-query';
import omit from 'lodash/omit';
import { getSupportData } from '@/app/supportWrapper/api';
import { SUPPORT_QUERY_KEY } from '@/shared/constants';

export const useGetSupportData = () => {
  const { data, refetch } = useQuery({
    queryKey: [SUPPORT_QUERY_KEY],
    queryFn: getSupportData,
  });

  return {
    isServerAvailable: data ? data.available : true,
    supportAlert: data ? omit(data, ['available']) : undefined,
    refetch,
  };
};
