import { OptionProps } from './types';

interface Params extends Pick<OptionProps, 'onMouseLeave' | 'onMouseEnter' | 'data'> {}

const useOption = ({ onMouseEnter, onMouseLeave, data }: Params) => {
  const handleMouseEnter = () => {
    onMouseEnter?.(data.value);
  };
  const handleMouseLeave = () => {
    onMouseLeave?.(data.value);
  };

  return {
    handleMouseEnter,
    handleMouseLeave,
  };
};

export default useOption;
