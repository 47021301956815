import { useMemo } from 'react';
import { TranscriptFrequencyProps } from '@/entities/transcript/types';
import { useParams } from '@/shared/hooks';
import { RadioNetworkRouteParams } from '@/shared/types';
import { parseFrequencyValue } from './utils';

interface Params extends TranscriptFrequencyProps {}

const useTranscriptFrequency = ({ isCluster, clusterMetadata, transcript }: Params) => {
  const { radioNetworkId } = useParams<RadioNetworkRouteParams>();
  const isNetwork = Boolean(transcript.networkId);

  const frequencyData = useMemo(() => {
    const sources = isCluster && clusterMetadata ? clusterMetadata.sources : [];
    const frequencies = isCluster && clusterMetadata ? clusterMetadata.frequencies : [];

    const firstSourceWithFrequency = sources.find((source) =>
      frequencies.some(({ transcriptIds }) => transcriptIds.includes(source.transcriptId))
    );
    const clusterFrequency = frequencies.find(({ transcriptIds }) =>
      transcriptIds.includes(firstSourceWithFrequency?.transcriptId ?? '')
    );

    const frequencyValue = clusterFrequency?.value ?? transcript.metadata.frequency;
    const parsedFrequencyValue = parseFrequencyValue(frequencies, frequencyValue ?? '');

    return {
      frequencyValue,
      parsedFrequencyValue,
    };
  }, [isCluster, clusterMetadata, transcript]);

  return {
    radioNetworkId,
    isNetwork,
    ...frequencyData,
  };
};

export default useTranscriptFrequency;
