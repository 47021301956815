import CreateFoxKeyModal from '@/entities/source/@fox/components/CreateFoxKeyModal';
import { useCreateFoxKeyModal } from '@/entities/source/@fox/hooks';
import { AddFoxKey } from '@/shared/assets';
import { AddListItemButton } from '@/shared/components';
import styles from './styles.module.css';

const AddNewFoxKeyButton = () => {
  const { isCreateFoxKeyModalOpened, handleOpenCreateFoxKeyModal, handleCloseCreateFoxKeyModal } =
    useCreateFoxKeyModal();

  return (
    <>
      <AddListItemButton
        mobileIcon={<AddFoxKey className={styles.icon} />}
        onClick={handleOpenCreateFoxKeyModal}
        isDetailsManagementEnabled={false}
      />
      <CreateFoxKeyModal
        open={isCreateFoxKeyModalOpened}
        onCloseModal={handleCloseCreateFoxKeyModal}
      />
    </>
  );
};

export default AddNewFoxKeyButton;
