import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/shared/types';
import { NetworkListState } from './types';

const initialState: NetworkListState = {
  isNetworkListUpdating: false,
  isCreateNetworkModalOpened: false,
};

const networkListSlice = createSlice({
  name: 'networkList',
  initialState,
  reducers: {
    setIsNetworkListUpdating: (
      state: NetworkListState,
      action: PayloadAction<NetworkListState['isNetworkListUpdating']>
    ) => {
      state.isNetworkListUpdating = action.payload;
    },
    setIsCreateNetworkModalOpened: (
      state: NetworkListState,
      action: PayloadAction<NetworkListState['isCreateNetworkModalOpened']>
    ) => {
      state.isCreateNetworkModalOpened = action.payload;
    },
    setScrollToNetworkId: (state: NetworkListState, action: PayloadAction<NetworkListState['scrollToNetworkId']>) => {
      state.scrollToNetworkId = action.payload;
    },
    addFrequencyToFrequency: (
      state: NetworkListState,
      action: PayloadAction<NetworkListState['defaultNetworkFrequencies']>
    ) => {
      state.isNetworkListUpdating = true;
      state.isCreateNetworkModalOpened = true;
      state.defaultNetworkFrequencies = action.payload;
    },
    resetFrequencyToFrequencyData: (state: NetworkListState) => {
      state.isNetworkListUpdating = false;
      state.isCreateNetworkModalOpened = false;
      state.defaultNetworkFrequencies = null;
    },
  },
});

export const {
  setIsNetworkListUpdating,
  setIsCreateNetworkModalOpened,
  setScrollToNetworkId,
  addFrequencyToFrequency,
  resetFrequencyToFrequencyData,
} = networkListSlice.actions;

export const selectIsNetworkListUpdating = (state: RootState) => state.networkList.isNetworkListUpdating;

export const selectIsCreateNetworkModalOpened = (state: RootState) => state.networkList.isCreateNetworkModalOpened;

export const selectDefaultNetworkFrequencies = (state: RootState) => state.networkList.defaultNetworkFrequencies;

export const selectScrollToNetworkId = (state: RootState) => state.networkList.scrollToNetworkId;

export default networkListSlice.reducer;
