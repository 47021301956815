import { BaseCategory } from '@/shared/types';
import { CategoryTagIcon } from '../components/CategoryTagIcon';

interface CategoryWithChildren extends BaseCategory {
  children?: BaseCategory[];
  subCategories?: BaseCategory[];
}

export const formatToSelectOptions = (categories: CategoryWithChildren[]) => {
  return categories.map((category) => ({
    label: category.name,
    options: (category.children || category.subCategories || []).map(({ id, name }) => ({
      label: (
        <>
          <CategoryTagIcon
            id={id}
            name={name}
          />
          {name}
        </>
      ),
      value: id,
      categoryName: name,
    })),
  }));
};
