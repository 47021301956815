import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RoleName } from '@/shared/constants';
import { RootState } from '@/shared/types';
import { CurrentUserState } from './types';

const initialState: CurrentUserState = {
  currentUser: null,
};

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setCurrentUser: (state: CurrentUserState, action: PayloadAction<CurrentUserState['currentUser']>) => {
      state.currentUser = action.payload;
    },
  },
});

export const { setCurrentUser } = currentUserSlice.actions;

export const selectCurrentUser = (state: RootState) => state.currentUser.currentUser;
export const selectCurrentUserRole = (state: RootState) => {
  return state.currentUser.currentUser?.role || RoleName.OPERATOR;
};

export default currentUserSlice.reducer;
