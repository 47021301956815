import { memo } from 'react';
import { Flex } from 'antd';
import { ZoomOutOutlined, ShrinkOutlined, ArrowsAltOutlined, ScanOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { Button } from '@/shared/components';
import styles from './styles.module.css';

interface Props {
  isResetZoomButtonVisible: boolean;
  memoOnResetZoomButtonClick: () => void;
  isFullScreenEnabled: boolean;
  memoOnFullScreenButtonClick: () => void;
  isDetectionsButtonActive: boolean;
  memoOnDetectionsButtonClick: () => void;
}

const ActionButtons = memo(
  ({
    isResetZoomButtonVisible,
    memoOnResetZoomButtonClick,
    isFullScreenEnabled,
    memoOnFullScreenButtonClick,
    isDetectionsButtonActive,
    memoOnDetectionsButtonClick,
  }: Props) => {
    return (
      <Flex
        vertical
        gap="small"
        wrap="wrap"
        className={styles.wrapper}
      >
        <Button
          withStopPropagation
          className={classNames(styles.button, styles.detectionsButton, {
            [styles.detectionsButtonDisabled]: !isDetectionsButtonActive,
          })}
          onClick={memoOnDetectionsButtonClick}
          icon={<ScanOutlined />}
        />
        {isResetZoomButtonVisible && (
          <Button
            withStopPropagation
            className={styles.button}
            onClick={memoOnResetZoomButtonClick}
            icon={<ZoomOutOutlined />}
          />
        )}
        {!isResetZoomButtonVisible && (
          <Button
            withStopPropagation
            className={styles.button}
            onClick={memoOnFullScreenButtonClick}
            icon={isFullScreenEnabled ? <ShrinkOutlined /> : <ArrowsAltOutlined />}
          />
        )}
      </Flex>
    );
  }
);

ActionButtons.displayName = 'ActionButtons';

export default ActionButtons;
