import { useMemo, useRef } from 'react';
import { useElementSize } from '@reactuses/core';
import classNames from 'classnames';
import styles from './styles.module.css';
import { getWaveClassName } from './utils';

const useSoundWave = (isActive: boolean) => {
  const ref = useRef<HTMLDivElement>(null);
  const [waveWidth] = useElementSize(ref);

  const waves = useMemo<{ index: number; className: string }[]>(() => {
    // wave width is equal to 2 and gap between waves is equal to 4, that's where from we get numbers 2 and 6
    if (waveWidth === 0) return [];
    const wavesLength = Math.floor((waveWidth - 2) / 6);
    return [...Array(wavesLength + 2)].map((_, index) => ({
      index,
      className: classNames(styles.wave, {
        [styles[getWaveClassName()]]: isActive,
      }),
    }));
  }, [waveWidth, isActive]);

  return {
    ref,
    waves,
  };
};

export default useSoundWave;
