import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { RadioNetworkMap } from '@/entities/map/types';
import { RootState } from '@/shared/types';
import { InterceptionsMapState as MapState, UpdateRadioNetworkMapPayload } from './types';

const initialState: MapState = {
  maps: {},
};

const interceptionsMapSlice = createSlice({
  name: 'interceptionsMap',
  initialState,
  reducers: {
    addRadioNetworkMap: (state: MapState, action: PayloadAction<RadioNetworkMap>) => {
      const { radioNetworkId } = action.payload;
      const map = state.maps[radioNetworkId];

      if (!map) {
        state.maps[radioNetworkId] = action.payload;
      }
    },
    updateRadioNetworkMap: (state: MapState, action: PayloadAction<UpdateRadioNetworkMapPayload>) => {
      const { radioNetworkId, changes } = action.payload;
      const map = state.maps[radioNetworkId];

      if (map) {
        state.maps[radioNetworkId] = {
          ...map,
          ...changes,
        };
      }
    },
  },
});

export const { addRadioNetworkMap, updateRadioNetworkMap } = interceptionsMapSlice.actions;

const selectMaps = (state: RootState) => state.interceptionsMap.maps;
const selectId = (_: unknown, id: string) => id;
const selectById = createSelector(
  selectMaps,
  selectId,
  (maps: MapState['maps'], id: string): RadioNetworkMap | undefined => maps[id]
);

export const selectRadioNetworkMapById = (id: string) => (state: RootState) => selectById(state, id);

export default interceptionsMapSlice.reducer;
