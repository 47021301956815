import { UNIT_FREE_SOURCES_ROUTE, UNIT_FREE_USERS_ROUTE } from '@/shared/constants';
import { useParams } from '@/shared/hooks';
import { UnitRouteParams } from '@/shared/types';

const useUnitRouteInfo = () => {
  const { unitId = '' } = useParams<UnitRouteParams>();
  const isFreeSourcesRoute = unitId === UNIT_FREE_SOURCES_ROUTE;
  const isFreeUsersRoute = unitId === UNIT_FREE_USERS_ROUTE;

  return {
    routeUnitId: isFreeSourcesRoute || isFreeUsersRoute ? 'null' : unitId,
    isFreeSourcesRoute,
    isFreeUsersRoute,
  };
};

export default useUnitRouteInfo;
