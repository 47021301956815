import { FC, ReactElement, ReactNode, useEffect } from 'react';
import classNames from 'classnames';
import { Header } from '@/shared/components/Header';
import { LayoutMobileView } from '@/shared/components/layouts/LayoutMobileView';
import { LayoutSidebar } from '@/shared/components/layouts/LayoutSidebar';
import { LayoutSidebarDrawer } from '@/shared/components/layouts/LayoutSidebarDrawer';
import { MobileView } from '@/shared/constants';
import { useIsMobile, useIsTabletDevice, useAppSelector, useAppDispatch } from '@/shared/hooks';
import {
  setIsLayoutSidebarLeftCollapsed,
  selectIsLayoutSidebarLeftCollapsed,
  selectIsNavigationSidebarCollapsed,
} from '@/shared/slices';
import { LayoutContent, LayoutContentHeader } from './components';
import styles from './styles.module.css';

export type Props = {
  sidebarTitle: string;
  sidebarTitleActions?: ReactElement;
  panel: ReactNode;
  contentHeader?: ReactNode;
  children: ReactNode;
  scrollDependOn?: unknown;
  storageKey: string;
  layoutSidebarClassName?: string;
  isTabletSidebarCollapsible?: boolean;
};

export const LayoutTwoColumnsSidebarLeft: FC<Props> = ({
  sidebarTitle,
  sidebarTitleActions,
  panel,
  contentHeader,
  children,
  scrollDependOn,
  storageKey,
  layoutSidebarClassName,
  isTabletSidebarCollapsible,
}) => {
  const isMobile = useIsMobile();
  const isTabletDevice = useIsTabletDevice();
  const isSidebarCollapsible = isTabletSidebarCollapsible && isTabletDevice;
  const dispatch = useAppDispatch();
  const isNavigationSidebarCollapsed = useAppSelector(selectIsNavigationSidebarCollapsed);
  const isSidebarCollapsed = useAppSelector(selectIsLayoutSidebarLeftCollapsed) && isSidebarCollapsible;

  const handleClickOverlay = () =>
    isSidebarCollapsible && dispatch(setIsLayoutSidebarLeftCollapsed(!isSidebarCollapsed));

  useEffect(() => {
    if (isSidebarCollapsible && !isNavigationSidebarCollapsed) dispatch(setIsLayoutSidebarLeftCollapsed(true));
  }, [dispatch, isNavigationSidebarCollapsed, isSidebarCollapsible]);

  const pageHeader = (
    <Header
      actions={sidebarTitleActions}
      testId="page-header"
    >
      {sidebarTitle}
    </Header>
  );

  const sidebarContent = (
    <div
      className={styles.sidebarContent}
      data-testid="page-sidebar-content"
    >
      {panel}
    </div>
  );

  const sidebar = (
    <LayoutSidebar
      storageKey={storageKey}
      layoutSidebarClassName={classNames(styles.sidebar, layoutSidebarClassName)}
    >
      {!isMobile && pageHeader}
      <LayoutMobileView
        viewType={MobileView.PrimaryScreen}
        title={pageHeader}
      >
        {sidebarContent}
      </LayoutMobileView>
    </LayoutSidebar>
  );

  const pageContentHeader = contentHeader && <LayoutContentHeader>{contentHeader}</LayoutContentHeader>;

  const pageContent = (
    <div
      data-testid="page-content"
      className={styles.content}
    >
      {children}
    </div>
  );

  return (
    <>
      {isSidebarCollapsible ? (
        <LayoutSidebarDrawer
          onClose={handleClickOverlay}
          isOpen={!isSidebarCollapsed}
        >
          {sidebar}
        </LayoutSidebarDrawer>
      ) : (
        sidebar
      )}
      <LayoutContent
        scrollDependOn={scrollDependOn}
        className={classNames({
          [styles.shiftedContent]: isSidebarCollapsible,
        })}
        header={isMobile ? undefined : pageContentHeader}
      >
        <LayoutMobileView
          viewType={MobileView.SecondaryScreen}
          title={pageContentHeader}
        >
          {pageContent}
        </LayoutMobileView>
      </LayoutContent>
    </>
  );
};
