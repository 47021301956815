interface Params {
  position: number;
  canvasWidth: number;
  startFrequency: number;
  endFrequency: number;
}

const getFrequencyByPosition = ({ position, startFrequency, endFrequency, canvasWidth }: Params) => {
  const cursorPositionPercentValue = position / (canvasWidth / 100);
  const frequenciesRangeOnePercentValue = (endFrequency - startFrequency) / 100;

  return (startFrequency + cursorPositionPercentValue * frequenciesRangeOnePercentValue).toFixed(4);
};

export default getFrequencyByPosition;
