import { memo } from 'react';
import { RadioNetwork } from '@/shared/types';
import StaticListItem from '../StaticListItem';
import { useDraggableListItem } from './hooks/useDraggableListItem';

interface Props {
  item: RadioNetwork;
  selectedRadioNetworkId?: string;
}

const DraggableListItem = memo(({ item, selectedRadioNetworkId }: Props) => {
  const { WrapperComponent, handleDragEnd, networkKey, isFrequencyDragging, isNetworkListUpdating } =
    useDraggableListItem(item);

  return (
    <WrapperComponent
      key={networkKey}
      item={item}
      onDragEnd={handleDragEnd}
      isDraggingEnabled={!isNetworkListUpdating}
    >
      <StaticListItem
        item={item}
        selectedRadioNetworkId={selectedRadioNetworkId}
        isDragging={isFrequencyDragging}
      />
    </WrapperComponent>
  );
});

DraggableListItem.displayName = 'DraggableListItem';

export default DraggableListItem;
