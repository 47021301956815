import {
  LayoutTwoColumnsSidebarLeft,
  LayoutTwoColumnsSidebarLeftProps,
  LayoutTwoColumnsSidebarRight,
  LayoutTwoColumnsSidebarRightProps,
} from './components';
import { isSidebarRightLayout } from './utils';

type Props = LayoutTwoColumnsSidebarLeftProps | LayoutTwoColumnsSidebarRightProps;

export const LayoutTwoColumns = (props: Props) => {
  return isSidebarRightLayout(props) ? (
    <LayoutTwoColumnsSidebarRight {...props} />
  ) : (
    <LayoutTwoColumnsSidebarLeft {...props} />
  );
};
