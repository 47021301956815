import { useEffect } from 'react';
import { useRadioNetworkMapStore } from '@/entities/map';
import { selectCenterTo, selectCenterToMetaData, setScrollToTranscriptId } from '@/entities/transcript';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import usePaginatedTranscriptIds from './usePaginatedTranscriptIds';

const useMapRequiredData = (onClickedMarkerTranscriptIdChange?: () => void) => {
  const { clickedMarkerTranscriptId, updateStore } = useRadioNetworkMapStore();
  const { paginatedTranscriptIds, isTranscriptsFetching } = usePaginatedTranscriptIds();
  const centerTo = useAppSelector(selectCenterTo);
  const centerToMetaData = useAppSelector(selectCenterToMetaData);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (clickedMarkerTranscriptId.length > 0) {
      onClickedMarkerTranscriptIdChange?.();
      dispatch(setScrollToTranscriptId(clickedMarkerTranscriptId));
      updateStore({ clickedMarkerTranscriptId: '' });
    }
  }, [clickedMarkerTranscriptId, dispatch]);

  return {
    paginatedTranscriptIds,
    isTranscriptsFetching,
    centerTo,
    centerToMetaData,
  };
};

export default useMapRequiredData;
