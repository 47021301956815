import { Row, Col } from 'antd';
import { COPIES } from '@/shared/constants';
import styles from './styles.module.css';

const RECORDS = 'записів';
const NOTES = 'нотатки';
const ACTIVITY = 'активність';

const ColumnsTitles = () => {
  return (
    <Row className={styles.row}>
      <Col>{COPIES.FREQUENCY}</Col>
      <Col>{RECORDS}</Col>
      <Col>{NOTES}</Col>
      <Col>{ACTIVITY}</Col>
    </Row>
  );
};

export default ColumnsTitles;
