import { MouseEventHandler } from 'react';
import { useNavigateToRecord } from '@/entities/source/@fox/components/RecordsTab/hooks';
import {
  setSelectedRecordId,
  selectSelectedRecordId,
  setIsRecordPlaying,
  selectIsRecordPlaying,
} from '@/entities/source/slices';
import { PauseOutlined, PlayOutlined } from '@/shared/assets';
import { useAppDispatch, useAppSelector, useIsMobile, useParams } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';

const usePlayButton = () => {
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const { sourceId } = useParams<SourceRouteParams>();
  const isRecordPlaying = useAppSelector(selectIsRecordPlaying);
  const selectedRecordId = useAppSelector(selectSelectedRecordId);
  const navigateToRecord = useNavigateToRecord();

  const handlePlay =
    (id: string): MouseEventHandler<HTMLElement> =>
    (event) => {
      if (isMobile) {
        event.stopPropagation();
        navigateToRecord({
          routeParams: {
            sourceId,
            tabEntityId: id,
          },
          options: { withAllQueryParams: true },
        });
      }
      dispatch(setSelectedRecordId(id));
      dispatch(setIsRecordPlaying(id === selectedRecordId ? !isRecordPlaying : true));
    };

  const getIcon = (id: string) => {
    if (id === selectedRecordId && isRecordPlaying) {
      return <PauseOutlined />;
    }

    return <PlayOutlined />;
  };

  return {
    handlePlay,
    getIcon,
  };
};

export default usePlayButton;
