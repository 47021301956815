import { SpectrogramFrequency } from '@/entities/source/@fox/components/ScanningTab/components/Spectrogram/types';

interface Params {
  frequencies: SpectrogramFrequency[];
  frequencyValue: SpectrogramFrequency['frequency'];
}

const isUniqueFrequency = ({ frequencyValue, frequencies }: Params) => {
  return !frequencies.some(({ frequency: existingFrequency }) => existingFrequency === frequencyValue);
};

export default isUniqueFrequency;
