import { FoxFrequencyRangeLimitation } from '@/entities/source/@fox/constants';
import handleLeftStep from './handleLeftStep';

const handleDraggingLeftSlider = (steps: number[], prevRange: number[], value: number) => {
  const maxStep = steps[steps.length - 1];
  const minStep = steps[0];

  // Handle dragging slider to the left
  if (value < prevRange[0] && value + maxStep < prevRange[1]) {
    return [value, value + maxStep];
  }

  // Handle dragging slider to the rigth
  if (value > prevRange[0]) {
    const rightValue = value + minStep;

    if (rightValue >= FoxFrequencyRangeLimitation.Max) {
      return [FoxFrequencyRangeLimitation.Max - minStep, FoxFrequencyRangeLimitation.Max];
    }

    if (rightValue >= prevRange[1]) {
      return [value, rightValue];
    }
  }

  return handleLeftStep(steps, prevRange, value);
};

export default handleDraggingLeftSlider;
