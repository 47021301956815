import { hasTouchScreen } from '@/shared/utils';
import { useIsTablet, useIsTabletOrMobile } from './useMediaQuery';

export const useIsTouchDevice = () => {
  const isTabletOrMobile = useIsTabletOrMobile();
  return isTabletOrMobile && hasTouchScreen();
};

export const useIsTabletDevice = () => {
  const isTablet = useIsTablet();
  return isTablet && hasTouchScreen();
};
