import { useUnitRouteInfo } from '@/entities/unit/hooks';
import { UnitUser } from '@/entities/unit/types';
import { useParams } from '@/shared/hooks';
import { UnitRouteParams } from '@/shared/types';
import useGetFreeUsers from './useGetFreeUsers';
import useGetUnitUsers from './useGetUnitUsers';

const useGetUnitUser = (userId: UnitUser['id'] | null) => {
  const { unitId = '' } = useParams<UnitRouteParams>();
  const { isFreeUsersRoute } = useUnitRouteInfo();

  const { unitUsersList } = useGetUnitUsers({
    unitId,
    options: { enabled: false },
  });

  const { freeUsersList } = useGetFreeUsers({
    options: { enabled: false },
  });
  const usersList = isFreeUsersRoute ? freeUsersList : unitUsersList;

  return usersList.find((userItem) => userItem.id === userId);
};

export default useGetUnitUser;
