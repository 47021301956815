import { Form } from 'antd';
import { PHONE_MASK_PATTERN } from '@/shared/constants';
import { MaskedInput } from '../ui/MaskedInput';

const PHONE_NUMBER_REQUIRED = "Номер телефону обов'язковий";
const PHONE_NUMBER_MUST_BE_UA = 'Вкажіть телефон з кодом країни';

type Props = {
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
};

const PhoneField = ({ name, label, placeholder, required = false }: Props) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        { required, message: PHONE_NUMBER_REQUIRED },
        { pattern: /^\+380 \d\d \d\d\d \d\d \d\d$/, message: PHONE_NUMBER_MUST_BE_UA },
      ]}
    >
      <MaskedInput
        type="tel"
        placeholder={placeholder}
        mask={PHONE_MASK_PATTERN}
        maskOptions={{ lazy: true }}
      />
    </Form.Item>
  );
};

export default PhoneField;
