import { RouteLink } from '@/shared/components';
import useNetworkLink from './useNetworkLink';

type Props = {
  networkId: string;
  networkName: string;
};

const NetworkLink = ({ networkId, networkName }: Props) => {
  const { networkLinkHref, handleNetworkClick } = useNetworkLink(networkId);

  return (
    <RouteLink
      href={networkLinkHref}
      onClick={handleNetworkClick}
    >
      {networkName}
    </RouteLink>
  );
};

export default NetworkLink;
