import { z } from 'zod';
import { paginatedSchema } from '@/shared/validationSchemas';
import { SubscriptionType } from '../constants';

export const subscriptionItemSchema = z.object({
  type: z.nativeEnum(SubscriptionType),
  value: z.string().or(z.number()).nullable(),
});

export const subscriptionSchema = z.object({
  id: z.number(),
  is_active: z.boolean(),
  value: z.array(subscriptionItemSchema),
});

export const getSubscriptionsSchema = paginatedSchema(subscriptionSchema);
