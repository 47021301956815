import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { authService } from '@/shared/api';
import { ALLOWED_PAGES } from '@/shared/constants';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { selectCurrentUserRole, setShouldRedirectToPageAccessDenied } from '@/shared/slices';
import { roleHasAccess } from '../utils';

const useAccessDeniedRedirect = () => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const role = useAppSelector(selectCurrentUserRole);

  useEffect(() => {
    const hasAuthData = Boolean(authService.getAuthDataFromLocalStorage());
    if (!hasAuthData) return;

    if (!roleHasAccess({ role, pathname, allowedPages: ALLOWED_PAGES })) {
      dispatch(setShouldRedirectToPageAccessDenied(true));
    }
  }, [dispatch, pathname, role]);
};

export default useAccessDeniedRedirect;
