import type { FormInstance } from 'rc-field-form';
import type { RuleObject } from 'rc-field-form/lib/interface';
import { CreateFoxKeyPayload } from '@/entities/source/@fox/types';

const getFoxKeyGroupIdFrequencyRule = ({ getFieldsValue }: FormInstance<Partial<CreateFoxKeyPayload>>): RuleObject => {
  const { groupId, targetFrequencyId } = getFieldsValue();

  if (!groupId && !targetFrequencyId) return { required: true, message: 'Частота або Group ID обовʼязкові' };

  return {};
};

export default getFoxKeyGroupIdFrequencyRule;
