interface Params {
  isLocalBuild: boolean;
  fistCommitHash: string;
  secondCommitHash: string;
}

const isLastBuild = ({ isLocalBuild, fistCommitHash, secondCommitHash }: Params) => {
  if (isLocalBuild) return true;

  return fistCommitHash === secondCommitHash;
};

export default isLastBuild;
