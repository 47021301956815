import { useDrag, ConnectDragSource } from 'react-dnd';
import { DRAG_TYPE } from '@/entities/frequency/constants';
import { useDraggingFrequencyId, useIsFrequencyDragging } from '@/entities/frequency/hooks';
import { RadioNetwork, Frequency } from '@/shared/types';

interface UseDraggableItemWrapper {
  isItemDragging: boolean;
  isFrequencyDragging: boolean;
  drag: ConnectDragSource;
}

interface Params {
  item: RadioNetwork;
  onDragEnd: (network: RadioNetwork, frequency: Frequency) => void;
  isDraggingEnabled: boolean;
}

const useDraggableItemWrapper = ({ item, isDraggingEnabled, onDragEnd }: Params): UseDraggableItemWrapper => {
  const draggingFrequencyId = useDraggingFrequencyId();
  const isFrequencyDragging = useIsFrequencyDragging();

  const [, drag] = useDrag({
    type: DRAG_TYPE,
    item: { item },
    end: (currentItem, monitor) => {
      const dropResult = monitor.getDropResult<{ item: RadioNetwork }>();
      if (currentItem && dropResult) {
        onDragEnd(dropResult.item, currentItem.item as Frequency);
      }
    },
    canDrag: isDraggingEnabled,
  });

  return {
    isFrequencyDragging,
    isItemDragging: draggingFrequencyId === item.id, // draggingFrequencyId === item.id is more reliable than monitor.isDragging()
    drag,
  };
};

export default useDraggableItemWrapper;
