import { FC, PropsWithChildren, ReactNode } from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import { Empty } from '@/shared/components/ui/Empty';
import { COPIES } from '@/shared/constants';
import styles from './styles.module.css';

interface Props {
  isLoading: boolean;
  isEmpty?: boolean;
  emptyClassName?: string;
  spinTip?: string;
  emptyComponent?: ReactNode;
  spinClassName?: string;
  isFixedOverlay?: boolean;
  emptyText?: string;
  wrapperClassName?: string;
}

export const LoadingWrapper: FC<PropsWithChildren<Props>> = ({
  children,
  isLoading,
  isEmpty,
  emptyClassName,
  spinTip,
  emptyComponent,
  spinClassName,
  isFixedOverlay,
  emptyText,
  wrapperClassName,
}) => {
  const renderEmpty = () => {
    if (emptyComponent) {
      return emptyComponent;
    }
    return (
      <Empty
        description={emptyText ?? COPIES.NO_DATA}
        className={emptyClassName}
      />
    );
  };
  if (isEmpty && isLoading) {
    return (
      <Spin
        className={spinClassName}
        wrapperClassName={styles.spin}
        spinning={isLoading}
        tip={isEmpty && spinTip}
      >
        {renderEmpty()}
      </Spin>
    );
  }
  if (isEmpty) {
    return renderEmpty();
  }

  if (isFixedOverlay) {
    return (
      <>
        {children}
        {isLoading && (
          <Spin
            className={styles.loader}
            spinning
          />
        )}
      </>
    );
  }
  return (
    <Spin
      wrapperClassName={classNames(styles.spinWrapper, wrapperClassName)}
      spinning={isLoading}
      tip={isEmpty && spinTip}
    >
      {children}
    </Spin>
  );
};
