interface Params {
  target: EventTarget;
  attributeName: string;
}

const getEventTargetAttributeValue = ({ target, attributeName }: Params) => {
  if ('getAttribute' in target && typeof target.getAttribute === 'function') {
    return target.getAttribute(attributeName) as string | null;
  }

  return null;
};

export default getEventTargetAttributeValue;
