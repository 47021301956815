interface Params {
  radioNetworkName: string;
  lpdSet: Set<string>;
  pmrSet: Set<string>;
}

const getFormattedRadioNetworkName = ({ radioNetworkName, lpdSet, pmrSet }: Params) => {
  if (lpdSet.has(radioNetworkName)) return `${radioNetworkName} (LPD)`;
  if (pmrSet.has(radioNetworkName)) return `${radioNetworkName} (PMR)`;
  return radioNetworkName;
};

export default getFormattedRadioNetworkName;
