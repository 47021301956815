import { DefaultOptionType } from 'antd/es/select';
import type { FilterFunc } from 'rc-select/lib/Select';

const APOSTROPHES_TO_REPLACE = /['’‘`]/g;
const APOSTROPHE = 'ʼ';

export const filterSelectOption =
  (optionFilterProp: string = 'label'): FilterFunc<DefaultOptionType> =>
  (input, option) => {
    const inputWithSupportedApostrophes = input.replace(APOSTROPHES_TO_REPLACE, APOSTROPHE);
    let searchLabel = option?.[optionFilterProp];
    if (Array.isArray(searchLabel)) {
      searchLabel = searchLabel.join('');
    }
    const optionWithSupportedApostrophes = searchLabel?.replace(APOSTROPHES_TO_REPLACE, APOSTROPHE);
    if (optionWithSupportedApostrophes) {
      return optionWithSupportedApostrophes.toLowerCase().includes(inputWithSupportedApostrophes.toLowerCase());
    }
    return false;
  };
