import {
  useState,
  ChangeEvent,
  KeyboardEvent,
  FocusEvent,
  MouseEvent,
  useEffect,
  KeyboardEventHandler,
  useRef,
} from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { useHover } from '@reactuses/core';
import classNames from 'classnames';
import { Key } from 'ts-key-enum';
import { Input, InputRef } from '@/shared/components/ui/Input';
import { BaseTag } from '@/shared/components/ui/Tag';
import styles from './styles.module.css';

const PLACEHOLDER_TEXT = 'Введіть значення';
const DUPLICATED_ERROR = 'Вже додане';
const CLEAR_BUTTON_ID = 'clear-button';

type TagProps = {
  values: string[];
  closable: boolean;
  onTagRemove: (val: string) => void;
};

const Tags = ({ values, closable, onTagRemove }: TagProps) => (
  <>
    {values.map((value) => (
      <BaseTag
        key={value}
        closable={closable}
        onClose={() => onTagRemove(value)}
        className={styles.inputTag}
      >
        {value}
      </BaseTag>
    ))}
  </>
);

type Props = {
  data?: string[];
  onDataUpdated: (data: string[]) => void;
  minLength?: number;
  maxLength?: number;
  disabled?: boolean;
};

export const InputTags = ({ data = [], onDataUpdated, minLength = 3, maxLength = 64, disabled = false }: Props) => {
  const [value, setValue] = useState<string[]>(data);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');
  const inputRef = useRef<InputRef>(null);
  const inputTagsRef = useRef(null);
  const isHovered = useHover(inputTagsRef);

  useEffect(() => setValue(data), [data]);

  const hasDuplication = (newValue: string) => !!value.find((val) => val === newValue.toLowerCase());

  useEffect(() => {
    if (!hasDuplication(inputValue)) setError('');
  }, [value.length, inputValue]);

  const updateValue = (newValue: string[]) => {
    setValue(newValue);
    onDataUpdated(newValue);
  };

  const addNewValue = (text: string) => {
    const trimmedText = text.trim();
    if (trimmedText.length < minLength) {
      setInputValue(trimmedText);
      return;
    }
    if (hasDuplication(trimmedText)) {
      setError(DUPLICATED_ERROR);
      return;
    }
    updateValue([...value, trimmedText.toLowerCase()]);
    setInputValue('');
  };

  const handleEnterPress = (e: KeyboardEvent<HTMLInputElement>) => addNewValue(e.currentTarget.value);
  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (e.relatedTarget?.id !== CLEAR_BUTTON_ID) addNewValue(e.currentTarget.value);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const text = e.currentTarget.value;
    if (text.length >= maxLength) addNewValue(text.slice(0, maxLength));
    else setInputValue(e.currentTarget.value);
  };

  const handleTagRemove = (tagValue: string) => updateValue(value.filter((val) => val !== tagValue));

  const handleClear = (e: MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    setInputValue('');
    updateValue([]);
    inputRef.current?.focus();
  };

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.nativeEvent.key === Key.Tab) {
      event.preventDefault();
      addNewValue(event.currentTarget.value);
    }
  };

  const showCloseIcon = isHovered && (Boolean(value.length) || Boolean(inputValue.length)) && !disabled;

  return (
    <div>
      <div
        ref={inputTagsRef}
        className={classNames(styles.wrapper, { [styles.inputDisabled]: disabled })}
      >
        <div className={styles.inputWrapper}>
          <Tags
            values={value}
            closable={!disabled}
            onTagRemove={handleTagRemove}
          />
          <Input
            value={inputValue}
            variant="borderless"
            onPressEnter={handleEnterPress}
            onBlur={handleBlur}
            onChange={handleChange}
            status={error && 'error'}
            className={styles.input}
            placeholder={value.length ? '' : PLACEHOLDER_TEXT}
            onKeyDown={onKeyDown}
            disabled={disabled}
            ref={inputRef}
          />
        </div>
        <div className={styles.closeIconWrapper}>
          {showCloseIcon && (
            <CloseOutlined
              id={CLEAR_BUTTON_ID}
              className={styles.closeIcon}
              onClick={handleClear}
            />
          )}
        </div>
      </div>
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};
