import { memo } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './styles.module.css';

interface Props {
  isVisible: boolean;
}

const OverlaySpin = memo(({ isVisible }: Props) => {
  if (!isVisible) return null;

  return (
    <div className={styles.wrapper}>
      <Spin
        indicator={
          <LoadingOutlined
            spin
            className={styles.spin}
          />
        }
      />
    </div>
  );
});

OverlaySpin.displayName = 'OverlaySpin';

export default OverlaySpin;
