import { FC, ReactNode } from 'react';
import { Layout } from 'antd';
import classNames from 'classnames';
import { useScrollToTop } from '@/shared/hooks';
import styles from './styles.module.css';

export const LayoutContent: FC<{
  children: ReactNode;
  scrollDependOn: unknown;
  header?: ReactNode;
  className?: string;
}> = ({ children, scrollDependOn, header, className }) => {
  const layoutRef = useScrollToTop(scrollDependOn);

  return (
    <Layout.Content
      ref={layoutRef}
      className={classNames(styles.contentWrapper, className)}
    >
      {header}
      {children}
    </Layout.Content>
  );
};
