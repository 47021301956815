import { MobileView, MobileViewQueryParam } from '@/shared/constants';
import { useSearchParam } from '@/shared/hooks/routingHooks/useSearchParam';
import { useIsMobile } from './useMediaQuery';

export const useMobileActiveViewQueryParam = () => {
  const isMobile = useIsMobile();
  const [mobileActiveView, setMobileActiveView] = useSearchParam<MobileView>(MobileViewQueryParam.Mobile);

  return {
    mobileActiveView: isMobile && mobileActiveView ? mobileActiveView : null,
    setMobileActiveView: isMobile ? setMobileActiveView : null,
  };
};
