import { FoxFrequencyEncryptionType } from '@/entities/source/@fox/constants';
import { timeService } from '@/shared/services';
import { isEnumValue } from '@/shared/utils';
import { frequencyEncryptionTypeService } from '../services';
import styles from '../styles.module.css';
import { FoxFrequencyTypeTagProps } from '../types';

const UNKNOWN_ENCRYPTION_TYPE = {
  fullName: 'Unknown',
  shortName: 'UN',
  possibilityOfDecryption: 'not supported',
  possibilityOfDecryptionTranslation: 'не підтримується',
} as const;

interface Params extends Pick<FoxFrequencyTypeTagProps, 'encryptionType'> {}

const useFoxFrequencyEncryptionTypeTag = ({ encryptionType }: Params) => {
  const { type, lastActiveAt } = encryptionType;
  const isUnknownEncryptionType = !isEnumValue(type, FoxFrequencyEncryptionType);

  const possibilityOfDecryption = isUnknownEncryptionType
    ? UNKNOWN_ENCRYPTION_TYPE.possibilityOfDecryption
    : frequencyEncryptionTypeService.getPossibilityOfDecryption(type);

  const possibilityOfDecryptionTranslation =
    frequencyEncryptionTypeService.getPossibilityOfDecryptionTranslation(possibilityOfDecryption);

  const { shortName, fullName } = isUnknownEncryptionType
    ? {
        shortName: UNKNOWN_ENCRYPTION_TYPE.shortName,
        fullName: UNKNOWN_ENCRYPTION_TYPE.fullName,
      }
    : frequencyEncryptionTypeService.getEncryptionTypeName(type);

  const tagClassName = frequencyEncryptionTypeService.getTagClassName({
    possibilityOfDecryption,
    greenClassName: styles.greenTag,
    orangeClassName: styles.orangeTag,
    redClassName: styles.redTag,
  });

  const tooltipLastActiveAt = timeService.getFormattedFullTimeMonthLong(lastActiveAt);

  return {
    isUnknownEncryptionType,
    possibilityOfDecryptionTranslation,
    shortName,
    fullName,
    tagClassName,
    tooltipLastActiveAt,
    unknownEncryptionType: UNKNOWN_ENCRYPTION_TYPE,
  };
};

export default useFoxFrequencyEncryptionTypeTag;
