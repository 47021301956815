import { Form, Flex } from 'antd';
import { Button, LoadingWrapper } from '@/shared/components';
import { COPIES } from '@/shared/constants';
import { useSettingsTab } from './hooks';
import styles from './styles.module.css';

const INTEREST_AREA_LABEL = 'Зона інтересу:';

const SettingsTab = () => {
  const {
    isFormChanged,
    isFormSaving,
    handleFormReset,
    handleFormSave,
    coverageArea,
    setCoverageArea,
    isDrawModeEnabled,
    setIsDrawModeEnabled,
    isTabContentLoading,
    renderHexagonMap,
  } = useSettingsTab();

  return (
    <LoadingWrapper
      isLoading={isTabContentLoading}
      isEmpty={!coverageArea}
      emptyClassName={styles.empty}
    >
      <Form
        className={styles.settingsForm}
        onReset={handleFormReset}
        onFinish={handleFormSave}
      >
        <Form.Item label={INTEREST_AREA_LABEL}>
          {renderHexagonMap({
            coverageArea: coverageArea ?? [],
            onCoverageChange: setCoverageArea,
            onDrawModeChange: setIsDrawModeEnabled,
          })}
        </Form.Item>
        {isFormChanged && !isDrawModeEnabled && (
          <Flex className={styles.settingsFormActions}>
            <Button htmlType="reset">{COPIES.CANCEL}</Button>
            <Button
              htmlType="submit"
              type="primary"
              loading={isFormSaving}
              disabled={isFormSaving}
            >
              {COPIES.APPLY}
            </Button>
          </Flex>
        )}
      </Form>
    </LoadingWrapper>
  );
};

export default SettingsTab;
