import { useEffect, useRef } from 'react';
import { Typography } from 'antd';
import { ECElementEvent } from 'echarts';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import { GridComponent, TooltipComponent, LegendComponent } from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import merge from 'lodash/merge';
import { useDefaultOption } from './hooks';
import { EChartsOption } from './types';

// we are reducing Echarts bundle size by using only needed modules
// if it's required for your tasks to use other modules than in the list
// please read how to do it in the Usage section of https://www.npmjs.com/package/echarts-for-react

echarts.use([GridComponent, TooltipComponent, LegendComponent, BarChart, CanvasRenderer]);

type Props = {
  title?: string;
  option: EChartsOption;
  onClick: (e: ECElementEvent) => void;
};

export const Chart = ({ title, option, onClick }: Props) => {
  const chartRef = useRef<ReactEChartsCore | null>(null);
  const defaultOption = useDefaultOption();

  useEffect(() => {
    // Fix chart visibility when switching between pages on small screen
    chartRef.current?.getEchartsInstance().resize();
  }, []);

  return (
    <div>
      {title && <Typography.Title level={4}>{title}</Typography.Title>}
      <ReactEChartsCore
        option={merge(defaultOption, option)}
        echarts={echarts}
        ref={chartRef}
        onEvents={{
          click: onClick,
        }}
      />
    </div>
  );
};

export * from './types';
export * from './utils';
