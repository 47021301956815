import { ReactElement } from 'react';
import { Alert, Typography } from 'antd';
import {
  CheckCircleOutlined,
  InfoCircleOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { useAppSelector, useAppDispatch } from '@/shared/hooks';
import { selectSupportAlert, selectIsAlertClosed, setIsAlertClosed } from '@/shared/slices';
import styles from './styles.module.css';

const ICONS: Record<string, ReactElement> = {
  success: <CheckCircleOutlined />,
  info: <InfoCircleOutlined />,
  warning: <ExclamationCircleOutlined />,
  error: <CloseCircleOutlined />,
};

const SupportNotification = () => {
  const { alertLevel, alertHeader, alertText, alertExpiredAt } = useAppSelector(selectSupportAlert) || {};
  const isAlertClosed = useAppSelector(selectIsAlertClosed);
  const dispatch = useAppDispatch();

  const handleClose = () => dispatch(setIsAlertClosed(true));

  const isExpired = dayjs().isAfter(dayjs(alertExpiredAt));
  const shouldNotRender = !alertLevel || !alertText || isExpired || isAlertClosed;

  if (shouldNotRender) return null;

  const header = alertHeader ? (
    <Typography.Text
      className={styles.header}
      ellipsis={{ tooltip: alertHeader }}
    >
      {alertHeader}
    </Typography.Text>
  ) : undefined;

  const content = (
    <Typography.Paragraph
      className={styles.content}
      ellipsis={{ rows: 2, tooltip: alertText }}
    >
      {alertText}
    </Typography.Paragraph>
  );

  return (
    <Alert
      className={styles.alert}
      type={alertLevel}
      icon={ICONS[alertLevel]}
      message={header}
      description={content}
      onClose={handleClose}
      showIcon
      closable
    />
  );
};

export default SupportNotification;
