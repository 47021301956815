import styles from './styles.module.css';

type Props = {
  frequencyValue: string[];
};

const ParsedFrequencyValue = ({ frequencyValue }: Props) => {
  const [common, difference] = frequencyValue;

  return (
    <>
      {common}
      {difference && <span className={styles.difference}>{difference}</span>}
    </>
  );
};

export default ParsedFrequencyValue;
