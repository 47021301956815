import { useLocation } from 'react-router-dom';
import { useNavigateToUnit } from '@/entities/unit/hooks';
import { RouteLink } from '@/shared/components';
import { UnitTab } from '@/shared/constants';
import { useParams } from '@/shared/hooks';
import { routingService } from '@/shared/services';
import { UnitRouteParams } from '@/shared/types';
import { isEnumValue } from '@/shared/utils';
import { UnitUsersTab, SettingsTab, UnitSourcesTab } from '../components';
import useTabRouteGuard from './useTabRouteGuard';

const TABS = [
  {
    key: UnitTab.Users,
    label: 'Користувачі',
    children: <UnitUsersTab />,
  },
  {
    key: UnitTab.Sources,
    label: 'Джерела',
    children: <UnitSourcesTab />,
  },
  {
    key: UnitTab.Settings,
    label: 'Налаштування',
    children: <SettingsTab />,
  },
];

const useTabs = () => {
  const { unitId, activeTab = UnitTab.Users } = useParams<UnitRouteParams>();
  const navigate = useNavigateToUnit();
  const { search } = useLocation();

  const handleTabChange = (tab: string) => {
    if (isEnumValue(tab, UnitTab)) {
      navigate({
        routeParams: {
          unitId,
          activeTab: tab,
        },
      });
    }
  };

  const tabLinks = TABS.map((item) => ({
    ...item,
    label: (
      <RouteLink
        href={`${routingService.getUnitUrl({ unitId, activeTab: item.key })}${search}`}
        onClick={() => handleTabChange(item.key)}
      >
        {item.label}
      </RouteLink>
    ),
  }));

  useTabRouteGuard({ navigateToTab: handleTabChange });

  return { tabs: tabLinks, activeTab };
};

export default useTabs;
