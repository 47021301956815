const getStringifiedFrequency = (numberFrequency: number) => {
  const stringifiedFrequency = numberFrequency.toString();

  return adjustFrequencySize(stringifiedFrequency);
};

function adjustFrequencySize(frequency: string): string {
  if (frequency.length === 8) return frequency;

  if (frequency.length > 8) return frequency.substring(0, 8);

  return adjustFrequencySize(frequency.concat(frequency.includes('.') ? '0' : '.'));
}

export default getStringifiedFrequency;
