import { useQuery } from '@tanstack/react-query';
import { ALL_FREQUENCIES_KEY } from '@/shared/constants';
import { Frequency } from '@/shared/types';
import { getFrequencies } from '../api';

type Options = {
  search?: string;
  notInNetworks?: boolean;
  ids?: string[];
  isEnabled?: boolean;
  defaultData?: Pick<Frequency, 'id' | 'value' | 'source_name'>[];
};

export const useGetFrequencies = (options: Options = {}) => {
  const { search = '', notInNetworks = false, ids = [], isEnabled = true, defaultData } = options;

  const { data, isFetching, isInitialLoading, isSuccess } = useQuery({
    queryKey: [ALL_FREQUENCIES_KEY, search, notInNetworks, ids],
    queryFn: () => getFrequencies(search, notInNetworks, ids),
    staleTime: Infinity,
    enabled: isEnabled,
    keepPreviousData: isEnabled,
    placeholderData: defaultData && {
      data: defaultData,
    },
  });

  return {
    frequencies: data?.data || [],
    isFrequenciesFetching: isFetching,
    isInitialLoading,
    isFrequenciesFetchedSuccessfully: isSuccess,
  };
};
