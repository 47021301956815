import { Layout, Typography } from 'antd';
import { COPIES } from '@/shared/constants';
import styles from './styles.module.css';

export const TechSupportFooter = () => (
  <Layout.Footer className={styles.footer}>
    <Typography.Text
      type="secondary"
      className={styles.notificationText}
    >
      {COPIES.CONTACT_SUPPORT}
    </Typography.Text>
  </Layout.Footer>
);
