import { UpdateUserParams } from '@/entities/user/types';
import { Paginated, request, ResponseData } from '@/shared/api';
import { DEFAULT_PAGE_SIZE } from '@/shared/constants';
import { User, BaseUser, NewUser, UserRole } from '@/shared/types';
import {
  getUserDataSchema,
  getUsersSchema,
  getNewUsersSchema,
  activateUserSchema,
  deactivateUserSchema,
  getUserRolesSchema,
  updateUserSchema,
} from './validationSchemas';

export const getUserData = () =>
  request<ResponseData<User>>('/users/me', {
    validationSchema: getUserDataSchema,
  });

export const getUsers = async ({ currentPage, groupId }: { currentPage: number; groupId?: string }) => {
  const queryParams = new URLSearchParams([
    ['page', String(currentPage)],
    ['size', DEFAULT_PAGE_SIZE],
  ]);

  return request<Paginated<BaseUser>>(`/groups/${groupId}/users?${queryParams.toString()}`, {
    validationSchema: getUsersSchema,
  });
};

export const getNewUsers = (currentPage: number) => {
  const queryParams = new URLSearchParams([
    ['page', String(currentPage)],
    ['size', DEFAULT_PAGE_SIZE],
  ]);

  return request<Paginated<NewUser>>(`/users/new?${queryParams.toString()}`, {
    validationSchema: getNewUsersSchema,
  });
};

export const activateUser = (id: number) =>
  request<ResponseData<User>>(`/users/${id}/activate`, {
    method: 'PATCH',
    validationSchema: activateUserSchema,
  });

export const deactivateUser = (id: number) =>
  request<ResponseData<User>>(`/users/${id}/deactivate`, {
    method: 'PATCH',
    validationSchema: deactivateUserSchema,
  });

export const getUserRoles = () =>
  request<ResponseData<UserRole[]>>(`/roles`, {
    validationSchema: getUserRolesSchema,
  });

export const updateUser = ({ userId, payload }: UpdateUserParams) =>
  request<ResponseData<User>>(`/users/${userId}`, {
    method: 'PATCH',
    body: JSON.stringify(payload),
    validationSchema: updateUserSchema,
  });
