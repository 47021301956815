import { Typography } from 'antd';
import getFrequencyFullName from '@/entities/frequency/utils/getFrequencyFullName';
import { LABEL_SEPARATOR } from '@/shared/constants';
import { Frequency } from '@/shared/types';

interface Props {
  name: Frequency['value'];
  sourceName: Frequency['source_name'];
  withTooltip?: boolean;
}

const FrequencyName = ({ name, sourceName, withTooltip = false }: Props) => {
  const wrapperEllipsis = withTooltip ? { tooltip: getFrequencyFullName({ name, sourceName }) } : true;

  return (
    <Typography.Text ellipsis={wrapperEllipsis}>
      {name}
      {sourceName && (
        <Typography.Text type="secondary">
          {LABEL_SEPARATOR}
          {sourceName}
        </Typography.Text>
      )}
    </Typography.Text>
  );
};

export default FrequencyName;
