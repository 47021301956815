import { memo } from 'react';
import { Timeline, Pipe, PlayButton, RateMenu, AutoplayButton, RecordInformation, SilenceSkip } from './components';
import { usePlayer } from './hooks';
import styles from './styles.module.css';
import { AudioPlayerProps } from './type';

const AudioPlayer = memo(
  ({
    record,
    isRecordSelected,
    isLoading = false,
    shouldBePlaying = false,
    memoSettings,
    memoOnPlayPause,
    memoOnSettingsUpdate,
    memoPlayNextRecord,
    memoNavigateToPreviousRecord,
    memoNavigateToNextRecord,
    recordHasVoice,
    recordTimestamp,
  }: AudioPlayerProps) => {
    const {
      containerRef,
      currentTime,
      isPlaying,
      duration,
      isAutoplayOn,
      audioRate,
      timeTooltipPosition,
      handleRateSelect,
      memoToggleAutoplay,
      memoPlayPause,
      isSkipSilenceEnabled,
      memoHandleSilenceSkipChange,
    } = usePlayer({
      record,
      isRecordSelected,
      isLoading,
      shouldBePlaying,
      memoSettings,
      memoPlayNextRecord,
      memoOnPlayPause,
      memoOnSettingsUpdate,
      memoNavigateToPreviousRecord,
      memoNavigateToNextRecord,
      recordHasVoice,
    });

    return (
      <div
        className={styles.playerWrap}
        tabIndex={0}
      >
        <Timeline
          containerRef={containerRef}
          currentTime={currentTime}
          timeTooltipPosition={timeTooltipPosition}
        />
        <div className={styles.player}>
          <div className={styles.leftNav}>
            <PlayButton
              isLoading={isLoading}
              isPlaying={isPlaying}
              playPause={memoPlayPause}
            />
            <Pipe />
            <AutoplayButton
              autoplay={isAutoplayOn}
              onClick={memoToggleAutoplay}
            />
            <Pipe />
            <RecordInformation
              recordTimestamp={recordTimestamp}
              currentTime={currentTime}
              duration={isLoading ? 0 : duration}
            />
          </div>
          <div className={styles.rightNav}>
            <RateMenu
              audioRate={audioRate}
              onRateSelect={handleRateSelect}
            />
            <SilenceSkip
              isSkipSilenceEnabled={isSkipSilenceEnabled}
              onChange={memoHandleSilenceSkipChange}
            />
          </div>
        </div>
      </div>
    );
  }
);

AudioPlayer.displayName = 'AudioPlayer';

export default AudioPlayer;
