import { TranscriptionCluster } from '@/entities/transcript/types';

const parseFrequencyValue = (frequencies: TranscriptionCluster['metadata']['frequencies'], frequencyValue: string) => {
  let common = '';
  let difference = '';

  frequencyValue.split('').every((_char, index) => {
    const start = frequencyValue.slice(0, index + 1);
    const end = frequencyValue.slice(index + 1);
    const hasNotDifference = frequencies.every(({ value }) => value.startsWith(start));

    if (hasNotDifference) {
      common = start;
      difference = end;
    }

    return hasNotDifference;
  });

  return [common, common ? difference : frequencyValue];
};

export default parseFrequencyValue;
