import { useRef } from 'react';
import { useContentSize as useObserveContentSize } from '@/shared/hooks';

const useContentSize = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const contentSize = useObserveContentSize(wrapperRef);

  return {
    wrapperRef,
    ...contentSize,
  };
};

export default useContentSize;
