import { PropsWithChildren, ReactNode } from 'react';
import { Typography } from 'antd';
import { NoDataPlaceholder } from '@/shared/components/NoDataPlaceholder';
import { useIsMobile } from '@/shared/hooks';
import styles from './styles.module.css';

type Props = {
  title: ReactNode;
  isNoData?: boolean;
};

export const MobileDetailItem = ({ title, isNoData, children }: PropsWithChildren<Props>) => {
  const isMobile = useIsMobile();
  return isMobile ? (
    <div className={styles.detailItemContainer}>
      <Typography.Text className={styles.detailTitle}>{title}</Typography.Text>
      <div>
        {isNoData ? <NoDataPlaceholder>{children}</NoDataPlaceholder> : <Typography.Text>{children}</Typography.Text>}
      </div>
    </div>
  ) : (
    children
  );
};
