import { DetectionProps } from '../types';

interface Params
  extends Pick<DetectionProps, 'detectionFrequency' | 'startFrequency' | 'endFrequency' | 'memoFrequencies'> {
  hiddenRange: number;
}

const isDetectionHidden = ({
  detectionFrequency,
  memoFrequencies,
  endFrequency,
  startFrequency,
  hiddenRange,
}: Params) => {
  return (
    detectionFrequency < startFrequency ||
    detectionFrequency > endFrequency ||
    memoFrequencies.some((frequency) => Math.abs(frequency - detectionFrequency) <= hiddenRange)
  );
};

export default isDetectionHidden;
