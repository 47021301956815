import { FoxRecordsQueryParam } from '@/shared/constants';
import { useSearchParam } from '@/shared/hooks';
import useRecordsFilterButtonCount from './useRecordsFilterButtonCount';

const useRecordsSearchField = () => {
  const count = useRecordsFilterButtonCount();
  const [search, setSearch] = useSearchParam(FoxRecordsQueryParam.Search);

  return {
    count,
    search,
    handleSearch: setSearch,
  };
};

export default useRecordsSearchField;
