import { useQuery } from '@tanstack/react-query';
import { USER_ROLES_QUERY_KEY } from '@/shared/constants';
import { getUserRoles } from '../api';

const useGetUserRoles = () => {
  const { data: { data: userRoles = [] } = {}, isLoading } = useQuery({
    queryKey: [USER_ROLES_QUERY_KEY],
    queryFn: getUserRoles,
    staleTime: Infinity,
  });

  return { userRoles, isUserRolesLoading: isLoading };
};

export default useGetUserRoles;
