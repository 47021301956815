import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/shared/types';
import { HexagonMapState as MapState } from './types';

const initialState: MapState = {
  hexagons: [],
  draftCells: [],
  isDrawEnabled: false,
};

const hexagonMapSlice = createSlice({
  name: 'hexagonMap',
  initialState,
  reducers: {
    setHexagons: (state: MapState, action: PayloadAction<MapState['hexagons']>) => {
      state.hexagons = action.payload;
    },
    setDraftCells: (state: MapState, action: PayloadAction<MapState['draftCells']>) => {
      state.draftCells = action.payload;
    },
    setIsDrawEnabled: (state: MapState, action: PayloadAction<boolean>) => {
      state.isDrawEnabled = action.payload;
    },
    resetHexagons: () => initialState,
  },
});

export const { setHexagons, setDraftCells, setIsDrawEnabled, resetHexagons } = hexagonMapSlice.actions;

export const selectHexagons = (state: RootState) => state.hexagonMap.hexagons;
export const selectDraftCells = (state: RootState) => state.hexagonMap.draftCells;
export const selectIsDrawEnabled = (state: RootState) => state.hexagonMap.isDrawEnabled;

export default hexagonMapSlice.reducer;
