import { findAll } from 'highlight-words-core';
import styles from './styles.module.css';

type Props = {
  text: string;
  searchTokens?: string[];
};

const Highlighter = ({ text, searchTokens }: Props) => {
  if (!searchTokens?.length) return text;

  const chunks = findAll({
    searchWords: searchTokens.map((key) => key.trim()),
    textToHighlight: text,
    caseSensitive: false,
    autoEscape: true,
  });

  return (
    <>
      {chunks.map((chunk) => {
        const chunkText = text.substring(chunk.start, chunk.end);
        const chunkKey = `${chunk.start} ${chunk.end} ${chunkText}`;

        if (!chunk.highlight) return chunkText;

        return (
          <mark
            key={chunkKey}
            className={styles.highlight}
          >
            {chunkText}
          </mark>
        );
      })}
    </>
  );
};

export default Highlighter;
