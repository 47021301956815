import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { BUILD_COMMIT_HASH, IS_LOCAL_BUILD } from '@/shared/config';
import { IS_LAST_BUILD_QUERY_KEY } from '@/shared/constants';
import { sentryService } from '@/shared/services';
import { getLastCommitHash } from '../api';
import { isLastBuild as getIsLastBuild } from '../utils';

const useGetIsLastBuild = () => {
  const { data, refetch } = useQuery({
    queryKey: [IS_LAST_BUILD_QUERY_KEY],
    queryFn: getLastCommitHash,
  });

  const isLastBuild = useMemo(() => {
    if (!data) return true;

    if (!data?.version || !BUILD_COMMIT_HASH) {
      sentryService.captureException(
        `useGetIsLastBuild isLastBuild issue, data.version - ${data?.version}, BUILD_COMMIT_HASH - ${BUILD_COMMIT_HASH}`
      );
      return true;
    }

    return getIsLastBuild({
      isLocalBuild: !!IS_LOCAL_BUILD,
      fistCommitHash: BUILD_COMMIT_HASH,
      secondCommitHash: data.version,
    });
  }, [data]);

  return {
    isLastBuild,
    refetch,
  };
};

export default useGetIsLastBuild;
