import { SubscriptionItem, SubscriptionItemId, SubscriptionType } from '@/entities/subscription';
import { useGetSubscriptions, useUpdateSubscription } from '@/widgets/SubscriptionDrawer/hooks';

const useSubscriptionState = () => {
  const { subscriptions } = useGetSubscriptions();
  const { updateSubscription } = useUpdateSubscription();

  const handleSubscriptionChange = (value: SubscriptionItemId[], type: SubscriptionType) => {
    if (subscriptions) {
      const valuesFromSelect: SubscriptionItem[] = value.map((item) => ({ type, value: item }));
      const currentSubscriptionValues: SubscriptionItem[] = subscriptions.models?.[0]?.value.filter(
        (item) => item.type !== type && item.value
      );
      const mergedValues: SubscriptionItem[] = [...currentSubscriptionValues, ...valuesFromSelect];

      updateSubscription({
        id: subscriptions.models?.[0]?.id,
        body: mergedValues,
      });
    }
  };

  const getSubscriptionValues = (type: SubscriptionType): SubscriptionItemId[] => {
    if (!subscriptions || !subscriptions.models.length) {
      return [];
    }

    return subscriptions.models?.[0]?.value?.flatMap((item) => (item.type === type && item.value ? [item.value] : []));
  };

  return {
    handleSubscriptionChange,
    getSubscriptionValues,
  };
};

export default useSubscriptionState;
