import { FoxWaterfallAuth } from '@/entities/source/@fox/types';
import { ResponseData } from '@/shared/api';

interface Params {
  sourceId: string;
  tokenType: keyof FoxWaterfallAuth;
  generateFoxWaterfallAuth: (sourceId: string) => Promise<ResponseData<FoxWaterfallAuth>>;
}

let cache: Promise<ResponseData<FoxWaterfallAuth>> | null = null;
const RESET_CACHE_TIMEOUT = 1000;

// all three tokens for centrigfugejs expire at the same time,
// and we have only one endpoint for three channels by which we request new tokens.
// centrigfugejs provides token refetch methods for each chanel, and it is impossible to pass new tokens through params.
// to get rid of three simultaneous requests, caching added.

const getWaterfallAuthToken =
  ({ sourceId, tokenType, generateFoxWaterfallAuth }: Params) =>
  async () => {
    if (cache) {
      const { data } = await cache;
      return data[tokenType];
    }

    cache = generateFoxWaterfallAuth(sourceId);
    const { data } = await cache;
    setTimeout(() => {
      cache = null;
    }, RESET_CACHE_TIMEOUT);
    return data[tokenType];
  };

export default getWaterfallAuthToken;
