import { useConfirmModal } from '@/shared/hooks';
import styles from '../styles.module.css';

const OK_TEXT = 'Оновити';
const MODAL_TITLE = 'Оновити прошивку?';
const MODAL_CONTENT = (
  <>
    <p className={styles.modalContent}>Швидкість завантаження нової прошивки залежить від швидкості інтернету.</p>
    <p className={styles.modalContent}>
      Після завантаження прошивки відразу почнеться оновлення, яке займає близько 10с, під час якого пристрїй не буде
      збирати інформацію.
    </p>
    <p className={styles.modalContent}>Будь ласка, оновлюйте пристрій тільки якщо впевнені в тому що робите.</p>
  </>
);

interface Params {
  onUpgradeFox: () => Promise<void>;
}

const useOpenUpgradeFirmwareConfirmModal = ({ onUpgradeFox }: Params) => {
  return useConfirmModal({
    title: MODAL_TITLE,
    content: MODAL_CONTENT,
    okText: OK_TEXT,
    onOk: onUpgradeFox,
  });
};

export default useOpenUpgradeFirmwareConfirmModal;
