import { MutableRefObject, ReactElement, UIEventHandler, useEffect } from 'react';
import { useDebounceFn } from '@reactuses/core';
import { useVirtualizer } from '@tanstack/react-virtual';
import uniqBy from 'lodash/uniqBy';
import { DEBOUNCE_DEFAULT } from '@/shared/constants';
import styles from './styles.module.css';
import { getDuplicatedRowsTotalSize } from './utils';

type Props<T> = {
  listRef: MutableRefObject<HTMLDivElement | null>;
  data: T[];
  renderRow: (item: T, index: number) => ReactElement;
  defaultRowHeight?: number;
  scrollToRowIndex?: number;
  onScroll?: UIEventHandler<HTMLDivElement>;
  loadMore?: () => void;
  overscan?: number;
  header?: ReactElement;
};

const HEADER_ITEM = { id: 'header' };

const VirtualList = <T extends { id: string }>({
  listRef,
  data,
  renderRow,
  defaultRowHeight = 200,
  scrollToRowIndex = -1,
  overscan = 5,
  onScroll,
  loadMore,
  header,
}: Props<T>) => {
  const listData = header ? [HEADER_ITEM, ...data] : data;
  const virtualizer = useVirtualizer({
    count: listData.length,
    getScrollElement: () => listRef.current,
    getItemKey: (index) => listData[index].id,
    estimateSize: () => defaultRowHeight,
    overscan,
  });
  const allVirtualItems = virtualizer.getVirtualItems();
  const virtualItems = uniqBy(allVirtualItems, 'key');

  const height = virtualizer.getTotalSize() - getDuplicatedRowsTotalSize(allVirtualItems);

  const { run: loadMoreDebounced } = useDebounceFn(() => loadMore?.(), DEBOUNCE_DEFAULT);

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    onScroll?.(event);

    if (!loadMore) return;

    const {
      currentTarget: { scrollTop, clientHeight, scrollHeight },
    } = event;
    const isAtBottom = scrollTop + clientHeight + defaultRowHeight > scrollHeight;
    if (isAtBottom) {
      loadMoreDebounced();
    }
  };

  useEffect(() => {
    if (scrollToRowIndex >= 0) {
      virtualizer.scrollToIndex(scrollToRowIndex, { align: 'start' });
    }
  }, [virtualizer, scrollToRowIndex]);

  return (
    <div
      ref={listRef}
      className={styles.virtualList}
      onScroll={handleScroll}
    >
      <div
        className={styles.virtualListInner}
        style={{ height }}
        data-testid="virtual-list"
      >
        <div
          className={styles.virtualListContent}
          style={{
            transform: `translateY(${virtualItems[0]?.start ?? 0}px)`,
          }}
        >
          {virtualItems.map(({ key, index }) => {
            const item = listData[index];

            return (
              <div
                key={key}
                data-index={index}
                ref={virtualizer.measureElement}
              >
                {item.id === HEADER_ITEM.id ? header : renderRow(item as T, index)}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default VirtualList;
