import { ReactElement } from 'react';
import { SearchField } from '@/shared/components';
import { useScanningSearchField } from './hooks';

interface Props {
  onFilterClick: () => void;
  additionalControlsLeft?: ReactElement;
  additionalControlsRight?: ReactElement;
}

const ScanningSearchField = ({ onFilterClick, additionalControlsLeft, additionalControlsRight }: Props) => {
  const { count, search, handleSearch } = useScanningSearchField();

  return (
    <SearchField
      value={search}
      onSearch={handleSearch}
      filterButtonOptions={{ selectedCount: count, onClick: onFilterClick }}
      additionalControlsLeft={additionalControlsLeft}
      additionalControlsRight={additionalControlsRight}
      isAsync
    />
  );
};

export default ScanningSearchField;
