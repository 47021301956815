import { Transcription } from '@/entities/transcript/types';
import { LatLngCoordinates, TranscriptLocationsProps } from '@/shared/types';
import { CoordinatesTag } from './components';
import useLocationsCoordinatesTags from './useLocationsCoordinatesTags';

interface Props extends Pick<TranscriptLocationsProps, 'locations' | 'isCluster'> {
  transcript: Transcription;
  coordinates: LatLngCoordinates[];
}

const LocationsCoordinatesTags = ({ transcript, locations, coordinates, isCluster }: Props) => {
  const { handleCoordinatesClick, handleLocationClick, renderTranscriptLocations } =
    useLocationsCoordinatesTags(transcript);

  return (
    <>
      {coordinates.map((item) => (
        <CoordinatesTag
          key={`${item.lat}-${item.lng}`}
          coordinates={item}
          onClick={handleCoordinatesClick}
        />
      ))}
      {renderTranscriptLocations({
        transcriptId: transcript.id,
        locations,
        onItemClick: handleLocationClick,
        isCluster,
      })}
    </>
  );
};

export default LocationsCoordinatesTags;
