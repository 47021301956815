import { Detection } from './components';
import { DetectionsProps as Props } from './types';

const Detections = ({
  startFrequency,
  endFrequency,
  detections,
  detectionsVisibilityRange,
  memoFrequencies,
  memoOnCreateFrequency,
  topPosition,
}: Props) => {
  return detections.map((detection) => (
    <Detection
      key={detection.frequency}
      detectionFrequency={detection.frequency}
      isDigital={detection.isDigital}
      topPosition={topPosition}
      detectionsVisibilityRange={detectionsVisibilityRange}
      startFrequency={startFrequency}
      endFrequency={endFrequency}
      memoOnCreateFrequency={memoOnCreateFrequency}
      memoFrequencies={memoFrequencies}
    />
  ));
};

export default Detections;
