import { PropsWithChildren } from 'react';
import { Tooltip as AntTooltip, TooltipProps as AntdTooltipProps } from 'antd';
import classNames from 'classnames';
import { useIsTouchDevice } from '@/shared/hooks';
import styles from './styles.module.css';

export type TooltipProps = AntdTooltipProps & {
  isDesktopOnly?: boolean;
  isOneLine?: boolean;
};

export const Tooltip = ({
  children,
  overlayClassName,
  isDesktopOnly,
  isOneLine,
  mouseEnterDelay = 1,
  ...props
}: PropsWithChildren<TooltipProps>) => {
  const isTouchDevice = useIsTouchDevice();

  return isDesktopOnly && isTouchDevice ? (
    children
  ) : (
    <AntTooltip
      {...props}
      mouseEnterDelay={mouseEnterDelay}
      overlayClassName={classNames(overlayClassName, {
        [styles.oneLine]: isOneLine,
      })}
    >
      {children}
    </AntTooltip>
  );
};
