import { z } from 'zod';
import { responseDataSchema } from '@/shared/validationSchemas';

const mapPointTypeSchema = z.enum(['point', 'location']);

export const geoJsonFeatureSchema = z.object({
  type: z.literal('Feature'),
  properties: z.object({
    type: mapPointTypeSchema,
    date: z.number(),
    transcriptId: z.string(),
    frequencyId: z.string().nullable(),
    locations: z.array(z.string()),
  }),
  geometry: z.object({
    type: z.literal('Point'),
    coordinates: z.tuple([z.number(), z.number()]),
  }),
});

export const geoJsonFeatureCollectionSchema = z.object({
  type: z.literal('FeatureCollection'),
  features: z.array(geoJsonFeatureSchema),
});

export const getAllInterceptionsMapPointsSchema = responseDataSchema(geoJsonFeatureCollectionSchema);

export const getMapKMLDataSchema = responseDataSchema(z.string());
