import styles from './styles.module.css';
import useSoundWave from './useSoundWave';

interface Props {
  isActive?: boolean;
}

const SoundWave = ({ isActive = true }: Props) => {
  const { ref, waves } = useSoundWave(isActive);

  return (
    <div
      ref={ref}
      className={styles.wrapper}
    >
      {waves.map(({ index, className }) => (
        <div
          key={index}
          className={className}
        />
      ))}
    </div>
  );
};

export default SoundWave;
