import axios from 'axios';
import { LAST_COMMIT_HASH_SERVER_HOST } from '@/shared/config';
import { validationService, sentryService } from '@/shared/services';
import { LastCommitHash } from './types';
import { getLastCommitHashSchema } from './validationSchemas';

export const getLastCommitHash = async (): Promise<LastCommitHash> => {
  if (!LAST_COMMIT_HASH_SERVER_HOST) {
    sentryService.captureException('getLastCommitHash issue, LAST_HASH_SERVER_HOST is missed');
    return { version: null };
  }

  const result = await axios.get<LastCommitHash>(LAST_COMMIT_HASH_SERVER_HOST, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  validationService.validateResponseData({
    url: LAST_COMMIT_HASH_SERVER_HOST,
    responseData: result.data,
    validationSchema: getLastCommitHashSchema,
  });

  return result.data;
};
