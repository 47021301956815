import { BASE_COLORS } from '../common';

export const COLORS = {
  ...BASE_COLORS,
  bgContainer: '#222222',
  bgElevated: '#292929',
  bgPanel: '#2d2d2d',
  bgPopover: '#373737',
  border: '#4e4e4e',
  bgItemSelected: 'rgba(255, 83, 85, 0.28)',
  linkHover: '#d17072',
  bgSlider: '#69C0ff',
  bgSliderHover: '#1890ff',
};
