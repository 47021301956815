import { Spin } from 'antd';
import { SpinProps } from 'antd/es/spin';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './styles.module.css';

const SpinIcon = ({ iconFontSize = '24px' }: { iconFontSize?: string }) => (
  <LoadingOutlined
    style={{ fontSize: iconFontSize }}
    spin
  />
);

interface SpinnerProps extends SpinProps {
  iconFontSize?: string;
}

export const SpinnerIndicator = ({ iconFontSize, children, ...props }: SpinnerProps) => (
  <Spin
    indicator={<SpinIcon iconFontSize={iconFontSize} />}
    {...props}
  >
    {children}
  </Spin>
);

const Spinner = (props: SpinnerProps) => (
  <SpinnerIndicator {...props}>
    <div className={styles.spinnerInner} />
  </SpinnerIndicator>
);

export default Spinner;
