import { selectCallingElementPosition, selectPointCoordinates } from '@/entities/map/slices';
import { useAppSelector } from '@/shared/hooks';
import { getModalMapPosition } from './utils';

const useModalMap = () => {
  const callingElementPosition = useAppSelector(selectCallingElementPosition);
  const pointCoordinates = useAppSelector(selectPointCoordinates);

  const modalPosition = getModalMapPosition({
    callingElementPosition,
    modalMapWidth: 320,
    spaceBetweenMapAndCallingElement: 15,
  });

  return {
    modalPosition,
    pointCoordinates,
  };
};

export default useModalMap;
