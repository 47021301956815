import { Unit, UnitsDictionary } from '@/entities/unit/types';

const getUnitsDictionary = (units: Unit[]) => {
  const unitsDictionary: UnitsDictionary = {};

  const addUnitToDictionary = (unit: Unit, dictionary: UnitsDictionary) => {
    const { children, ...unitWithoutChildren } = unit;
    dictionary[unitWithoutChildren.id] = unitWithoutChildren;
    if (children) {
      children.forEach((child) => {
        addUnitToDictionary(child, dictionary);
      });
    }
  };

  units.forEach((unit) => {
    addUnitToDictionary(unit, unitsDictionary);
  });

  return unitsDictionary;
};

export default getUnitsDictionary;
