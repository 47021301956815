import { Space } from 'antd';
import { LoadingWrapper } from '@/shared/components';
import { useGetSubscriptions } from '@/widgets/SubscriptionDrawer/hooks';
import { NotifyMeSection, SubscriptionSettingsControls } from './components';
import styles from './styles.module.css';

const SubscriptionsSettings = () => {
  const { subscriptions, isSubscriptionFetching } = useGetSubscriptions();

  return (
    <LoadingWrapper
      isLoading={isSubscriptionFetching}
      isEmpty={!subscriptions?.models}
    >
      <Space
        direction="vertical"
        size="large"
        className={styles.contentWrapper}
      >
        <NotifyMeSection />
        <SubscriptionSettingsControls />
      </Space>
    </LoadingWrapper>
  );
};

export default SubscriptionsSettings;
