import { useState, useEffect, useMemo } from 'react';
import { List } from 'antd';
import { MobileDetailsManagerState } from '@/shared/constants';
import { useAppSelector, useAppDispatch } from '@/shared/hooks';
import {
  selectActiveMobileDetailsManagerState,
  setActiveMobileDetailsManagerState,
  resetActiveMobileDetailsManagerState,
} from '@/shared/slices';
import { MobileDetailsManager } from '../MobileDetailsManager';
import { MobileListManagerProps } from '../types';
import { MobileListItem } from './components';
import { getPreparedListColumns } from './utils';

export { AddListItemButton } from './components/AddListItemButton';

export const MobileListManager = <T extends Record<string, unknown>>({
  keyAsId = 'id',
  listClassName,
  listData,
  fullListData = listData,
  isLoading,
  listColumns = [],
  listColumnsOrder,
  getItemRef,
  onItemClick,
  searchTokens,
  searchedColumns,
  rowClassName,
  renderRow,
  itemDetailsManager = {},
}: MobileListManagerProps<T>) => {
  const {
    headerClassName,
    createTitlePrefix,
    detailItemsOrder,
    renderCreateComponent,
    renderEditComponent,
    renderActions,
  } = itemDetailsManager;

  const viewType = useAppSelector(selectActiveMobileDetailsManagerState);
  const dispatch = useAppDispatch();
  const isDetailsContainerVisible = !!viewType;

  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const selectedItem = useMemo(() => {
    if (!selectedItemId) return;
    return fullListData.find((item) => String(item[keyAsId]).toLowerCase() === selectedItemId.toLowerCase());
  }, [fullListData, keyAsId, selectedItemId]);

  const handleListItemClick = (item: T) => {
    setSelectedItemId(String(item[keyAsId]));
    dispatch(setActiveMobileDetailsManagerState(MobileDetailsManagerState.View));
    onItemClick?.(item);
  };

  const navigateToEdit = () => dispatch(setActiveMobileDetailsManagerState(MobileDetailsManagerState.Edit));

  const navigateToList = () => dispatch(resetActiveMobileDetailsManagerState());

  useEffect(() => {
    if (!isDetailsContainerVisible) setSelectedItemId(null);
  }, [isDetailsContainerVisible]);

  // this useEffect is a fix for issue
  // that occurs when a user is on the details/edit/create page
  // and switches to desktop view and then back to mobile.
  // after it viewType still contains value but selectedItem doesn't and user see empty MobileDetailsManager
  useEffect(() => {
    if (viewType && !selectedItem) {
      navigateToList();
    }
  }, [viewType, selectedItem]);

  return viewType ? (
    <MobileDetailsManager
      headerClassName={headerClassName}
      createTitlePrefix={createTitlePrefix}
      items={listColumns}
      detailItemsOrder={detailItemsOrder}
      selectedItem={selectedItem}
      detailsTitle={selectedItem ? itemDetailsManager.renderDetailsTitle?.(selectedItem) : undefined}
      detailsComponent={selectedItem ? itemDetailsManager.renderDetailsComponent?.(selectedItem) : undefined}
      editComponent={selectedItem ? renderEditComponent?.(selectedItem) : undefined}
      createComponent={renderCreateComponent?.(navigateToList)}
      actions={selectedItem ? renderActions?.(selectedItem, navigateToEdit, navigateToList) : undefined}
      onDetailContainerClose={navigateToList}
      isOpen={isDetailsContainerVisible}
      isLoading={isLoading}
    />
  ) : (
    <List
      rowKey={keyAsId}
      className={listClassName}
      dataSource={listData}
      renderItem={(item, index) => (
        <MobileListItem<T>
          itemKey={item[keyAsId]}
          itemRef={getItemRef?.(index)}
          className={typeof rowClassName === 'function' ? rowClassName(item) : rowClassName}
          listRow={renderRow?.(item)}
          columns={getPreparedListColumns(item, listColumns, listColumnsOrder)}
          searchTokens={searchTokens}
          searchedColumns={searchedColumns}
          onClick={() => handleListItemClick(item)}
        />
      )}
    />
  );
};
