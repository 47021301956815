import { Paginated, request } from '@/shared/api';
import { Location } from '@/shared/types';
import { LOCATIONS_PAGE_SIZE } from '../constants';
import { getLocationsByIdsSchema } from './validationSchemas';

export const getLocations = (search: string, type?: 'state') => {
  const params = new URLSearchParams({
    size: LOCATIONS_PAGE_SIZE,
  });

  if (search) {
    params.set('name', search.toLowerCase());
  }

  if (type) {
    params.set('type', type);
  }

  return request<Paginated<Location>>(`/locations?${params.toString()}`);
};

export const getLocationsByIds = (ids: Array<string | number>) =>
  request<Paginated<Location>>(`/locations?${ids.map((id) => `id[]=${id}`).join('&')}`, {
    validationSchema: getLocationsByIdsSchema,
  });
