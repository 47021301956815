import { FoxFrequencyEncryptionType } from '@/entities/source/@fox/constants';
import { FoxFrequency } from '@/entities/source/@fox/types';

const DECRYPTED_HYTERAS_DICTIONARY = {
  [FoxFrequencyEncryptionType.Hytera40]: true,
  [FoxFrequencyEncryptionType.Hytera128]: true,
  [FoxFrequencyEncryptionType.Hytera256]: true,
};

const getEncryptionTypesWithFreshestHytera = (encryptionTypes: FoxFrequency['encryptionTypes']) => {
  let freshestHyteraLastActiveAt: number | null = null;

  encryptionTypes.forEach((encryptionType) => {
    if (typeof encryptionType.type !== 'string') return;
    if (!Object.hasOwn(DECRYPTED_HYTERAS_DICTIONARY, encryptionType.type)) return;

    if (!freshestHyteraLastActiveAt) {
      freshestHyteraLastActiveAt = encryptionType.lastActiveAt;
      return;
    }

    freshestHyteraLastActiveAt =
      encryptionType.lastActiveAt > freshestHyteraLastActiveAt
        ? encryptionType.lastActiveAt
        : freshestHyteraLastActiveAt;
  });

  if (!freshestHyteraLastActiveAt) return encryptionTypes;

  return encryptionTypes.filter((encryptionType) => {
    return !(
      typeof encryptionType.type === 'string' &&
      Object.hasOwn(DECRYPTED_HYTERAS_DICTIONARY, encryptionType.type) &&
      encryptionType.lastActiveAt !== freshestHyteraLastActiveAt
    );
  });
};

export default getEncryptionTypesWithFreshestHytera;
