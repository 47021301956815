import { ChangeEventHandler, KeyboardEventHandler, useState, RefObject, FocusEventHandler } from 'react';
import { InputProps, InputRef } from 'antd';
import { Key } from 'ts-key-enum';
import { FrequencyProps } from '../types';
import { getStringifiedFrequency } from '../utils';

interface Params
  extends Pick<
    FrequencyProps,
    'frequency' | 'memoOnDeleteFrequency' | 'isActive' | 'memoOnSave' | 'id' | 'isDisabled' | 'isDigital'
  > {
  inputMaxLength: number;
  isInputActive: boolean;
  setIsInputActive: (value: boolean) => void;
  inputRef: RefObject<InputRef>;
}

const useInputEventHandlers = ({
  frequency,
  memoOnDeleteFrequency,
  inputMaxLength,
  inputRef,
  isActive,
  memoOnSave,
  id,
  setIsInputActive,
  isDisabled,
  isDigital,
}: Params) => {
  const defaultFrequency = getStringifiedFrequency(frequency);
  const [inputStatus, setInputStatus] = useState<InputProps['status']>();
  const [inputValue, setInputValue] = useState<string | undefined>(defaultFrequency);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (isDisabled) return;

    if (inputStatus === 'error') {
      setInputStatus('');
    }

    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(value)) {
      setInputValue(value || undefined);
    }
  };

  const handleInputKeyUp: KeyboardEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.currentTarget;
    if (e.nativeEvent.key === Key.Enter) {
      handleInputSaveChanges(value);
    } else if (e.nativeEvent.key === Key.Delete || e.nativeEvent.key === Key.Escape) {
      handleDeleteFrequency();
    }
  };

  const handleInputBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    handleInputSaveChanges(value);
  };

  async function handleInputSaveChanges(value: string) {
    const numberValue = Number(value);
    if (!numberValue) {
      await handleDeleteFrequency();
    } else if (value.length < inputMaxLength) {
      setInputStatus('error');
      inputRef.current?.focus();
    } else {
      await handleSaveFrequency({ frequencyId: id, value: numberValue });
    }
  }

  async function handleDeleteFrequency() {
    try {
      setIsLoading(true);
      await memoOnDeleteFrequency(id);
    } finally {
      setIsLoading(false);
      setIsInputActive(false);
    }
  }

  async function handleSaveFrequency({ frequencyId, value }: { frequencyId: string; value: number }) {
    try {
      setIsLoading(true);
      await memoOnSave({ id: frequencyId, frequency: value, isActive, isDigital });
    } finally {
      setIsLoading(false);
      setIsInputActive(false);
    }
  }

  const handleInputFocus = () => {
    if (isDisabled) {
      inputRef.current?.blur();
    }
  };

  return {
    inputValue,
    inputStatus,
    isLoading,
    handleInputChange,
    handleInputKeyUp,
    handleInputBlur,
    handleInputFocus,
  };
};

export default useInputEventHandlers;
