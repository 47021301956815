import { useEffect } from 'react';
import { useNavigateToRecord } from '@/entities/source/@fox/components/RecordsTab/hooks';
import { useAppDispatch, useParams } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';

interface Params<T> {
  list: T[];
  listItemPropertyName: keyof T;
  compareValue?: T[keyof T];
}

const useRedirectToRecordList = <T>({ list, listItemPropertyName, compareValue }: Params<T>) => {
  const { sourceId = '' } = useParams<SourceRouteParams>();
  const dispatch = useAppDispatch();
  const navigateToRecord = useNavigateToRecord();

  useEffect(() => {
    if (!compareValue || list.length === 0) return;

    if (!list.some((listItem) => listItem[listItemPropertyName] === compareValue)) {
      navigateToRecord({
        routeParams: {
          sourceId,
          tabEntityId: '',
        },
        options: {
          withAllQueryParams: true,
        },
      });
    }
  }, [dispatch, compareValue, list, listItemPropertyName]);
};

export default useRedirectToRecordList;
