import { useEffect, useState, EffectCallback } from 'react';

export const useConditionEffectOnce = (effectCallback: EffectCallback, condition: boolean) => {
  const [isEffectCalled, setIsEffectCalled] = useState(false);

  useEffect(() => {
    if (!isEffectCalled && condition) {
      setIsEffectCalled(true);
    }
  }, [condition, isEffectCalled]);

  useEffect(() => {
    if (isEffectCalled) {
      effectCallback();
    }
  }, [isEffectCalled]);
};
