import { NetworkQueryParam } from '@/shared/constants';
import { useSearchParam } from '@/shared/hooks';
import { queryParamsService } from '@/shared/services';

enum RequestQueryParam {
  Search = 's',
  Location = 'location[]',
  Faction = 'faction[]',
  Category = 'category[]',
  Group = 'group[]',
  DateFrom = 'date_from',
  DateTo = 'date_to',
}

export const useNetworkQueryParams = () => {
  const [search] = useSearchParam(NetworkQueryParam.Search);
  const [location] = useSearchParam(NetworkQueryParam.Location);
  const [faction] = useSearchParam(NetworkQueryParam.Faction);
  const [category] = useSearchParam(NetworkQueryParam.Category);
  const [group] = useSearchParam(NetworkQueryParam.Group);
  const [dateRange] = useSearchParam(NetworkQueryParam.DateRange);

  const [dateFrom, dateTo] = queryParamsService.convertQueryParamToArray(dateRange);

  const queryParams = new URLSearchParams([
    ...(search ? [[RequestQueryParam.Search, search]] : []),
    ...queryParamsService.convertQueryParamToArray(location).map((item) => [RequestQueryParam.Location, item]),
    ...queryParamsService.convertQueryParamToArray(faction).map((item) => [RequestQueryParam.Faction, item]),
    ...queryParamsService.convertQueryParamToArray(category).map((item) => [RequestQueryParam.Category, item]),
    ...queryParamsService.convertQueryParamToArray(group).map((item) => [RequestQueryParam.Group, item]),
    ...(dateFrom ? [[RequestQueryParam.DateFrom, dateFrom]] : []),
    ...(dateTo ? [[RequestQueryParam.DateTo, dateTo]] : []),
  ]).toString();

  return {
    search,
    location,
    faction,
    category,
    group,
    dateRange,
    queryParams,
  };
};
