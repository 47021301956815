import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { GripIcon } from '@/shared/assets';
import { RadioNetwork, Frequency } from '@/shared/types';
import styles from './styles.module.css';
import useDraggableItemWrapper from './useDraggableItemWrapper';

interface Props {
  item: RadioNetwork;
  onDragEnd: (network: RadioNetwork, frequency: Frequency) => void;
  isDraggingEnabled: boolean;
}

const DraggableItemWrapper: FC<PropsWithChildren<Props>> = ({
  item,
  children,
  onDragEnd,
  isDraggingEnabled = true,
}) => {
  const { isItemDragging, isFrequencyDragging, drag } = useDraggableItemWrapper({
    item,
    onDragEnd,
    isDraggingEnabled,
  });

  return (
    <div
      ref={drag}
      className={classNames(styles.wrapper, {
        [styles.draggingWrapper]: isItemDragging,
      })}
    >
      {!isFrequencyDragging && <GripIcon className={styles.dragIcon} />}
      {children}
    </div>
  );
};

export default DraggableItemWrapper;
