import { useLocation } from 'react-router-dom';
import { useExtendActiveFilters } from '@/entities/transcript/components/Transcripts/components/TranscriptList/components/TranscriptItem/hooks';
import { TranscriptQueryParam } from '@/shared/constants';
import { useNavigate } from '@/shared/hooks';

const useFrequencyLink = (frequencyValue: string) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const filterParams = useExtendActiveFilters({
    filterName: TranscriptQueryParam.Frequency,
    filterValue: frequencyValue,
  });

  const path = pathname;
  const queryString = filterParams;

  const frequencyLinkHref = `${path}?${queryString}`;

  const handleFrequencyClick = () => {
    navigate({
      pathname: path,
      options: {
        additionalQueryParams: queryString,
      },
    });
  };

  return {
    frequencyLinkHref,
    handleFrequencyClick,
  };
};

export default useFrequencyLink;
