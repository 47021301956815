import { useMemo, useState } from 'react';
import { getTranscriptsList } from '@/entities/transcript/api';
import { TRANSCRIPTS_QUERY_KEY } from '@/shared/constants';
import { useInfiniteListQuery } from '@/shared/hooks';
import { queryParamsService } from '@/shared/services';
import useSyncTranscriptsDictionary from '../useSyncTranscriptsDictionary';
import useRefetchInterval from './useRefetchInterval';
import useUpdateExtraParams from './useUpdateExtraParams';

type Params = {
  queryParams: string;
  shouldAutoRefetch?: boolean;
};

const useGetTranscripts = ({ queryParams, shouldAutoRefetch }: Params) => {
  const { refetchInterval, setRefetchInterval } = useRefetchInterval(shouldAutoRefetch);
  const [foffset, setFoffset] = useState<string | null>(null);
  const [clusterExcluded, setClusterExcluded] = useState<string[]>();
  const [lastItemTimestamp, setLastItemTimestamp] = useState<number | null>();
  const [score, setScore] = useState<number | null>();

  const paginationParams = useMemo(() => {
    return {
      foffset,
      clusterExcluded,
      lastItemTimestamp: typeof lastItemTimestamp === 'number' ? String(lastItemTimestamp) : lastItemTimestamp,
      score: typeof score === 'number' ? String(score) : score,
    };
  }, [foffset, clusterExcluded, lastItemTimestamp, score]);

  const { data, list, getListRef, isLoading, isFetching, refetch, remove, forceFetchNextPage } = useInfiniteListQuery({
    // We intentionally do not observe paginationParams parameter in queryKey to avoid redundant requests
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [TRANSCRIPTS_QUERY_KEY, queryParams],
    queryFn: ({ pageParam = 1 }) => {
      return getTranscriptsList({
        currentPage: pageParam,
        queryParams: queryParamsService.extendByExtra({
          pageParam,
          queryParams,
          extra: paginationParams,
        }),
      });
    },
    refetchInterval,
  });

  useUpdateExtraParams({ data, setFoffset, setClusterExcluded, setLastItemTimestamp, setScore });
  useSyncTranscriptsDictionary({ memoTranscripts: list });

  return {
    data,
    transcripts: list,
    getTranscriptsRef: getListRef,
    isLoading,
    isFetching,
    refetch,
    remove,
    forceFetchNextPage,
    refetchInterval,
    setRefetchInterval,
  };
};

export default useGetTranscripts;
