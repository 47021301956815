import { getFrequencyPosition } from '@/entities/source/@fox/components/ScanningTab/components/Spectrogram/utils';
import { FrequencyProps } from '../types';
import { getColoredLineHeight, getFrequencyInputPosition } from '../utils';

interface Params
  extends Pick<
    FrequencyProps,
    'startFrequency' | 'endFrequency' | 'frequencyHeight' | 'frequency' | 'maxLeftPosition' | 'maxRightPosition'
  > {
  inputWrapperWidth: number;
  wrapperLeftPosition: number;
  wrapperRightPosition: number;
  isInputVisible: boolean;
}

const useCalculatedStyles = ({
  startFrequency,
  endFrequency,
  frequencyHeight,
  frequency,
  inputWrapperWidth,
  maxLeftPosition,
  maxRightPosition,
  wrapperLeftPosition,
  wrapperRightPosition,
  isInputVisible,
}: Params) => {
  const frequencyPosition = getFrequencyPosition({
    startFrequency,
    endFrequency,
    frequency,
  });

  const isOverflowRight =
    isInputVisible && maxRightPosition !== undefined && maxRightPosition < wrapperRightPosition + 50;
  const isOverflowLeft = isInputVisible && maxLeftPosition !== undefined && maxLeftPosition > wrapperLeftPosition - 50;

  const inputPosition = getFrequencyInputPosition({
    frequencyWidth: inputWrapperWidth,
    isOverflowRight,
    isOverflowLeft,
    isInputVisible,
  });

  const coloredLineHeight = getColoredLineHeight(frequencyHeight);

  const wrapperStyles = { ...frequencyPosition, ...coloredLineHeight };

  return {
    wrapperStyles,
    inputPosition,
    isOverflowRight,
    isOverflowLeft,
  };
};

export default useCalculatedStyles;
