import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getFoxFrequencies } from '@/entities/source/@fox/api';
import { FoxFrequency } from '@/entities/source/@fox/types';
import { RequestQueryParams, ResponseData } from '@/shared/api';
import { FOX_FREQUENCIES_QUERY_KEY } from '@/shared/constants';

interface Params {
  sourceId: string;
  options?: UseQueryOptions<
    ResponseData<FoxFrequency[]>,
    unknown,
    ResponseData<FoxFrequency[]>,
    (string | RequestQueryParams | undefined)[]
  >;
  queryParams?: RequestQueryParams;
}

const useGetFoxFrequencies = ({ sourceId, options, queryParams }: Params) => {
  const {
    data,
    isLoading: isFoxFrequenciesLoading,
    isFetching: isFoxFrequenciesFetching,
    refetch: refetchFoxFrequencies,
  } = useQuery({
    queryKey: [FOX_FREQUENCIES_QUERY_KEY, sourceId, queryParams],
    queryFn: () => getFoxFrequencies({ sourceId, queryParams }),
    enabled: Boolean(sourceId),
    ...options,
  });

  return {
    foxFrequencies: data?.data ?? [],
    isFoxFrequenciesLoading,
    isFoxFrequenciesFetching,
    refetchFoxFrequencies,
  };
};

export default useGetFoxFrequencies;
