import { useMemo } from 'react';
import { useDragLayer } from 'react-dnd';
import { DraggableItemWrapper, DroppableItemWrapper } from '@/entities/frequency';
import { RadioNetworkType } from '@/shared/constants';
import { RadioNetwork } from '@/shared/types';

export const useDragWrapperComponent = (item: RadioNetwork) => {
  const { draggingItem } = useDragLayer((monitor) => ({
    draggingItem: monitor.getItem<{ item: RadioNetwork } | null>(),
  }));

  return useMemo(() => {
    if (!draggingItem) {
      return item.type === RadioNetworkType.FREQUENCY ? DraggableItemWrapper : DroppableItemWrapper;
    }

    const { item: draggingFrequency } = draggingItem;

    if (draggingFrequency && draggingFrequency.id === item.id) {
      return DraggableItemWrapper;
    }

    return DroppableItemWrapper;
  }, [draggingItem, item.id, item.type]);
};
