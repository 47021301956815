import { TableColumnsType, Tag, Typography, theme, Flex } from 'antd';
import { useUnitRouteInfo, useTableItemModalManager } from '@/entities/unit/hooks';
import { UnitUser } from '@/entities/unit/types';
import { ROLES_DESCRIPTION, UNIT_USERS_QUERY_KEY, UNIT_FREE_USERS_QUERY_KEY } from '@/shared/constants';
import { useParams, useScrollUpToReFetch } from '@/shared/hooks';
import { UnitRouteParams } from '@/shared/types';
import { UnitUserActions } from '../components';
import styles from '../styles.module.css';
import useGetFreeUsers from './useGetFreeUsers';
import useGetUnitUsers from './useGetUnitUsers';

enum ColumnTitle {
  User = 'КОРИСТУВАЧ',
  Role = 'РОЛЬ',
  AccessGroup = 'ГРУПА ДОСТУПУ',
}

const USER_DEACTIVATED = 'Блоковано';

const useUnitUsersTab = () => {
  const { token } = theme.useToken();
  const { unitId } = useParams<UnitRouteParams>();
  const { isFreeUsersRoute } = useUnitRouteInfo();

  const { unitUsersList, getUnitUserRef, isUnitUsersFetching } = useGetUnitUsers({
    unitId: unitId ?? '',
    options: { enabled: !!unitId && !isFreeUsersRoute },
  });
  const { freeUsersList, getFreeUsersRef, isFreeUsersFetching } = useGetFreeUsers({
    options: { enabled: isFreeUsersRoute },
  });
  const usersList = isFreeUsersRoute ? freeUsersList : unitUsersList;
  const isLoading = (isFreeUsersRoute && isFreeUsersFetching) || (!isFreeUsersRoute && isUnitUsersFetching);
  const getUserRef = isFreeUsersRoute ? getFreeUsersRef : getUnitUserRef;

  const { itemId: editUnitUserId, handleOpenModal, handleCloseModal } = useTableItemModalManager<UnitUser['id']>();

  const { handleScrollUpToReFetch: handleScrollUpToReFetchUnitUsers } = useScrollUpToReFetch([
    UNIT_USERS_QUERY_KEY,
    unitId,
  ]);

  const { handleScrollUpToReFetch: handleScrollUpToReFetchFreeUsers } = useScrollUpToReFetch([
    UNIT_FREE_USERS_QUERY_KEY,
  ]);

  const handleScrollUpToReFetch = async () => {
    if (isFreeUsersRoute) {
      await handleScrollUpToReFetchFreeUsers();
    } else {
      await handleScrollUpToReFetchUnitUsers();
    }
  };

  const columns: TableColumnsType<UnitUser> = [
    {
      title: ColumnTitle.User,
      width: '40%',
      ellipsis: true,
      className: styles.column,
      render: (_, { fullName, phone, isActive }, index) => (
        <Flex
          ref={getUserRef(index)}
          vertical
        >
          <Flex className={styles.nameContainer}>
            <Typography.Text
              strong
              type={!isActive ? 'secondary' : undefined}
              ellipsis={{ tooltip: fullName }}
            >
              {fullName ?? '--'}
            </Typography.Text>
            {!isActive && <Tag color={token.colorPrimary}>{USER_DEACTIVATED}</Tag>}
          </Flex>
          <Typography.Text
            type={!isActive ? 'secondary' : undefined}
            ellipsis={{ tooltip: phone }}
          >
            {phone}
          </Typography.Text>
        </Flex>
      ),
    },
    {
      title: ColumnTitle.Role,
      width: '30%',
      className: styles.column,
      render: (_, { isActive, role }) => {
        const roleLabel = ROLES_DESCRIPTION[role].label;
        return (
          <Typography.Text
            type={!isActive ? 'secondary' : undefined}
            ellipsis={{ tooltip: roleLabel }}
          >
            {roleLabel || '--'}
          </Typography.Text>
        );
      },
    },
    {
      title: ColumnTitle.AccessGroup,
      width: '30%',
      className: styles.column,
      render: (_, { groupName }) => (
        <Typography.Paragraph ellipsis={{ tooltip: groupName, rows: 2 }}>{groupName}</Typography.Paragraph>
      ),
    },
    {
      width: 64,
      render: (_, { id, isActive }) => (
        <UnitUserActions
          userId={id}
          isActive={isActive}
          onEditUserClick={handleOpenModal}
        />
      ),
    },
  ];

  return {
    editUnitUserId,
    usersList,
    columns,
    isLoading,
    handleScrollUpToReFetch,
    handleCloseModal,
  };
};

export default useUnitUsersTab;
