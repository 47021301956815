import { useEffect, useState } from 'react';
import { COPIES, NOTIFICATION_TYPES } from '@/shared/constants';
import { useNotificationContext } from '@/shared/context';

const ONLINE_KEY = 'ONLINE_MODE';
const OFFLINE_KEY = 'OFFLINE_MODE';

export const useConnectionNotification = () => {
  const [isOnline, setIsOnline] = useState<boolean>();
  const { api, openNotification } = useNotificationContext();

  const handleStatusChange = () => setIsOnline(window.navigator.onLine);

  useEffect(() => {
    if (typeof isOnline === 'undefined') return;

    if (isOnline) {
      api.destroy(OFFLINE_KEY);
      openNotification({
        key: ONLINE_KEY,
        type: NOTIFICATION_TYPES.SUCCESS,
        title: COPIES.ONLINE_STATUS,
      });
    } else {
      api.destroy(ONLINE_KEY);
      openNotification({
        key: OFFLINE_KEY,
        type: NOTIFICATION_TYPES.ERROR,
        title: COPIES.OFFLINE_STATUS,
        duration: 0,
      });
    }
  }, [isOnline, api, openNotification]);

  useEffect(() => {
    window.addEventListener('online', handleStatusChange);
    window.addEventListener('offline', handleStatusChange);

    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, []);
};
