import { LOCALES, LOCATION_TYPES } from '../constants';
import capitalizeWord from './capitalizeWord';

const getLocationNameWithDistrict = (name: string, district: string | null, type: 'DISTRICT' | string) => {
  if (type === LOCATION_TYPES.district) {
    return `${capitalizeWord(name)} район`;
  }
  if (name && district && type !== LOCATION_TYPES.district) {
    return `${capitalizeWord(name)}, ${capitalizeWord(district)} ${LOCALES['location.type.district']}`;
  }
  return `${capitalizeWord(name)}`;
};

export default getLocationNameWithDistrict;
