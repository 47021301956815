import { useEffect } from 'react';
import { useAppDispatch, useParams } from '@/shared/hooks';
import { setShouldRedirectToPageNotFound } from '@/shared/slices';
import { RadioNetworkRouteParams } from '@/shared/types';
import useGetRadioNetwork from './useGetRadioNetwork';

const useSelectedRadioNetwork = (shouldFetch: boolean = false) => {
  const { radioNetworkId } = useParams<RadioNetworkRouteParams>();
  const dispatch = useAppDispatch();

  const { radioNetwork, isLoading, isFetching, isSuccess, isError } = useGetRadioNetwork({
    id: radioNetworkId || '',
    enabled: shouldFetch && Boolean(radioNetworkId),
  });

  useEffect(() => {
    if (isError) dispatch(setShouldRedirectToPageNotFound(true));
  }, [isError, dispatch]);

  return {
    selectedRadioNetwork: radioNetwork,
    isSelectedRadioNetworkFetchedSuccessfully: isSuccess,
    isSelectedRadioNetworkLoading: !!radioNetworkId && isLoading,
    isSelectedRadioNetworkFetching: isFetching,
  };
};

export default useSelectedRadioNetwork;
