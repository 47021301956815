import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/shared/types';
import { TranscriptState } from './types';

const initialState: TranscriptState = {
  centerTo: null,
  centerToMetaData: null,
};

export const transcriptSlice = createSlice({
  name: 'transcript',
  initialState,
  reducers: {
    setCenterTo: (state: TranscriptState, action: PayloadAction<TranscriptState['centerTo']>) => {
      state.centerTo = action.payload;
    },
    setCenterToMetaData: (state: TranscriptState, action: PayloadAction<TranscriptState['centerToMetaData']>) => {
      state.centerToMetaData = action.payload;
    },
  },
});

export const { setCenterTo, setCenterToMetaData } = transcriptSlice.actions;

export const selectCenterTo = (state: RootState) => state.transcript.centerTo;
export const selectCenterToMetaData = (state: RootState) => state.transcript.centerToMetaData;

export default transcriptSlice.reducer;
