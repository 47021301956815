import { useEffect, Dispatch, SetStateAction } from 'react';
import { InfiniteData } from '@tanstack/react-query';
import { InfiniteQueryData } from '@/shared/api';
import { reactQueryHelperService } from '@/shared/services';

interface Params {
  data?: InfiniteData<InfiniteQueryData<unknown>>;
  setFoffset: Dispatch<SetStateAction<string | null>>;
}

const useUpdateFoffset = ({ data, setFoffset }: Params) => {
  useEffect(() => {
    if (data?.pages && data.pages.length > 0) {
      const lastPage = data.pages[data.pages.length - 1];
      if (reactQueryHelperService.isPaginatedWithFoffset(lastPage)) {
        setFoffset(lastPage.foffset ?? null);
      }
    }
  }, [data?.pages, setFoffset]);
};

export default useUpdateFoffset;
