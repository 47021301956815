import { Highlighter } from '@/shared/components';
import useRadioNetworkName from './useRadioNetworkName';

interface Props {
  radioNetworkName: string;
  searchTokens?: string[];
}

const RadioNetworkName = ({ radioNetworkName, searchTokens }: Props) => {
  const { formattedRadioNetworkName } = useRadioNetworkName(radioNetworkName);

  return (
    <Highlighter
      text={formattedRadioNetworkName}
      searchTokens={searchTokens}
    />
  );
};

export default RadioNetworkName;
