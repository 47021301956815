import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/shared/types';
import { SupportState } from './types';

const initialState: SupportState = {
  isServerAvailable: true,
  isCheckEnabled: true,
  alert: null,
  isAlertClosed: false,
};

const supportSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {
    setServerAvailability: (state: SupportState, action: PayloadAction<boolean>) => {
      state.isServerAvailable = action.payload;
    },
    setServerAvailabilityCheckEnabled: (state: SupportState, action: PayloadAction<boolean>) => {
      state.isCheckEnabled = action.payload;
    },
    setSupportAlert: (state: SupportState, action: PayloadAction<SupportState['alert']>) => {
      state.alert = action.payload;
    },
    setIsAlertClosed: (state: SupportState, action: PayloadAction<boolean>) => {
      state.isAlertClosed = action.payload;
    },
  },
});

export const { setServerAvailability, setServerAvailabilityCheckEnabled, setSupportAlert, setIsAlertClosed } =
  supportSlice.actions;

export const selectServerAvailability = (state: RootState) => state.support.isServerAvailable;

export const selectServerAvailabilityCheckEnabled = (state: RootState) => state.support.isCheckEnabled;

export const selectSupportAlert = (state: RootState) => state.support.alert;

export const selectIsAlertClosed = (state: RootState) => state.support.isAlertClosed;

export default supportSlice.reducer;
