import { FoxRecordsQueryParam } from '@/shared/constants';
import { useSearchParam } from '@/shared/hooks';

const useSignalType = () => {
  const [isDigitalSignalSelected, setIsDigitalSignalSelected] = useSearchParam(FoxRecordsQueryParam.DigitalSignal);
  const [isAnalogSignalSelected, setIsAnalogSignalSelected] = useSearchParam(FoxRecordsQueryParam.AnalogSignal);

  const toggleIsDigitalSignalSelected = () => {
    setIsDigitalSignalSelected(isDigitalSignalSelected ? '' : 'true');
  };

  const toggleIsAnalogSignalSelected = () => {
    setIsAnalogSignalSelected(isAnalogSignalSelected ? '' : 'true');
  };

  return {
    isDigitalSignalSelected: Boolean(isDigitalSignalSelected),
    isAnalogSignalSelected: Boolean(isAnalogSignalSelected),
    toggleIsDigitalSignalSelected,
    toggleIsAnalogSignalSelected,
  };
};

export default useSignalType;
