import { getItemValue, getOrderedItems } from '@/shared/components/mobile/mobileManagers/utils';
import { MobileListColumnType } from '@/shared/types';
import { DetailItem } from '../types';

export const getPreparedDetailItems = <T extends Record<string, unknown>>(
  entity: T,
  items: MobileListColumnType<T>[],
  itemsOrder?: (keyof T)[]
): DetailItem[] => {
  const orderedItems = itemsOrder?.length ? getOrderedItems(items, itemsOrder) : items;

  return orderedItems.map((item) => ({
    id: String(item.dataIndex),
    value: getItemValue(entity, item),
    placeholder: item.placeholder,
    title: item.title ?? '',
  }));
};
