import { z } from 'zod';
import { responseDataSchema } from '@/shared/validationSchemas';

export const foxSettingsSchema = z.object({
  id: z.string(),
  receiverName: z.string(),
  range: z.object({
    from: z.number(),
    to: z.number(),
  }),
  frequencyAmplification: z.number(),
  frequencyAutoAmplification: z.boolean(),
  bandwidthSteps: z.array(z.number()),
});

export const foxWaterfallAuthSchema = z.object({
  authToken: z.string(),
  scannerToken: z.string(),
  spectrumToken: z.string(),
});

export const activateFoxSchema = responseDataSchema(z.object({}));

export const reloadFoxSchema = responseDataSchema(z.object({}));

export const upgradeFoxSchema = responseDataSchema(z.object({}));

export const activateReceiverSchema = responseDataSchema(foxSettingsSchema.pick({ id: true, range: true }));

export const stopReceiverSchema = responseDataSchema(z.object({}));

export const getFoxSettingsSchema = responseDataSchema(foxSettingsSchema).or(responseDataSchema(z.object({})));

export const saveFoxSettingsSchema = responseDataSchema(z.object({}));

export const generateFoxWaterfallAuthSchema = responseDataSchema(foxWaterfallAuthSchema);
