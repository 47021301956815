import { selectSelectedFrequencies } from '@/entities/source/slices';
import { useAppSelector } from '@/shared/hooks';

const useListRow = () => {
  const selectedFrequencies = useAppSelector(selectSelectedFrequencies);

  return {
    selectedFrequencies,
  };
};

export default useListRow;
