export enum CoordinatesSystem {
  WGS84 = 'EPSG:4326',
  UCS2000_4_ZONE = 'EPSG:5562',
  UCS2000_5_ZONE = 'EPSG:5563',
  UCS2000_6_ZONE = 'EPSG:5564',
  UCS2000_7_ZONE = 'EPSG:5565',
}

export const COORDINATES_SYSTEM_MAP: { [key: number]: CoordinatesSystem } = {
  4: CoordinatesSystem.UCS2000_4_ZONE,
  5: CoordinatesSystem.UCS2000_5_ZONE,
  6: CoordinatesSystem.UCS2000_6_ZONE,
  7: CoordinatesSystem.UCS2000_7_ZONE,
};
