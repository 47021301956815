import { NavigationDrawer } from '@/shared/components';
import { COPIES } from '@/shared/constants';
import { useAppSelector } from '@/shared/hooks';
import { selectNavigationDrawer } from '@/shared/slices';
import { SubscriptionsSettings } from './components';

export const SubscriptionDrawer = () => {
  const navigationDrawer = useAppSelector(selectNavigationDrawer);

  return (
    <NavigationDrawer
      title={COPIES.SUBSCRIPTION}
      open={navigationDrawer === 'subscription'}
    >
      <SubscriptionsSettings />
    </NavigationDrawer>
  );
};
