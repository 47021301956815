export const AUTH_FIELDS = {
  PHONE: 'phone',
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirmPassword', // internal only
  CODE: 'code',
};

export const PHONE_LENGTH_REQUIRED = 13;

export const CODE_MASK = '000 000';
export const CODE_LENGTH_REQUIRED = 6;
export const CODE_TIMEOUT = 3 * 60 * 1000; // 3 minutes

export const PHONE_NUMBER_NOT_FOUND = 'Номер телефону не знайдено';
export const PHONE_NUMBER_HAS_ACTIVE_CODE = 'На цей номер вже було відправлено код';
export const PASSWORD_REQUIRED = "Пароль обов'язковий";
export const PASSWORD_CONFIRMATION_REQUIRED = "Підтвердження паролю обов'язкове";
export const CODE_REQUIRED = "Код обов'язковий";
export const PASSWORDS_DO_NOT_MATCH = 'Два паролі, які ви ввели, не збігаються';
export const PASSWORD_IS_WRONG = 'Введено хибний номер телефону або пароль';
export const ACCOUNT_IS_ALREADY_EXISTS = 'Кабінет з цим телефоном вже існує';
export const ACCOUNT_DONT_HAVE = 'Не маєте акаунту?';

export const PHONE_NUMBER_MASK = '+380 ХХ ХХХ ХХ ХХ';
export const PASSWORD = 'Пароль';
export const PASSWORD_NEW = 'Новий пароль';
export const PASSWORD_CONFIRMATION = 'Підтвердження паролю';
export const PASSWORD_FORGOT = 'Забули пароль?';
export const PASSWORD_CHANGE = 'Змінити пароль';
export const ACCOUNT_ENTRANCE = 'Вхід в кабінет';
export const BACK_TO_LOGIN = 'Назад до входу';

export const CODE_IN_SIGNAL = 'Код в сигналі';
export const SEND_AGAIN = 'Надіслати ще раз?';
export const TIME_IS_OVER = 'Час вийшов';
export const CODE_IS_WRONG = 'Введено хибний код';
export const USER_IS_INACTIVE = 'Ваша заявка на розгляді або доступ заблоковано. Зверніться до адміністратора.';

export const CODE_IN_APP = 'Код в google authenticator';
export const SCAN_QR_CODE_STEP_1 = 'запустіть застосунок';
export const SCAN_QR_CODE_STEP_2 = 'натисніть іконку "+"';
export const SCAN_QR_CODE_STEP_3 = 'виберіть "Сканувати QR-код"';
export const CAN_NOT_SCAN = 'Не можете відсканувати?';

export const AUTH_DATA_QUERY_KEY = 'AUTH_DATA';
