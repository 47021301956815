import { memo } from 'react';
import classNames from 'classnames';
import { RouteLink } from '@/shared/components';
import { useFoxFrequencyCountRecords } from './hooks';
import styles from './styles.module.css';
import { FoxFrequencyCountRecordsProps } from './types';

const FoxFrequencyCountRecords = memo(({ countRecords, className, frequency }: FoxFrequencyCountRecordsProps) => {
  const { isClickable, href, handleClick } = useFoxFrequencyCountRecords({ countRecords, frequency });

  return (
    <RouteLink
      className={classNames(
        {
          [styles.notClickable]: !isClickable,
        },
        className
      )}
      onClick={handleClick}
      href={href}
    >
      {countRecords}
    </RouteLink>
  );
});

FoxFrequencyCountRecords.displayName = 'FoxFrequencyCountRecords';

export default FoxFrequencyCountRecords;
