import { Modal } from 'antd';
import { useIsMobile } from '@/shared/hooks';
import styles from './styles.module.css';

type Props = {
  isOpen: boolean;
};

export const MobileOverlay = ({ isOpen }: Props) => {
  const isMobile = useIsMobile();
  return (
    isMobile &&
    isOpen && (
      <Modal
        open
        wrapClassName={styles.wrap}
        className={styles.modal}
        destroyOnClose
      />
    )
  );
};
