import { getFrequencyPosition } from '@/entities/source/@fox/components/ScanningTab/components/Spectrogram/utils';
import { DetectionProps as Params } from './types';
import { isDetectionHidden } from './utils';

const useDetection = ({
  detectionFrequency,
  isDigital,
  detectionsVisibilityRange,
  startFrequency,
  endFrequency,
  memoFrequencies,
  memoOnCreateFrequency,
  topPosition,
}: Params) => {
  const adjustedDetectionFrequency = detectionFrequency / 1000000;

  const isHidden = isDetectionHidden({
    detectionFrequency: adjustedDetectionFrequency,
    startFrequency,
    endFrequency,
    memoFrequencies,
    hiddenRange: detectionsVisibilityRange,
  });

  const detectionPosition = getFrequencyPosition({
    startFrequency,
    endFrequency,
    frequency: adjustedDetectionFrequency,
    top: `calc(${topPosition}% + 18px)`,
  });

  const handleDetectionClick = async () => {
    await memoOnCreateFrequency({ value: adjustedDetectionFrequency, isDigital });
  };

  return {
    isDetectionHidden: isHidden,
    detectionPosition,
    handleDetectionClick,
    isDigital,
    adjustedDetectionFrequency,
  };
};

export default useDetection;
