import Icon from '@ant-design/icons';
import classNames from 'classnames';
import { AnalogFrequency, DigitalFrequency } from '@/shared/assets';
import styles from './styles.module.css';

interface Props {
  isDigital: boolean;
  className?: string;
}

const SignalTypeIcon = ({ isDigital, className }: Props) => {
  const IconComponent = isDigital ? DigitalFrequency : AnalogFrequency;

  return (
    <Icon
      component={IconComponent}
      className={classNames(
        styles.icon,
        {
          [styles.digital]: isDigital,
          [styles.analog]: !isDigital,
        },
        className
      )}
    />
  );
};

export default SignalTypeIcon;
