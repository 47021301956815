import { z } from 'zod';
import { FoxKeyEncryption } from '@/entities/source/@fox/constants';
import { paginatedWithFoffsetSchema, responseDataSchema } from '@/shared/validationSchemas';

export const foxKeySchema = z.object({
  id: z.string(),
  groupId: z.number().nullish(),
  keyId: z.number().nullish(),
  sourceFrequency: z.string().nullish(),
  targetFrequency: z
    .object({
      id: z.string(),
      value: z.string(),
    })
    .nullish(),
  lastActiveAt: z.number().nullish(),
  encryption: z.nativeEnum(FoxKeyEncryption),
  body: z.string(),
});

export const getFoxKeysSchema = paginatedWithFoffsetSchema(foxKeySchema);

export const createFoxKeySchema = responseDataSchema(foxKeySchema);
