import { foxWaterfallAuthSchema } from '@/entities/source/@fox/api';

const isFoxWaterfallAuthValid = (foxWaterfallAuth: unknown) => {
  try {
    foxWaterfallAuthSchema.parse(foxWaterfallAuth);
    return true;
  } catch {
    return false;
  }
};

export default isFoxWaterfallAuthValid;
