import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postSubscriptions } from '@/entities/subscription';
import { SUBSCRIPTION_MUTATION_KEY, SUBSCRIPTIONS_QUERY_KEY } from '@/shared/constants';

const useCreateSubscription = () => {
  const queryClient = useQueryClient();
  const { mutate: createSubscription, isLoading: isSubscriptionCreating } = useMutation({
    mutationKey: [SUBSCRIPTION_MUTATION_KEY],
    mutationFn: postSubscriptions,
    onSuccess: () => queryClient.invalidateQueries([SUBSCRIPTIONS_QUERY_KEY]),
  });
  return {
    createSubscription,
    isSubscriptionCreating,
  };
};

export default useCreateSubscription;
