import { useState } from 'react';

const useFiltersDrawerManager = () => {
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);

  const handleOpenFilters = () => {
    setIsFiltersOpen(true);
  };

  const handleCloseFilters = () => {
    setIsFiltersOpen(false);
  };

  return {
    isFiltersOpen,
    handleOpenFilters,
    handleCloseFilters,
  };
};

export default useFiltersDrawerManager;
