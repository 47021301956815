import { EditOutlined } from '@ant-design/icons';
import { UnitSourceActionsProps } from '../types';

enum ItemKey {
  Edit = 'edit',
}

enum ItemLabel {
  Edit = 'Редагувати джерело',
}

interface Params extends UnitSourceActionsProps {}

const useUnitSourceActions = ({ sourceId, onEditSourceClick }: Params) => {
  const handleOpenEditSourceModal = () => onEditSourceClick(sourceId);

  const actionItems = [
    {
      key: ItemKey.Edit,
      label: ItemLabel.Edit,
      icon: <EditOutlined />,
      onClick: handleOpenEditSourceModal,
    },
  ];

  return {
    actionItems,
  };
};

export default useUnitSourceActions;
