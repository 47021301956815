import { ActionsDropdown } from '@/shared/components';
import { useUnitSourceActions } from './hooks';
import { UnitSourceActionsProps } from './types';

const UnitSourceActions = (props: UnitSourceActionsProps) => {
  const { actionItems } = useUnitSourceActions(props);

  return <ActionsDropdown items={actionItems} />;
};

export default UnitSourceActions;
