import { MobileView } from '@/shared/constants';
import { useNavigateWithMobileView } from '@/shared/hooks';
import { routingService } from '@/shared/services';
import { QueryParamsOptionsWithAllParams, UnitRouteParams } from '@/shared/types';

interface Params {
  routeParams: UnitRouteParams;
  options?: {
    withMobileView?: boolean;
  } & QueryParamsOptionsWithAllParams;
}

const useNavigateToUnit = () => {
  const navigate = useNavigateWithMobileView();

  return ({ routeParams, options = {} }: Params) => {
    const { withMobileView = true, withAllQueryParams = true, ...navigateParams } = options;
    return navigate({
      pathname: routingService.getUnitUrl(routeParams),
      options: { withAllQueryParams, ...navigateParams },
      mobileView: withMobileView ? MobileView.SecondaryScreen : undefined,
    });
  };
};

export default useNavigateToUnit;
