import { useQuery } from '@tanstack/react-query';
import { TRANSCRIPTS_FILTER_QUERY_KEY } from '@/shared/constants';
import { TranscriptionFilters } from '@/shared/types';
import { getTranscriptsFilter } from '../api';

const DEFAULT_FILTERS: TranscriptionFilters = {
  locations: [],
  factions: [],
  categories: [],
  groups: [],
};

type Options = {
  enabled?: boolean;
};

export const useTranscriptsFilter = (options: Options = {}) => {
  const { enabled } = options;

  const { data, isLoading } = useQuery({
    queryKey: [TRANSCRIPTS_FILTER_QUERY_KEY],
    queryFn: getTranscriptsFilter,
    cacheTime: Infinity,
    enabled,
  });

  return {
    transcriptFilters: data?.data || DEFAULT_FILTERS,
    isTranscriptFiltersLoading: isLoading,
  };
};
