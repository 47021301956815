import { createContext, ReactElement, useContext, useState, useMemo } from 'react';
import { AccessGroup } from '@/shared/types';

interface AccessGroupModal {
  isAccessGroupsModalOpen: boolean;
  mode: 'create' | 'edit';
  initialState: AccessGroup | null;
  initialParentId: string | null;
  openCreateModal: (parentId?: string) => void;
  openEditModal: (accessGroup: AccessGroup) => void;
  closeModal: () => void;
}

const AccessGroupModalContext = createContext<AccessGroupModal | null>(null);

export const useAccessGroupModalContext = () => {
  const context = useContext(AccessGroupModalContext);
  if (!context) {
    throw new Error('useAccessGroupModalContext should be used inside the AccessGroupModalContextProvider');
  }
  return context;
};

export const AccessGroupModalContextProvider = ({ children }: { children: ReactElement }) => {
  const [isAccessGroupsModalOpen, setIsAccessGroupsModalOpen] = useState<boolean>(false);
  const [mode, setMode] = useState<'create' | 'edit'>('create');
  const [initialState, setInitialState] = useState<AccessGroup | null>(null);
  const [initialParentId, setInitialParentId] = useState<string | null>(null);

  const openCreateModal = (parentId?: string) => {
    setIsAccessGroupsModalOpen(true);
    setMode('create');
    if (parentId) {
      setInitialParentId(parentId);
    }
  };
  const openEditModal = (accessGroup: AccessGroup) => {
    setIsAccessGroupsModalOpen(true);
    setMode('edit');
    setInitialState(accessGroup);
  };

  const closeModal = () => {
    setIsAccessGroupsModalOpen(false);
    setMode('create');
    setInitialState(null);
    setInitialParentId(null);
  };

  const value = useMemo(
    () => ({
      isAccessGroupsModalOpen,
      mode,
      initialState,
      initialParentId,
      openCreateModal,
      openEditModal,
      closeModal,
    }),
    [isAccessGroupsModalOpen, mode, initialState, initialParentId]
  );
  return <AccessGroupModalContext.Provider value={value}>{children}</AccessGroupModalContext.Provider>;
};
