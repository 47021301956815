import { Form } from 'antd';
import { MODAL_COPIES } from '@/entities/user/constants';
import { useGetUserRoles } from '@/entities/user/hooks';
import { Select } from '@/shared/components';
import { RoleName, ROLES_DESCRIPTION } from '@/shared/constants';
import { useAppSelector } from '@/shared/hooks';
import { selectCurrentUserRole } from '@/shared/slices';

const ALLOWED_FOR_SELECTION = [
  RoleName.ADMIN,
  RoleName.SUPERVISOR,
  RoleName.OPERATOR,
  RoleName.ANALYST,
  RoleName.TECHNOLOGIST,
];

const FORBIT_FOR_SELECTION: Partial<Record<RoleName, RoleName[]>> = {
  [RoleName.SUPERVISOR]: [RoleName.ADMIN, RoleName.ANALYST, RoleName.TECHNOLOGIST],
};

const PLACEHOLDER = `Оберіть ${MODAL_COPIES.ROLE}`;

const isRoleOptionSelectable = (role: RoleName, roleOption: RoleName) => {
  if (!ALLOWED_FOR_SELECTION.includes(roleOption) || FORBIT_FOR_SELECTION[role]?.includes(roleOption)) {
    return false;
  }

  return true;
};

const UserRoleSelect = () => {
  const { userRoles, isUserRolesLoading } = useGetUserRoles();
  const role = useAppSelector(selectCurrentUserRole);

  const options = userRoles.length
    ? userRoles.map(({ id, name }) => ({
        label: ROLES_DESCRIPTION[name].label,
        value: id,
        disabled: !isRoleOptionSelectable(role, name),
      }))
    : [];

  return (
    <Form.Item
      label={MODAL_COPIES.ROLE}
      name="role"
      rules={[{ required: true, message: `Оберіть ${MODAL_COPIES.ROLE}` }]}
    >
      <Select
        placeholder={PLACEHOLDER}
        options={options}
        optionFilterProp="label"
        loading={isUserRolesLoading}
      />
    </Form.Item>
  );
};

export default UserRoleSelect;
