import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/shared/types';
import { RouterState } from './types';

const initialState: RouterState = {
  shouldRedirectToPageAccessDenied: false,
  shouldRedirectToPageNotFound: false,
  isDataPartiallyVisible: false,
  redirectAfterLogInPath: null,
};

const routerSlice = createSlice({
  name: 'router',
  initialState,
  reducers: {
    setShouldRedirectToPageAccessDenied: (state: RouterState, action: PayloadAction<boolean>) => {
      state.shouldRedirectToPageAccessDenied = action.payload;
    },
    setShouldRedirectToPageNotFound: (state: RouterState, action: PayloadAction<boolean>) => {
      state.shouldRedirectToPageNotFound = action.payload;
    },
    setIsDataPartiallyVisible: (state: RouterState, action: PayloadAction<boolean>) => {
      state.isDataPartiallyVisible = action.payload;
    },
    setRedirectAfterLogInPath: (state: RouterState, action: PayloadAction<RouterState['redirectAfterLogInPath']>) => {
      state.redirectAfterLogInPath = action.payload;
    },
  },
});

export const {
  setShouldRedirectToPageAccessDenied,
  setShouldRedirectToPageNotFound,
  setIsDataPartiallyVisible,
  setRedirectAfterLogInPath,
} = routerSlice.actions;

export const selectShouldRedirectToPageAccessDenied = (state: RootState) =>
  state.router.shouldRedirectToPageAccessDenied;

export const selectShouldRedirectToPageNotFound = (state: RootState) => state.router.shouldRedirectToPageNotFound;

export const selectIsDataPartiallyVisible = (state: RootState) => state.router.isDataPartiallyVisible;

export const selectRedirectAfterLogInPath = (state: RootState) => state.router.redirectAfterLogInPath;
export default routerSlice.reducer;
