import { useState, useEffect, useCallback } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { DEBOUNCE_DEFAULT } from '@/shared/constants';

interface Params {
  wavesurfer: WaveSurfer | null;
  isPlaying: boolean;
  pause: VoidFunction;
  play: VoidFunction;
}

const useTimeTooltipPosition = ({ wavesurfer, isPlaying, pause, play }: Params) => {
  const [timeTooltipPosition, setTimeTooltipPosition] = useState<number | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [playOnDragEnd, setPlayOnDragEnd] = useState(false);

  useEffect(() => {
    if (!wavesurfer) return;

    const unsubscribe = wavesurfer.on('drag', (relativeX) => {
      setTimeTooltipPosition(relativeX);
      if (isPlaying) {
        setPlayOnDragEnd(true);
        pause();
      }
      if (!isDragging) {
        setIsDragging(true);
      }
    });

    return () => unsubscribe();
  }, [wavesurfer, isPlaying]);

  const autoplayOnDragEnd = useCallback(() => {
    if (isDragging && wavesurfer && playOnDragEnd) {
      setIsDragging(false);
      setPlayOnDragEnd(false);

      setTimeout(() => {
        play();
        setTimeTooltipPosition(null);
      }, DEBOUNCE_DEFAULT);
    }
  }, [isDragging, playOnDragEnd]);

  useEffect(() => {
    document.addEventListener('pointerup', autoplayOnDragEnd);

    return () => {
      document.removeEventListener('pointerup', autoplayOnDragEnd);
    };
  }, [autoplayOnDragEnd]);

  return timeTooltipPosition;
};

export default useTimeTooltipPosition;
