export const RATE_OPTIONS = [2, 1.75, 1.5, 1.25, 1, 0.75, 0.5].map((n) => ({ key: n, label: `${n}x` }));

export const SILENCE_ID = 'silence';

export enum UpdateRate {
  Increase = 1,
  Decrease = -1,
}

export const SLIDER_CSS_SELECTOR = '[data-audio-slider]';

export const SILENCE_LABEL = 'Пропуск тиші';
export const SILENCE_TITLE = 'Активувати/вимкнути пропуск тиші (Shift + S)';

export const NOISE_LABEL = 'Подавлення шуму';
export const NOISE_TITLE = 'Активувати/вимкнути подавлення шумів (Shift + N)';
