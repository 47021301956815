import { memo } from 'react';
import styles from './style.module.css';
import { DecibelAxisProps as Props } from './types';
import useDecibelAxis from './useDecibelAxis';

const DecibelAxis = memo(({ minDecibel, maxDecibel, bottom }: Props) => {
  const { wrapperStyle, decibels } = useDecibelAxis({
    minDecibel,
    maxDecibel,
    bottom,
  });

  return (
    <div
      className={styles.wrapper}
      style={wrapperStyle}
    >
      {decibels.map((num) => (
        <span key={num}>{num}</span>
      ))}
    </div>
  );
});

DecibelAxis.displayName = 'DecibelAxis';

export default DecibelAxis;
