import { ApartmentOutlined } from '@ant-design/icons';
import type { LabelInValueType } from 'rc-select/lib/Select';
import { getFactionLabel, FactionFormMultiSelect, ALL_FACTIONS } from '@/entities/faction';
import { SubscriptionType, SubscriptionItemId } from '@/entities/subscription';
import { Faction } from '@/shared/types';
import { useGetSubscriptions } from '@/widgets/SubscriptionDrawer/hooks';
import { useSubscriptionState } from '../hooks';

type Props = {
  factions: Faction[];
  isLoading?: boolean;
};

interface ExtendedOptionType extends LabelInValueType {
  factionName: string;
  shortFactionName: string;
}

const FactionField = ({ factions, isLoading }: Props) => {
  const { subscriptions } = useGetSubscriptions();
  const { handleSubscriptionChange, getSubscriptionValues } = useSubscriptionState();

  const factionsOptions: ExtendedOptionType[] = factions.map((faction) => ({
    label: (
      <>
        <ApartmentOutlined style={{ transform: 'rotate(-90deg)' }} />
        {getFactionLabel(faction)}
      </>
    ),
    value: faction.id,
    factionName: `${faction.full_name},${faction.full_short_name}`,
    shortFactionName: faction.full_short_name,
  }));

  const values = getSubscriptionValues(SubscriptionType.Faction) as Faction['id'][];
  const handleChange = (value: SubscriptionItemId[]) => handleSubscriptionChange(value, SubscriptionType.Faction);

  return (
    <FactionFormMultiSelect
      options={factionsOptions}
      isLoading={isLoading}
      value={values}
      onChange={handleChange}
      disabled={!subscriptions?.models.length}
      placeholder={ALL_FACTIONS}
    />
  );
};

export default FactionField;
