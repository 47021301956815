import { UIEventHandler, useState, useRef, useEffect, UIEvent, ForwardedRef } from 'react';
import { DISABLED_TIME_ON_SCROLL_TOP } from '@/shared/constants';
import { useIsTabletOrMobile } from '@/shared/hooks';

enum RefreshTriggerStatus {
  Triggered = 'triggered',
  NotTriggered = 'not-triggered',
}

const OVER_SCROLL_TRIGGER = -1750;

interface Params {
  onRefresh: () => Promise<unknown>;
  isScrollUpToRefreshEnabled: boolean;
  onScroll?: (e: UIEvent<HTMLDivElement>) => void;
  isRefreshTriggerDisabled?: boolean; // isRefreshTriggerDisabled is needed only for components where used react-virtualized
  forwardRef: ForwardedRef<HTMLDivElement | null>;
}

const useScrollUpToRefresh = ({
  onScroll,
  isScrollUpToRefreshEnabled,
  onRefresh,
  isRefreshTriggerDisabled,
  forwardRef,
}: Params) => {
  const isTabletOrMobile = useIsTabletOrMobile();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [isOnTop, setIsOnTop] = useState<boolean>(true);
  const [isWheelDisabled, setIsWheelDisabled] = useState<boolean>(false);
  const [overScrollUp, setOverScrollUp] = useState<number>(0);
  const [refreshTriggered, setRefreshTriggered] = useState<RefreshTriggerStatus>(RefreshTriggerStatus.NotTriggered);
  const isLoadingComponentVisible = refreshTriggered === RefreshTriggerStatus.Triggered;
  const isChildrenWrapperMovedDown = isLoadingComponentVisible;

  const setRefs = (instance: HTMLDivElement | null) => {
    wrapperRef.current = instance;
    if (forwardRef !== null) {
      if (typeof forwardRef === 'function') {
        forwardRef(instance);
      } else if ('current' in forwardRef) {
        // have no idea how to get rid of next line, if it's possible please fix it
        // eslint-disable-next-line no-param-reassign
        forwardRef.current = instance;
      }
    }
  };

  const debouceWheelDisabled = () => {
    setIsWheelDisabled(true);
    setTimeout(() => {
      setIsWheelDisabled(false);
    }, DISABLED_TIME_ON_SCROLL_TOP);
  };

  const handleScroll: UIEventHandler<HTMLDivElement> = (e) => {
    if (e.currentTarget.scrollTop === 0 && !isOnTop) {
      debouceWheelDisabled();
      setIsOnTop(true);
    } else if (e.currentTarget.scrollTop !== 0 && isOnTop) {
      setIsOnTop(false);
    }
    if (onScroll) {
      onScroll(e);
    }
  };

  const handleWheelOrTouchMove = (e: MouseEvent | TouchEvent, deltaY: number) => {
    if (!isOnTop || deltaY >= 0 || !isScrollUpToRefreshEnabled) return;
    if (isWheelDisabled) {
      e.preventDefault();
      return;
    }
    setOverScrollUp((prevValue) => prevValue + deltaY);
  };

  const handleWheel = (e: WheelEvent) => {
    handleWheelOrTouchMove(e, e.deltaY);
  };

  let startY: number;
  const handleTouchStart = (e: TouchEvent) => {
    startY = e.touches[0].clientY;
  };

  const handleTouchMove = (e: TouchEvent) => {
    const deltaY = startY - e.touches[0].clientY;
    handleWheelOrTouchMove(e, deltaY);
  };

  const resetScrollUpState = () => {
    setOverScrollUp(0);
    setRefreshTriggered(RefreshTriggerStatus.NotTriggered);
  };

  useEffect(() => {
    if (isRefreshTriggerDisabled) {
      resetScrollUpState();
      return;
    }

    if (refreshTriggered === RefreshTriggerStatus.Triggered) return;

    const resetScrollUpStateTimeoutID = setTimeout(resetScrollUpState, 1000);

    if (overScrollUp < OVER_SCROLL_TRIGGER && refreshTriggered === RefreshTriggerStatus.NotTriggered) {
      clearTimeout(resetScrollUpStateTimeoutID);
      setRefreshTriggered(RefreshTriggerStatus.Triggered);
    }

    return () => {
      clearTimeout(resetScrollUpStateTimeoutID);
    };
  }, [overScrollUp, refreshTriggered]);

  useEffect(() => {
    if (isRefreshTriggerDisabled) return;

    if (refreshTriggered === RefreshTriggerStatus.Triggered) {
      (async () => {
        const startTime = performance.now();
        try {
          setIsWheelDisabled(true);
          await onRefresh();
        } finally {
          const endTime = performance.now();
          // If the request duration is less than 1000 ms, we allocate time for animation.
          // In all other cases, we wait for the request to be resolved.
          // For instance, if the request takes 600 ms, we add an extra 400 ms delay.
          // If the request takes 5 seconds, we set a 0 ms delay to setTimeout.
          const delayTime = Math.max(1000 - (endTime - startTime), 0);
          setTimeout(() => {
            setIsWheelDisabled(false);
            resetScrollUpState();
          }, delayTime);
        }
      })();
    }
  }, [refreshTriggered]);

  useEffect(() => {
    const wrapperElement = wrapperRef.current;
    if (wrapperElement && !isRefreshTriggerDisabled) {
      wrapperElement.addEventListener('wheel', handleWheel, { passive: false });

      if (isTabletOrMobile) {
        wrapperElement.addEventListener('touchstart', handleTouchStart);
        wrapperElement.addEventListener('touchmove', handleTouchMove);
      }

      return () => {
        wrapperElement.removeEventListener('wheel', handleWheel);
        if (isTabletOrMobile) {
          wrapperElement.removeEventListener('touchstart', handleTouchStart);
          wrapperElement.removeEventListener('touchmove', handleTouchMove);
        }
      };
    }
  }, [isOnTop, isScrollUpToRefreshEnabled, isWheelDisabled, isRefreshTriggerDisabled]);

  return {
    handleScroll,
    isLoadingComponentVisible,
    isChildrenWrapperMovedDown,
    setRefs,
  };
};

export default useScrollUpToRefresh;
