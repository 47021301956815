export const ELEVATION_METERS = [
  { label: '360+', color: '#3E2221' },
  { label: '340', color: '#4D2624' },
  { label: '320', color: '#783430' },
  { label: '300', color: '#B24E47' },
  { label: '280', color: '#A2553D' },
  { label: '260', color: '#794B32' },
  { label: '240', color: '#975E3F' },
  { label: '220', color: '#B48151' },
  { label: '200', color: '#7C5E1D' },
  { label: '180', color: '#A4791E' },
  { label: '160', color: '#BE953F' },
  { label: '140', color: '#CFA958' },
  { label: '120', color: '#9AA456' },
  { label: '100', color: '#7D8736' },
  { label: '80', color: '#5C7C46' },
  { label: '60', color: '#6E9355' },
  { label: '40', color: '#94C176' },
  { label: '20', color: '#BAD6A3' },
  { label: '0', color: '#74ACBD' },
];
