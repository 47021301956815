import { FoxFrequency } from '@/entities/source/@fox/types';
import { ResponseData } from '@/shared/api';
import { FoxScanningQueryParam } from '@/shared/constants';
import { useSearchParam } from '@/shared/hooks';

const useSelectSearchedFrequencies = () => {
  const [search] = useSearchParam(FoxScanningQueryParam.Search);

  return (data: ResponseData<FoxFrequency[]>): ResponseData<FoxFrequency[]> => {
    return {
      data: data.data.filter((frequency) => frequency.value.includes(search)),
    };
  };
};

export default useSelectSearchedFrequencies;
