import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';
import loadable from '@loadable/component';
import { ErrorBoundary, wrapCreateBrowserRouter } from '@sentry/react';
import { LoginFormData, SignupFormData, ResetPasswordFormData, ACCOUNT_ENTRANCE } from '@/entities/auth';
import { ModalMap } from '@/entities/map';
import { NetworkFiltersStorageWrapper } from '@/entities/network';
import { UserWatermark, UserDataGuard, UserProfileDrawer } from '@/entities/user';
import { ErrorCrash, LayoutApp, LayoutAuth } from '@/shared/components';
import { paths } from '@/shared/constants';
import { sentryService } from '@/shared/services';
import { SubscriptionDrawer } from '@/widgets';
import {
  AuthRouteGuard,
  TitleProvider,
  RouteNotFound,
  AccessRouteGuard,
  ApplicationFreshnessGuard,
} from './components';

const OverviewDashboard = loadable(() => import('@/pages/OverviewDashboard'));
const AllInterceptions = loadable(() => import('@/pages/AllInterceptions'));
const RadioNetworks = loadable(() => import('@/pages/RadioNetworks'));
const FactionManagement = loadable(() => import('@/pages/FactionManagement'));
const CategoryManagement = loadable(() => import('@/pages/CategoryManagement'));
const AccessGroupManagement = loadable(() => import('@/pages/AccessGroupManagement'));
const Units = loadable(() => import('@/pages/Units'));
const Login = loadable(() => import('@/pages/Login'));
const ConfirmSignal = loadable(() => import('@/pages/ConfirmSignal'));
const AuthenticatorSetup = loadable(() => import('@/pages/AuthenticatorSetup'));
const ConfirmGoogleAuth = loadable(() => import('@/pages/ConfirmGoogleAuth'));
const Signup = loadable(() => import('@/pages/Signup'));
const ResetPassword = loadable(() => import('@/pages/ResetPassword'));
const ChangePassword = loadable(() => import('@/pages/ChangePassword'));
const Sources = loadable(() => import('@/pages/Sources'));

const PAGE_TITLES = {
  LOGIN: 'Логін',
  SIGNUP: 'Реєстрація',
  RECOVERY_PASSWORD: 'Відновлення паролю',
  OVERVIEW: 'Огляд',
  ALL_INTERCEPTIONS: 'Всі перехоплення',
  RADIO_NETWORKS: 'Мережі / частоти',
  FACTIONS: 'Угруповання',
  CATEGORIES: 'Категорії',
  ACCESS_GROUPS: 'Доступи',
  UNITS: 'Підрозділи',
  SOURCES: 'Джерела',
};

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    element: (
      /**
       * We should repeat ErrorBoundary one more time here for the root route
       * because errors from route content don't bubble to parent ErrorBoundary
       * @see https://stackoverflow.com/questions/74019392/using-react-error-boundary-with-react-router
       */
      <ErrorBoundary
        fallback={ErrorCrash}
        beforeCapture={sentryService.beforeCapture('router level')}
      >
        <ApplicationFreshnessGuard>
          <Outlet />
        </ApplicationFreshnessGuard>
      </ErrorBoundary>
    ),
    children: [
      {
        path: paths.ROOT_PATH,
        element: (
          <Navigate
            to={paths.OVERVIEW_PATH}
            replace
          />
        ),
      },
      {
        element: (
          <AuthRouteGuard otherwise={paths.LOGIN_PATH}>
            <UserDataGuard>
              <NetworkFiltersStorageWrapper>
                <AccessRouteGuard>
                  <LayoutApp>
                    <Outlet />
                    <SubscriptionDrawer />
                    <UserProfileDrawer />
                    <UserWatermark />
                  </LayoutApp>
                  <ModalMap renderWatermark={(props) => <UserWatermark {...props} />} />
                </AccessRouteGuard>
              </NetworkFiltersStorageWrapper>
            </UserDataGuard>
          </AuthRouteGuard>
        ),
        children: [
          {
            path: paths.OVERVIEW_PATH,
            element: (
              <TitleProvider title={PAGE_TITLES.OVERVIEW}>
                <OverviewDashboard />
              </TitleProvider>
            ),
          },
          {
            path: paths.ALL_INTERCEPTIONS_PATH,
            element: (
              <TitleProvider title={PAGE_TITLES.ALL_INTERCEPTIONS}>
                <AllInterceptions />
              </TitleProvider>
            ),
          },
          {
            path: paths.RADIO_NETWORKS_PATH,
            element: (
              <TitleProvider title={PAGE_TITLES.RADIO_NETWORKS}>
                <RadioNetworks />
              </TitleProvider>
            ),
          },
          {
            path: paths.SOURCES_PATH,
            element: (
              <TitleProvider title={PAGE_TITLES.SOURCES}>
                <Sources />
              </TitleProvider>
            ),
          },
          {
            path: paths.FACTIONS_PATH,
            element: (
              <TitleProvider title={PAGE_TITLES.FACTIONS}>
                <FactionManagement />
              </TitleProvider>
            ),
          },
          {
            path: paths.CATEGORIES_PATH,
            element: (
              <TitleProvider title={PAGE_TITLES.CATEGORIES}>
                <CategoryManagement />
              </TitleProvider>
            ),
          },
          {
            path: paths.ACCESS_GROUPS_PATH,
            element: (
              <TitleProvider title={PAGE_TITLES.ACCESS_GROUPS}>
                <AccessGroupManagement />
              </TitleProvider>
            ),
          },
          {
            path: paths.UNITS_PATH,
            element: (
              <TitleProvider title={PAGE_TITLES.UNITS}>
                <Units />
              </TitleProvider>
            ),
          },
        ],
      },
      {
        element: (
          <AuthRouteGuard
            isAuthRequired={false}
            otherwise={paths.OVERVIEW_PATH}
          >
            <Outlet />
          </AuthRouteGuard>
        ),
        children: [
          {
            path: paths.LOGIN_PATH,
            element: (
              <TitleProvider title={PAGE_TITLES.LOGIN}>
                <LayoutAuth<LoginFormData> title={ACCOUNT_ENTRANCE} />
              </TitleProvider>
            ),
            children: [
              ...['', paths.USER_INACTIVE_PATH].map((path) => ({
                path,
                element: <Login />,
              })),
              {
                path: paths.PHONE_CONFIRMATION_PATH,
                element: <ConfirmSignal />,
              },
              {
                path: paths.AUTHENTICATOR_SETUP_PATH,
                element: <AuthenticatorSetup />,
              },
              {
                path: paths.GOOGLE_AUTH_CONFIRMATION_PATH,
                element: <ConfirmGoogleAuth />,
              },
            ],
          },
          {
            path: paths.SIGNUP_PATH,
            element: (
              <TitleProvider title={PAGE_TITLES.SIGNUP}>
                <LayoutAuth<SignupFormData> title={PAGE_TITLES.SIGNUP} />
              </TitleProvider>
            ),
            children: [
              {
                path: '',
                element: <Signup />,
              },
              {
                path: paths.PHONE_CONFIRMATION_PATH,
                element: <ConfirmSignal />,
              },
              {
                path: paths.AUTHENTICATOR_SETUP_PATH,
                element: <AuthenticatorSetup />,
              },
              {
                path: paths.GOOGLE_AUTH_CONFIRMATION_PATH,
                element: <ConfirmGoogleAuth />,
              },
            ],
          },
          {
            path: paths.RECOVERY_PASSWORD_PATH,
            element: (
              <TitleProvider title={PAGE_TITLES.RECOVERY_PASSWORD}>
                <LayoutAuth<ResetPasswordFormData> title={PAGE_TITLES.RECOVERY_PASSWORD} />
              </TitleProvider>
            ),
            children: [
              {
                path: '',
                element: <ResetPassword />,
              },
              {
                path: paths.PHONE_CONFIRMATION_PATH,
                element: <ConfirmSignal />,
              },
              {
                path: paths.CHANGE_PASSWORD_PATH,
                element: <ChangePassword />,
              },
            ],
          },
        ],
      },
      {
        path: '*',
        element: (
          <RouteNotFound
            authorisedPath={paths.OVERVIEW_PATH}
            notAuthorisedPath={paths.LOGIN_PATH}
          />
        ),
      },
    ],
  },
]);
