import { z } from 'zod';
import {
  baseFactionSchema,
  factionSchema,
  factionDetailsSchema,
  paginatedSchema,
  responseDataSchema,
} from '@/shared/validationSchemas';

export const factionParamSchema = z.object({
  id: z.number(),
  name: z.string(),
  order: z.number(),
});

export const factionLevelSchema = factionParamSchema;

export const factionTypeSchema = factionParamSchema.merge(
  z.object({
    children: z.array(factionParamSchema).optional(),
  })
);

export const getFactionsSchema = paginatedSchema(factionSchema);

export const getFactionByIdSchema = responseDataSchema(factionDetailsSchema);

export const createFactionResponseSchema = baseFactionSchema.pick({ id: true });
export const createFactionSchema = responseDataSchema(createFactionResponseSchema);

export const updateFactionResponseSchema = baseFactionSchema.pick({ id: true });
export const updateFactionSchema = responseDataSchema(updateFactionResponseSchema);

export const getFactionLevelsSchema = responseDataSchema(z.array(factionLevelSchema));

export const getFactionTypesSchema = responseDataSchema(z.array(factionTypeSchema));
