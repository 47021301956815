import { useCallback } from 'react';
import { NavigateToRecordParams } from '@/entities/source/@fox/components/RecordsTab/types';
import { useParams } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';
import useGetSiblingRecordId from './useGetSiblingRecordId';
import useNavigateToRecord from './useNavigateToRecord';

const useNavigateToSiblingRecord = () => {
  const { tabEntityId, sourceId } = useParams<SourceRouteParams>();
  const { memoGetPreviousRecordId, memoGetNextRecordId } = useGetSiblingRecordId();
  const navigateToRecord = useNavigateToRecord();

  const navigateTo = (id: string | null) => {
    if (id) {
      navigateToRecord({
        routeParams: {
          sourceId,
          tabEntityId: id,
        },
        options: {
          withAllQueryParams: true,
        },
      });
    }
  };

  const memoNavigateToNextRecord = useCallback(
    ({ isSkipNotDecodedFileEnabled, isSkipAudioWithoutVoiceEnabled }: NavigateToRecordParams) => {
      if (!tabEntityId) return;

      const nextRecordId = memoGetNextRecordId({
        currentRecordId: tabEntityId,
        isSkipAudioWithoutVoiceEnabled,
        isSkipNotDecodedFileEnabled,
      });
      navigateTo(nextRecordId);
    },
    [tabEntityId, memoGetNextRecordId]
  );

  const memoNavigateToPreviousRecord = useCallback(
    ({ isSkipNotDecodedFileEnabled, isSkipAudioWithoutVoiceEnabled }: NavigateToRecordParams) => {
      if (!tabEntityId) return;

      const previousRecordId = memoGetPreviousRecordId({
        currentRecordId: tabEntityId,
        isSkipAudioWithoutVoiceEnabled,
        isSkipNotDecodedFileEnabled,
      });
      navigateTo(previousRecordId);
    },
    [tabEntityId, memoGetPreviousRecordId]
  );

  return { memoNavigateToNextRecord, memoNavigateToPreviousRecord };
};

export default useNavigateToSiblingRecord;
