import { useMapContext } from '@/entities/map/context';
import { useMapEffect } from '@/entities/map/hooks';
import { selectPointCoordinates } from '@/entities/map/slices';
import { useAppSelector } from '@/shared/hooks';
import marker from './marker';

const useMarkerPosition = () => {
  const position = useAppSelector(selectPointCoordinates);
  const { map } = useMapContext();

  useMapEffect(() => {
    if (!position) return;

    map.jumpTo({
      center: position,
    });
    marker.setLngLat(position).addTo(map);

    return () => {
      marker.remove();
    };
  }, [map, position]);
};

export default useMarkerPosition;
