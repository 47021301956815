import { useEffect } from 'react';
import { usePrevious } from '@reactuses/core';
import { QueryKey } from '@tanstack/react-query';
import useDeleteInfinitePages from './useDeleteInfinitePages';

interface Params<T> {
  query: T;
  queryKey: QueryKey;
  onDeleteInfiniteCache?: () => void;
}
const useDeleteInfinitePagesOnQueryChange = <T>({ query, queryKey, onDeleteInfiniteCache }: Params<T>) => {
  const prevQuery = usePrevious(query);
  const handleDeleteInfinitePages = useDeleteInfinitePages(queryKey);

  useEffect(() => {
    if (prevQuery !== undefined && query !== prevQuery) {
      if (onDeleteInfiniteCache) onDeleteInfiniteCache();
      handleDeleteInfinitePages(0);
    }
  }, [prevQuery, query, onDeleteInfiniteCache]);
};

export default useDeleteInfinitePagesOnQueryChange;
