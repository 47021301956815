import { useState, useEffect } from 'react';
import { useSelectedRadioNetwork } from '@/entities/network';
import { useTranscriptQueryParams, useTranscriptsFilter } from '@/entities/transcript';
import { RadioNetworkType } from '@/shared/constants';
import { useRenderPropsContext } from '@/shared/context';
import { useIsMobile, useIsTabletOrMobile } from '@/shared/hooks';
import { TranscriptRenderProps } from '@/shared/types';
import useMapRequiredData from './useMapRequiredData';
import useMobileMapVisible from './useMobileMapVisible';
import useResizeSidebar from './useResizeSidebar';

const DRAWER_FILTER_TITLE = {
  [RadioNetworkType.NETWORK]: 'Фільтр в мережі',
  [RadioNetworkType.FREQUENCY]: 'Фільтр в частоті',
};

const useTranscriptsAndMap = (sidebarStorageKey: string) => {
  const { renderTranscripts, renderTranscriptFilters } = useRenderPropsContext<TranscriptRenderProps>();
  const { search } = useTranscriptQueryParams();
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);
  const { isMobileMapVisible, toggleMobileMapVisible, showMobileMap, hideMobileMap } = useMobileMapVisible();
  const isMobile = useIsMobile();
  const isTablet = useIsTabletOrMobile();
  const { selectedRadioNetwork } = useSelectedRadioNetwork();
  const filtersDrawerTitle = selectedRadioNetwork ? DRAWER_FILTER_TITLE[selectedRadioNetwork.type] : undefined;

  const { transcriptFilters, isTranscriptFiltersLoading } = useTranscriptsFilter({
    enabled: search.length !== 1,
  });

  const {
    sidebarDefaultWidth,
    forceResizeSidebarToWidth,
    handleResizeFinish,
    handleResizeStart,
    transcriptsAndMapWrapperRef,
    transcriptsAndMapWrapperWidth,
    layoutSidebarWrapperRef,
    isMapVisible,
    handleToggleMapVisibilityButtonClick: toggleDesktopMapVisible,
  } = useResizeSidebar({ sidebarStorageKey });

  const handleToggleMapVisibilityButtonClick = isMobile ? toggleMobileMapVisible : toggleDesktopMapVisible;

  const handleFilterButtonClick = () => setIsFiltersVisible(true);

  const mapRequiredData = useMapRequiredData(isMobile ? hideMobileMap : undefined);
  const { centerTo, centerToMetaData } = mapRequiredData;

  // in mobile view we need to switch to map view when coordinates/locations are clicked in transcriptions list
  useEffect(() => {
    if (centerTo && centerToMetaData) {
      showMobileMap();
    }
  }, [centerTo, centerToMetaData]);

  return {
    sidebarDefaultWidth,
    sidebarMinWidth: isTablet ? 360 : 440,
    isFiltersVisible,
    setIsFiltersVisible,
    forceResizeSidebarToWidth,
    handleResizeFinish,
    handleResizeStart,
    transcriptsAndMapWrapperRef,
    transcriptsAndMapWrapperWidth,
    layoutSidebarWrapperRef,
    isMapVisible: isMobile ? isMobileMapVisible : isMapVisible,
    isMobileViewAndMapVisible: isMobile && isMobileMapVisible,
    isMobile,
    handleToggleMapVisibilityButtonClick,
    handleFilterButtonClick,
    transcriptFilters,
    isTranscriptFiltersLoading,
    filtersDrawerTitle,
    renderTranscripts,
    renderTranscriptFilters,
    ...mapRequiredData,
  };
};

export default useTranscriptsAndMap;
