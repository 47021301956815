import { useCallback, useState, useEffect } from 'react';
import { useLocalStorageForLimitedDays, useLocalStorageListener } from '@/shared/hooks';
import { PersistedData } from '@/shared/types';
import { PlayerSettings } from '../components/AudioPlayer/type';

const AUDIOPLAYER_SETTINGS = 'audioPlayerSettings';
const DEFAULT_SETTINGS: PlayerSettings = {
  isSkipSilenceEnabled: true,
};

const useAudioPlayerStorageSettings = () => {
  const { setStorageValue, getStorageValue } = useLocalStorageForLimitedDays<PlayerSettings>(AUDIOPLAYER_SETTINGS);
  const [settings, setSettings] = useState<PlayerSettings>(getStorageValue() ?? DEFAULT_SETTINGS);

  const memoSaveSettings = useCallback((options: PlayerSettings) => {
    const currentSettings = getStorageValue() ?? {};
    setStorageValue({ ...currentSettings, ...options });
  }, []);

  const memoHandleLocalStorageChange = useCallback((data: PersistedData<PlayerSettings> | null) => {
    if (!data?.value) return;

    setSettings(data.value);
  }, []);

  useLocalStorageListener<PersistedData<PlayerSettings>>({
    key: AUDIOPLAYER_SETTINGS,
    memoOnLocalStorageChange: memoHandleLocalStorageChange,
  });

  useEffect(() => {
    const currentSettings = getStorageValue();
    if (!currentSettings || !Object.hasOwn(currentSettings, 'isSkipSilenceEnabled')) {
      setStorageValue(DEFAULT_SETTINGS);
    }
  }, []);

  return {
    settings,
    memoSaveSettings,
    getStorageValue,
  };
};

export default useAudioPlayerStorageSettings;
