import { useMemo } from 'react';
import { RequestQueryParams } from '@/shared/api';
import { FoxRecordsQueryParam } from '@/shared/constants';
import { useSearchParam } from '@/shared/hooks';
import { queryParamsService } from '@/shared/services';

const useFoxRecordsQueryParams = (): RequestQueryParams => {
  const [encryptionType] = useSearchParam(FoxRecordsQueryParam.EncryptionType);
  const [frequency] = useSearchParam(FoxRecordsQueryParam.Frequency);
  const [sourceAddress] = useSearchParam(FoxRecordsQueryParam.SourceAddress);
  const [groupAddress] = useSearchParam(FoxRecordsQueryParam.GroupAddress);
  const [dateRange] = useSearchParam(FoxRecordsQueryParam.DateRange);
  const [digitalSignal] = useSearchParam(FoxRecordsQueryParam.DigitalSignal);
  const [analogSignal] = useSearchParam(FoxRecordsQueryParam.AnalogSignal);
  const [decoded] = useSearchParam(FoxRecordsQueryParam.Decoded);
  const [notDecoded] = useSearchParam(FoxRecordsQueryParam.NotDecoded);
  const [search] = useSearchParam(FoxRecordsQueryParam.Search);

  return useMemo<RequestQueryParams>(() => {
    const decodingStatus: string[] = [];
    if (decoded) {
      decodingStatus.push('decoded');
    }
    if (notDecoded) {
      decodingStatus.push('notDecoded');
    }

    const signalType: string[] = [];
    if (digitalSignal) {
      signalType.push('digital');
    }
    if (analogSignal) {
      signalType.push('analog');
    }

    const dateRangeArr = queryParamsService.convertQueryParamToArray(dateRange);
    const dateFrom = dateRangeArr.length > 1 ? dateRangeArr[0] : null;
    const dateTo = dateRangeArr.length > 1 ? dateRangeArr[1] : null;

    return {
      decodingStatus,
      signalType,
      encryptionType: queryParamsService.convertQueryParamToArray(encryptionType),
      frequency: queryParamsService.convertQueryParamToArray(frequency),
      sourceAddress: queryParamsService.convertQueryParamToArray(sourceAddress),
      groupAddress: queryParamsService.convertQueryParamToArray(groupAddress),
      dateFrom,
      dateTo,
      search: search || null,
    };
  }, [
    decoded,
    notDecoded,
    digitalSignal,
    analogSignal,
    encryptionType,
    frequency,
    sourceAddress,
    groupAddress,
    dateRange,
    search,
  ]);
};

export default useFoxRecordsQueryParams;
