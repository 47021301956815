import { Source } from '@/entities/source/types';
import { useParams } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';
import useGetSourceById from './useGetSourceById';

const useSource = <T = Source>() => {
  const { sourceId = '' } = useParams<SourceRouteParams>();
  const { source } = useGetSourceById<T>(sourceId);

  return source;
};

export default useSource;
