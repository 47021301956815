import { useQueryClient } from '@tanstack/react-query';
import { LOGOUT } from '@/entities/user/constants';
import { logout } from '@/shared/api';
import { LogoutIcon } from '@/shared/assets';
import { Button } from '@/shared/components';
import { COPIES, NOTIFICATION_TYPES } from '@/shared/constants';
import { useNotificationContext } from '@/shared/context';
import styles from './styles.module.css';

const LogoutButton = () => {
  const queryClient = useQueryClient();
  const { openNotification } = useNotificationContext();

  const handleClick = async () => {
    // locks the navigation if user is offline
    if (!window.navigator.onLine) {
      openNotification({ type: NOTIFICATION_TYPES.ERROR, title: COPIES.CONNECTION_FAILED });
      return;
    }

    await queryClient.cancelQueries();
    logout();
  };

  return (
    <Button
      type="primary"
      icon={<LogoutIcon className={styles.icon} />}
      onClick={handleClick}
    >
      {LOGOUT}
    </Button>
  );
};

export default LogoutButton;
