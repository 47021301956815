import { useState } from 'react';
import { SelectedSlider } from '../constants';
import { isRoundedNumbersEqual } from '../utils';

type FocusInfo = {
  slider: SelectedSlider;
  initValue: number[];
} | null;

const useFocusInfo = (range: number[]) => {
  const [focusInfo, setFocusInfo] = useState<FocusInfo>(null);

  const getFocusInfo = (value: number[]) => {
    if (focusInfo) {
      return focusInfo;
    }

    const isSelectedMiddle = !isRoundedNumbersEqual(value[0], range[0]) && !isRoundedNumbersEqual(value[1], range[1]);

    const info = {
      slider: isSelectedMiddle
        ? SelectedSlider.Middle
        : value.findIndex((val, i) => !isRoundedNumbersEqual(val, range[i])),
      initValue: value,
    };

    setFocusInfo(info);

    return info;
  };

  return { getFocusInfo, setFocusInfo };
};

export default useFocusInfo;
