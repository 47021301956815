import {
  ApartmentOutlined,
  ApiOutlined,
  AppstoreOutlined,
  DeploymentUnitOutlined,
  PlusSquareOutlined,
  TagOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import classNames from 'classnames';
import { FrequencyIcon } from '@/shared/assets';
import {
  ACCESS_GROUPS_PATH_BASE,
  ALL_INTERCEPTIONS_PATH,
  CATEGORIES_PATH_BASE,
  FACTIONS_PATH_BASE,
  OVERVIEW_PATH,
  Page,
  RADIO_NETWORKS_PATH_BASE,
  SOURCES_PATH_BASE,
  UNITS_PATH_BASE,
} from '@/shared/constants';
import styles from './styles.module.css';
import { MenuItem } from './types';

export const ITEMS: MenuItem[] = [
  {
    key: OVERVIEW_PATH,
    label: Page.Overview,
    className: styles.itemGap,
    icon: (
      <AppstoreOutlined
        className={styles.icon}
        data-testid="overview-button"
      />
    ),
  },
  {
    key: ALL_INTERCEPTIONS_PATH,
    label: Page.AllInterceptions,
    icon: (
      <FrequencyIcon
        className={styles.icon}
        data-testid="all-interceptions-button"
      />
    ),
  },
  {
    key: RADIO_NETWORKS_PATH_BASE,
    label: Page.RadioNetworks,
    className: styles.itemGap,
    icon: (
      <DeploymentUnitOutlined
        className={styles.icon}
        data-testid="radio-networks-button"
      />
    ),
  },
  {
    key: SOURCES_PATH_BASE,
    label: Page.Sources,
    icon: (
      <ApiOutlined
        className={styles.icon}
        data-testid="sources-button"
      />
    ),
  },
  {
    key: FACTIONS_PATH_BASE,
    label: Page.Factions,
    icon: (
      <ApartmentOutlined
        className={classNames(styles.icon, styles.rotateIcon)}
        data-testid="factions-button"
      />
    ),
  },
  {
    key: CATEGORIES_PATH_BASE,
    label: Page.Categories,
    icon: (
      <TagOutlined
        className={styles.icon}
        data-testid="categories-button"
      />
    ),
  },
  {
    key: ACCESS_GROUPS_PATH_BASE,
    label: Page.AccessGroups,
    icon: (
      <TeamOutlined
        className={styles.icon}
        data-testid="access-groups-button"
      />
    ),
  },
  {
    key: UNITS_PATH_BASE,
    label: Page.Units,
    icon: (
      <PlusSquareOutlined
        className={styles.icon}
        data-testid="units-button"
      />
    ),
  },
];

export const ITEMS_WITH_COLLAPSIBLE_PANEL = [RADIO_NETWORKS_PATH_BASE, SOURCES_PATH_BASE, FACTIONS_PATH_BASE];
