import { useCallback } from 'react';
import dayjs from 'dayjs';
import { PersistedData } from '@/shared/types';
import { useLocalStorage } from './useLocalStorage';

const DEFAULT_STALE_AFTER_DAYS = 7;

export const useLocalStorageForLimitedDays = <T>(key: string, daysLimit: number = DEFAULT_STALE_AFTER_DAYS) => {
  const {
    getStorageValue: defaultGetStorageValue,
    setStorageValue: defaultSetStorageValue,
    removeStorageValue,
  } = useLocalStorage<PersistedData<T>>(key);

  const persistedObj = defaultGetStorageValue();
  if (persistedObj?.timestamp && dayjs(persistedObj.timestamp).isBefore(dayjs().subtract(daysLimit, 'day'))) {
    removeStorageValue();
  }

  const getStorageValue = useCallback(() => {
    const persisted = defaultGetStorageValue();
    return persisted ? persisted.value : persisted;
  }, []);

  const setStorageValue = useCallback((value: T) => {
    const currentMomentTimestamp = dayjs().valueOf();
    defaultSetStorageValue({ value, timestamp: currentMomentTimestamp });
  }, []);

  return {
    getStorageValue,
    setStorageValue,
  };
};
