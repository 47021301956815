import { PropsWithChildren, ReactNode } from 'react';
import { Drawer } from 'antd';
import classNames from 'classnames';
import { LoadingWrapper } from '@/shared/components/LoadingWrapper';
import {
  MobileMenuButtonIcon,
  MobileCloseButtonIcon,
  MobileBackButtonIcon,
} from '@/shared/components/mobile/MobileButtonIcons';
import { MobileView } from '@/shared/constants';
import { useSyncMobileModalStateWithQueryParam } from './hooks';
import styles from './styles.module.css';

type Props = {
  title?: ReactNode;
  viewType: MobileView;
  bodyClassName?: string;
  isOpen?: boolean;
  isLoading?: boolean;
  onClose: VoidFunction;
  footer?: ReactNode;
  hasMotion?: boolean;
  shouldDestroyOnClose?: boolean;
  shouldSynchronizeWithModalQueryParam?: boolean;
};

export const MobileContainer = ({
  title,
  viewType,
  isOpen = false,
  isLoading = false,
  bodyClassName,
  onClose,
  footer,
  hasMotion = true,
  shouldDestroyOnClose = true,
  shouldSynchronizeWithModalQueryParam = false,
  children,
}: PropsWithChildren<Props>) => {
  useSyncMobileModalStateWithQueryParam(isOpen, onClose, shouldSynchronizeWithModalQueryParam);

  let actionButtonIcon;

  if (viewType === MobileView.Menu) actionButtonIcon = <MobileCloseButtonIcon />;
  if (viewType === MobileView.PrimaryScreen) actionButtonIcon = <MobileMenuButtonIcon />;
  if (viewType === MobileView.SecondaryScreen) actionButtonIcon = <MobileBackButtonIcon />;

  return (
    <Drawer
      title={title}
      width="100vw"
      rootClassName={styles.container}
      classNames={{ header: styles.header, body: classNames(styles.body, bodyClassName), footer: styles.footer }}
      closeIcon={actionButtonIcon}
      open={isOpen}
      onClose={onClose}
      footer={footer}
      destroyOnClose={shouldDestroyOnClose}
      {...(hasMotion ? {} : { motion: undefined })}
    >
      <LoadingWrapper
        isLoading={isLoading}
        isFixedOverlay
      >
        {children}
      </LoadingWrapper>
    </Drawer>
  );
};
