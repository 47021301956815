import { Typography } from 'antd';
import styles from './styles.module.css';

interface Props {
  value: string;
}

const Title = ({ value }: Props) => {
  return <Typography className={styles.title}>{value}</Typography>;
};

export default Title;
