const convertFilterDataToOptions = (data?: string[]) => {
  if (!data) return [];

  return data.map((element) => ({
    label: element,
    value: element,
  }));
};

export default convertFilterDataToOptions;
