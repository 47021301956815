import classNames from 'classnames';
import styles from './styles.module.css';
import { OptionProps } from './types';
import useOption from './useOption';

interface Props extends OptionProps {
  className?: string;
}

const Option = ({ data, className, onMouseEnter, onMouseLeave }: Props) => {
  const { handleMouseLeave, handleMouseEnter } = useOption({ data, onMouseEnter, onMouseLeave });

  return (
    <div
      className={classNames(styles.option, className)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {data.icon}
      {data.label}
    </div>
  );
};

export default Option;
