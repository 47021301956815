export const USER_NAME = "Ім'я користувача";
export const USER_NAME_REQUIRED = "Ім'я користувача обов'язкове";
export const USER_ROLE = 'Роль користувача';
export const PHONE_NUMBER = 'Номер телефону';
export const YOUR_NICKNAME = 'Ваш нікнейм:';
export const YOUR_NUMBER = 'Ваш номер:';
export const ROLE = 'Роль:';
export const HELP = 'Допомога';
export const SUPPORT = 'Технічна підтримка:';
export const LOGOUT = 'Вийти з системи';

export const MODAL_COPIES = {
  TITLE: 'Редагувати користувача',
  NAME: 'Ім’я',
  ROLE: 'Права доступу',
  SAVE: 'Зберегти',
  CANCEL: 'Відмінити',
};

export const USER_COLUMNS_COPIES = {
  USER: 'КОРИСТУВАЧ',
  NAME: "ІМ'Я",
  USER_GROUP: 'Групи доступів',
  PHONE: 'Телефон',
  ACCESS_RIGHTS: 'РОЛЬ',
  USER_DEACTIVATED: 'Блоковано',
  CREATED_AT: 'РЕЄСТРАЦІЯ',
};
