import { useDragLayer } from 'react-dnd';

const useIsFrequencyDragging = () => {
  const { isFrequencyDragging } = useDragLayer((monitor) => ({
    isFrequencyDragging: monitor.isDragging(),
  }));

  return isFrequencyDragging;
};

export default useIsFrequencyDragging;
