import { AudioPlayerProps } from '../type';
import useAudioPlayerHotKeys from './useAudioPlayerHotKeys';
import useAutoplayControl from './useAutoplayControl';
import usePlayPause from './usePlayPause';
import useRateControls from './useRateControls';
import useSilenceSkipManager from './useSilenceSkipManager';
import useSkipRecordsWithoutVoice from './useSkipRecordsWithoutVoice';
import useTimeTooltipPosition from './useTimeTooltipPosition';
import useWavesurfer from './useWavesurfer';
import useWsFinishEventListener from './useWsFinishEventListener';

interface Params
  extends Pick<
    AudioPlayerProps,
    | 'record'
    | 'isRecordSelected'
    | 'isLoading'
    | 'shouldBePlaying'
    | 'memoSettings'
    | 'memoOnPlayPause'
    | 'memoOnSettingsUpdate'
    | 'memoPlayNextRecord'
    | 'memoNavigateToPreviousRecord'
    | 'memoNavigateToNextRecord'
    | 'recordHasVoice'
  > {}

const usePlayer = ({
  record,
  isRecordSelected,
  isLoading,
  shouldBePlaying,
  memoSettings,
  memoOnPlayPause,
  memoOnSettingsUpdate,
  memoPlayNextRecord,
  memoNavigateToPreviousRecord,
  memoNavigateToNextRecord,
  recordHasVoice,
}: Params) => {
  const { containerRef, wavesurfer, currentTime, isPlaying, isReady, duration } = useWavesurfer({
    record,
    isLoading,
    memoSettings,
    memoOnPlayPause,
  });

  const { memoPlayPause, memoStop, memoPlay, memoPause } = usePlayPause({
    isRecordSelected,
    wavesurfer,
    shouldBePlaying,
    isPlaying,
    isReady,
    isLoading,
    memoOnPlayPause,
    recordHasVoice,
    isSkipSilenceEnabled: memoSettings.isSkipSilenceEnabled,
  });

  const { isAutoplayOn, memoToggleAutoplay } = useAutoplayControl({
    memoSettings,
    memoOnSettingsUpdate,
    memoPlay,
  });

  const { audioRate, handleRateSelect, memoUpdateRate } = useRateControls({
    memoSettings,
    memoOnSettingsUpdate,
  });

  const { isSkipSilenceEnabled, memoHandleSilenceSkipChange } = useSilenceSkipManager({
    defaultIsSkipSilenceEnabled: memoSettings.isSkipSilenceEnabled,
    memoOnSettingsUpdate,
  });

  const timeTooltipPosition = useTimeTooltipPosition({ wavesurfer, isPlaying, pause: memoPause, play: memoPlay });

  useWsFinishEventListener({ wavesurfer, isAutoplayOn, memoStop, memoPlayNextRecord });

  useAudioPlayerHotKeys({
    wavesurfer,
    currentTime,
    audioRate,
    memoUpdateRate,
    memoToggleAutoplay,
    memoNavigateToNextRecord,
    memoNavigateToPreviousRecord,
    memoPlayPause,
    memoHandleSilenceSkipChange,
    duration,
  });

  useSkipRecordsWithoutVoice({
    isPlaying,
    recordHasVoice,
    memoSettings,
    memoFinishRecord: memoStop,
    memoPlayNextRecord,
  });

  return {
    containerRef,
    currentTime,
    isPlaying,
    duration,
    isAutoplayOn,
    audioRate,
    timeTooltipPosition,
    handleRateSelect,
    memoToggleAutoplay,
    memoPlayPause,
    isSkipSilenceEnabled,
    memoHandleSilenceSkipChange,
  };
};

export default usePlayer;
