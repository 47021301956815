import { useMemo } from 'react';
import { FoxRecordsQueryParam } from '@/shared/constants';
import { useSearchParam } from '@/shared/hooks';

const useRecordsFilterButtonCount = () => {
  const [encryptionType] = useSearchParam(FoxRecordsQueryParam.EncryptionType);
  const [frequency] = useSearchParam(FoxRecordsQueryParam.Frequency);
  const [sourceAddress] = useSearchParam(FoxRecordsQueryParam.SourceAddress);
  const [groupAddress] = useSearchParam(FoxRecordsQueryParam.GroupAddress);
  const [dateRange] = useSearchParam(FoxRecordsQueryParam.DateRange);
  const [digitalSignal] = useSearchParam(FoxRecordsQueryParam.DigitalSignal);
  const [analogSignal] = useSearchParam(FoxRecordsQueryParam.AnalogSignal);
  const [decoded] = useSearchParam(FoxRecordsQueryParam.Decoded);
  const [notDecoded] = useSearchParam(FoxRecordsQueryParam.NotDecoded);

  const count = useMemo(() => {
    return [
      encryptionType,
      frequency,
      sourceAddress,
      groupAddress,
      dateRange,
      digitalSignal,
      analogSignal,
      decoded,
      notDecoded,
    ].reduce((accumulator, filter) => (filter ? accumulator + 1 : accumulator), 0);
  }, [
    encryptionType,
    frequency,
    sourceAddress,
    groupAddress,
    dateRange,
    digitalSignal,
    analogSignal,
    decoded,
    notDecoded,
  ]);

  return count;
};

export default useRecordsFilterButtonCount;
