import { Typography } from 'antd';
import { NOT_IN_NETWORK } from '@/entities/transcript/constants';
import { Transcription } from '@/entities/transcript/types';
import { NetworkLink } from './components';
import styles from './styles.module.css';

type Props = {
  networkId: Transcription['networkId'];
  networkName: Transcription['networkName'];
};

const TranscriptNetwork = ({ networkId, networkName }: Props) => {
  if (networkId && networkName) {
    return (
      <NetworkLink
        networkId={networkId}
        networkName={networkName}
      />
    );
  }

  return (
    <Typography.Text
      className={styles.notInNetwork}
      type="secondary"
    >
      {NOT_IN_NETWORK}
    </Typography.Text>
  );
};

export default TranscriptNetwork;
