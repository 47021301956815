import useSpectrogramData from './useSpectrogramData';
import useSpectrogramHandlers from './useSpectrogramHandlers';

interface Params {
  isSpectrogramEnabled: boolean;
}

const useSpectrogramController = ({ isSpectrogramEnabled }: Params) => {
  const spectrogramData = useSpectrogramData({ isSpectrogramEnabled });
  const handlers = useSpectrogramHandlers();

  return {
    ...spectrogramData,
    ...handlers,
  };
};

export default useSpectrogramController;
