import {
  CartoDarkMapPreview,
  CartoLightMapPreview,
  ElevationMapPreview,
  GoogleMapPreview,
  OpenStreetMapPreview,
} from '@/entities/map/assets';
import { MapSource } from '@/entities/map/constants';
import { PreviewIcons } from './types';

export const PREVIEW_ICONS: PreviewIcons = {
  [MapSource.CartoLight]: { label: 'Світла', icon: <CartoLightMapPreview /> },
  [MapSource.CartoDark]: { label: 'Темна', icon: <CartoDarkMapPreview /> },
  [MapSource.OpenStreet]: { label: 'Open Street', icon: <OpenStreetMapPreview /> },
  [MapSource.Google]: { label: 'Google Map', icon: <GoogleMapPreview /> },
  [MapSource.Elevation]: { label: 'Висоти', icon: <ElevationMapPreview /> },
};
