import { ELEVATION_SERVER_HOST } from '@/shared/config';
import { LatLngCoordinates } from '@/shared/types';

export const GOOGLE_HYBRID_URL_PATTERN = 'https://{s}.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}';
export const GOOGLE_SATELLITE_URL_PATTERN = 'https://{s}.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}';
export const GOOGLE_LABELS_URL_PATTERN = 'https://{s}.google.com/vt/lyrs=h&hl=en&x={x}&y={y}&z={z}';
export const GOOGLE_SUBDOMAINS = ['mt0', 'mt1', 'mt2', 'mt3'];

export const OPENSTREET_URL_PATTERN = 'https://tile.openstreetmap.org/{z}/{x}/{y}.png';

export const ELEVATION_URL_PATTERN = `${ELEVATION_SERVER_HOST}/{z}/{x}/{y}.png`;
export const ELEVATION_SOUTH_WEST_POINT: LatLngCoordinates = { lat: 40.54375, lng: 19.9229167 };
export const ELEVATION_NORTH_EAST_POINT: LatLngCoordinates = { lat: 55.0304167, lng: 48.24625 };

export const CARTO_VECTOR_URL = 'https://tiles.basemaps.cartocdn.com/vector/carto.streets/v1/tiles.json';
export const CARTO_GLYPH_URL_PATTERN = 'https://tiles.basemaps.cartocdn.com/fonts/{fontstack}/{range}.pbf';
export const CARTO_POSITRON_SPRITE_URL = 'https://tiles.basemaps.cartocdn.com/gl/positron-gl-style/sprite';
export const CARTO_DARK_MATTER_SPRITE_URL = 'https://tiles.basemaps.cartocdn.com/gl/dark-matter-gl-style/sprite';
export const CARTO_POSITRON_URL_PATTERN = 'https://basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png';
export const CARTO_DARK_MATTER_URL_PATTERN = 'https://basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}.png';
