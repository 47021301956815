import classNames from 'classnames';
import { Tag } from '@/shared/components';
import { EncryptionTypeTooltipContent } from './components';
import { useFoxFrequencyEncryptionTypeTag } from './hooks';
import styles from './styles.module.css';
import { FoxFrequencyTypeTagProps as Props } from './types';

const FoxFrequencyEncryptionTypeTag = ({ encryptionType }: Props) => {
  const {
    isUnknownEncryptionType,
    tagClassName,
    fullName,
    shortName,
    possibilityOfDecryptionTranslation,
    tooltipLastActiveAt,
    unknownEncryptionType,
  } = useFoxFrequencyEncryptionTypeTag({ encryptionType });

  if (isUnknownEncryptionType) {
    return (
      <Tag
        className={classNames(styles.baseTag, styles.redTag)}
        overlayClassName={styles.tooltipOverlay}
        tooltipContent={
          <EncryptionTypeTooltipContent
            fullName={unknownEncryptionType.fullName}
            possibilityOfDecryption={unknownEncryptionType.possibilityOfDecryptionTranslation}
            lastActiveAt={tooltipLastActiveAt}
          />
        }
      >
        {unknownEncryptionType.shortName}
      </Tag>
    );
  }

  return (
    <Tag
      className={classNames(styles.baseTag, tagClassName)}
      overlayClassName={styles.tooltipOverlay}
      tooltipContent={
        <EncryptionTypeTooltipContent
          fullName={fullName}
          possibilityOfDecryption={possibilityOfDecryptionTranslation}
          lastActiveAt={tooltipLastActiveAt}
        />
      }
    >
      {shortName}
    </Tag>
  );
};

export default FoxFrequencyEncryptionTypeTag;
