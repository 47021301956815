import { TranscriptEntitiesProps } from '@/entities/transcript/types';
import { useRenderPropsContext } from '@/shared/context';
import { TranscriptRenderProps } from '@/shared/types';

interface Params extends TranscriptEntitiesProps {}

const useTranscriptEntities = ({ isCluster, clusterMetadata, transcript }: Params) => {
  const { renderTranscriptFactions, renderTranscriptCategories } = useRenderPropsContext<TranscriptRenderProps>();

  const factions = isCluster && clusterMetadata ? clusterMetadata.factions : transcript.factions;
  const coordinates = isCluster && clusterMetadata ? clusterMetadata.coordinates : transcript.metadata.coordinates;
  const locations = isCluster && clusterMetadata ? clusterMetadata.locations : transcript.locations;
  const categories = isCluster && clusterMetadata ? clusterMetadata.categories : transcript.categories;
  const tags = isCluster && clusterMetadata ? [] : transcript.tags;

  return {
    factions,
    coordinates,
    locations,
    categories,
    tags,
    renderTranscriptFactions,
    renderTranscriptCategories,
  };
};

export default useTranscriptEntities;
