import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { deactivateUnitUser as deactivateUnitUserApi } from '@/entities/unit/api';
import { UnitUser } from '@/entities/unit/types';
import { ResponseData } from '@/shared/api';
import { DEACTIVATE_UNIT_USER_MUTATION_KEY } from '@/shared/constants';

interface Params {
  userId: number;
  options?: UseMutationOptions<ResponseData<UnitUser>, unknown, unknown>;
}

const useDeactivateUnitUser = ({ userId, options }: Params) => {
  const { mutate: deactivateUnitUser, isLoading: isUnitUserDeactivating } = useMutation({
    ...options,
    mutationKey: [DEACTIVATE_UNIT_USER_MUTATION_KEY, userId],
    mutationFn: () => {
      return deactivateUnitUserApi(userId);
    },
  });

  return {
    deactivateUnitUser,
    isUnitUserDeactivating,
  };
};

export default useDeactivateUnitUser;
