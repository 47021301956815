import { useQueryClient } from '@tanstack/react-query';
import { FoxFrequencyRange } from '@/entities/source/@fox/types';
import { FoxSource } from '@/entities/source/types';
import { ResponseData } from '@/shared/api';
import { SOURCE_BY_ID_QUERY_KEY } from '@/shared/constants';

const useUpdateDeviceRange = (id?: string) => {
  const queryClient = useQueryClient();

  return (range: FoxFrequencyRange) => {
    queryClient.setQueryData<ResponseData<FoxSource>>(
      [SOURCE_BY_ID_QUERY_KEY, id],
      (staleData): ResponseData<FoxSource> | undefined => {
        if (!staleData) return;

        return {
          data: {
            ...staleData.data,
            frequencyRange: { ...range },
          },
        };
      }
    );
  };
};

export default useUpdateDeviceRange;
