import { FC } from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import { useAppSelector, useIsMobile } from '@/shared/hooks';
import { selectIsNavigationSidebarCollapsed } from '@/shared/slices';
import styles from './styles.module.css';

type Props = {
  Icon: FC<{ className?: string }>;
  label: string;
  onClick?: VoidFunction;
};

const NavigationButton = ({ Icon, label, onClick }: Props) => {
  const isMobile = useIsMobile();
  const isNavigationSidebarCollapsed = useAppSelector(selectIsNavigationSidebarCollapsed);

  const isCollapsed = isNavigationSidebarCollapsed && !isMobile;

  return (
    <Button
      className={classNames(styles.navigationButton, {
        [styles.navigationButtonCollapsed]: isCollapsed,
      })}
      type="text"
      icon={<Icon className={styles.icon} />}
      onClick={onClick}
    >
      {!isCollapsed ? label : ''}
    </Button>
  );
};

export default NavigationButton;
