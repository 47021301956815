import { Typography } from 'antd';
import classNames from 'classnames';
import styles from './styles.module.css';
import { getAliasWithComma } from './utils';

type Props = {
  aliases: string[];
  current?: string;
  className?: string;
  inline?: boolean;
};

const CallSignAliases = ({ aliases, current, className, inline }: Props) => {
  return (
    <Typography.Text
      className={classNames(
        styles.aliasesContainer,
        {
          [styles.row]: inline,
          [styles.column]: !inline,
        },
        className
      )}
    >
      {aliases.map((alias, aliasIndex, arr) => {
        const addComa = aliasIndex < arr.length - 1 && inline;
        return (
          <Typography.Text
            strong={alias === current}
            key={alias}
            className={styles.alias}
          >
            {addComa ? getAliasWithComma({ alias, aliasIndex, aliasesLength: arr.length }) : alias}
          </Typography.Text>
        );
      })}
    </Typography.Text>
  );
};

export default CallSignAliases;
