import { getLocalStorageValue } from '@/shared/utils';

export const useLocalStorage = <T>(key: string) => {
  const getStorageValue = getLocalStorageValue<T>(key);

  const setStorageValue = (value: T) => {
    localStorage.setItem(key, JSON.stringify(value));
    window.dispatchEvent(new Event('storage'));
  };

  const removeStorageValue = () => {
    localStorage.removeItem(key);
    window.dispatchEvent(new Event('storage'));
  };

  return { getStorageValue, setStorageValue, removeStorageValue };
};
