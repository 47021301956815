import { useCallback, useState, useEffect } from 'react';
import useGetFilteredFoxFrequencies from './useGetFilteredFoxFrequencies';

const FREQUENCY_HIGHLIGHT_DURATION = 5000;

type Params = {
  memoCloseSpectrogram?: VoidFunction;
};

const useScrollToSpectrogramFrequency = ({ memoCloseSpectrogram }: Params) => {
  const [highlightedFoxFrequencyId, setHighlightedFoxFrequencyId] = useState<string | null>(null);
  const [scrollToFoxFrequencyIndex, setScrollToFoxFrequencyIndex] = useState<number | null>(null);
  const { foxFrequencies } = useGetFilteredFoxFrequencies();

  const memoHandleScrollToSpectrogramFrequency = useCallback(
    (id: string) => {
      const index = foxFrequencies.findIndex(({ id: frequencyId }) => frequencyId === id);
      setScrollToFoxFrequencyIndex(index);

      setHighlightedFoxFrequencyId(id);
      memoCloseSpectrogram?.();

      const row = document.querySelector(`[data-row-key="${id}"]`);
      row?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
    [memoCloseSpectrogram, foxFrequencies]
  );

  useEffect(() => {
    if (!highlightedFoxFrequencyId || scrollToFoxFrequencyIndex === null || scrollToFoxFrequencyIndex < 0) return;

    const timeoutId = setTimeout(() => {
      setHighlightedFoxFrequencyId(null);
      setScrollToFoxFrequencyIndex(null);
    }, FREQUENCY_HIGHLIGHT_DURATION);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [highlightedFoxFrequencyId, scrollToFoxFrequencyIndex]);

  return { highlightedFoxFrequencyId, scrollToFoxFrequencyIndex, memoHandleScrollToSpectrogramFrequency };
};

export default useScrollToSpectrogramFrequency;
