import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { USER_DATA_QUERY_KEY } from '@/shared/constants';
import { useAppDispatch } from '@/shared/hooks';
import { sentryService } from '@/shared/services';
import { setCurrentUser } from '@/shared/slices';
import { User } from '@/shared/types';
import { getUserData } from '../api';

const useUserData = (enabled: boolean = true) => {
  const dispatch = useAppDispatch();

  const { data, isLoading, isSuccess } = useQuery({
    queryKey: [USER_DATA_QUERY_KEY],
    queryFn: getUserData,
    enabled,
  });

  useEffect(() => {
    if (isSuccess) {
      const { data: user } = data;
      sentryService.setUser({
        id: String(user.id),
      });

      dispatch(setCurrentUser(user));
    }
  }, [isSuccess]);

  return {
    userData: (data?.data || {}) as User,
    isUserDataLoading: isLoading,
  };
};

export default useUserData;
