import { Category, SubCategory } from '@/entities/category/types';
import { SortType } from '@/shared/constants';

const sortCategories = (categories: Category[], sortType: SortType): Category[] => {
  return categories.map(({ subCategories, ...rest }: Category): Category => {
    if (!subCategories) return rest;
    return {
      subCategories: subCategories.toSorted((a: SubCategory, b: SubCategory): number => {
        if (sortType === SortType.Ascending) {
          return a.countTags - b.countTags;
        }
        return b.countTags - a.countTags;
      }),
      ...rest,
    };
  });
};

export default sortCategories;
