// Typescript doesn't provide type safe JSON.stringify/parse
// service implementation inspired by this issue https://github.com/Microsoft/TypeScript/issues/19244
import { Stringified } from '@/shared/types';

export class JSONService {
  stringify<T>(value: T): Stringified<T> | null {
    try {
      return JSON.stringify(value) as Stringified<T>;
    } catch {
      return null;
    }
  }

  parse<T>(value: Stringified<T>): T | null {
    try {
      return JSON.parse(value) as T;
    } catch {
      return null;
    }
  }
}

export const jsonService = new JSONService();
