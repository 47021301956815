import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate, useLocalStorageForLimitedDays } from '@/shared/hooks';
import { queryParamsService } from '@/shared/services';

/**
 * This hook retrieves filtered query parameters from local storage.
 */
export const useNavigateWithStorageParams = (queryParam: Record<string, string>, key: string) => {
  const { getStorageValue } = useLocalStorageForLimitedDays(key);
  const navigate = useNavigate();
  const storage = getStorageValue();
  const [, setSearchParams] = useSearchParams();

  return useCallback(
    (pathname: string) => {
      navigate(pathname);
      if (storage) {
        setSearchParams(queryParamsService.getUpdatedFilterQueryParam(queryParam, storage), { replace: true });
      }
    },
    [storage]
  );
};
