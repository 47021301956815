import { useMemo } from 'react';
import { convertEncryptionTypesToOptions } from '@/entities/source/@fox/utils';
import { useParams } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';
import { convertFilterDataToOptions } from '../utils';
import useGetFileFilters from './useGetFileFilters';

const useOptions = () => {
  const { sourceId = '' } = useParams<SourceRouteParams>();
  const { filters, isLoading } = useGetFileFilters({ sourceId });

  const options = useMemo(
    () => ({
      frequencyOptions: convertFilterDataToOptions(filters?.data.frequencies),
      sourceAddressOptions: convertFilterDataToOptions(filters?.data.sourceAddresses),
      groupAddressOptions: convertFilterDataToOptions(filters?.data.groupAddresses),
      encryptionTypeOptions: convertEncryptionTypesToOptions(filters?.data.encryptionTypes),
    }),
    [filters]
  );

  return {
    options,
    isLoading,
  };
};

export default useOptions;
