import { configureStore } from '@reduxjs/toolkit';
import { callSignsReducer } from '@/entities/callSign';
import { categoriesReducer } from '@/entities/category';
import { codesReducer } from '@/entities/code';
import { factionsReducer } from '@/entities/faction';
import { interceptionsMapReducer, modalMapReducer, hexagonMapReducer } from '@/entities/map';
import { networkListReducer } from '@/entities/network';
import { notesReducer, noteAppliedDatesReducer } from '@/entities/note';
import { sourceReducer } from '@/entities/source';
import {
  transcriptReducer,
  transcriptsReducer,
  transcriptListScrollReducer,
  entitySelectionReducer,
} from '@/entities/transcript';
import { unitsReducer } from '@/entities/unit';
import {
  navigationMenuReducer,
  themeReducer,
  supportReducer,
  currentUserReducer,
  routerReducer,
  mobileViewReducer,
  layoutSidebarReducer,
} from '@/shared/slices';

export const store = configureStore({
  reducer: {
    support: supportReducer,
    theme: themeReducer,
    navigationMenu: navigationMenuReducer,
    currentUser: currentUserReducer,
    networkList: networkListReducer,
    transcript: transcriptReducer,
    transcripts: transcriptsReducer,
    transcriptListScroll: transcriptListScrollReducer,
    entitySelection: entitySelectionReducer,
    interceptionsMap: interceptionsMapReducer,
    modalMap: modalMapReducer,
    hexagonMap: hexagonMapReducer,
    callSigns: callSignsReducer,
    codes: codesReducer,
    notes: notesReducer,
    noteAppliedDates: noteAppliedDatesReducer,
    router: routerReducer,
    categories: categoriesReducer,
    mobileView: mobileViewReducer,
    source: sourceReducer,
    layoutSidebar: layoutSidebarReducer,
    factions: factionsReducer,
    units: unitsReducer,
  },
});
