import axios, { AxiosError } from 'axios';
import { errorHandlingService } from '@/shared/api';
import { BACKEND_SUPPORT_HOST } from '@/shared/config';
import { validationService } from '@/shared/services';
import { SupportData } from './types';
import { getSupportDataSchema } from './validationSchemas';

export const getSupportData = async () => {
  try {
    const result = await axios.get<SupportData>(`${BACKEND_SUPPORT_HOST}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    validationService.validateResponseData({
      url: BACKEND_SUPPORT_HOST as string,
      responseData: result.data,
      validationSchema: getSupportDataSchema,
    });

    return result.data;
  } catch (err) {
    throw AxiosError.from(errorHandlingService.getError(err as AxiosError));
  }
};
