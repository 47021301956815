import { Form } from 'antd';
import { Select } from '@/shared/components';
import { UnitSelectProps } from '@/shared/types';
import { useUnitSelect } from './hooks';

const UnitSelect = ({ name, label, placeholder, required, disabled }: UnitSelectProps) => {
  const {
    options,
    isLoading,
    formItemName,
    formItemLabel,
    selectPlaceholder,
    selectOptionFilterProp,
    selectValidation,
  } = useUnitSelect();

  return (
    <Form.Item
      name={name || formItemName}
      label={label || formItemLabel}
      rules={required ? selectValidation : undefined}
    >
      <Select
        placeholder={placeholder || selectPlaceholder}
        options={options}
        optionFilterProp={selectOptionFilterProp}
        loading={isLoading}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default UnitSelect;
