import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import { DownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import {
  useFoxRecordsQueryParams,
  useGetFiles,
  useNavigateToRecord,
} from '@/entities/source/@fox/components/RecordsTab/hooks';
import { getAudioUrl } from '@/entities/source/@fox/components/RecordsTab/utils';
import { File } from '@/entities/source/@fox/types';
import { FILES_QUERY_KEY } from '@/shared/constants';
import { useConfirmModal, useDeleteInfiniteItem, useParams } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';
import useDeleteRecord from './useDeleteRecord';
import useDownloadRecord from './useDownloadRecord';

export enum ItemLabel {
  Download = 'Завантажити запис',
  Delete = 'Видалити запис',
}

export enum ItemKey {
  Download = 'download',
  Delete = 'delete',
}

const DELETE_MODAL_TITLE = 'Видалити запис?';
const DELETE_MODAL_CONTENT = `Вибраний запис буде видалено з цього списку, а також з джерела, назавжди.`;

const useRecordActions = (id: string, fileName: string, onDelete?: () => void) => {
  const { isDownloading, downloadRecord } = useDownloadRecord();
  const { sourceId, tabEntityId } = useParams<SourceRouteParams>();
  const queryParams = useFoxRecordsQueryParams();
  const { filesList } = useGetFiles({ options: { enabled: false }, queryParams });
  const navigateToRecord = useNavigateToRecord();

  const deleteRecordFromCache = useDeleteInfiniteItem<File>([FILES_QUERY_KEY, sourceId, queryParams]);

  const { deleteRecord, isRecordDeleting } = useDeleteRecord({
    onSuccess: () => {
      onDelete?.();
      if (tabEntityId === id) {
        navigateToRecord({
          routeParams: {
            sourceId,
            tabEntityId: filesList.find((file) => file.id !== tabEntityId)?.id,
          },
          options: {
            withAllQueryParams: true,
          },
        });
      }
      deleteRecordFromCache('id', id);
    },
  });

  const handleDeleteRecord = useConfirmModal({
    title: DELETE_MODAL_TITLE,
    content: DELETE_MODAL_CONTENT,
    onOk: async () => {
      if (!id) return;

      deleteRecord(id);
    },
  });

  const withStopPropagation =
    (callback: () => void): MenuClickEventHandler =>
    (e) => {
      e.domEvent.stopPropagation();
      callback();
    };

  const items: MenuItemType[] = [
    {
      key: ItemKey.Download,
      label: ItemLabel.Download,
      icon: <DownloadOutlined />,
      onClick: withStopPropagation(() => downloadRecord(getAudioUrl(id), fileName)),
    },
  ];

  const dangerItems: MenuItemType[] = [
    {
      key: ItemKey.Delete,
      label: ItemLabel.Delete,
      icon: <DeleteOutlined />,
      onClick: withStopPropagation(handleDeleteRecord),
    },
  ];

  return {
    items,
    dangerItems,
    isLoading: isDownloading || isRecordDeleting,
  };
};

export default useRecordActions;
