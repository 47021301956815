import { Watermark, WatermarkProps } from '@/shared/components';
import { useAppSelector } from '@/shared/hooks';
import { selectCurrentUser } from '@/shared/slices';

export const UserWatermark = ({ color, zIndex }: Omit<WatermarkProps, 'content'>) => {
  const currentUser = useAppSelector(selectCurrentUser);

  return (
    currentUser && (
      <Watermark
        content={currentUser.full_name}
        color={color}
        zIndex={zIndex}
      />
    )
  );
};
