import { useEffect } from 'react';
import { selectSelectedFrequencies, setSelectedFrequencies } from '@/entities/source/slices';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { convertFrequenciesArrayToDictionary } from '../utils';
import useGetFilteredFoxFrequencies from './useGetFilteredFoxFrequencies';

const useSyncSelectedFrequenciesWithAllFrequencies = () => {
  const dispatch = useAppDispatch();
  const selectedFrequencies = useAppSelector(selectSelectedFrequencies);
  const { foxFrequencies } = useGetFilteredFoxFrequencies();

  useEffect(() => {
    if (!selectedFrequencies) return;

    const foxFrequenciesDictionary = convertFrequenciesArrayToDictionary(foxFrequencies);
    const { ...selectedFrequenciesCopy } = selectedFrequencies;
    let shouldUpdateSelectedFrequencies = false;

    for (const i in selectedFrequencies) {
      if (!Object.hasOwn(foxFrequenciesDictionary, i)) {
        delete selectedFrequenciesCopy[i];
        shouldUpdateSelectedFrequencies = true;
      }
    }

    if (shouldUpdateSelectedFrequencies) {
      dispatch(setSelectedFrequencies(selectedFrequenciesCopy));
    }
  }, [foxFrequencies]);
};

export default useSyncSelectedFrequenciesWithAllFrequencies;
