import { ModalProps, Form } from 'antd';
import { Input, Modal, Select } from '@/shared/components';
import { COPIES } from '@/shared/constants';
import {
  FOX_KEY_ENCRYPTION_PLACEHOLDER,
  FOX_KEY_ENCRYPTION_LABEL,
  FOX_KEY_GROUP_ID_LABEL,
  FOX_KEY_BODY_FIELD_NAME,
  FOX_KEY_BODY_LABEL,
  FOX_KEY_GROUP_ID_FIELD_NAME,
  FOX_KEY_ENCRYPTION_FIELD_NAME,
  FOX_KEY_KEY_ID_FIELD_NAME,
  FOX_KEY_KEY_ID_LABEL,
  ADD_KEY,
  FOX_KEY_TARGET_FREQUENCY_FIELD_NAME,
  FOX_KEY_TARGET_FREQUENCY_LABEL,
  FOX_KEY_TARGET_FREQUENCY_PLACEHOLDER,
  FOX_KEY_TARGET_FREQUENCY_OPTION_FILTER_PROP,
} from './constants';
import { useCreateFoxKeyModal } from './hooks';

const { Item } = Form;

interface Props extends Omit<ModalProps, 'onCancel'> {
  onCloseModal: VoidFunction;
}

const CreateFoxKeyModal = (props: Props) => {
  const { onCloseModal, open: isOpen, ...modalProps } = props;
  const {
    form,
    isFoxKeyCreating,
    foxKeyEncryptionOptions,
    foxKeyTargetOptions,
    isAllFoxFrequenciesFetching,
    initialValues,
    validationRules,
    handleModalOkClick,
    handleFinish,
    handleNormalizeItem,
    handleCloseModal,
    handleFoxKeyEncryptionChange,
    handleFoxKeyGroupIdChange,
    handleFoxKeyTargetFrequencyIdChange,
  } = useCreateFoxKeyModal({ isOpen, onCloseModal });

  return (
    <Modal
      title={ADD_KEY}
      okText={COPIES.ADD}
      onOk={handleModalOkClick}
      onCancel={handleCloseModal}
      confirmLoading={isFoxKeyCreating}
      destroyOnClose
      open={isOpen}
      {...modalProps}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={handleFinish}
        initialValues={initialValues}
      >
        <Item
          rules={validationRules.keyId}
          normalize={handleNormalizeItem}
          name={FOX_KEY_KEY_ID_FIELD_NAME}
          label={FOX_KEY_KEY_ID_LABEL}
        >
          <Input />
        </Item>
        <Item
          rules={validationRules.groupId}
          normalize={handleNormalizeItem}
          name={FOX_KEY_GROUP_ID_FIELD_NAME}
          label={FOX_KEY_GROUP_ID_LABEL}
        >
          <Input onChange={handleFoxKeyGroupIdChange} />
        </Item>
        <Item
          rules={validationRules.targetFrequencyId}
          name={FOX_KEY_TARGET_FREQUENCY_FIELD_NAME}
          label={FOX_KEY_TARGET_FREQUENCY_LABEL}
        >
          <Select
            options={foxKeyTargetOptions}
            optionFilterProp={FOX_KEY_TARGET_FREQUENCY_OPTION_FILTER_PROP}
            placeholder={FOX_KEY_TARGET_FREQUENCY_PLACEHOLDER}
            loading={isAllFoxFrequenciesFetching}
            onChange={handleFoxKeyTargetFrequencyIdChange}
          />
        </Item>
        <Item
          rules={validationRules.encryption}
          name={FOX_KEY_ENCRYPTION_FIELD_NAME}
          label={FOX_KEY_ENCRYPTION_LABEL}
        >
          <Select
            options={foxKeyEncryptionOptions}
            placeholder={FOX_KEY_ENCRYPTION_PLACEHOLDER}
            onChange={handleFoxKeyEncryptionChange}
          />
        </Item>
        <Item
          normalize={handleNormalizeItem}
          rules={validationRules.body}
          name={FOX_KEY_BODY_FIELD_NAME}
          label={FOX_KEY_BODY_LABEL}
        >
          <Input />
        </Item>
      </Form>
    </Modal>
  );
};

export default CreateFoxKeyModal;
