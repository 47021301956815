import { FrequencyAmplification, FrequencyRange, ReceiverName } from '@/entities/source/@fox';
import { LoadingWrapper } from '@/shared/components';
import { SettingsForm, CoverageArea } from './components';
import { useSettingsTab } from './hooks';
import styles from './styles.module.css';

const SettingsTab = () => {
  const {
    form,
    isFormChanged,
    isFormSaving,
    handleFormReset,
    handleFormSave,
    settings,
    isSettingsEmpty,
    isSettingsDisabled,
    range,
    setRange,
    amplification,
    setAmplification,
    autoAmplification,
    setAutoAmplification,
    coverageArea,
    setCoverageArea,
    isDrawModeEnabled,
    setIsDrawModeEnabled,
    isTabContentLoading,
  } = useSettingsTab();

  return (
    <LoadingWrapper
      isLoading={isTabContentLoading}
      isEmpty={!coverageArea}
      isFixedOverlay
    >
      <div className={styles.settingsContent}>
        <SettingsForm
          form={form}
          onReset={handleFormReset}
          onFinish={handleFormSave}
          isFormChanged={isFormChanged}
          isLoading={isFormSaving}
          isDrawModeEnabled={isDrawModeEnabled}
        >
          {settings && !isSettingsEmpty && (
            <>
              <ReceiverName receiverName={settings.receiverName} />
              <FrequencyRange
                steps={settings.bandwidthSteps}
                range={range}
                setRange={setRange}
                isDisabled={isSettingsDisabled}
              />
              <FrequencyAmplification
                amplification={amplification}
                setAmplification={setAmplification}
                autoAmplification={autoAmplification}
                setAutoAmplification={setAutoAmplification}
                isDisabled={isSettingsDisabled}
              />
            </>
          )}
          <CoverageArea
            coverageArea={coverageArea}
            setCoverageArea={setCoverageArea}
            setIsDrawModeEnabled={setIsDrawModeEnabled}
          />
        </SettingsForm>
      </div>
    </LoadingWrapper>
  );
};

export default SettingsTab;
