import { LayerSpecification } from 'maplibre-gl';
import { MapLayer, MapSource } from '@/entities/map/constants';

export const googleLayers: LayerSpecification[] = [
  {
    id: MapLayer.Bg,
    type: 'raster',
    source: MapSource.Google,
  },
];
