import TranscriptMessageProps from '../types';

export const areEqual = (
  {
    isCluster: prevIsCluster,
    transcriptId: prevTranscriptId,
    networkId: prevNetworkId,
    message: prevMessage,
    tags: prevTags,
    categories: prevCategories,
    locations: prevLocations,
    factions: prevFactions,
    codes: prevCodes,
    callSigns: prevCallSigns,
  }: TranscriptMessageProps,
  {
    isCluster,
    transcriptId,
    networkId,
    message,
    tags,
    categories,
    locations,
    factions,
    codes,
    callSigns,
  }: TranscriptMessageProps
) =>
  prevIsCluster === isCluster &&
  prevTranscriptId === transcriptId &&
  prevNetworkId === networkId &&
  prevMessage === message &&
  prevTags === tags &&
  prevCategories === categories &&
  prevLocations === locations &&
  prevFactions === factions &&
  prevCodes === codes &&
  prevCallSigns === callSigns;
