import { Watermark as AntWatermark, WatermarkProps as AntdWatermarkProps } from 'antd';
import { useAppSelector } from '@/shared/hooks';
import { selectIsDarkMode } from '@/shared/slices';
import styles from './styles.module.css';

export interface WatermarkProps extends Pick<AntdWatermarkProps, 'content' | 'zIndex'> {
  color?: string;
}

const DARK_MODE_COLOR = 'rgba(139, 139, 139, 0.08)';
const WHITE_MODE_COLOR = 'rgba(103, 103, 103, 0.05)';
const WATERMARK_Z_INDEX = 10000;

export const Watermark = ({ content, zIndex = WATERMARK_Z_INDEX, color }: WatermarkProps) => {
  const isDarkMode = useAppSelector(selectIsDarkMode);
  const modeColor = isDarkMode ? DARK_MODE_COLOR : WHITE_MODE_COLOR;
  const font = {
    color: color ?? modeColor,
    fontWeight: 700,
  };

  return (
    <AntWatermark
      content={content}
      className={styles.watermark}
      width={250}
      zIndex={zIndex}
      gap={[20, 20]}
      font={font}
    />
  );
};
