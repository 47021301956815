import { Form, SelectProps } from 'antd';
import { FACTIONS } from '@/entities/faction/constants';
import { Select } from '@/shared/components';
import { useIsMobile } from '@/shared/hooks';
import { ArrayElement, Faction } from '@/shared/types';
import FactionTag from '../FactionTag';
import styles from './styles.module.css';

type Props = Pick<SelectProps, 'placeholder' | 'disabled' | 'options'> & {
  value: Faction['id'][];
  onChange: (value: Faction['id'][]) => void;
  isLoading?: boolean;
};

export const FactionFormMultiSelect = ({
  options,
  value,
  onChange,
  isLoading,
  disabled = false,
  placeholder,
}: Props) => {
  const isMobile = useIsMobile();

  const tagRender: SelectProps['tagRender'] = ({ label, closable, onClose }) => {
    return (
      <FactionTag
        // as optionLabelProp="shortFactionName" is used in Select, type of this property is used for label
        label={label as ArrayElement<typeof options>['shortFactionName']}
        onClose={closable ? onClose : undefined}
        isLarge={isMobile}
        className="factionTag"
      />
    );
  };

  return (
    <Form.Item label={FACTIONS}>
      <Select
        mode="multiple"
        className={styles.factionSelect}
        options={options}
        optionFilterProp="factionName"
        optionLabelProp="shortFactionName"
        value={value}
        onChange={onChange}
        tagRender={tagRender}
        loading={isLoading}
        disabled={disabled}
        placeholder={placeholder}
      />
    </Form.Item>
  );
};
