import { getItemValue, getOrderedItems } from '@/shared/components/mobile/mobileManagers/utils';
import { MobileListColumnType } from '@/shared/types';
import { ListColumn } from '../types';

export const getPreparedListColumns = <T extends Record<string, unknown>>(
  entity: T,
  items: MobileListColumnType<T>[],
  itemsOrder?: (keyof T)[]
): ListColumn[] => {
  const orderedItems = itemsOrder?.length ? getOrderedItems(items, itemsOrder) : items.slice(0, 2);

  return orderedItems.map((item) => ({
    id: String(item.dataIndex),
    value: getItemValue(entity, item),
    placeholder: item.placeholder,
    showOriginalValue: item.showOriginalValue,
  }));
};
