import { z } from 'zod';
import {
  locationSchema,
  radioNetworkSchema,
  frequencySchema,
  factionSchema,
  baseCategorySchema,
} from '@/shared/validationSchemas';

const detailsSchema = z
  .object({
    frequencies: z.array(frequencySchema),
    details: z.object({
      locations: z.array(locationSchema),
      factions: z.array(factionSchema),
      categories: z.array(baseCategorySchema),
    }),
  })
  .and(radioNetworkSchema);

export const getDetailsSchema = detailsSchema;
