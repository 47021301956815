import { z } from 'zod';
import { responseDataSchema, accessGroupSchema } from '@/shared/validationSchemas';

export const getAccessGroupsSchema = responseDataSchema(z.array(accessGroupSchema));

export const getAccessGroupsByNameSchema = responseDataSchema(z.array(accessGroupSchema));

export const createAccessGroupSchema = responseDataSchema(accessGroupSchema);

export const updateAccessGroupSchema = responseDataSchema(accessGroupSchema);
