import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/shared/types';
import { UnitsState } from './types';

const initialState: UnitsState = {
  unitModalType: null,
};

const unitsSlice = createSlice({
  name: 'units',
  initialState,
  reducers: {
    setUnitModalType: (state: UnitsState, action: PayloadAction<UnitsState['unitModalType']>) => {
      state.unitModalType = action.payload;
    },
  },
});

export const { setUnitModalType } = unitsSlice.actions;

export const selectUnitModalType = (state: RootState) => state.units.unitModalType;

export default unitsSlice.reducer;
