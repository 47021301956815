import { CSSProperties } from 'react';

interface Params {
  startPoint: number;
  endPoint: number;
  markPoint: number;
}

const getMarkStyle = ({ startPoint, endPoint, markPoint }: Params): CSSProperties | undefined => {
  if (markPoint < startPoint || markPoint > endPoint) return;

  const percentValue = ((markPoint - startPoint) / (endPoint - startPoint)) * 100;
  const leftValue = `${percentValue}%`;

  return { left: leftValue };
};

export default getMarkStyle;
