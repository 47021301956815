import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/shared/types';
import { CategoriesState } from './types';

const initialState: CategoriesState = {
  selectedCategory: null,
  parentCategory: null,
};

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setSelectedCategory: (state: CategoriesState, action: PayloadAction<CategoriesState['selectedCategory']>) => {
      state.selectedCategory = action.payload;
    },
    setParentCategory: (state: CategoriesState, action: PayloadAction<CategoriesState['parentCategory']>) => {
      state.parentCategory = action.payload;
    },
  },
});

export const { setSelectedCategory, setParentCategory } = categoriesSlice.actions;

export const selectSelectedCategory = (state: RootState) => state.categories.selectedCategory;
export const selectParentCategory = (state: RootState) => state.categories.parentCategory;

export default categoriesSlice.reducer;
