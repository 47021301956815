import { CreateFoxKeyPayload } from '@/entities/source/@fox/types';

const getNormalizedFormValues = (formValues: CreateFoxKeyPayload) => {
  const normalizedFormValues = { ...formValues };
  const groupId = Number(formValues.groupId);
  const keyId = Number(formValues.keyId);

  if (!Number.isNaN(groupId)) {
    normalizedFormValues.groupId = groupId;
  }

  if (!Number.isNaN(keyId)) {
    normalizedFormValues.keyId = keyId;
  }

  return normalizedFormValues;
};

export default getNormalizedFormValues;
