import getFixedRange from './getFixedRange';
import isRoundedNumbersEqual from './isRoundedNumbersEqual';

const handleLeftStep = (steps: number[], prevRange: number[], value: number) => {
  const equalStep = steps.find((step) => isRoundedNumbersEqual(value, prevRange[1] - step));

  return equalStep ? getFixedRange([prevRange[1] - equalStep, prevRange[1]]) : prevRange;
};

export default handleLeftStep;
