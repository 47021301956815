import { InfiniteData, QueryKey, useQueryClient } from '@tanstack/react-query';
import { Paginated } from '@/shared/api';

// function returned by useDeleteInfiniteItem deletes item in useInfiniteQuery's cache
// example: const deleteInfiniteItem = useDeleteInfiniteItem<ItemType>(['some key']); deleteInfiniteItem('id', 'item-id');

const useDeleteInfiniteItem = <T>(queryKey: QueryKey) => {
  const queryClient = useQueryClient();

  return <K extends keyof T>(infiniteItemKey: K, infiniteItemValue: T[K]) => {
    queryClient.setQueryData<InfiniteData<Paginated<T>>>(queryKey, (data) => {
      if (data) {
        const updatedPages: InfiniteData<Paginated<T>>['pages'] = data.pages.map((page) => ({
          ...page,
          models: page.models.filter((model) => model[infiniteItemKey] !== infiniteItemValue),
        }));
        return {
          pages: updatedPages,
          pageParams: data.pageParams,
        };
      }
    });
  };
};

export default useDeleteInfiniteItem;
