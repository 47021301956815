import { MouseEvent as ReactMouseEvent } from 'react';
import { Row, Flex } from 'antd';
import classNames from 'classnames';
import { FoxFrequenciesTableProps } from '@/entities/source/@fox/components/ScanningTab/components/FoxFrequenciesTable/types';
import FoxFrequencyActions from '@/entities/source/@fox/components/ScanningTab/components/FoxFrequencyActions';
import FoxFrequencyCountRecords from '@/entities/source/@fox/components/ScanningTab/components/FoxFrequencyCountRecords';
import FoxFrequencyNote from '@/entities/source/@fox/components/ScanningTab/components/FoxFrequencyNote';
import SourceActivity from '@/entities/source/@fox/components/SourceActivity';
import { FoxFrequencyStatus as Status } from '@/entities/source/@fox/constants';
import { FoxFrequency } from '@/entities/source/@fox/types';
import { jsonService } from '@/shared/services';
import { FrequencyValueCell } from './components';
import { useListRow } from './hooks';
import styles from './styles.module.css';

interface Props extends Pick<FoxFrequenciesTableProps, 'highlightedFoxFrequencyId'> {
  frequency: FoxFrequency;
  onRowClick: (frequencyId: FoxFrequency['id']) => (event: ReactMouseEvent<any, MouseEvent>) => void;
  search: string;
}

const ListRow = ({ frequency, highlightedFoxFrequencyId, onRowClick, search }: Props) => {
  const { selectedFrequencies } = useListRow();

  return (
    <Row
      className={classNames(styles.row, {
        [styles.halfTransparentRow]: frequency.status === Status.Inactive,
        [styles.highlightedRow]: highlightedFoxFrequencyId === frequency.id,
        [styles.selectedRow]: Boolean(selectedFrequencies?.[frequency.id]),
      })}
      onClick={onRowClick(frequency.id)}
    >
      <FrequencyValueCell
        search={search}
        wrapperClassName={styles.valueRow}
        topColumnClassName={styles.valueCol}
        frequency={frequency.value}
        isDigital={frequency.isDigital}
        stringifiedEncryptionTypes={jsonService.stringify(frequency.encryptionTypes)}
      />
      <Flex>
        <FoxFrequencyCountRecords
          countRecords={frequency.countRecords}
          frequency={frequency.value}
        />
      </Flex>
      <FoxFrequencyNote
        className={styles.formItem}
        note={frequency.note}
        foxFrequencyId={frequency.id}
      />
      <SourceActivity activity={frequency.lastActiveAt} />
      <FoxFrequencyActions
        foxFrequencyId={frequency.id}
        foxFrequencyStatus={frequency.status}
      />
    </Row>
  );
};

export default ListRow;
