import { useUserData } from '@/entities/user/hooks';
import { useAppSelector } from '@/shared/hooks';
import { selectCurrentUser } from '@/shared/slices';

const useUserDataGuard = () => {
  const currentUser = useAppSelector(selectCurrentUser);
  const isUserDataExists = !!currentUser;

  useUserData(!isUserDataExists);

  return {
    isUserDataExists,
  };
};

export default useUserDataGuard;
