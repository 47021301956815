import { Form, SelectProps } from 'antd';
import { FrequencyId } from '@/entities/frequency/types';
import { COPIES } from '@/shared/constants';
import { Frequency } from '@/shared/types';
import { FrequencySelect } from '../FrequencySelect';

type Props = Pick<SelectProps, 'placeholder' | 'disabled'> & {
  defaultFrequencies?: Pick<Frequency, 'id' | 'value' | 'source_name'>[];
  value: FrequencyId[];
  onChange: (value: FrequencyId[]) => void;
};

export const FrequencyFormMultiSelect = ({ defaultFrequencies, value, onChange, placeholder, disabled }: Props) => {
  return (
    <Form.Item label={COPIES.FREQUENCY}>
      <FrequencySelect
        defaultFrequencies={defaultFrequencies}
        defaultValues={value}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
      />
    </Form.Item>
  );
};
