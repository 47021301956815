import { FC } from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import { useBlurButtonOnClick } from '@/entities/source/@fox/components/RecordsTab/components/AudioPlayer/hooks';
import { AutoPlayIcon } from '@/shared/assets';
import { Tooltip } from '@/shared/components';
import styles from './styles.module.css';

interface Props {
  autoplay: boolean;
  onClick: VoidFunction;
}

const TOOLTIP_TITLE = 'Активувати авто-відтворення (Shift + Пробіл)';

const AutoplayButton: FC<Props> = ({ autoplay, onClick }) => {
  const { buttonRef, blurButtonOnClick: handleClick } = useBlurButtonOnClick({ onClick });

  return (
    <Tooltip
      isDesktopOnly
      isOneLine
      rootClassName={styles.tooltip}
      title={TOOLTIP_TITLE}
    >
      <Button
        ref={buttonRef}
        icon={<AutoPlayIcon className={classNames(styles.icon, { active: autoplay })} />}
        onClick={handleClick}
      />
    </Tooltip>
  );
};

export default AutoplayButton;
