import { CSSProperties } from 'react';
import { SpectrogramProps, SpectrogramFrequency } from '../types';

interface Params extends Pick<SpectrogramProps, 'startFrequency' | 'endFrequency'> {
  frequency: SpectrogramFrequency['frequency'];
  top?: number | string;
}

const getFrequencyPosition = ({ frequency, startFrequency, endFrequency, top = 5 }: Params): CSSProperties => {
  const range = endFrequency - startFrequency;
  const distanceFromStart = frequency - startFrequency;
  const percentage = (distanceFromStart / range) * 100;
  return { top, left: `calc(${percentage}% - 2px)` };
};

export default getFrequencyPosition;
