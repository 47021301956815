import { FoxRecordsFilterLocalStorageParams, FoxScanningFilterLocalStorageParams } from '@/entities/source/@fox/types';
import {
  MobileView,
  MobileViewQueryParam,
  FoxRecordsQueryParam,
  FoxScanningQueryParam,
  SourceTab,
} from '@/shared/constants';
import { queryParamsService } from '@/shared/services';

interface Params {
  sourceId?: string;
  activeTab?: SourceTab;
  searchParams: URLSearchParams;
  withMobileView: boolean;
  scanningQueryParams?: Record<string, string>;
  recordsQueryParams?: Record<string, string>;
  scanningFilterList?: Record<string, FoxScanningFilterLocalStorageParams>;
  recordsFilterList?: Record<string, FoxRecordsFilterLocalStorageParams>;
  additionalQueryParams?: [string, string][];
}

const getSourceSearchParams = ({
  sourceId,
  activeTab,
  searchParams,
  withMobileView,
  scanningQueryParams,
  recordsQueryParams,
  scanningFilterList,
  recordsFilterList,
  additionalQueryParams,
}: Params) => {
  if (scanningQueryParams) {
    Object.values(scanningQueryParams).forEach((paramName) => {
      searchParams.delete(paramName);
    });
  }
  if (recordsQueryParams) {
    Object.values(recordsQueryParams).forEach((paramName) => {
      searchParams.delete(paramName);
    });
  }

  if (withMobileView) searchParams.set(MobileViewQueryParam.Mobile, MobileView.SecondaryScreen);

  let updatedQueryParams = searchParams;

  if (activeTab === SourceTab.Scanning && sourceId && scanningFilterList && scanningFilterList[sourceId]) {
    updatedQueryParams = queryParamsService.getUpdatedFilterQueryParam(
      FoxScanningQueryParam,
      scanningFilterList[sourceId],
      searchParams
    )();
  } else if (activeTab === SourceTab.Records && sourceId && recordsFilterList && recordsFilterList[sourceId]) {
    updatedQueryParams = queryParamsService.getUpdatedFilterQueryParam(
      FoxRecordsQueryParam,
      recordsFilterList[sourceId],
      searchParams
    )();
  }

  if (additionalQueryParams) {
    additionalQueryParams.forEach(([name, value]) => {
      updatedQueryParams.set(name, value);
    });
  }

  return updatedQueryParams;
};

export default getSourceSearchParams;
