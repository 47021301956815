import { Flex, Typography } from 'antd';
import classNames from 'classnames';
import FoxFrequencyCountRecords from '@/entities/source/@fox/components/ScanningTab/components/FoxFrequencyCountRecords';
import FoxFrequencyValue from '@/entities/source/@fox/components/ScanningTab/components/FoxFrequencyValue';
import SignalTypeIcon from '@/entities/source/@fox/components/SignalTypeIcon';
import SourceActivity from '@/entities/source/@fox/components/SourceActivity';
import { FoxFrequencyStatus } from '@/entities/source/@fox/constants';
import { FoxFrequency } from '@/entities/source/@fox/types';
import styles from './styles.module.css';

const PLACEHOLDER = 'немає нотатків';

type Props = {
  frequency: FoxFrequency;
  search: string;
};

const FoxFrequencyRow = ({
  frequency: { status, value, isDigital, countRecords, note, lastActiveAt },
  search,
}: Props) => {
  return (
    <div
      className={classNames(styles.row, {
        [styles.inactive]: status === FoxFrequencyStatus.Inactive,
      })}
    >
      <Flex
        gap={8}
        align="center"
      >
        <FoxFrequencyValue
          value={value}
          search={search}
        />
        <SignalTypeIcon isDigital={isDigital} />
        <FoxFrequencyCountRecords
          className={styles.small}
          countRecords={countRecords}
          frequency={value}
        />
        {note ? (
          <Typography.Text
            className={styles.small}
            type="secondary"
            ellipsis
          >
            {note}
          </Typography.Text>
        ) : (
          <Typography.Text
            className={styles.small}
            disabled
            ellipsis
          >
            {PLACEHOLDER}
          </Typography.Text>
        )}
      </Flex>
      <SourceActivity
        className={styles.small}
        activity={lastActiveAt}
      />
    </div>
  );
};

export default FoxFrequencyRow;
