import { useMemo } from 'react';
import { SpectrogramProps } from '../types';

interface Params extends Pick<SpectrogramProps, 'status'> {
  isSpectrogramDrawing: boolean;
}

const useSpectrogramStatus = ({ status, isSpectrogramDrawing }: Params) => {
  return useMemo(() => {
    if (status === 'success' && !isSpectrogramDrawing) return 'default';

    return status;
  }, [isSpectrogramDrawing, status]);
};

export default useSpectrogramStatus;
