import { Flex, Row, Col, Typography, Space } from 'antd';
import { YOUR_NICKNAME, YOUR_NUMBER, ROLE, HELP, SUPPORT } from '@/entities/user/constants';
import { SignalIcon } from '@/shared/assets';
import { NavigationDrawer } from '@/shared/components';
import { COPIES } from '@/shared/constants';
import { ThemeToggleButton, LogoutButton } from './components';
import styles from './styles.module.css';
import useUserProfileDrawer from './useUserProfileDrawer';

const UserProfileDrawer = () => {
  const { navigationDrawer, user, userPhone, userRole } = useUserProfileDrawer();

  return (
    <NavigationDrawer
      title={COPIES.PROFILE}
      open={navigationDrawer === 'profile'}
      footer={
        <Flex
          vertical
          gap={24}
        >
          <ThemeToggleButton />
          <LogoutButton data-testid="logout-button" />
        </Flex>
      }
    >
      <Flex
        vertical
        gap={24}
      >
        {user && (
          <Flex
            vertical
            gap={8}
          >
            <Row>
              <Col span={8}>
                <Typography.Text type="secondary">{YOUR_NICKNAME}</Typography.Text>
              </Col>
              <Col span={16}>
                <Typography.Text>{user.full_name}</Typography.Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Typography.Text type="secondary">{YOUR_NUMBER}</Typography.Text>
              </Col>
              <Col span={16}>
                <Typography.Text>{userPhone}</Typography.Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Typography.Text type="secondary">{ROLE}</Typography.Text>
              </Col>
              <Col span={16}>
                <Typography.Text>{userRole}</Typography.Text>
              </Col>
            </Row>
          </Flex>
        )}
        <Flex
          vertical
          gap={8}
        >
          <Typography.Text type="secondary">{HELP}</Typography.Text>
          <Typography.Text strong>{SUPPORT}</Typography.Text>
          <Space>
            <SignalIcon className={styles.signalIcon} />
            <Typography.Text>{COPIES.CONTACT_SUPPORT_PHONE}</Typography.Text>
          </Space>
        </Flex>
      </Flex>
    </NavigationDrawer>
  );
};

export default UserProfileDrawer;
