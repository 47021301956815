import { LayerSpecification } from 'maplibre-gl';
import { MapLayer, MapSource } from '@/entities/map/constants';

export const openStreetLayers: LayerSpecification[] = [
  {
    id: MapLayer.Bg,
    type: 'raster',
    source: MapSource.OpenStreet,
  },
];
