import { FoxScanningQueryParam } from '@/shared/constants';
import { useSearchParam } from '@/shared/hooks';
import useScanningFilterButtonCount from './useScanningFilterButtonCount';

const useScanningSearchField = () => {
  const count = useScanningFilterButtonCount();
  const [search, setSearch] = useSearchParam(FoxScanningQueryParam.Search);

  return {
    count,
    search,
    handleSearch: setSearch,
  };
};

export default useScanningSearchField;
