import { QueryKey, useQueryClient } from '@tanstack/react-query';

// function returned by useRefetchInfinitePage refetches infinite page
// example: const refetchInfinitePage = useRefetchInfinitePage(['some-key']); refetchInfinitePage(2)

const useRefetchInfinitePage = (queryKey: QueryKey) => {
  const queryClient = useQueryClient();

  return async (pageIndex: number) => {
    await queryClient.refetchQueries(queryKey, {
      refetchPage: (_page, index) => index === pageIndex,
    });
  };
};

export default useRefetchInfinitePage;
