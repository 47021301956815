import { STATUS_TYPES } from '@/shared/constants';
import validation from '@/shared/constants/validation.json';
import { NotificationProps } from '@/shared/context';
import { v3Service } from '@/shared/services';
import { isJsonString } from '@/shared/utils';

const handleBlobError = (obj: Blob, openNotification: (args: NotificationProps) => void) => {
  const reader = new FileReader();
  reader.onload = () => {
    if (isJsonString(reader.result)) {
      const error = {
        response: {
          data: JSON.parse(reader.result as string),
        },
      };

      v3Service.openErrorNotifications({
        error,
        validation,
        title: STATUS_TYPES.ERROR,
        openNotification,
      });
    }
  };
  reader.readAsText(obj);
};

export default handleBlobError;
