import { TableColumnsType } from 'antd';
import FoxKeyActions from '@/entities/source/@fox/components/KeysTab/components/FoxKeyActions';
import FoxKeyEncryption from '@/entities/source/@fox/components/KeysTab/components/FoxKeyEncryption';
import FoxKeyFrequency from '@/entities/source/@fox/components/KeysTab/components/FoxKeyFrequency';
import FoxKeyGroupId from '@/entities/source/@fox/components/KeysTab/components/FoxKeyGroupId';
import FoxKeyKeyId from '@/entities/source/@fox/components/KeysTab/components/FoxKeyKeyId';
import { useGetFoxKeys, useNavigateToFoxKey } from '@/entities/source/@fox/components/KeysTab/hooks';
import SourceActivity from '@/entities/source/@fox/components/SourceActivity';
import { useRow, useRowClassName } from '@/entities/source/@fox/hooks';
import { FoxKey } from '@/entities/source/@fox/types';
import { useSource, useSourceScroll } from '@/entities/source/hooks';
import { FoxSource } from '@/entities/source/types';
import { FOX_KEYS_QUERY_KEY } from '@/shared/constants';
import { useScrollUpToReFetch, useReFetchInfiniteListOnTop, useParams } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';
import styles from '../styles.module.css';
import useAutoNavigateToFirstFoxKey from './useAutoNavigateToFirstFoxKey';
import useRedirectToFoxKeyNotFound from './useRedirectToFoxKeyNotFound';

enum ColumnTitle {
  KeyId = 'key id',
  GroupId = 'group id',
  Frequency = 'частота',
  Type = 'тип',
  Activity = 'активність',
}

const useFoxKeysTable = () => {
  const source = useSource<FoxSource>();
  const isSourceOnline = source?.status === 'online';
  const { tabEntityId } = useParams<SourceRouteParams>();
  const { foxKeysList, isFoxKeysLoading, getFoxKeyRef } = useGetFoxKeys({
    options: {
      enabled: isSourceOnline,
    },
  });
  const { handleScrollUpToReFetch, isReFetchingByScrollUp } = useScrollUpToReFetch([FOX_KEYS_QUERY_KEY, source?.id]);
  const { handleScroll, scrollTopPosition } = useSourceScroll();

  const navigateToFoxKey = useNavigateToFoxKey();
  const handleRow = useRow<FoxKey>(navigateToFoxKey);
  const rowClassName = useRowClassName<FoxKey>();

  useReFetchInfiniteListOnTop({
    queryKey: [FOX_KEYS_QUERY_KEY, source?.id],
    scrollTopPosition,
    isRefetchEnabled: !isReFetchingByScrollUp,
  });

  useAutoNavigateToFirstFoxKey();
  useRedirectToFoxKeyNotFound({ list: foxKeysList, listItemPropertyName: 'id', compareValue: tabEntityId });

  const columns: TableColumnsType<FoxKey> = [
    {
      dataIndex: 'keyId',
      title: ColumnTitle.KeyId,
      width: '15%',
      className: styles.column,
      render: (_, foxKey: FoxKey, index) => (
        <div ref={getFoxKeyRef(index)}>
          <FoxKeyKeyId keyId={foxKey.keyId} />
        </div>
      ),
    },
    {
      dataIndex: 'groupId',
      title: ColumnTitle.GroupId,
      width: '15%',
      className: styles.column,
      render: (_, foxKey: FoxKey) => <FoxKeyGroupId groupId={foxKey.groupId} />,
    },
    {
      dataIndex: 'sourceFrequency',
      title: ColumnTitle.Frequency,
      width: '15%',
      className: styles.column,
      render: (_, foxKey: FoxKey) => <FoxKeyFrequency frequency={foxKey.targetFrequency?.value} />,
    },
    {
      dataIndex: 'encryption',
      title: ColumnTitle.Type,
      width: '25%',
      className: styles.column,
      render: (_, foxKey: FoxKey) => <FoxKeyEncryption encryption={foxKey.encryption} />,
    },
    {
      dataIndex: 'lastActiveAt',
      title: ColumnTitle.Activity,
      width: '25%',
      className: styles.column,
      render: (_, foxKey: FoxKey) => <SourceActivity activity={foxKey.lastActiveAt} />,
    },
    {
      dataIndex: 'id',
      width: 64,
      className: styles.actionsColumn,
      render: (_, foxKey: FoxKey) => <FoxKeyActions keyId={foxKey.id} />,
    },
  ];

  return {
    columns,
    foxKeysList,
    isFoxKeysLoading,
    isSourceOnline,
    handleScrollUpToReFetch,
    handleScroll,
    handleRow,
    rowClassName,
  };
};

export default useFoxKeysTable;
