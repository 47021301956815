import { useMutation, MutateOptions } from '@tanstack/react-query';
import {
  deleteFoxFrequencies as deleteFoxFrequenciesApi,
  DeleteFoxFrequenciesParams,
} from '@/entities/source/@fox/api';
import { DELETE_FOX_FREQUENCIES_MUTATION_KEY } from '@/shared/constants';

const useDeleteFoxFrequencies = (options: MutateOptions<unknown, unknown, DeleteFoxFrequenciesParams> = {}) => {
  const { mutateAsync: deleteFoxFrequenciesAsync } = useMutation({
    ...options,
    mutationKey: [DELETE_FOX_FREQUENCIES_MUTATION_KEY],
    mutationFn: deleteFoxFrequenciesApi,
  });

  return {
    deleteFoxFrequenciesAsync,
  };
};

export default useDeleteFoxFrequencies;
