import { UseFoxFrequencyActionsParams } from '../types';
import useDangerItems from './useDangerItems';
import useItems from './useItems';

const useFoxFrequencyActions = ({ foxFrequencyId, foxFrequencyStatus, onDelete }: UseFoxFrequencyActionsParams) => {
  const items = useItems({ foxFrequencyId, foxFrequencyStatus });
  const dangerItems = useDangerItems({ foxFrequencyId, onDelete });

  return {
    items,
    dangerItems,
  };
};

export default useFoxFrequencyActions;
