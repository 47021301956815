import { Form, SelectProps } from 'antd';
import { CATEGORY } from '@/entities/category/constants';
import { CategoryId } from '@/entities/category/types';
import { Select } from '@/shared/components';

type Props = Pick<SelectProps, 'options' | 'placeholder' | 'disabled'> & {
  value: CategoryId[];
  onChange: (value: CategoryId[]) => void;
  isLoading?: boolean;
};

export const CategoryFormMultiSelect = ({
  options,
  isLoading = false,
  value,
  onChange,
  disabled = false,
  placeholder,
}: Props) => (
  <Form.Item label={CATEGORY}>
    <Select
      mode="multiple"
      options={options}
      optionFilterProp="categoryName"
      value={value}
      onChange={onChange}
      loading={isLoading}
      disabled={disabled}
      placeholder={placeholder}
    />
  </Form.Item>
);
