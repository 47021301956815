import { Button, Typography } from 'antd';
import { Tag, LocationIcon } from '@/shared/components';
import { LatLngCoordinates } from '@/shared/types';
import styles from './styles.module.css';

type Props = {
  coordinates: LatLngCoordinates;
  onClick: (coordinates: LatLngCoordinates) => void;
};

const CoordinatesTag = ({ coordinates, onClick }: Props) => {
  const handleClick = () => onClick(coordinates);

  return (
    <Button
      className={styles.button}
      type="link"
      onClick={handleClick}
    >
      <Tag
        className={styles.tag}
        icon={<LocationIcon />}
      >
        <Typography.Text>
          {coordinates.lat}, {coordinates.lng}
        </Typography.Text>
      </Tag>
    </Button>
  );
};

export default CoordinatesTag;
