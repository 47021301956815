import { Typography } from 'antd';
import styles from './styles.module.css';

const NO_FREQUENCY = 'Немає частоти';

interface Props {
  frequency?: string | null;
}

const FoxKeyFrequency = ({ frequency }: Props) => {
  const tooltipTitle = frequency ?? NO_FREQUENCY;

  return (
    <Typography.Text ellipsis={{ tooltip: tooltipTitle }}>
      {frequency ?? (
        <Typography.Text
          className={styles.noFrequency}
          type="secondary"
        >
          {NO_FREQUENCY}
        </Typography.Text>
      )}
    </Typography.Text>
  );
};

export default FoxKeyFrequency;
