export const CATEGORY = 'Категорія';
export const CATEGORIES = 'Категорії';
export const CATEGORIES_PLACEHOLDER = 'Всі категорії';
export const SUBCATEGORY_NAME = 'Назва';
export const CATEGORY_NAME_REQUIRED = "Назва категорії обов'язкова";
export const CATEGORY_NAME_MIN_SIZE = 'Назва категорія має бути не менше 3 символів';
export const SELECT_CATEGORY = 'Виберіть категорію';
export const PARENT_CATEGORY = 'Батьківська категорія';

export enum TagQueryParam {
  SearchTag = 'searchTag',
}
