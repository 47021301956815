import {
  FrequenciesSelectedBoundaries,
  SpectrogramFrequency,
} from '@/entities/source/@fox/components/ScanningTab/components/Spectrogram/types';

interface Params {
  frequenciesSelectedBoundaries: FrequenciesSelectedBoundaries;
  spectrogramFrequencies: SpectrogramFrequency[];
}

const getSelectedFrequenciesIds = ({ frequenciesSelectedBoundaries, spectrogramFrequencies }: Params) => {
  return spectrogramFrequencies
    .filter(
      ({ frequency }) =>
        frequency >= frequenciesSelectedBoundaries.startSelectedFrequency &&
        frequency <= frequenciesSelectedBoundaries.endSelectedFrequency
    )
    .map(({ id }) => id);
};

export default getSelectedFrequenciesIds;
