import { ReactNode, useEffect, useRef, useState } from 'react';
import { useElementSize } from '@reactuses/core';
import styles from './styles.module.css';

type Props = {
  items: ReactNode[];
  counterMinWidth?: number;
  overflowCounterRender: (hiddenItemsCount: number) => ReactNode;
};

export const OverflowList = ({ items, counterMinWidth = 75, overflowCounterRender }: Props) => {
  const hiddenListRef = useRef<HTMLDivElement>(null);

  const [visibleItemsCount, setVisibleItemsCount] = useState(0);

  const [containerWidth] = useElementSize(hiddenListRef, { box: 'border-box' });

  const hiddenItemsCount = items.length - visibleItemsCount;

  const isCounterVisible = (visibleItemsCount > 0 || items.length > 0) && hiddenItemsCount > 0;

  const visibleItems = items.slice(0, visibleItemsCount);

  useEffect(() => {
    let visibleItemsAmount = 0;
    let childrenWidthSum = counterMinWidth;

    const children = Array.from(hiddenListRef.current?.children || []);

    children.every((child) => {
      childrenWidthSum += child.clientWidth;
      if (childrenWidthSum > containerWidth) return false;
      visibleItemsAmount += 1;
      return true;
    });
    setVisibleItemsCount(visibleItemsAmount);
  }, [containerWidth, items]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.list}>
        {visibleItems}
        {isCounterVisible && <div>{overflowCounterRender(hiddenItemsCount)}</div>}
      </div>
      <div
        ref={hiddenListRef}
        className={styles.hiddenList}
      >
        {items}
      </div>
    </div>
  );
};
