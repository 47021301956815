import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/shared/types';
import { TranscriptListScrollState } from './types';

const initialState: TranscriptListScrollState = {
  isOnTopOfTranscriptList: true,
  scrollToTranscriptId: '',
};

export const transcriptListScrollSlice = createSlice({
  name: 'transcriptListScroll',
  initialState,
  reducers: {
    setIsOnTopOfTranscriptList: (state: TranscriptListScrollState, action: PayloadAction<boolean>) => {
      state.isOnTopOfTranscriptList = action.payload;
    },
    setScrollToTranscriptId: (
      state: TranscriptListScrollState,
      action: PayloadAction<TranscriptListScrollState['scrollToTranscriptId']>
    ) => {
      state.scrollToTranscriptId = action.payload;
    },
  },
});

export const { setIsOnTopOfTranscriptList, setScrollToTranscriptId } = transcriptListScrollSlice.actions;

export const selectIsOnTopOfTranscriptList = (state: RootState) => state.transcriptListScroll.isOnTopOfTranscriptList;

export const selectScrollToTranscriptId = (state: RootState) => state.transcriptListScroll.scrollToTranscriptId;

export default transcriptListScrollSlice.reducer;
