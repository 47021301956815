import { forwardRef, useEffect, useState } from 'react';
import { TextArea, TextAreaProps, TextAreaRef } from '@/shared/components';

const CallSignTextArea = forwardRef<TextAreaRef, TextAreaProps>(
  ({ autoSize = true, isTransparentOnDesktop = true, defaultValue, ...restProps }, ref) => {
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
      setValue(defaultValue);
    }, [defaultValue]);

    return (
      <TextArea
        ref={ref}
        autoSize={autoSize}
        isTransparentOnDesktop={isTransparentOnDesktop}
        value={value}
        defaultValue={defaultValue}
        onChange={(e) => setValue(e.target.value)}
        {...restProps}
      />
    );
  }
);

CallSignTextArea.displayName = 'CallSignTextArea';

export default CallSignTextArea;
