import { Typography } from 'antd';
import classNames from 'classnames';
import { DetailsItem } from '@/entities/source/@fox/types';
import { Empty } from '@/shared/components';
import { DetailItem } from './components';
import styles from './styles.module.css';

const { Title } = Typography;

const TITLE = 'Деталі';
const NO_DETAILS = 'Деталі відсутні';

type Props = {
  details?: Record<string, Pick<DetailsItem, 'value' | 'icon' | 'canBeCopied'>>;
  wrapperClassname?: string;
  titleClassName?: string;
  withTitle?: boolean;
};

const Details = ({ details, titleClassName, wrapperClassname, withTitle = true }: Props) => {
  if (!details) {
    return (
      <Empty
        description={NO_DETAILS}
        className={styles.noData}
      />
    );
  }

  return (
    <div className={classNames(styles.detailsWrapper, wrapperClassname)}>
      {withTitle && <Title className={classNames(styles.title, titleClassName)}>{TITLE}</Title>}
      {Object.entries(details).map(([key, { value, icon, canBeCopied }]) => (
        <DetailItem
          key={key}
          name={key}
          value={value}
          icon={icon}
          canBeCopied={canBeCopied}
        />
      ))}
    </div>
  );
};

export default Details;
