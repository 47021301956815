import { MapLayer, MarkerType } from '@/entities/map/constants';

const getMarkerLayerPrefixes = (type: MarkerType) => {
  const markerLayer = type === MarkerType.Cluster ? MapLayer.Cluster : MapLayer.Point;
  const markerInnerLayer = type === MarkerType.Cluster ? MapLayer.ClusterInner : MapLayer.PointInner;
  const markerCountLayer = type === MarkerType.Cluster ? MapLayer.ClusterCount : MapLayer.PointCount;

  return {
    markerLayer,
    markerInnerLayer,
    markerCountLayer,
  };
};

export default getMarkerLayerPrefixes;
