import pick from 'lodash/pick';
import { FactionKey } from '@/entities/faction/constants';
import { FactionModalFormData, FactionModalType } from '@/entities/faction/types';
import { Faction } from '@/shared/types';

export const DEFAULT_VALUES: FactionModalFormData = {
  [FactionKey.ParentId]: null,
  [FactionKey.LevelId]: null,
  [FactionKey.TypeId]: null,
  [FactionKey.ShortName]: '',
  [FactionKey.ShortNameRU]: null,
  [FactionKey.Name]: '',
  [FactionKey.NameRU]: null,
  [FactionKey.MilitaryBase]: null,
  [FactionKey.RegistryNumber]: null,
};

const getInitialValues = (modalType: FactionModalType | null, faction?: Faction) => {
  if (modalType === 'edit-faction' && faction) {
    return pick(faction, Object.keys(DEFAULT_VALUES));
  }
  if (modalType === 'create-nested-faction' && faction) {
    return { ...DEFAULT_VALUES, [FactionKey.ParentId]: faction.id };
  }

  return DEFAULT_VALUES;
};

export default getInitialValues;
