import { Dispatch, SetStateAction, useEffect } from 'react';
import { InfiniteData } from '@tanstack/react-query';
import { PaginatedTranscripts } from '@/entities/transcript/api/types';
import { useUpdateFoffset } from '@/shared/hooks';
import { reactQueryHelperService } from '@/shared/services';

interface Params {
  data?: InfiniteData<Omit<PaginatedTranscripts, 'foffset'> & { foffset?: string }>;
  setFoffset: Dispatch<SetStateAction<string | null>>;
  setClusterExcluded: Dispatch<SetStateAction<string[] | undefined>>;
  setLastItemTimestamp: Dispatch<SetStateAction<number | null | undefined>>;
  setScore: Dispatch<SetStateAction<number | null | undefined>>;
}

const useUpdateExtraParams = ({ data, setFoffset, setClusterExcluded, setLastItemTimestamp, setScore }: Params) => {
  useUpdateFoffset({ data, setFoffset });

  useEffect(() => {
    if (data?.pages && data.pages.length > 0) {
      const lastPage = data.pages[data.pages.length - 1];
      if (reactQueryHelperService.hasPaginatedParam(lastPage, 'clusterIncluded')) {
        setClusterExcluded(lastPage.clusterIncluded);
      }
      if (reactQueryHelperService.hasPaginatedParam(lastPage, 'lastItemTimestamp')) {
        setLastItemTimestamp(lastPage.lastItemTimestamp);
      }
      if (reactQueryHelperService.hasPaginatedParam(lastPage, 'score')) {
        setScore(lastPage.score);
      }
    }
  }, [data?.pages, setClusterExcluded, setLastItemTimestamp, setScore]);
};

export default useUpdateExtraParams;
