import { LoadingWrapper, ScrollUpToRefresh, Table } from '@/shared/components';
import { EditUnitUserModal } from './components';
import { useUnitUsersTab } from './hooks';

const UnitUsersTab = () => {
  const { usersList, columns, isLoading, handleScrollUpToReFetch, editUnitUserId, handleCloseModal } =
    useUnitUsersTab();

  return (
    <LoadingWrapper
      isLoading={isLoading}
      isFixedOverlay
    >
      <ScrollUpToRefresh
        isScrollUpToRefreshEnabled={usersList.length > 0}
        onRefresh={handleScrollUpToReFetch}
      >
        <Table
          rowKey="id"
          columns={columns}
          dataSource={usersList}
        />
      </ScrollUpToRefresh>
      {editUnitUserId && (
        <EditUnitUserModal
          userId={editUnitUserId}
          onClose={handleCloseModal}
        />
      )}
    </LoadingWrapper>
  );
};

export default UnitUsersTab;
