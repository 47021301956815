import { Flex } from 'antd';
import {
  FOX_SIDEBAR_DEFAULT_WIDTH,
  FOX_SIDEBAR_MAX_WIDTH,
  FOX_SIDEBAR_MIN_WIDTH,
} from '@/entities/source/@fox/constants';
import { useFiltersDrawerManager } from '@/entities/source/hooks';
import { LayoutTwoColumns } from '@/shared/components';
import { useIsMobile } from '@/shared/hooks';
import Details from '../Details';
import ToggleDetailsButton from '../ToggleDetailsButton';
import { RecordsTable, AudioPlayer, MobileRecordsList, FiltersDrawer, RecordsSearchField } from './components';
import { useRecordDetails, useAudioPlayer } from './hooks';
import styles from './styles.module.css';

const SOURCE_RECORDS_SIDEBAR_STORAGE_KEY = 'source-records';

const RecordsTab = () => {
  const details = useRecordDetails();
  const isMobile = useIsMobile();
  const {
    file,
    record,
    isRecordSelected,
    isFetching,
    isPlaying,
    interceptedAt,
    settings,
    memoOnPlayPause,
    memoSaveSettings,
    memoPlayNextRecord,
    memoNavigateToPreviousRecord,
    memoNavigateToNextRecord,
  } = useAudioPlayer();
  const { isFiltersOpen, handleOpenFilters, handleCloseFilters } = useFiltersDrawerManager();

  return (
    <>
      <Flex
        className={styles.recordsTab}
        vertical
      >
        <LayoutTwoColumns
          isSidebarRight
          sidebarMinWidth={FOX_SIDEBAR_MIN_WIDTH}
          sidebarDefaultWidth={FOX_SIDEBAR_DEFAULT_WIDTH}
          sidebarMaxWidth={FOX_SIDEBAR_MAX_WIDTH}
          storageKey={SOURCE_RECORDS_SIDEBAR_STORAGE_KEY}
          panel={<Details details={details} />}
          isMobileViewCollapsed
        >
          {({ isSidebarVisible, toggleSidebarVisibility }) =>
            isMobile ? (
              <>
                <RecordsSearchField onFilterClick={handleOpenFilters} />
                <MobileRecordsList />
              </>
            ) : (
              <>
                <RecordsSearchField
                  onFilterClick={handleOpenFilters}
                  additionalControlsRight={
                    <ToggleDetailsButton
                      isActive={isSidebarVisible}
                      onClick={toggleSidebarVisibility}
                    />
                  }
                />
                <RecordsTable />
              </>
            )
          }
        </LayoutTwoColumns>
        <AudioPlayer
          record={record}
          isRecordSelected={isRecordSelected}
          recordHasVoice={file?.metadata.hasVoice}
          memoSettings={settings}
          isLoading={isFetching}
          shouldBePlaying={isPlaying}
          memoOnPlayPause={memoOnPlayPause}
          memoOnSettingsUpdate={memoSaveSettings}
          memoPlayNextRecord={memoPlayNextRecord}
          memoNavigateToPreviousRecord={memoNavigateToPreviousRecord}
          memoNavigateToNextRecord={memoNavigateToNextRecord}
          recordTimestamp={interceptedAt}
        />
      </Flex>
      <FiltersDrawer
        isOpen={isFiltersOpen}
        onClose={handleCloseFilters}
      />
    </>
  );
};

export default RecordsTab;
