import { PropsWithChildren } from 'react';
import { useAppSelector } from '@/shared/hooks';
import { selectCurrentUser } from '@/shared/slices';
import { useStorageUpdateOnUserGroupChanged } from './hooks';

export const NetworkFiltersStorageWrapper = ({ children }: PropsWithChildren) => {
  const currentUser = useAppSelector(selectCurrentUser);

  useStorageUpdateOnUserGroupChanged(currentUser?.group.id);

  return children;
};
