import { EffectCallback, DependencyList, useEffect } from 'react';
import { useMapContext } from '@/entities/map/context';

/**
 * Use this hook every time when you use a map inside the effect callback.
 * It can help to avoid application crash when map.style is not ready yet after the map re-creation.
 * (situation: return back to the browser tab with the map)
 */
const useMapEffect = (effect: EffectCallback, deps: DependencyList = []) => {
  const { map } = useMapContext();

  useEffect(() => {
    if (!map.getStyle()) return;
    return effect();
  }, [map, ...deps]);
};

export default useMapEffect;
