import { useMemo } from 'react';
import { TabsProps } from 'antd';
import { DEFAULT_CLUSTER_TAB } from '@/entities/transcript/constants';
import { selectActiveClusterTabs, addActiveClusterTabs } from '@/entities/transcript/slices';
import { TranscriptSourcesProps } from '@/entities/transcript/types';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Tab } from '@/shared/types';
import TranscriptSource from '../components/TranscriptSource';
import styles from '../styles.module.css';

const ALL_ITEM: Tab = {
  key: 'all',
  label: 'ВСІ',
};

interface Params extends TranscriptSourcesProps {}

const useTabs = ({ clusterId, clusterMetadata, transcript }: Params) => {
  const activeTab = useAppSelector(selectActiveClusterTabs)[clusterId] ?? DEFAULT_CLUSTER_TAB;
  const dispatch = useAppDispatch();

  const tabs: Tab[] = useMemo(() => {
    const sources = clusterMetadata?.sources ?? [];
    const isNetwork = Boolean(transcript.networkId);

    const sourcesTabs = sources.map(({ transcriptId, creator_name, id, name, type }) => ({
      key: transcriptId,
      label: (
        <TranscriptSource
          className={styles.sourceName}
          creatorName={creator_name}
          sourceId={id}
          sourceName={name}
          sourceType={type}
          isNetwork={isNetwork}
          isCluster
        />
      ),
    }));

    return [ALL_ITEM, ...sourcesTabs];
  }, [clusterMetadata, transcript]);

  const handleTabChange: TabsProps['onChange'] = (key) => {
    dispatch(addActiveClusterTabs({ [clusterId]: key }));
  };

  return {
    tabs,
    activeTab,
    handleTabChange,
  };
};

export default useTabs;
