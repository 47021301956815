import { memo } from 'react';
import {
  SelectedFrequenciesDictionary,
  SpectrogramFrequency,
  SpectrogramProps,
} from '@/entities/source/@fox/components/ScanningTab/components/Spectrogram/types';
import Frequency from '../Frequency';

interface Props
  extends Pick<SpectrogramProps, 'memoOnEditFrequency' | 'memoOnDeleteFrequency' | 'memoOnFrequencyClick'> {
  memoFrequencies: SpectrogramFrequency[];
  startFrequency: number;
  endFrequency: number;
  frequencyHeight: number;
  maxLeftPosition: number;
  maxRightPosition: number;
  memoSelectedFrequenciesDictionary?: SelectedFrequenciesDictionary;
}

const Frequencies = memo(
  ({
    memoFrequencies,
    startFrequency,
    endFrequency,
    frequencyHeight,
    memoOnEditFrequency,
    memoOnDeleteFrequency,
    memoOnFrequencyClick,
    maxLeftPosition,
    maxRightPosition,
    memoSelectedFrequenciesDictionary,
  }: Props) => {
    return memoFrequencies.map(({ frequency, id, isActive, isDigital }) => (
      <Frequency
        key={`${startFrequency}-${endFrequency}-${id}`}
        id={id}
        isDisabled
        isDigital={isDigital}
        isFrequencySelected={memoSelectedFrequenciesDictionary?.[id]}
        frequency={frequency}
        isActive={isActive}
        startFrequency={startFrequency}
        endFrequency={endFrequency}
        frequencyHeight={frequencyHeight}
        memoOnSave={memoOnEditFrequency}
        memoOnDeleteFrequency={memoOnDeleteFrequency}
        maxLeftPosition={maxLeftPosition}
        maxRightPosition={maxRightPosition}
        onFrequencyClick={memoOnFrequencyClick}
      />
    ));
  }
);

Frequencies.displayName = 'Frequencies';

export default Frequencies;
