import {
  Category,
  RecommendedCategory,
  Tag,
  CategoryCreatePayload,
  CategoryUpdatePayload,
} from '@/entities/category/types';
import { Paginated, request, ResponseData } from '@/shared/api';
import { DEFAULT_PAGE_SIZE } from '@/shared/constants';
import {
  getCategoriesSchema,
  getRecommendedCategoriesSchema,
  createCategorySchema,
  updateCategorySchema,
  getCategoryTagsSchema,
} from './validationSchemas';

export const getCategories = () =>
  request<ResponseData<Category[]>>('/categories', {
    validationSchema: getCategoriesSchema,
  });

interface GetRecommendedCategoriesParams {
  transcriptId: string;
}

export const getRecommendedCategories = ({ transcriptId }: GetRecommendedCategoriesParams) =>
  request<ResponseData<RecommendedCategory[]>>(`/transcripts/${transcriptId}/recommended-categories`, {
    validationSchema: getRecommendedCategoriesSchema,
  });

export type CreateCategoryParams = { name: string; parentId?: number };
export const createCategory = ({ name, parentId }: CreateCategoryParams) =>
  request<ResponseData<CategoryCreatePayload>>('/categories', {
    method: 'POST',
    body: JSON.stringify(parentId ? { name, parent_id: parentId } : { name }),
    validationSchema: createCategorySchema,
  });

export type UpdateCategoryParams = { id: number; name: string };
export const updateCategory = ({ id, name }: UpdateCategoryParams) =>
  request<ResponseData<CategoryUpdatePayload>>(`/categories/${id}`, {
    method: 'PATCH',
    body: JSON.stringify({ name }),
    validationSchema: updateCategorySchema,
  });

export type DeleteCategoryParams = { id: number };
export const deleteCategory = ({ id }: DeleteCategoryParams) =>
  request<Category>(`/categories/${id}`, {
    method: 'DELETE',
  });

export const getCategoryTags = ({ id, page, search }: { id: number; page: number; search: string }) => {
  const params = new URLSearchParams();

  params.set('page', String(page));
  params.set('size', DEFAULT_PAGE_SIZE);

  if (search) {
    params.set('s', search.trim().toLowerCase());
  }

  return request<Paginated<Tag>>(`/categories/${id}/tags?${params.toString()}`, {
    validationSchema: getCategoryTagsSchema,
  });
};

export const deleteCategoryTag = ({ tagId }: { tagId: string }) =>
  request(`/tags/${tagId}`, {
    method: 'DELETE',
  });
