import { Checkbox } from 'antd';
import classNames from 'classnames';
import { Tooltip } from '@/shared/components';
import styles from './styles.module.css';

const TO_SKIP_SILENCE = 'Пропускати тишу';
const SKIPPING_SILENCE = 'Пропуск тиші';
const DISABLE_SKIP_SILENCE_HOTKEY = 'Вимкнути пропуск тиші (S)';
const ENABLE_SKIP_SILENCE_HOTKEY = 'Активувати пропуск тиші (S)';

interface Props {
  onChange: () => void;
  isSkipSilenceEnabled: boolean;
}

const SilenceSkip = ({ isSkipSilenceEnabled, onChange }: Props) => {
  return (
    <Tooltip
      isOneLine
      isDesktopOnly
      title={isSkipSilenceEnabled ? DISABLE_SKIP_SILENCE_HOTKEY : ENABLE_SKIP_SILENCE_HOTKEY}
    >
      {/* need to wrap Checkbox with plain DOM element to avoid findDOMNode warning caused by antd component */}
      <div>
        <Checkbox
          checked={isSkipSilenceEnabled}
          onChange={onChange}
          className={classNames(styles.checkbox, {
            [styles.checkboxHidden]: !isSkipSilenceEnabled,
          })}
        >
          {isSkipSilenceEnabled ? SKIPPING_SILENCE : TO_SKIP_SILENCE}
        </Checkbox>
      </div>
    </Tooltip>
  );
};

export default SilenceSkip;
