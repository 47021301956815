import { MouseEvent, PropsWithChildren } from 'react';
import { Typography } from 'antd';
import classNames from 'classnames';
import styles from './styles.module.css';

type Props = {
  className?: string;
  href?: string;
  onClick?: VoidFunction;
  isBlock?: boolean;
  hasStopPropagation?: boolean;
};

const RouteLink = ({
  className,
  href,
  onClick,
  isBlock = false,
  hasStopPropagation = true,
  children,
}: PropsWithChildren<Props>) => {
  const handleClick = (e: MouseEvent) => {
    if (typeof onClick === 'undefined' || e.ctrlKey || e.metaKey) return;

    e.preventDefault();
    if (hasStopPropagation) {
      e.stopPropagation();
    }
    onClick();
  };

  return (
    <Typography.Link
      className={classNames(
        styles.routeLink,
        {
          [styles.routeLinkBlock]: isBlock,
        },
        className
      )}
      href={href}
      onClick={handleClick}
    >
      {children}
    </Typography.Link>
  );
};

export default RouteLink;
