import { CreateFoxKeyPayload, DeleteFoxKeyParams, FoxKey } from '@/entities/source/@fox/types';
import { PaginatedWithFoffset, request, RequestQueryParams, ResponseData } from '@/shared/api';
import { V3_PREFIX } from '@/shared/config';
import { DEFAULT_PAGE_SIZE } from '@/shared/constants';
import { createFoxKeySchema, getFoxKeysSchema } from './validationSchemas';

export const getFoxKeys = ({ sourceId, queryParams }: { sourceId: string; queryParams: RequestQueryParams }) => {
  return request<PaginatedWithFoffset<FoxKey>>(`/sources/fox/${sourceId}/keys`, {
    endpointPrefix: V3_PREFIX,
    validationSchema: getFoxKeysSchema,
    params: {
      size: DEFAULT_PAGE_SIZE,
      ...queryParams,
    },
  });
};

export const createFoxKey = ({ sourceId, payload }: { sourceId: string; payload: CreateFoxKeyPayload }) => {
  return request<ResponseData<FoxKey>>(`/sources/fox/${sourceId}/keys`, {
    method: 'POST',
    body: JSON.stringify(payload),
    endpointPrefix: V3_PREFIX,
    validationSchema: createFoxKeySchema,
  });
};

export const deleteFoxKey = ({ sourceId, foxKeyId }: DeleteFoxKeyParams) => {
  return request(`/sources/fox/${sourceId}/keys/${foxKeyId}`, {
    method: 'DELETE',
    endpointPrefix: V3_PREFIX,
  });
};
