import { CSSProperties } from 'react';
import { VerticalDashedElementProps } from '../types';

interface Params
  extends Pick<
    VerticalDashedElementProps,
    | 'cursorPosition'
    | 'cursorDownXPosition'
    | 'canvasWidth'
    | 'isElementExpandable'
    | 'spectrumPercent'
    | 'isMouseDownOnWaterfall'
  > {}

const getDashedElementStyle = ({
  cursorPosition,
  cursorDownXPosition,
  canvasWidth,
  isElementExpandable,
  isMouseDownOnWaterfall,
  spectrumPercent,
}: Params): CSSProperties => {
  if (!cursorPosition) {
    return { display: 'none' };
  }
  if (!cursorDownXPosition || !isElementExpandable) {
    return { left: cursorPosition, top: 0, bottom: 0 };
  }
  return {
    left: cursorPosition < cursorDownXPosition ? cursorPosition : cursorDownXPosition,
    right: cursorPosition < cursorDownXPosition ? canvasWidth - cursorDownXPosition : canvasWidth - cursorPosition,
    top: isMouseDownOnWaterfall ? `calc(${spectrumPercent}% + 45px)` : 0,
    bottom: isMouseDownOnWaterfall ? 0 : `calc(${100 - spectrumPercent}% - 45px)`,
  };
};

export default getDashedElementStyle;
