import { useEffect, useState } from 'react';
import { useDebounce } from '@reactuses/core';
import { DEBOUNCE_DEFAULT, DEFAULT_FETCH_INTERVAL } from '@/shared/constants';

const useRefetchInterval = (shouldAutoRefetch = false) => {
  const [refetchInterval, setRefetchInterval] = useState<number | false>(DEFAULT_FETCH_INTERVAL);
  const debouncedRefetchInterval = useDebounce(refetchInterval, DEBOUNCE_DEFAULT, { leading: true });

  useEffect(() => setRefetchInterval(shouldAutoRefetch ? DEFAULT_FETCH_INTERVAL : false), [shouldAutoRefetch]);

  return {
    refetchInterval: debouncedRefetchInterval,
    setRefetchInterval,
  };
};

export default useRefetchInterval;
