import { Row, Switch, Typography, Form } from 'antd';
import Icon from '@ant-design/icons';
import classNames from 'classnames';
import { AnalogFrequency, DigitalFrequency } from '@/shared/assets';
import { useIsDigitalToggle } from './hooks';
import styles from './styles.module.css';

interface Props {
  formItemName?: string;
}

const IsDigitalToggle = ({ formItemName }: Props) => {
  const { isDigital, label, handleSwitchClick } = useIsDigitalToggle();

  const switchElement = (
    <Switch
      checkedChildren={<Icon component={DigitalFrequency} />}
      unCheckedChildren={<Icon component={AnalogFrequency} />}
      defaultChecked
      onClick={handleSwitchClick}
      className={classNames({
        [styles.analogSwitch]: !isDigital,
      })}
    />
  );

  return (
    <Row className={styles.wrapper}>
      {formItemName ? (
        <Form.Item
          name={formItemName}
          valuePropName="checked"
        >
          {switchElement}
        </Form.Item>
      ) : (
        switchElement
      )}
      <Typography.Text
        type="secondary"
        className={styles.label}
      >
        {label}
      </Typography.Text>
    </Row>
  );
};

export default IsDigitalToggle;
