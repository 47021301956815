import { Checkbox, Row } from 'antd';
import { COPIES } from '@/shared/constants';
import Title from '../Title';
import { useSignalType } from './hooks';
import styles from './styles.module.css';

const SignalType = () => {
  const {
    isDigitalSignalSelected,
    toggleIsDigitalSignalSelected,
    isAnalogSignalSelected,
    toggleIsAnalogSignalSelected,
  } = useSignalType();
  return (
    <Row>
      <Title value={COPIES.BY_FREQUENCY_TYPE} />
      <Checkbox
        className={styles.checkbox}
        checked={isAnalogSignalSelected}
        onChange={toggleIsAnalogSignalSelected}
      >
        {COPIES.ANALOG}
      </Checkbox>
      <Checkbox
        className={styles.checkbox}
        checked={isDigitalSignalSelected}
        onChange={toggleIsDigitalSignalSelected}
      >
        {COPIES.DIGITAL}
      </Checkbox>
    </Row>
  );
};

export default SignalType;
