export const MAP_COLORS = {
  base: '#fff',
  primary: '#ff4d4f',
  location: '#a5b8ca',
  locationCounterText: 'rgba(0, 0, 0, 0.85)',
  clusterPolygon: '#627bc1',
  watermark: 'rgba(139, 139, 139, 0.5)',
  hexagonUnselectedBg: 'rgba(217, 217, 217, 0.1)',
  hexagonHoveredBg: 'rgba(255, 77, 79, 0.25)',
  hexagonSelectedBg: 'rgba(255, 77, 79, 0.5)',
  hexagonBorder: 'rgba(0, 0, 0, 0.2)',
};
