import { memo } from 'react';
import { Tag, Flex } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { SpectrogramProps } from '@/entities/source/@fox/components/ScanningTab/components/Spectrogram/types';
import styles from './styles.module.css';

const STATUS_TEXT = {
  default: 'очікування даних з пристрою',
  success: 'підключено',
  processing: 'підключення',
  error: 'не вдалось підключитись',
  warning: 'відключено',
};

interface Props extends Pick<SpectrogramProps, 'status'> {}

const Status = memo(({ status }: Props) => {
  const tagIcon = status === 'processing' ? <SyncOutlined spin /> : null;
  const tagText = STATUS_TEXT[status];

  return (
    <Flex
      className={classNames(styles.tagWrapper, {
        [styles.hidden]: status === 'success',
      })}
      justify="center"
    >
      <Tag
        color={status}
        icon={tagIcon}
      >
        {tagText}
      </Tag>
    </Flex>
  );
});

Status.displayName = 'Status';

export default Status;
