import { useCallback } from 'react';
import classNames from 'classnames';
import type { RowClassName } from 'rc-table/lib/interface';
import { useParams } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';
import styles from '../styles.module.css';
import { TableRow } from '../types';

const useRowClassName = <T extends TableRow>() => {
  const { tabEntityId } = useParams<SourceRouteParams>();

  const rowClassName: RowClassName<T> = useCallback(
    ({ id }) => {
      if (id === tabEntityId) return classNames(styles.row, styles.activeRow);
      return styles.row;
    },
    [tabEntityId]
  );

  return rowClassName;
};

export default useRowClassName;
