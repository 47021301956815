import { Category } from '@/entities/category/types';

const getFirstAvailableCategories = (categories: Category[]) => {
  const parentWithSubcategories = categories.find(({ subCategories }) => subCategories && subCategories.length);

  if (parentWithSubcategories) {
    const { id: parentId, name: parentName } = parentWithSubcategories;
    const { id, name } = parentWithSubcategories.subCategories![0];
    return {
      parent: { id: parentId, name: parentName },
      category: { id, name },
    };
  }

  return {};
};

export default getFirstAvailableCategories;
