import { useMemo } from 'react';
import { LPD_SET, PMR_SET } from './constants';
import { getFormattedRadioNetworkName } from './utils';

const useRadioNetworkName = (radioNetworkName: string) => {
  const formattedRadioNetworkName = useMemo(
    () =>
      getFormattedRadioNetworkName({
        radioNetworkName,
        lpdSet: LPD_SET,
        pmrSet: PMR_SET,
      }),
    [radioNetworkName]
  );

  return {
    formattedRadioNetworkName,
  };
};

export default useRadioNetworkName;
