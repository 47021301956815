import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { TranscriptQueryParam } from '@/shared/constants';

type Params = {
  filterName: TranscriptQueryParam;
  filterValue: string;
};

const useExtendActiveFilters = ({ filterName, filterValue }: Params) => {
  const { search } = useLocation();

  return useMemo(() => {
    const transcriptFilters = new URLSearchParams(search);

    transcriptFilters.set(filterName, filterValue);

    return transcriptFilters.toString();
  }, [filterName, filterValue, search]);
};

export default useExtendActiveFilters;
