import { useEffect } from 'react';
import { Key } from 'ts-key-enum';
import WaveSurfer from 'wavesurfer.js';
import { SPACE_KEY } from '@/shared/constants';
import { hotkeysHelperService } from '@/shared/services';
import { UpdateRate } from '../constants';
import { AudioPlayerProps } from '../type';
import { getUpdatedRate } from '../utils';

const S_KEY = 's';

interface Params extends Pick<AudioPlayerProps, 'memoNavigateToPreviousRecord' | 'memoNavigateToNextRecord'> {
  wavesurfer: WaveSurfer | null;
  currentTime: number;
  audioRate: number;
  memoUpdateRate: (rate: number) => void;
  memoToggleAutoplay: VoidFunction;
  memoPlayPause: (shouldPlaySelectedRecord: boolean) => void;
  memoHandleSilenceSkipChange: VoidFunction;
  duration: number;
}

const useAudioPlayerHotKeys = ({
  wavesurfer,
  currentTime,
  audioRate,
  memoUpdateRate,
  memoToggleAutoplay,
  memoNavigateToNextRecord,
  memoNavigateToPreviousRecord,
  memoPlayPause,
  memoHandleSilenceSkipChange,
  duration,
}: Params) => {
  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (hotkeysHelperService.getIsInputOrTextarea(e) || hotkeysHelperService.getIsOverlayedComponentOpen()) return;

      const { key, shiftKey } = e;

      if (shiftKey) {
        switch (key) {
          case SPACE_KEY:
            e.preventDefault();
            memoToggleAutoplay();
            return;
          case Key.ArrowDown:
            {
              const increasedRate = getUpdatedRate(UpdateRate.Increase, audioRate);
              if (increasedRate) {
                memoUpdateRate(increasedRate);
              }
            }
            return;
          case Key.ArrowUp:
            {
              const decreasedRate = getUpdatedRate(UpdateRate.Decrease, audioRate);
              if (decreasedRate) {
                memoUpdateRate(decreasedRate);
              }
            }
            return;
          default:
            break;
        }
      }

      switch (key) {
        case SPACE_KEY:
          e.preventDefault();
          memoPlayPause(true);
          break;
        case Key.ArrowLeft:
          wavesurfer?.setTime(currentTime > 1 ? currentTime - 1 : 0);
          break;
        case Key.ArrowRight:
          if (currentTime + 1 < duration) {
            wavesurfer?.setTime(currentTime + 1);
          }
          break;
        case Key.ArrowUp:
          memoNavigateToNextRecord?.({
            isSkipAudioWithoutVoiceEnabled: false,
            isSkipNotDecodedFileEnabled: false,
          });
          break;
        case Key.ArrowDown:
          memoNavigateToPreviousRecord?.({
            isSkipAudioWithoutVoiceEnabled: false,
            isSkipNotDecodedFileEnabled: false,
          });
          break;
        case S_KEY:
          memoHandleSilenceSkipChange();
          break;
        default:
          break;
      }
    };

    document.addEventListener('keydown', handleKeydown);
    return () => document.removeEventListener('keydown', handleKeydown);
  }, [
    memoUpdateRate,
    memoToggleAutoplay,
    memoNavigateToNextRecord,
    memoNavigateToPreviousRecord,
    memoHandleSilenceSkipChange,
    memoPlayPause,
    audioRate,
    currentTime,
    duration,
  ]);
};

export default useAudioPlayerHotKeys;
