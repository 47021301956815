import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EntityType } from '@/shared/constants';
import { RootState } from '@/shared/types';
import {
  SelectionState,
  SetTranscriptIdPayload,
  OpenSelectionBoxPayload,
  OpenSelectionDropdownPayload,
  OpenSelectionDetailsPayload,
} from './types';

const initialState: SelectionState = {
  isCluster: false,
  transcriptId: null,
  networkId: '',
  selection: '',
  points: { x: 0, y: 0, originalX: 0, originalY: 0 },
  entityType: EntityType.TAG,
  entity: null,
  isSelectionBoxOpen: false,
  isSelectionDropdownOpen: false,
  isSelectionDetailsOpen: false,
};

export const entitySelectionSlice = createSlice({
  name: 'entitySelection',
  initialState,
  reducers: {
    setTranscriptId: (_, { payload }: PayloadAction<SetTranscriptIdPayload>) => ({
      ...initialState,
      ...payload,
    }),
    openSelectionBox: (state: SelectionState, { payload }: PayloadAction<OpenSelectionBoxPayload>) => ({
      ...state,
      ...payload,
      networkId: payload.networkId ?? '',
      entity: null,
      isSelectionBoxOpen: true,
      isSelectionDetailsOpen: false,
    }),
    openSelectionDropdown: (state: SelectionState, { payload }: PayloadAction<OpenSelectionDropdownPayload>) => ({
      ...state,
      ...payload,
      transcriptId: payload.transcriptId ?? state.transcriptId,
      selection: payload.selection ?? state.selection,
      points: payload.points ?? state.points,
      isSelectionBoxOpen: false,
      isSelectionDropdownOpen: true,
      isSelectionDetailsOpen: false,
    }),
    openSelectionDetails: (state: SelectionState, { payload }: PayloadAction<OpenSelectionDetailsPayload>) => ({
      ...state,
      ...payload,
      networkId: payload.networkId ?? '',
      isSelectionDetailsOpen: true,
    }),
    resetSelection: (state: SelectionState) => ({ ...initialState, entityType: state.entityType }),
    closeSelectBox: (state: SelectionState) => ({
      ...state,
      isSelectionBoxOpen: false,
      isSelectionDropdownOpen: false,
      isSelectionDetailsOpen: false,
    }),
  },
});

export const {
  setTranscriptId,
  openSelectionBox,
  openSelectionDropdown,
  openSelectionDetails,
  resetSelection,
  closeSelectBox,
} = entitySelectionSlice.actions;

export const getEntitySelection = (state: RootState) => state.entitySelection;

export const getIsCluster = (state: RootState) => state.entitySelection.isCluster;

export const getTranscriptId = (state: RootState) => state.entitySelection.transcriptId;

export const getSelectionText = (state: RootState) => state.entitySelection.selection;

export default entitySelectionSlice.reducer;
