import { useMutation, useQueryClient } from '@tanstack/react-query';
import { patchSubscription } from '@/entities/subscription';
import { SUBSCRIPTION_UPDATE_MUTATION_KEY, SUBSCRIPTIONS_QUERY_KEY } from '@/shared/constants';

const useUpdateSubscription = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading: isUpdating } = useMutation({
    mutationKey: [SUBSCRIPTION_UPDATE_MUTATION_KEY],
    mutationFn: patchSubscription,
    onSuccess: () => queryClient.invalidateQueries([SUBSCRIPTIONS_QUERY_KEY]),
  });
  return {
    updateSubscription: mutate,
    isUpdating,
  };
};

export default useUpdateSubscription;
