import { z } from 'zod';
import {
  networkSchema,
  networkFiltersSchema,
  radioNetworkSchema,
  paginatedWithFoffsetSchema,
  responseDataSchema,
  radioNetworkItemSchema,
} from '@/shared/validationSchemas';

export const getNetworksSchema = responseDataSchema(z.array(networkSchema));
export const createNetworkSchema = networkSchema;
export const updateNetworkSchema = networkSchema;
export const addToNetworkSchema = networkSchema;
export const getRadioNetworksSchema = paginatedWithFoffsetSchema(radioNetworkSchema);
export const getRadioNetworksFilterSchema = responseDataSchema(networkFiltersSchema);
export const getRadioNetworkSchema = radioNetworkItemSchema;
