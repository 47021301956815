import { useRef, useMemo, useEffect } from 'react';
import { theme } from 'antd';
import { useUnmount } from '@reactuses/core';
import { useWavesurfer as useWs } from '@wavesurfer/react';
import Minimap from 'wavesurfer.js/dist/plugins/minimap';
import { NEUTRAL_COLOR_PALETTE } from '@/shared/constants';
import { useAppSelector } from '@/shared/hooks';
import { selectIsDarkMode } from '@/shared/slices';
import { AudioPlayerProps } from '../type';
import { renderFunction } from '../utils';
import useWavesurferStyles from './useWavesurferStyles';

const { gray4, gray5, gray6, gray7, gray10 } = NEUTRAL_COLOR_PALETTE;

const PROGRESS_COLOR = '#FF4D4F';

interface Params extends Pick<AudioPlayerProps, 'record' | 'isLoading' | 'memoSettings' | 'memoOnPlayPause'> {}

const useWavesurfer = ({ record, isLoading, memoSettings: { audioRate }, memoOnPlayPause }: Params) => {
  const { token } = theme.useToken();
  const isDarkMode = useAppSelector(selectIsDarkMode);
  const containerRef = useRef(null);
  const waveSurferOptions = useMemo(
    () => ({
      backend: 'WebAudio' as const,
      container: containerRef,
      dragToSeek: true,
      height: 24,
      normalize: false,
      waveColor: isDarkMode ? gray7 : gray5,
      progressColor: PROGRESS_COLOR,
      cursorColor: PROGRESS_COLOR,
      renderFunction,
      audioRate,
      plugins: [
        Minimap.create({
          dragToSeek: true,
          height: 32,
          cursorWidth: 0,
          waveColor: isDarkMode ? gray7 : gray6,
          progressColor: isDarkMode ? gray4 : gray10,
          overlayColor: token.colorBgContainer,
          barWidth: 2,
          barGap: 2,
        }),
      ],
    }),
    [isDarkMode, token.colorBgContainer]
  );

  const { wavesurfer, currentTime, isPlaying, isReady } = useWs(waveSurferOptions);

  useWavesurferStyles(wavesurfer);

  const duration = wavesurfer?.getDuration() ?? 0;

  useEffect(() => {
    if (!wavesurfer || !record) return;

    if (wavesurfer.isPlaying()) wavesurfer.stop();
    wavesurfer.empty();
    wavesurfer.load(URL.createObjectURL(record));
    const unsubscribe = wavesurfer.on('ready', () => {
      if (audioRate) {
        wavesurfer.setPlaybackRate(audioRate);
      }
      memoOnPlayPause?.({ isPlaying: true });
    });

    return () => {
      unsubscribe?.();
    };
  }, [wavesurfer, record]);

  useEffect(() => {
    if (isLoading && isPlaying) {
      wavesurfer?.stop();
    }
  }, [wavesurfer, isLoading, isPlaying]);

  useEffect(() => {
    wavesurfer?.setOptions({ audioRate });
  }, [wavesurfer, audioRate]);

  useUnmount(() => wavesurfer?.destroy());

  return {
    wavesurfer,
    containerRef,
    currentTime,
    isPlaying,
    isReady,
    duration,
  };
};

export default useWavesurfer;
