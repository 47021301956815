import { Row } from 'antd';
import { FoxFrequency } from '@/entities/source/@fox/types';
import { OverflowList, BaseTag } from '@/shared/components';
import { useIsMobile } from '@/shared/hooks';
import { FoxFrequencyEncryptionTypeTag } from './components';
import styles from './styles.module.css';
import { getEncryptionTypesWithFreshestHytera } from './utils';

interface Props {
  encryptionTypes: FoxFrequency['encryptionTypes'];
}

const FoxFrequencyEncryptionType = ({ encryptionTypes }: Props) => {
  const isMobile = useIsMobile();

  const items = getEncryptionTypesWithFreshestHytera(encryptionTypes).map((encryptionType) => (
    <FoxFrequencyEncryptionTypeTag
      key={`${encryptionType.type}-${encryptionType.lastActiveAt}`}
      encryptionType={encryptionType}
    />
  ));

  const handleOverflowCounterRender = (hiddenItemsCount: number) => (
    <BaseTag
      closable={false}
      className={styles.tag}
    >
      +{hiddenItemsCount}
    </BaseTag>
  );

  return isMobile ? (
    items
  ) : (
    <Row className={styles.wrapper}>
      <OverflowList
        items={items}
        counterMinWidth={0}
        overflowCounterRender={handleOverflowCounterRender}
      />
    </Row>
  );
};

export default FoxFrequencyEncryptionType;
