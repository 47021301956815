import { Ref, Fragment, ReactElement } from 'react';
import { List, Row, Col, Typography } from 'antd';
import classNames from 'classnames';
import Highlighter from '@/shared/components/Highlighter';
import { NoDataPlaceholder } from '@/shared/components/NoDataPlaceholder';
import { MobileOpenButtonIcon } from '@/shared/components/mobile/MobileButtonIcons';
import { ListColumn } from '@/shared/components/mobile/mobileManagers/MobileListManager/types';
import { COPIES } from '@/shared/constants';
import styles from './styles.module.css';

type Props<T> = {
  itemKey?: unknown;
  itemRef?: Ref<HTMLDivElement>;
  className?: string;
  listRow?: ReactElement;
  columns: ListColumn[];
  searchTokens?: string[];
  searchedColumns?: (keyof T)[];
  onClick: VoidFunction;
};

export const MobileListItem = <T,>({
  itemKey,
  itemRef,
  className,
  listRow,
  columns,
  searchTokens,
  searchedColumns,
  onClick,
}: Props<T>) => {
  return (
    <List.Item
      ref={itemRef}
      className={classNames(styles.listItem, className)}
      onClick={onClick}
      data-row-key={itemKey}
    >
      <Row
        className={styles.listRow}
        wrap={false}
      >
        <Col flex="auto">
          {listRow || (
            <div className={styles.columns}>
              {columns.map(({ id: colId, value, placeholder, showOriginalValue }, index, arr) =>
                showOriginalValue ? (
                  <Fragment key={colId}>{value}</Fragment>
                ) : (
                  <div
                    key={colId}
                    className={classNames({
                      [styles.primaryCol]: index !== arr.length - 1,
                      [styles.secondaryCol]: index === arr.length - 1,
                    })}
                  >
                    {value ? (
                      <Typography.Text strong={index === 0}>
                        <Highlighter
                          text={String(value)}
                          searchTokens={searchedColumns?.includes(colId as keyof T) ? searchTokens : undefined}
                        />
                      </Typography.Text>
                    ) : (
                      <NoDataPlaceholder>{placeholder ?? COPIES.NO_DATA}</NoDataPlaceholder>
                    )}
                  </div>
                )
              )}
            </div>
          )}
        </Col>
        <Col
          className={styles.buttonCol}
          flex="none"
        >
          <MobileOpenButtonIcon />
        </Col>
      </Row>
    </List.Item>
  );
};
