// List of existing category IDs on prod env
const CATEGORY_ID_LIST = new Set([
  31, 42, 47, 59, 79, 85, 86, 87, 88, 89, 91, 92, 93, 94, 95, 96, 98, 99, 100, 101, 103, 107, 110, 111, 114, 116, 117,
  118, 119, 120, 121, 122, 124, 129, 130, 132,
]);

const DEFAULT_ICON = 'default_category_icon';

export const getImagePath = (id: number) => {
  return `/categoryIcons/${CATEGORY_ID_LIST.has(id) ? id : DEFAULT_ICON}.svg`;
};
