const getMiddleRange = (prevRange: number[], newRange: number[]) => {
  const leftRangeDecimal = getDecimalPart(prevRange[0]);
  const rightRangeDecimal = getDecimalPart(prevRange[1]);
  const difference = Math.floor(newRange[0]) - Math.floor(prevRange[0]);

  return [
    Math.floor(prevRange[0]) + difference + leftRangeDecimal,
    Math.floor(prevRange[1]) + difference + rightRangeDecimal,
  ];
};

function getDecimalPart(num: number) {
  return Number((num - Math.floor(num)).toFixed(3));
}

export default getMiddleRange;
