import { ActionsDropdown } from '@/shared/components';
import { useUnitUserActions } from './hooks';
import { UnitUserActionsProps } from './types';

const UnitUserActions = ({ userId, isActive, onEditUserClick }: UnitUserActionsProps) => {
  const { actionItems } = useUnitUserActions({ userId, isActive, onEditUserClick });

  return <ActionsDropdown items={actionItems} />;
};

export default UnitUserActions;
