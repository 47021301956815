import { Flex } from 'antd';
import classNames from 'classnames';
import { TranscriptEntitiesProps } from '@/entities/transcript/types';
import { scrollableStyles } from '@/shared/styles';
import { LocationsCoordinatesTags } from './components';
import styles from './styles.module.css';
import useTranscriptEntities from './useTranscriptEntities';

const TranscriptEntities = ({ isCluster, clusterMetadata, transcript }: TranscriptEntitiesProps) => {
  const { factions, coordinates, locations, categories, tags, renderTranscriptFactions, renderTranscriptCategories } =
    useTranscriptEntities({
      isCluster,
      clusterMetadata,
      transcript,
    });

  if (factions.length === 0 && coordinates.length === 0 && locations.length === 0 && categories.length === 0) {
    return null;
  }

  return (
    <Flex
      className={classNames(styles.tags, scrollableStyles.scrollableContainer)}
      gap={8}
      data-testid="tags"
    >
      {renderTranscriptFactions({
        transcriptId: transcript.id,
        factions,
        isCluster,
      })}
      <LocationsCoordinatesTags
        transcript={transcript}
        locations={locations}
        coordinates={coordinates}
        isCluster={isCluster}
      />
      {renderTranscriptCategories({
        transcriptId: transcript.id,
        categories,
        tags,
        isCluster,
      })}
    </Flex>
  );
};

export default TranscriptEntities;
