import { PropsWithChildren } from 'react';
import { Form, FormProps, FormInstance, Flex } from 'antd';
import { SettingsFormData } from '@/entities/source/components/SourceContent/components/SettingsTab/types';
import { Button } from '@/shared/components';
import { COPIES } from '@/shared/constants';
import styles from './styles.module.css';

interface Props extends PropsWithChildren<FormProps> {
  form: FormInstance<SettingsFormData>;
  isFormChanged: boolean;
  isLoading: boolean;
  isDrawModeEnabled: boolean;
}

const SettingsForm = ({ isFormChanged, isLoading, isDrawModeEnabled, children, ...formProps }: Props) => {
  return (
    <Form
      {...formProps}
      className={styles.settingsForm}
    >
      {children}
      {isFormChanged && !isDrawModeEnabled && (
        <Flex className={styles.settingsFormActions}>
          <Button htmlType="reset">{COPIES.CANCEL}</Button>
          <Button
            htmlType="submit"
            type="primary"
            loading={isLoading}
            disabled={isLoading}
          >
            {COPIES.APPLY}
          </Button>
        </Flex>
      )}
    </Form>
  );
};

export default SettingsForm;
