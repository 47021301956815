import { Flex, TableColumnsType, Typography } from 'antd';
import UnitSourceActions from '@/entities/unit/components/UnitContent/components/UnitSourcesTab/components/UnitSourceActions';
import { UnitSource } from '@/entities/unit/types';
import { useRenderPropsContext } from '@/shared/context';
import { timeService } from '@/shared/services';
import { GetListRefFn, UnitRenderProps } from '@/shared/types';
import styles from '../styles.module.css';

enum ColumnTitle {
  Name = 'НАЗВА',
  AddedAt = 'ДОДАНИЙ',
  AccessGroup = 'ГРУПА ДОСТУПУ',
}

type Params = {
  getUnitSourcesRef: GetListRefFn;
  handleOpenModal: (id: UnitSource['id']) => void;
};

const useUnitSourcesTableColumns = ({ getUnitSourcesRef, handleOpenModal }: Params): TableColumnsType<UnitSource> => {
  const { renderSourceTypeIcon, renderSourceStatusIcon } = useRenderPropsContext<UnitRenderProps>();

  return [
    {
      title: ColumnTitle.Name,
      width: '40%',
      ellipsis: true,
      className: styles.column,
      render: (_, { name, type, status, externalId }, index) => (
        <Flex
          ref={getUnitSourcesRef(index)}
          vertical
        >
          <Flex
            align="center"
            gap={8}
          >
            <Typography.Text
              strong
              ellipsis={{ tooltip: name }}
            >
              {name}
            </Typography.Text>
            {renderSourceTypeIcon?.({
              className: styles.icon,
              type,
            })}
            {status &&
              renderSourceStatusIcon?.({
                className: styles.icon,
                status,
              })}
          </Flex>
          <Typography.Text
            className={styles.externalId}
            ellipsis={{ tooltip: externalId }}
          >
            {externalId}
          </Typography.Text>
        </Flex>
      ),
    },
    {
      title: ColumnTitle.AddedAt,
      width: '30%',
      className: styles.column,
      render: (_, { createdAt }) => {
        const date = timeService.getFormattedDate(createdAt);
        return <Typography.Text ellipsis={{ tooltip: date }}>{date}</Typography.Text>;
      },
    },
    {
      title: ColumnTitle.AccessGroup,
      width: '30%',
      className: styles.column,
      render: (_, { groupName }) => (
        <Typography.Paragraph ellipsis={{ tooltip: groupName, rows: 2 }}>{groupName}</Typography.Paragraph>
      ),
    },
    {
      width: 64,
      render: (_, { id }) => (
        <UnitSourceActions
          sourceId={id}
          onEditSourceClick={handleOpenModal}
        />
      ),
    },
  ];
};

export default useUnitSourcesTableColumns;
