import { useSynchronizeNetwork } from '@/entities/network';
import { NetworkQueryParam } from '@/shared/constants';
import { useGetFilteredQueryParams, useIsMobile, useSearchParam } from '@/shared/hooks';
import { routingService } from '@/shared/services';
import { RadioNetwork } from '@/shared/types';

const useStaticListItem = (item: RadioNetwork) => {
  const isMobile = useIsMobile();
  const [search] = useSearchParam(NetworkQueryParam.Search);
  const { chooseNetwork } = useSynchronizeNetwork();

  const path = routingService.getRadioNetworkUrl({ radioNetworkId: item.id });
  const queryParams = useGetFilteredQueryParams({ routeQueryParams: NetworkQueryParam, routePath: path });

  const radioNetworkLinkHref = queryParams ? `${path}?${queryParams}` : path;

  const handleItemClick = () => chooseNetwork(item, true);

  return {
    isMobile,
    search,
    radioNetworkLinkHref,
    handleItemClick,
  };
};

export default useStaticListItem;
