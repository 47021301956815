import { ExpressionSpecification } from 'maplibre-gl';
import { MAP_COLORS } from './colors';

export const HAS_LOCATION_TYPE: ExpressionSpecification = ['==', ['get', 'type'], 'location'];
export const HAS_POINT_TYPE: ExpressionSpecification = ['==', ['get', 'type'], 'point'];

export const HAS_CLUSTER: ExpressionSpecification = ['==', 'cluster', true];
export const HAS_NOT_CLUSTER: ExpressionSpecification = ['!=', 'cluster', true];

export const IS_LOCATION_CLUSTER: ExpressionSpecification = ['==', ['get', 'point_features'], 0];
export const IS_MARKER_CENTERED: ExpressionSpecification = ['boolean', ['feature-state', 'centered'], false];

const IS_HEXAGON_HOVERED: ExpressionSpecification = ['boolean', ['get', 'hovered'], false];
const IS_HEXAGON_SELECTED: ExpressionSpecification = ['boolean', ['get', 'selected'], false];

export const HEXAGON_COLOR: ExpressionSpecification = [
  'case',
  IS_HEXAGON_HOVERED,
  MAP_COLORS.hexagonHoveredBg,
  IS_HEXAGON_SELECTED,
  MAP_COLORS.hexagonSelectedBg,
  MAP_COLORS.hexagonUnselectedBg,
];
