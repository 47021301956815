import useFiltersManager from './useFiltersManager';
import useOptions from './useOptions';
import useSaveScanningFilter from './useSaveScanningFilter';

const useFiltersDrawer = () => {
  const {
    activeStatus,
    deactivatedStatus,
    toggleActiveStatus,
    toggleDeactivatedStatus,
    digitalSignalType,
    analogSignalType,
    toggleDigitalSignalType,
    toggleAnalogSignalType,
    memoEncryptionType,
    handleEncryptionTypeChange,
  } = useFiltersManager();

  useSaveScanningFilter({
    active: activeStatus,
    deactivated: deactivatedStatus,
    digital: digitalSignalType,
    analog: analogSignalType,
    memoEncryptionType,
  });

  const { isFiltersLoading, options } = useOptions();

  return {
    isActiveFrequenciesSelected: Boolean(activeStatus),
    isDeactivatedFrequenciesSelected: Boolean(deactivatedStatus),
    toggleActiveStatus,
    toggleDeactivatedStatus,
    isDigitalFrequenciesSelected: Boolean(digitalSignalType),
    isAnalogFrequenciesSelected: Boolean(analogSignalType),
    toggleDigitalSignalType,
    toggleAnalogSignalType,
    memoEncryptionType,
    isFiltersLoading,
    handleEncryptionTypeChange,
    ...options,
  };
};

export default useFiltersDrawer;
