import { useState } from 'react';
import { getFoxKeys } from '@/entities/source/@fox/api';
import { FoxKey } from '@/entities/source/@fox/types';
import { FOX_KEYS_QUERY_KEY } from '@/shared/constants';
import { useInfiniteListQuery, UseInfiniteListQueryOptions, useParams, useUpdateFoffset } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';

interface Params {
  options?: UseInfiniteListQueryOptions<FoxKey>;
}

const useGetFoxKeys = ({ options }: Params = {}) => {
  const { sourceId = '' } = useParams<SourceRouteParams>();
  const [foffset, setFoffset] = useState<string | null>(null);

  const {
    data,
    list: foxKeysList,
    getListRef: getFoxKeyRef,
    isLoading: isFoxKeysLoading,
    isFetching: isFoxKeysFetching,
    refetch: refetchFoxKeys,
  } = useInfiniteListQuery({
    ...options,
    // We intentionally do not observe foffset parameter in queryKey to avoid redundant requests
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [FOX_KEYS_QUERY_KEY, sourceId],
    queryFn: ({ pageParam = 1 }) => {
      return getFoxKeys({
        sourceId,
        queryParams: {
          page: pageParam,
          foffset: pageParam !== 1 ? foffset : null,
        },
      });
    },
  });

  useUpdateFoffset({ data, setFoffset });

  return {
    foxKeysList,
    getFoxKeyRef,
    isFoxKeysLoading,
    isFoxKeysFetching,
    refetchFoxKeys,
  };
};

export default useGetFoxKeys;
