import { useMemo } from 'react';
import { useSelectedRadioNetwork } from '@/entities/network';
import { useGetTranscripts, useTranscriptQueryParams } from '@/entities/transcript';

const usePaginatedTranscriptIds = () => {
  const { selectedRadioNetwork } = useSelectedRadioNetwork();
  const { queryParams } = useTranscriptQueryParams({
    networkId: selectedRadioNetwork?.id,
    networkType: selectedRadioNetwork?.type,
  });
  const { data, isFetching } = useGetTranscripts({ queryParams });

  const paginatedTranscriptIds = useMemo<string[][]>(() => {
    if (!data?.pages) return [[]];

    return data.pages.map((page) => {
      return page.models.flatMap(({ id, type, metadata }) => {
        if (type === 'cluster') {
          return metadata.sources.map(({ transcriptId }) => transcriptId);
        }

        return id;
      });
    });
  }, [data?.pages]);

  return {
    paginatedTranscriptIds,
    isTranscriptsFetching: isFetching,
  };
};

export default usePaginatedTranscriptIds;
