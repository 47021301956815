import { useMemo } from 'react';
import { FoxScanningQueryParam } from '@/shared/constants';
import { useSearchParam } from '@/shared/hooks';

const useScanningFilterButtonCount = () => {
  const [activeStatus] = useSearchParam(FoxScanningQueryParam.Active);
  const [deactivatedStatus] = useSearchParam(FoxScanningQueryParam.Deactivated);
  const [digitalSignalType] = useSearchParam(FoxScanningQueryParam.Digital);
  const [analogSignalType] = useSearchParam(FoxScanningQueryParam.Analog);
  const [encryptionType] = useSearchParam(FoxScanningQueryParam.EncryptionType);

  const count = useMemo(() => {
    return [activeStatus, deactivatedStatus, digitalSignalType, analogSignalType, encryptionType].reduce(
      (accumulator, filter) => (filter ? accumulator + 1 : accumulator),
      0
    );
  }, [activeStatus, deactivatedStatus, digitalSignalType, analogSignalType, encryptionType]);

  return count;
};

export default useScanningFilterButtonCount;
