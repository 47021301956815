import { Point, Polygon } from '@turf/turf';
import { Feature } from 'geojson';
import { ControlPosition, IControl, LngLatBounds } from 'maplibre-gl';
import * as icons from '@/entities/map/assets/icons';
import { DEFAULT_BOX_NORTH_EAST_POINT, DEFAULT_BOX_SOUTH_WEST_POINT } from '@/entities/map/constants';
import { MaplibreMap } from '@/entities/map/types';
import { PointCoordinates } from '@/shared/types';

const ICONS: Record<string, string | undefined> = icons;

type AddCustomIconParams = {
  iconName: string;
  sdf?: boolean;
};

class ViewportService {
  POLYGON_MIN_ZOOM = 9;

  cursors = {
    POINTER: 'mouse-pointer',
    REMOVE: 'mouse-remove',
  };

  constructor(private map: MaplibreMap) {}

  private static extendsByPoint(bounds: LngLatBounds, coordinates: PointCoordinates) {
    return bounds.extend(coordinates);
  }

  private static extendsByPolygonPoints(bounds: LngLatBounds, coordinates: PointCoordinates[]) {
    coordinates.forEach((coords) => bounds.extend(coords));
    return bounds;
  }

  static getMapBounds(features?: Feature<Point | Polygon>[]) {
    if (!features || features.length === 0) {
      return new LngLatBounds(DEFAULT_BOX_SOUTH_WEST_POINT, DEFAULT_BOX_NORTH_EAST_POINT);
    }

    return features.reduce((bounds, { geometry: { type, coordinates } }) => {
      const isPoint = type === 'Point';
      const isPolygon = type === 'Polygon';

      if (isPoint) {
        return this.extendsByPoint(bounds, coordinates as PointCoordinates);
      }
      if (isPolygon && coordinates[0]) {
        return this.extendsByPolygonPoints(bounds, coordinates[0] as PointCoordinates[]);
      }

      return bounds;
    }, new LngLatBounds());
  }

  addCustomIcon({ iconName, sdf = true }: AddCustomIconParams) {
    const iconSrc = ICONS[iconName];
    if (!iconSrc) return;

    const img = new Image();
    img.onload = () => {
      if (!this.map.getStyle() || this.map.getImage(iconName)) return;
      this.map.addImage(iconName, img, { sdf });
    };
    img.src = iconSrc;
  }

  addControl(control: IControl, position?: ControlPosition) {
    if (!this.map.hasControl(control)) {
      this.map.addControl(control, position);
    }
  }

  removeControl(control: IControl) {
    if (this.map.hasControl(control)) {
      this.map.removeControl(control);
    }
  }

  addCursor(className: string) {
    return this.map.getContainer().classList.add(className);
  }

  removeCursor(className: string) {
    return this.map.getContainer().classList.remove(className);
  }

  toggleCursor(className: string) {
    return this.map.getContainer().classList.toggle(className);
  }

  isNotValidPolygonZoom() {
    return Math.floor(this.map.getZoom()) < this.POLYGON_MIN_ZOOM;
  }
}

export default ViewportService;
