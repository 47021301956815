import { FC, RefObject } from 'react';
import classNames from 'classnames';
import { getFormattedTime } from '@/entities/source/@fox/components/RecordsTab/components/AudioPlayer/utils';
import { Tooltip } from '@/shared/components';
import styles from './styles.module.css';

interface Props {
  currentTime: number;
  containerRef: RefObject<HTMLDivElement>;
  timeTooltipPosition: number | null;
}

const TOOLTIP_TITLE = 'Перемотати вперед/назад аудіо (←/→)';

const Timeline: FC<Props> = ({ containerRef, currentTime, timeTooltipPosition }) => {
  const overlayStyle = timeTooltipPosition ? { left: `${timeTooltipPosition * 100}%` } : undefined;
  const title = timeTooltipPosition ? getFormattedTime(currentTime) : TOOLTIP_TITLE;

  return (
    <Tooltip
      isDesktopOnly
      isOneLine
      open={Boolean(timeTooltipPosition)}
      getPopupContainer={timeTooltipPosition ? () => containerRef.current as HTMLElement : undefined}
      className={classNames({
        [styles.tooltip]: timeTooltipPosition,
      })}
      overlayStyle={overlayStyle}
      title={title}
    >
      <div
        ref={containerRef}
        className={styles.timeline}
        data-audio-slider
      />
    </Tooltip>
  );
};

export default Timeline;
