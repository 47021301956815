import { Feature, Geometry } from 'geojson';
import { MapGeoJSONFeature } from 'maplibre-gl';
import { GeoJsonFeature, NormalizedGeoJsonFeature } from '@/entities/map/types';

const normalizeGeoJsonFeature = <P = GeoJsonFeature['properties'], G extends Geometry = GeoJsonFeature['geometry']>(
  feature: MapGeoJSONFeature | Feature
): NormalizedGeoJsonFeature<P, G> => {
  return {
    id: feature.id,
    type: feature.type,
    properties: feature.properties as P,
    geometry: feature.geometry as G,
    sourceId: 'source' in feature ? feature.source : undefined,
    layer: 'layer' in feature ? feature.layer : undefined,
    state: 'state' in feature ? feature.state : undefined,
  };
};

export default normalizeGeoJsonFeature;
