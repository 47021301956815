import { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetIsLastBuild } from './hooks';
import { syncServiceWorker } from './utils';

const ApplicationFreshnessGuard = ({ children }: PropsWithChildren) => {
  const { pathname } = useLocation();
  const { isLastBuild, refetch } = useGetIsLastBuild();

  useEffect(() => {
    if (isLastBuild) return;

    window.location.reload();
  }, [isLastBuild]);

  useEffect(() => {
    syncServiceWorker().then(async () => {
      await refetch();
    });
  }, [pathname]);

  return children;
};

export default ApplicationFreshnessGuard;
