import { useState, PropsWithChildren } from 'react';
import { Layout } from 'antd';
import classNames from 'classnames';
import { LogoIcon } from '@/shared/assets';
import { LayoutSidebarDrawer } from '@/shared/components/layouts/LayoutSidebarDrawer';
import { useAppSelector, useAppDispatch, useIsTablet } from '@/shared/hooks';
import { selectIsNavigationSidebarCollapsed, setIsNavigationSidebarCollapsed } from '@/shared/slices';
import { MenuToggleButton } from './components';
import { useTargetZone } from './hooks';
import styles from './styles.module.css';

const NAV_MENU_WIDTH = 220;
const COLLAPSED_NAV_MENU_WIDTH = 48;

export const NavigationSidebar = ({ children }: PropsWithChildren) => {
  const isTablet = useIsTablet();
  const dispatch = useAppDispatch();
  const isNavigationSidebarCollapsed = useAppSelector(selectIsNavigationSidebarCollapsed);
  const [isCollapseControlsVisible, setIsCollapseControlsVisible] = useState(false);

  const { targetZoneRef } = useTargetZone({
    onTarget: (isInTargetZone: boolean) => {
      if (isInTargetZone && !isCollapseControlsVisible) {
        setIsCollapseControlsVisible(true);
      }
      if (!isInTargetZone && isCollapseControlsVisible) {
        setIsCollapseControlsVisible(false);
      }
    },
  });

  const handleTabletOverlayClick = isTablet ? () => dispatch(setIsNavigationSidebarCollapsed(true)) : undefined;

  const handleTabletHeaderClick = isTablet ? () => dispatch(setIsNavigationSidebarCollapsed(false)) : undefined;

  const sideBar = (
    <Layout.Sider
      ref={targetZoneRef}
      className={styles.sidebar}
      collapsible
      collapsed={isNavigationSidebarCollapsed}
      trigger={null}
      width={NAV_MENU_WIDTH}
      collapsedWidth={COLLAPSED_NAV_MENU_WIDTH}
      data-testid="navigation-sidebar"
    >
      <Layout className={styles.sidebarInner}>
        {!isTablet && <MenuToggleButton isButtonVisible={isCollapseControlsVisible} />}
        <Layout.Header
          className={classNames(styles.sidebarHeader, isNavigationSidebarCollapsed && styles.sidebarHeaderCollapsed)}
          onClick={handleTabletHeaderClick}
        >
          <LogoIcon
            className={styles.logo}
            data-testid="navigation-sidebar-logo"
          />
        </Layout.Header>
        {children}
      </Layout>
    </Layout.Sider>
  );

  return isTablet && !isNavigationSidebarCollapsed ? (
    <LayoutSidebarDrawer onClose={handleTabletOverlayClick}>{sideBar}</LayoutSidebarDrawer>
  ) : (
    sideBar
  );
};
