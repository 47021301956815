import { forwardRef } from 'react';
import { Input as AntdInput, InputProps as AntdInputProps, InputRef as AntdInputRef } from 'antd';
import classNames from 'classnames';
import { useIsTabletOrMobile, useIsMobile } from '@/shared/hooks';
import commonInputStyles from '../commonInput.styles.module.css';

export type InputProps = AntdInputProps & {
  isTransparentOnDesktop?: boolean;
};
export type InputRef = AntdInputRef;

export const Input = forwardRef<InputRef, InputProps>(({ className, isTransparentOnDesktop, ...restProps }, ref) => {
  const isTabletOrMobile = useIsTabletOrMobile();
  const isMobile = useIsMobile();
  return (
    <AntdInput
      ref={ref}
      size={isTabletOrMobile ? 'large' : 'middle'}
      className={classNames({ [commonInputStyles.transparentInput]: isTransparentOnDesktop && !isMobile }, className)}
      {...restProps}
    />
  );
});

Input.displayName = 'Input';
