import { useQuery } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import { getFoxSettings } from '@/entities/source/@fox/api';
import { Source } from '@/entities/source/types';
import { FOX_SETTINGS_QUERY_KEY } from '@/shared/constants';

type Params = {
  sourceId: Source['id'];
  isFoxSource: boolean;
};

const useGetFoxSettings = ({ sourceId, isFoxSource }: Params) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: [FOX_SETTINGS_QUERY_KEY, sourceId],
    queryFn: () => getFoxSettings(sourceId),
    enabled: Boolean(sourceId) && isFoxSource,
  });
  const isSettingsEmpty = !data?.data || isEmpty(data.data);

  return {
    settings: data?.data,
    isSettingsLoading: isLoading,
    isSettingsEmpty,
    refetchSettings: refetch,
  };
};

export default useGetFoxSettings;
