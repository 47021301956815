interface Params {
  role: string;
  pathname: string;
  allowedPages: Record<string, string[]>;
}

const roleHasAccess = ({ role, pathname, allowedPages }: Params) => {
  if (!allowedPages[role]) return false;

  return allowedPages[role].some((allowedPage) => pathname.startsWith(allowedPage));
};

export default roleHasAccess;
