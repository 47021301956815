import { FrequencyFormMultiSelect, FrequencyId, FREQUENCIES_PLACEHOLDER } from '@/entities/frequency';
import { SubscriptionType, SubscriptionItemId } from '@/entities/subscription';
import { useGetSubscriptions } from '@/widgets/SubscriptionDrawer/hooks';
import { useSubscriptionState } from '../hooks';

type Props = {
  selectedFrequencies: FrequencyId[];
};

const FrequencyField = ({ selectedFrequencies }: Props) => {
  const { subscriptions } = useGetSubscriptions();
  const { handleSubscriptionChange } = useSubscriptionState();

  const handleChange = (values: SubscriptionItemId[]) => {
    handleSubscriptionChange(values, SubscriptionType.Frequency);
  };

  return (
    <FrequencyFormMultiSelect
      placeholder={FREQUENCIES_PLACEHOLDER}
      value={selectedFrequencies}
      onChange={handleChange}
      disabled={!subscriptions?.models.length}
    />
  );
};

export default FrequencyField;
