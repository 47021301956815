import { Form } from 'antd';
import { useCreateFoxFrequencyFormSubmit } from '@/entities/source/@fox/components/ScanningTab/hooks';
import { CreateFoxFrequencyPayload } from '@/entities/source/@fox/types';
import { useSource } from '@/entities/source/hooks';
import { FoxSource } from '@/entities/source/types';

type Params = {
  onCloseForm: VoidFunction;
};

const useCreateFoxFrequencyForm = ({ onCloseForm }: Params) => {
  const [form] = Form.useForm<CreateFoxFrequencyPayload>();
  const source = useSource<FoxSource>();

  const { handleFinish, isFoxFrequencyCreating } = useCreateFoxFrequencyFormSubmit({
    form,
    onCancelAddNewFoxFrequency: onCloseForm,
  });

  const handleCancel = () => {
    form.resetFields();
    onCloseForm();
  };

  return {
    form,
    isFoxFrequencyCreating,
    frequencyRange: source?.frequencyRange,
    handleFinish,
    handleCancel,
  };
};

export default useCreateFoxFrequencyForm;
