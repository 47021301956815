import { Flex, Typography } from 'antd';
import classNames from 'classnames';
import { Transcription } from '@/entities/transcript/types';
import { Tooltip } from '@/shared/components';
import { useRenderPropsContext } from '@/shared/context';
import { useParams } from '@/shared/hooks';
import { RadioNetworkRouteParams, TranscriptRenderProps } from '@/shared/types';
import { SourceLink, TranscriptMessengerName } from './components';
import styles from './styles.module.css';
import { getTranscriptSourceNameTooltipTitle } from './utils';

interface Props {
  className?: string;
  creatorName: Transcription['creator_name'];
  sourceId: Transcription['source_id'];
  sourceName: Transcription['source_name'];
  sourceType: Transcription['source_type'];
  isNetwork?: boolean;
  isCluster?: boolean;
}

const TranscriptSource = ({
  className,
  creatorName,
  sourceId,
  sourceName,
  sourceType,
  isNetwork,
  isCluster,
}: Props) => {
  const { radioNetworkId } = useParams<RadioNetworkRouteParams>();
  const { renderSourceTypeIcon } = useRenderPropsContext<TranscriptRenderProps>();

  if (!sourceId || (!sourceName && !sourceType)) return null;

  const tooltipTitle = getTranscriptSourceNameTooltipTitle(sourceType);
  let component;

  if (sourceType === 'signal' || sourceType === 'whatsapp') {
    component = (
      <TranscriptMessengerName
        creatorName={creatorName}
        sourceName={sourceName}
        sourceType={sourceType}
        tooltipMessage={tooltipTitle}
      />
    );
  } else {
    component = (
      <>
        {sourceType &&
          renderSourceTypeIcon?.({
            className: styles.icon,
            type: sourceType,
          })}
        <Tooltip
          title={tooltipTitle}
          isDesktopOnly
        >
          <Typography.Text
            ellipsis
            data-testid="source"
          >
            {sourceName}
          </Typography.Text>
        </Tooltip>
      </>
    );
  }

  return (
    <Flex
      className={classNames(styles.wrapper, className)}
      align="center"
      gap={5}
    >
      {!radioNetworkId || isNetwork ? (
        <SourceLink
          sourceId={sourceId}
          isCluster={isCluster}
        >
          {component}
        </SourceLink>
      ) : (
        component
      )}
    </Flex>
  );
};

export default TranscriptSource;
