import { useEffect, useCallback } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { useOnViewportTypeChange } from '@/shared/hooks';
import { AudioPlayerProps } from '../type';

interface Params
  extends Pick<AudioPlayerProps, 'shouldBePlaying' | 'isLoading' | 'memoOnPlayPause' | 'isRecordSelected'> {
  wavesurfer: WaveSurfer | null;
  isPlaying: boolean;
  isReady: boolean;
  recordHasVoice?: boolean;
  isSkipSilenceEnabled?: boolean;
}

const usePlayPause = ({
  wavesurfer,
  isRecordSelected,
  shouldBePlaying,
  isPlaying,
  isReady,
  isLoading,
  memoOnPlayPause,
  isSkipSilenceEnabled,
  recordHasVoice,
}: Params) => {
  const memoPlay = useCallback(
    (shouldPlaySelectedRecord: boolean = false) => {
      if (isReady) {
        wavesurfer?.play();
      }

      memoOnPlayPause?.({ isPlaying: true, shouldPlaySelectedRecord });
    },
    [isReady, wavesurfer, memoOnPlayPause]
  );

  const memoPause = useCallback(() => {
    wavesurfer?.pause();
    memoOnPlayPause?.({ isPlaying: false });
  }, [wavesurfer, memoOnPlayPause]);

  const memoStop = useCallback(() => {
    wavesurfer?.stop();
    memoOnPlayPause?.({ isPlaying: false });
  }, [wavesurfer, memoOnPlayPause]);

  const memoPlayPauseSelectedRecord = useCallback(() => {
    if (isPlaying) {
      if (isRecordSelected) {
        memoPause();
      } else {
        memoStop();
        memoPlay(true);
      }
    } else {
      memoPlay(true);
    }
  }, [isPlaying, isRecordSelected, memoPause, memoPlay, memoStop]);

  const memoPlayPause = useCallback(
    (shouldPlaySelectedRecord: boolean = false) => {
      if (shouldPlaySelectedRecord) {
        memoPlayPauseSelectedRecord();
      } else if (isPlaying) {
        memoPause();
      } else {
        memoPlay();
      }
    },
    [isPlaying, isRecordSelected, memoPause, memoPlay]
  );

  useEffect(() => {
    const reactToShouldBePlayingUpdate = () => {
      if (isPlaying && !recordHasVoice && isSkipSilenceEnabled) {
        memoStop();
      } else {
        memoPlayPause();
      }
    };

    if (!isLoading && isReady && isPlaying !== shouldBePlaying) {
      reactToShouldBePlayingUpdate();
    }
  }, [isReady, shouldBePlaying, isPlaying, isLoading, memoPlayPause, recordHasVoice, isSkipSilenceEnabled, memoStop]);

  useOnViewportTypeChange({ memoOnChange: memoPause });

  return { memoPlayPause, memoPlay, memoPause, memoStop };
};

export default usePlayPause;
