import { useState, useRef } from 'react';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import { TIME_HOUR_LONG } from '@/shared/constants';
import { useIsMobile, useIsTabletOrMobile } from '@/shared/hooks';
import { RangeValue } from '@/shared/types';
import { RANGE_PRESETS, DATE_RANGE_FORMAT, DATE_RANGE_FORMAT_SHORT, PREVIOUS_MONTH } from '../constants';
import { getFormattedRangeValue } from '../utils';

interface Params {
  onChange: (value: RangeValue) => void;
}

const useDateRangePicker = ({ onChange }: Params) => {
  const [defaultPickerValue, setDefaultPickerValue] = useState<[Dayjs, Dayjs] | undefined>([
    PREVIOUS_MONTH,
    PREVIOUS_MONTH,
  ]);
  const mobilePopupContainerRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();
  const isTabletOrMobile = useIsTabletOrMobile();

  const getPopupContainer = mobilePopupContainerRef.current
    ? () => mobilePopupContainerRef.current as HTMLDivElement
    : undefined;

  const applyChanges = (rangeValue: RangeValue) => {
    const newValue = rangeValue
      ? (rangeValue.map((singleValue, index) =>
          singleValue ? getFormattedRangeValue({ value: singleValue, index }) : null
        ) as RangeValue)
      : null;
    onChange(newValue);
  };

  const handleChange: RangePickerProps['onChange'] = (rangeValue) => applyChanges(rangeValue);

  const handleOk: RangePickerProps['onOk'] = (rangeValue) => {
    if (rangeValue[0] && rangeValue[1]) applyChanges(rangeValue);
  };

  const handleDisabledDate: RangePickerProps['disabledDate'] = (current) => current && current > dayjs().endOf('day');

  const handleCalendarChange: RangePickerProps['onCalendarChange'] = ([start, end]) => {
    if (start && !end) {
      return setDefaultPickerValue(undefined);
    }
    if (!start && end) {
      const defaultStart = end.add(-30, 'd').startOf('day');
      return setDefaultPickerValue([defaultStart, end]);
    }

    setDefaultPickerValue([PREVIOUS_MONTH, PREVIOUS_MONTH]);
  };

  return {
    isMobile,
    presets: isMobile ? undefined : RANGE_PRESETS,
    showTime: isMobile ? { format: TIME_HOUR_LONG } : undefined,
    dateFormat: isMobile ? DATE_RANGE_FORMAT_SHORT : DATE_RANGE_FORMAT,
    size: (isTabletOrMobile ? 'large' : 'middle') as SizeType,
    defaultPickerValue: isMobile ? undefined : defaultPickerValue,
    mobilePopupContainerRef,
    getPopupContainer,
    handleChange,
    handleOk,
    handleDisabledDate,
    handleCalendarChange,
  };
};

export default useDateRangePicker;
