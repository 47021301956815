import AntIcon from '@ant-design/icons';
import { LocationIcon as Icon } from '@/shared/assets';
import styles from './styles.module.css';

const LocationIcon = () => {
  return (
    <AntIcon
      component={Icon}
      className={styles.icon}
    />
  );
};

export default LocationIcon;
