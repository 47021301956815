import { useCallback, useState } from 'react';

const useDetectionsVisibility = () => {
  const [areDetectionsVisible, setAreDetectionsVisible] = useState(true);

  const memoToggleDetectionsVisibility = useCallback(() => {
    setAreDetectionsVisible((prevState) => !prevState);
  }, []);

  return {
    areDetectionsVisible,
    memoToggleDetectionsVisibility,
  };
};

export default useDetectionsVisibility;
