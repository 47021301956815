import { Typography } from 'antd';
import { FoxFrequency } from '@/entities/source/@fox/types';
import { Highlighter } from '@/shared/components';
import styles from './style.module.css';

interface Props {
  value: FoxFrequency['value'];
  search?: string;
}

const FoxFrequencyValue = ({ value, search }: Props) => {
  return (
    <Typography.Text
      strong
      className={styles.value}
    >
      <Highlighter
        text={value}
        searchTokens={search ? [search] : undefined}
      />
    </Typography.Text>
  );
};

export default FoxFrequencyValue;
