import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getFoxFrequenciesFilters } from '@/entities/source/@fox/api';
import { FoxFrequenciesFilters } from '@/entities/source/@fox/types';
import { ResponseData } from '@/shared/api';
import { FOX_FREQUENCIES_FILTERS_QUERY_KEY } from '@/shared/constants';

interface Params {
  sourceId: string;
  options?: UseQueryOptions<
    ResponseData<FoxFrequenciesFilters>,
    unknown,
    ResponseData<FoxFrequenciesFilters>,
    string[]
  >;
}

const useGetFoxFrequenciesFilters = ({ sourceId, options }: Params) => {
  const { data: filters, isLoading: isFiltersLoading } = useQuery({
    ...options,
    queryKey: [FOX_FREQUENCIES_FILTERS_QUERY_KEY, sourceId],
    queryFn: () => getFoxFrequenciesFilters(sourceId),
  });

  return { filters, isFiltersLoading };
};

export default useGetFoxFrequenciesFilters;
