import { useState } from 'react';

const useMobileMapVisible = () => {
  const [isMobileMapVisible, setIsMobileMapVisible] = useState(false);
  const toggleMobileMapVisible = () => setIsMobileMapVisible((prevState) => !prevState);
  const showMobileMap = () => setIsMobileMapVisible(true);
  const hideMobileMap = () => setIsMobileMapVisible(false);

  return {
    isMobileMapVisible,
    toggleMobileMapVisible,
    showMobileMap,
    hideMobileMap,
  };
};

export default useMobileMapVisible;
