import { InfiniteData } from '@tanstack/react-query';
import { InfiniteQueryData, PaginatedWithFoffset } from '@/shared/api/types';

export class ReactQueryHelperService {
  getInfiniteData<T>(data?: InfiniteData<InfiniteQueryData<T>>): T[] {
    return data?.pages.map((page) => page.models || []).flat() ?? [];
  }

  /**
   * @deprecated Please use hasPaginatedParam instead
   */
  isPaginatedWithFoffset<T>(paginatedPage?: InfiniteQueryData<T>): paginatedPage is PaginatedWithFoffset<T> {
    return typeof paginatedPage === 'object' && 'foffset' in paginatedPage;
  }

  hasPaginatedParam<T>(paginatedPage: InfiniteQueryData<T>, paginatedParam: string) {
    return paginatedParam in paginatedPage;
  }
}

export const reactQueryHelperService = new ReactQueryHelperService();
