import { useEffect } from 'react';
import { Form } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import useGetUnitUser from '@/entities/unit/components/UnitContent/components/UnitUsersTab/hooks/useGetUnitUser';
import { getUnitUsersQueryKey } from '@/entities/unit/components/UnitContent/components/UnitUsersTab/utils';
import validations from '@/shared/constants/validation.json';
import { useRenderPropsContext } from '@/shared/context';
import { useParams } from '@/shared/hooks';
import { validationService } from '@/shared/services';
import { UnitRenderProps, UnitRouteParams } from '@/shared/types';
import { EditUnitUserModalProps } from '../types';
import useUpdateUnitUser from './useUpdateUnitUser';

const MODAL_TITLE = 'Редагувати користувача';
const MODAL_OK_TEXT = 'Зберегти';
const FULL_NAME_FIELD_LABEL = 'Ім’я';
const FULL_NAME_FIELD_NAME = 'fullName';
const ENTER_FULL_NAME = 'Введіть ім’я';
const FULL_NAME_FIELD_VALIDATION = [{ required: true, message: ENTER_FULL_NAME }];

interface Params extends EditUnitUserModalProps {}

interface UnitUserForm {
  fullName: string;
  role: string;
  groupId: string;
  unitId: string;
}

const useEditUnitUserModal = ({ userId, onClose }: Params) => {
  const [form] = Form.useForm<UnitUserForm>();
  const user = useGetUnitUser(userId);
  const { unitId = '' } = useParams<UnitRouteParams>();
  const queryKey = getUnitUsersQueryKey(unitId);
  const { userRoles, renderUserRoleSelect, renderAccessGroupSelect } = useRenderPropsContext<UnitRenderProps>();
  const queryClient = useQueryClient();

  const { updateUnitUser, isUnitUserUpdating } = useUpdateUnitUser({
    options: {
      onSuccess: async () => {
        onClose();
        await queryClient.invalidateQueries(queryKey);
      },
      onError: (error) => {
        validationService.validateForm({ form, error, validations });
      },
    },
  });

  const userRole = userRoles.find((role) => role.name === user?.role);

  const handleFormFinish = (formData: UnitUserForm) => {
    const { role, ...restFormData } = formData;
    const newUserRole = userRoles.find((roleItem) => roleItem.id === role);

    if (!userId || !newUserRole) return;

    updateUnitUser({
      userId,
      payload: {
        role: newUserRole.name,
        ...restFormData,
      },
    });
  };

  useEffect(() => {
    if (!user || !userRole) return;

    form.setFieldsValue({
      fullName: user.fullName || undefined,
      role: userRole.id,
      groupId: user.groupId || undefined,
      unitId: user.unitId || undefined,
    });
  }, [user, userRole, form]);

  return {
    form,
    isUnitUserUpdating,
    modalTitle: MODAL_TITLE,
    modalOkText: MODAL_OK_TEXT,
    fullNameFieldLabel: FULL_NAME_FIELD_LABEL,
    fullNameFieldName: FULL_NAME_FIELD_NAME,
    fullNameFieldValidation: FULL_NAME_FIELD_VALIDATION,
    fullNameInputPlaceholder: ENTER_FULL_NAME,
    handleFormFinish,
    renderUserRoleSelect,
    renderAccessGroupSelect,
  };
};

export default useEditUnitUserModal;
