import { ALLOWED_PAGES, RoleName } from '@/shared/constants';
import { MenuItem } from '../types';

export const filterItemsByRole = (items: MenuItem[], role: RoleName): MenuItem[] => {
  if (!ALLOWED_PAGES[role]) return [];

  return items.filter(({ key }) => {
    return ALLOWED_PAGES[role].some((page) => {
      return typeof key === 'string' && key.startsWith(page);
    });
  });
};
