import { ConnectDropTarget, useDrop } from 'react-dnd';
import { DRAG_TYPE } from '@/entities/frequency/constants';
import { RadioNetwork } from '@/shared/types';

interface UseDroppableItemWrapper {
  isOver: boolean;
  drop: ConnectDropTarget;
}

const useDroppableItemWrapper = (item: RadioNetwork): UseDroppableItemWrapper => {
  const [{ isOver }, drop] = useDrop({
    accept: DRAG_TYPE,
    drop: () => ({ item }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return {
    isOver,
    drop,
  };
};

export default useDroppableItemWrapper;
