import { Form } from 'antd';
import { LOCATION } from '@/entities/location/constants';
import { LocationId } from '@/entities/location/types';
import { Select, SelectProps } from '@/shared/components';
import { LocationOptionType } from '@/shared/types';

type Props = SelectProps<LocationId[], LocationOptionType> & {
  isAsync?: boolean;
  isLoading?: boolean;
};

export const LocationFormMultiSelect = ({ isLoading, isAsync, disabled = false, ...restProps }: Props) => {
  return (
    <Form.Item label={LOCATION}>
      <Select
        {...restProps}
        mode="multiple"
        optionFilterProp={!isAsync ? 'label' : undefined}
        loading={isLoading}
        disabled={disabled}
      />
    </Form.Item>
  );
};
