import Details from '@/entities/source/@fox/components/Details';
import { useRecordDetails } from '@/entities/source/@fox/components/RecordsTab/hooks';
import styles from './styles.module.css';

const MetadataDetails = () => {
  const details = useRecordDetails();

  return (
    <Details
      details={details}
      wrapperClassname={styles.wrapper}
      titleClassName={styles.title}
      withTitle={false}
    />
  );
};

export default MetadataDetails;
