import { FocusEvent, KeyboardEventHandler, useState, useRef } from 'react';
import { Key } from 'ts-key-enum';
import { FoxFrequency } from '@/entities/source/@fox/types';
import { ResponseData } from '@/shared/api';
import { TextAreaRef } from '@/shared/components';
import { EMPTY_FORM_ITEM_VALIDATION } from '@/shared/constants';
import validations from '@/shared/constants/validation.json';
import { useParams } from '@/shared/hooks';
import { validationService } from '@/shared/services';
import { FormItemValidation, SourceRouteParams } from '@/shared/types';
import { FoxFrequencyNoteProps } from '../types';
import useUpdateFoxFrequency from './useUpdateFoxFrequency';
import useUpdateFoxFrequencyNoteCache from './useUpdateFoxFrequencyNoteCache';

const PLACEHOLDER = 'немає нотатків';

interface Params extends Pick<FoxFrequencyNoteProps, 'note' | 'foxFrequencyId'> {}

const useFoxFrequencyNote = ({ note, foxFrequencyId }: Params) => {
  const { sourceId = '' } = useParams<SourceRouteParams>();
  const textAreaRef = useRef<TextAreaRef>(null);
  const [foxFrequencyNoteKey, setFoxFrequencyNoteKey] = useState<number>(0);
  const [noteValidation, setNoteValidation] = useState<FormItemValidation>(EMPTY_FORM_ITEM_VALIDATION);
  const updateFoxFrequencyNoteCache = useUpdateFoxFrequencyNoteCache();
  const { updateFoxFrequency } = useUpdateFoxFrequency({
    onError: handleUpdateFoxFrequencyError,
    onSuccess: handleUpdateFoxFrequencySuccess,
  });

  function handleUpdateFoxFrequencyError(error: unknown) {
    const formItemValidation = validationService.getFormItemValidation({ error, validations, errorPointer: 'note' });

    if (!formItemValidation.validateStatus) {
      resetFoxFrequencyNote();
      return;
    }

    setNoteValidation(formItemValidation);
    textAreaRef.current?.focus();
  }

  function handleUpdateFoxFrequencySuccess({ data: { note: newNote } }: ResponseData<FoxFrequency>) {
    updateFoxFrequencyNoteCache({ sourceId, foxFrequencyId, note: newNote });
    resetFoxFrequencyNote();
  }

  const handleBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
    const newNote = e.currentTarget.value.trim();
    if (newNote === note || !sourceId) {
      return;
    }

    updateFoxFrequency({ sourceId, foxFrequencyId, payload: { note: newNote } });
  };

  const handleKeyDown: KeyboardEventHandler = (event) => {
    event.stopPropagation();
    if (event.nativeEvent.key === Key.Escape) {
      resetFoxFrequencyNote();
    }
  };

  function resetFoxFrequencyNote() {
    setFoxFrequencyNoteKey((prevKey) => prevKey + 1);
  }

  return {
    textAreaRef,
    placeholder: PLACEHOLDER,
    noteValidation,
    foxFrequencyNoteKey,
    handleBlur,
    handleKeyDown,
  };
};

export default useFoxFrequencyNote;
