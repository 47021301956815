import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/shared/types';
import { LayoutSidebarState } from './types';

const initialState: LayoutSidebarState = {
  isLayoutSidebarLeftCollapsed: true,
  isLayoutSidebarRightInDrawer: false,
};

const layoutSidebarSlice = createSlice({
  name: 'layoutSidebar',
  initialState,
  reducers: {
    setIsLayoutSidebarLeftCollapsed: (
      state: LayoutSidebarState,
      action: PayloadAction<LayoutSidebarState['isLayoutSidebarLeftCollapsed']>
    ) => {
      state.isLayoutSidebarLeftCollapsed = action.payload;
    },
    setIsLayoutSidebarRightInDrawer: (
      state: LayoutSidebarState,
      action: PayloadAction<LayoutSidebarState['isLayoutSidebarRightInDrawer']>
    ) => {
      state.isLayoutSidebarRightInDrawer = action.payload;
    },
  },
});

export const { setIsLayoutSidebarLeftCollapsed, setIsLayoutSidebarRightInDrawer } = layoutSidebarSlice.actions;

export const selectIsLayoutSidebarLeftCollapsed = (state: RootState) => {
  return state.layoutSidebar.isLayoutSidebarLeftCollapsed;
};

export const selectIsLayoutSidebarRightInDrawer = (state: RootState) => {
  return state.layoutSidebar.isLayoutSidebarRightInDrawer;
};

export default layoutSidebarSlice.reducer;
