import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateNetwork as updateNetworkApi } from '@/entities/network/api';
import { REBUILDING_CONNECTIONS_IS_IN_PROGRESS } from '@/entities/network/constants';
import { isRadioNetworkLocked } from '@/entities/network/utils';
import {
  UPDATE_NETWORK_MUTATION_KEY,
  TRANSCRIPTS_QUERY_KEY,
  DETAILS_QUERY_KEY,
  CALL_SIGNS_QUERY_KEY,
  CODES_QUERY_KEY,
  NOTES_QUERY_KEY,
  RADIO_NETWORK_QUERY_KEY,
  STATUS_TYPES,
} from '@/shared/constants';
import { useNotificationContext } from '@/shared/context';
import { Network } from '@/shared/types';

type Params = {
  onSuccess?: (data: Network) => void;
  onError?: () => void;
};

const FAILED_TO_UPDATE_NETWORK = 'Не вдалось оновити мережу';

export const useUpdateNetwork = ({ onSuccess, onError }: Params) => {
  const { openNotification } = useNotificationContext();
  const queryClient = useQueryClient();

  const { mutate: updateNetwork, isLoading: isUpdating } = useMutation({
    mutationKey: [UPDATE_NETWORK_MUTATION_KEY],
    mutationFn: updateNetworkApi,
    onSuccess: async (updatedNetwork) => {
      onSuccess?.(updatedNetwork);
      await queryClient.invalidateQueries([TRANSCRIPTS_QUERY_KEY]);
      queryClient.invalidateQueries([RADIO_NETWORK_QUERY_KEY, updatedNetwork.id]);
      queryClient.invalidateQueries([DETAILS_QUERY_KEY, updatedNetwork.id]);
      queryClient.invalidateQueries([CALL_SIGNS_QUERY_KEY, updatedNetwork.id]);
      queryClient.invalidateQueries([CODES_QUERY_KEY, updatedNetwork.id]);
      queryClient.invalidateQueries([NOTES_QUERY_KEY, updatedNetwork.id]);
    },
    onError: (error) => {
      const notificationDescription = isRadioNetworkLocked({ error, param: 'frequencies' })
        ? REBUILDING_CONNECTIONS_IS_IN_PROGRESS
        : FAILED_TO_UPDATE_NETWORK;
      openNotification({
        type: 'error',
        title: STATUS_TYPES.ERROR,
        description: notificationDescription,
      });
      onError?.();
    },
  });

  return {
    updateNetwork,
    isUpdating,
  };
};
