import { Typography } from 'antd';
import { FoxKey } from '@/entities/source/@fox/types';
import { useIsMobile } from '@/shared/hooks';

interface Props {
  keyId: FoxKey['keyId'];
}

const FoxKeyKeyId = ({ keyId }: Props) => {
  const isMobile = useIsMobile();

  return <Typography.Text strong={isMobile}>{keyId}</Typography.Text>;
};

export default FoxKeyKeyId;
