import { MouseEvent as ReactMouseEvent, useEffect } from 'react';
import { Key } from 'ts-key-enum';
import { convertFrequenciesArrayToDictionary } from '@/entities/source/@fox/components/ScanningTab/utils';
import { FoxFrequency } from '@/entities/source/@fox/types';
import {
  selectLastSelectedFrequency,
  selectSelectedFrequencies,
  setLastSelectedFrequency,
  setSelectedFrequencies,
} from '@/entities/source/slices';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';

interface Props {
  foxFrequencies: FoxFrequency[];
}

const useRowSelection = ({ foxFrequencies }: Props) => {
  const selectedFrequencies = useAppSelector(selectSelectedFrequencies);
  const lastSelectedFrequency = useAppSelector(selectLastSelectedFrequency);
  const dispatch = useAppDispatch();

  const handleRowClick = (frequencyId: FoxFrequency['id']) => {
    return (event: ReactMouseEvent<any, MouseEvent>) => {
      if (event.ctrlKey || event.metaKey) {
        toggleSelectedFrequency(frequencyId);
      } else if (event.shiftKey) {
        setSeveralSelectedFrequencies(frequencyId);
      } else {
        setSelectedFrequency(frequencyId);
      }
      dispatch(setLastSelectedFrequency(frequencyId));
    };
  };

  function toggleSelectedFrequency(frequencyId: string) {
    if (selectedFrequencies?.[frequencyId]) {
      const { ...frequenciesCopy } = selectedFrequencies;
      delete frequenciesCopy[frequencyId];
      dispatch(setSelectedFrequencies(frequenciesCopy));
    } else {
      dispatch(
        setSelectedFrequencies({
          ...selectedFrequencies,
          [frequencyId]: true,
        })
      );
    }
  }

  function setSeveralSelectedFrequencies(frequencyId: string) {
    if (lastSelectedFrequency) {
      const lastSelectedFrequencyIndex = foxFrequencies.findIndex(({ id }) => id === lastSelectedFrequency);
      const currentSelectedFrequencyIndex = foxFrequencies.findIndex(({ id }) => id === frequencyId);

      const foxFrequenciesSlice =
        lastSelectedFrequencyIndex < currentSelectedFrequencyIndex
          ? foxFrequencies.slice(lastSelectedFrequencyIndex, currentSelectedFrequencyIndex + 1)
          : foxFrequencies.slice(currentSelectedFrequencyIndex, lastSelectedFrequencyIndex + 1);

      dispatch(setSelectedFrequencies(convertFrequenciesArrayToDictionary(foxFrequenciesSlice)));
    } else {
      setSelectedFrequency(frequencyId);
    }
  }

  function setSelectedFrequency(frequencyId: string) {
    dispatch(
      setSelectedFrequencies({
        [frequencyId]: true,
      })
    );
  }

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === Key.Shift) {
        document.body.style.userSelect = 'none';
      }
    };
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === Key.Shift) {
        document.body.style.userSelect = 'initial';
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  return {
    handleRowClick,
  };
};

export default useRowSelection;
