import { TranscriptionCluster } from '@/entities/transcript/types';

export const EMPTY_NETWORK = {
  networkId: null,
  networkName: null,
};

const moveEmptyNetworkToEnd = (networks: TranscriptionCluster['metadata']['networks'] = []) => {
  const emptyNetworkIndex = networks.indexOf(EMPTY_NETWORK);

  if (emptyNetworkIndex < 0) return networks;

  return [...networks.slice(0, emptyNetworkIndex), ...networks.slice(emptyNetworkIndex + 1), EMPTY_NETWORK];
};

export default moveEmptyNetworkToEnd;
