import { MutableRefObject, useState } from 'react';
import { useDebounceFn, useResizeObserver } from '@reactuses/core';

const RESIZE_DELAY = 100;

export const useContentSize = (contentRef: MutableRefObject<HTMLElement | null>) => {
  const [contentWidth, setContentWidth] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);

  const handleResize: ResizeObserverCallback = (entries) => {
    const [entry] = entries;
    const { width, height } = entry.contentRect;
    setContentWidth(width);
    setContentHeight(height);
  };
  const { run: debouncedHandleResize } = useDebounceFn(handleResize, RESIZE_DELAY);

  useResizeObserver(contentRef, debouncedHandleResize);

  return {
    contentWidth,
    contentHeight,
  };
};
