import { PropsWithChildren, useEffect } from 'react';
import { ConfigProvider, App } from 'antd';
import ukUA from 'antd/locale/uk_UA';
import { Empty } from '@/shared/components';
import { COPIES } from '@/shared/constants';
import { useAppSelector } from '@/shared/hooks';
import { selectIsDarkMode } from '@/shared/slices';
import { InsertRootCssVariables } from './components/InsertRootCssVariables';
import { light, dark } from './config';

const ThemeConfigProvider = ({ children }: PropsWithChildren) => {
  const isDarkMode = useAppSelector(selectIsDarkMode);

  const renderEmpty = () => <Empty description={COPIES.NO_DATA} />;

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  return (
    <ConfigProvider
      theme={isDarkMode ? dark : light}
      locale={ukUA}
      renderEmpty={renderEmpty}
    >
      <App>
        <InsertRootCssVariables />
        {children}
      </App>
    </ConfigProvider>
  );
};

export default ThemeConfigProvider;
