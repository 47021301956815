import { Dayjs } from 'dayjs';

interface Params {
  value: Dayjs;
  index: number;
}

const getFormattedRangeValue = ({ value, index }: Params) => {
  if (value.hour() || value.minute()) return value;

  if (index === 0) {
    return value.hour(0).minute(0).second(0).millisecond(0);
  }

  return value.hour(23).minute(59).second(59).millisecond(999);
};

export default getFormattedRangeValue;
