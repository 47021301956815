import { PropsWithChildren, useState, useEffect } from 'react';

type Props = {
  timeout?: number;
};

/**
 * We have a problem when tab's content rendering is blocker the animation of the tabs switching.
 * This component can help to resolve it through delaying the tab's content rendering on the time Antd animation (500ms by default).
 */
const DeferredTabContent = ({ timeout = 500, children }: PropsWithChildren<Props>) => {
  const [shouldBeRender, setShouldBeRender] = useState(false);

  useEffect(() => {
    if (shouldBeRender) setShouldBeRender(false);

    const timeoutId = setTimeout(() => setShouldBeRender(true), timeout);
    return () => clearTimeout(timeoutId);
  }, [timeout]);

  return shouldBeRender ? children : null;
};

export default DeferredTabContent;
