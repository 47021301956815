import { useState } from 'react';
import { FrequencyProps } from '../types';
import useCalculatedStyles from './useCalculatedStyles';
import useFrequencyAutoFocus from './useFrequencyAutoFocus';
import useInputEventHandlers from './useInputEventHandlers';
import useWrappers from './useWrappers';

const INPUT_MAX_LENGTH = 8;

interface Params extends FrequencyProps {}

const useFrequency = ({
  startFrequency,
  endFrequency,
  frequencyHeight,
  memoOnDeleteFrequency,
  memoOnSave,
  isDisabled,
  isAutoFocusEnabled,
  frequency,
  id,
  isActive,
  onFrequencyClick,
  maxLeftPosition,
  maxRightPosition,
  isDigital,
}: Params) => {
  const [isInputActive, setIsInputActive] = useState(false);

  const {
    inputWrapperRef,
    inputWrapperWidth,
    wrapperRef,
    isWrapperHovered,
    inputRef,
    handleWrapperClick,
    wrapperLeftPosition,
    wrapperRightPosition,
  } = useWrappers({ setIsInputActive, isDisabled, id, onFrequencyClick });

  const isInputVisible = isWrapperHovered || isInputActive;

  const calculatedStyles = useCalculatedStyles({
    startFrequency,
    endFrequency,
    frequency,
    frequencyHeight,
    inputWrapperWidth,
    maxLeftPosition,
    maxRightPosition,
    wrapperLeftPosition,
    wrapperRightPosition,
    isInputVisible,
  });

  const { inputValue, inputStatus, isLoading, handleInputKeyUp, handleInputChange, handleInputBlur, handleInputFocus } =
    useInputEventHandlers({
      frequency,
      memoOnSave,
      memoOnDeleteFrequency,
      isActive,
      id,
      inputMaxLength: INPUT_MAX_LENGTH,
      isInputActive,
      setIsInputActive,
      inputRef,
      isDisabled,
      isDigital,
    });

  useFrequencyAutoFocus({ isAutoFocusEnabled, showInput: handleWrapperClick, inputRef });

  return {
    inputWrapperRef,
    isInputVisible,
    ...calculatedStyles,
    wrapperRef,
    inputValue,
    inputRef,
    isLoading,
    inputMaxLength: INPUT_MAX_LENGTH,
    inputStatus,
    handleInputKeyUp,
    handleInputBlur,
    handleWrapperClick,
    handleInputChange,
    handleInputFocus,
  };
};

export default useFrequency;
