import {
  CENTERED_CLUSTER_CIRCLE_RADIUS,
  CENTERED_POINT_CIRCLE_RADIUS,
  CLUSTER_CIRCLE_RADIUS,
  HAS_LOCATION_TYPE,
  IS_MARKER_CENTERED,
  IS_LOCATION_CLUSTER,
  MAP_COLORS,
  POINT_CIRCLE_RADIUS,
  SYMBOL_TEXT_FONTS,
  SYMBOL_TEXT_SIZE,
} from '@/entities/map/constants';
import { DefaultMarker } from '@/entities/map/types';

export const DEFAULT_CLUSTER: DefaultMarker = {
  marker: {
    type: 'circle',
    paint: {
      'circle-radius': ['case', IS_MARKER_CENTERED, CENTERED_CLUSTER_CIRCLE_RADIUS, CLUSTER_CIRCLE_RADIUS],
      'circle-color': ['case', IS_LOCATION_CLUSTER, MAP_COLORS.location, MAP_COLORS.primary],
      'circle-opacity': 0.5,
      'circle-stroke-width': 1,
      'circle-stroke-color': MAP_COLORS.base,
      'circle-stroke-opacity': ['case', IS_MARKER_CENTERED, 1, 0],
    },
  },
  markerInner: {
    type: 'circle',
    paint: {
      'circle-radius': 15,
      'circle-color': ['case', IS_LOCATION_CLUSTER, MAP_COLORS.location, MAP_COLORS.primary],
    },
  },
  markerCount: {
    type: 'symbol',
    layout: {
      'text-field': '{point_count}',
      'text-font': SYMBOL_TEXT_FONTS,
      'text-size': SYMBOL_TEXT_SIZE,
    },
  },
};

export const DEFAULT_POINT: DefaultMarker = {
  marker: {
    type: 'circle',
    paint: {
      'circle-radius': ['case', IS_MARKER_CENTERED, CENTERED_POINT_CIRCLE_RADIUS, POINT_CIRCLE_RADIUS],
      'circle-color': ['case', HAS_LOCATION_TYPE, MAP_COLORS.location, MAP_COLORS.primary],
      'circle-stroke-width': 1,
      'circle-stroke-color': MAP_COLORS.base,
      'circle-stroke-opacity': ['case', IS_MARKER_CENTERED, 1, 0],
    },
  },
  markerInner: {
    type: 'circle',
    layout: {
      visibility: 'none',
    },
  },
  markerCount: {
    type: 'symbol',
    layout: {
      visibility: 'none',
    },
  },
};
