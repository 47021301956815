import {
  ActivateFoxFrequency,
  CreateFoxFrequencyPayload,
  DeactivateFoxFrequency,
  FoxFrequenciesFilters,
  FoxFrequency,
  UpdateFoxFrequenciesStatus,
} from '@/entities/source/@fox/types';
import { request, RequestQueryParams, ResponseData } from '@/shared/api';
import { V3_PREFIX } from '@/shared/config';
import {
  ActivateFoxFrequenciesParams,
  ActivateFoxFrequencyParams,
  DeactivateFoxFrequenciesParams,
  DeactivateFoxFrequencyParams,
  DeleteFoxFrequenciesParams,
  DeleteFoxFrequencyParams,
  UpdateFoxFrequencyParams,
} from './types';
import {
  activateFoxFrequencySchema,
  createFoxFrequencySchema,
  deactivateFoxFrequencySchema,
  getFoxFrequenciesFiltersSchema,
  getFoxFrequenciesSchema,
  updateFoxFrequenciesStatusResponseSchema,
  updateFoxFrequencySchema,
} from './validationSchemas';

export const getFoxFrequencies = ({
  sourceId,
  queryParams,
}: {
  sourceId: string;
  queryParams?: RequestQueryParams;
}) => {
  return request<ResponseData<FoxFrequency[]>>(`/sources/fox/${sourceId}/frequencies`, {
    endpointPrefix: V3_PREFIX,
    validationSchema: getFoxFrequenciesSchema,
    params: queryParams,
  });
};

export const createFoxFrequency = ({ sourceId, payload }: { sourceId: string; payload: CreateFoxFrequencyPayload }) => {
  return request<ResponseData<FoxFrequency>>(`/sources/fox/${sourceId}/frequencies`, {
    method: 'POST',
    endpointPrefix: V3_PREFIX,
    validationSchema: createFoxFrequencySchema,
    body: JSON.stringify(payload),
  });
};

export const updateFoxFrequency = ({ sourceId, foxFrequencyId, payload }: UpdateFoxFrequencyParams) => {
  return request<ResponseData<FoxFrequency>>(`/sources/fox/${sourceId}/frequencies/${foxFrequencyId}`, {
    method: 'PATCH',
    body: JSON.stringify(payload),
    endpointPrefix: V3_PREFIX,
    validationSchema: updateFoxFrequencySchema,
  });
};

export const activateFoxFrequency = ({ sourceId, foxFrequencyId }: ActivateFoxFrequencyParams) => {
  return request<ActivateFoxFrequency>(`/sources/fox/${sourceId}/frequencies/${foxFrequencyId}/activate`, {
    method: 'POST',
    endpointPrefix: V3_PREFIX,
    validationSchema: activateFoxFrequencySchema,
  });
};

export const activateFoxFrequencies = ({ sourceId, queryParams }: ActivateFoxFrequenciesParams) => {
  return request<ResponseData<UpdateFoxFrequenciesStatus>>(`/sources/fox/${sourceId}/frequencies/activate/bulk`, {
    method: 'POST',
    endpointPrefix: V3_PREFIX,
    validationSchema: updateFoxFrequenciesStatusResponseSchema,
    params: queryParams,
  });
};

export const deactivateFoxFrequency = ({ sourceId, foxFrequencyId }: DeactivateFoxFrequencyParams) => {
  return request<DeactivateFoxFrequency>(`/sources/fox/${sourceId}/frequencies/${foxFrequencyId}/deactivate`, {
    method: 'POST',
    endpointPrefix: V3_PREFIX,
    validationSchema: deactivateFoxFrequencySchema,
  });
};

export const deactivateFoxFrequencies = ({ sourceId, queryParams }: DeactivateFoxFrequenciesParams) => {
  return request<ResponseData<UpdateFoxFrequenciesStatus>>(`/sources/fox/${sourceId}/frequencies/deactivate/bulk`, {
    method: 'POST',
    endpointPrefix: V3_PREFIX,
    validationSchema: updateFoxFrequenciesStatusResponseSchema,
    params: queryParams,
  });
};

export const deleteFoxFrequency = ({ sourceId, foxFrequencyId }: DeleteFoxFrequencyParams) => {
  return request(`/sources/fox/${sourceId}/frequencies/${foxFrequencyId}`, {
    method: 'DELETE',
    endpointPrefix: V3_PREFIX,
  });
};

export const deleteFoxFrequencies = ({ sourceId, queryParams }: DeleteFoxFrequenciesParams) => {
  return request(`/sources/fox/${sourceId}/frequencies/bulk`, {
    method: 'DELETE',
    endpointPrefix: V3_PREFIX,
    params: queryParams,
  });
};

export const getFoxFrequenciesFilters = (sourceId: string) => {
  return request<ResponseData<FoxFrequenciesFilters>>(`/sources/fox/${sourceId}/frequencies/filter`, {
    endpointPrefix: V3_PREFIX,
    validationSchema: getFoxFrequenciesFiltersSchema,
  });
};
