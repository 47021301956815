import { EntityType } from '@/shared/constants';

export const ALLOWED_CHARS = [
  '-',
  '—',
  '[',
  ']',
  '{',
  '}',
  '(',
  ')',
  '*',
  '+',
  '?',
  '=',
  '№',
  'ʼ',
  '‘',
  '’',
  '"',
  '«',
  '»',
  "'",
  '“',
  '”',
  '₴',
  '%',
  '!',
  '>',
  '<',
  ':',
  ';',
  '.',
  ',',
  '~',
  '$',
  '/',
  '\\',
  '^',
  '&',
  '|',
  '#',
  '@',
  '_',
].join('\\');

export const TEMPORARY_WRAPPERS: Record<EntityType, [string, string]> = {
  [EntityType.TAG]: ['', ''],
  [EntityType.LOCATION]: ['', ''],
  [EntityType.FACTION]: ['', ''],
  [EntityType.CALL_SIGN]: ['<x>', '</x>'],
  [EntityType.CODE]: ['<xx>', '</xx>'],
  [EntityType.SEARCH]: ['<xxx>', '</xxx>'],
};
