import {
  ActivateReceiver,
  ActivateFox,
  ReloadFox,
  StopReceiver,
  UpgradeFox,
  FoxWaterfallAuth,
  FoxSettings,
  SaveFoxSettings,
} from '@/entities/source/@fox/types';
import { request, ResponseData } from '@/shared/api';
import { V3_PREFIX } from '@/shared/config';
import { SaveFoxSettingsParams } from './types';
import {
  activateReceiverSchema,
  activateFoxSchema,
  reloadFoxSchema,
  stopReceiverSchema,
  upgradeFoxSchema,
  generateFoxWaterfallAuthSchema,
  getFoxSettingsSchema,
  saveFoxSettingsSchema,
} from './validationSchemas';

export const activateFox = (sourceId: string) => {
  return request<ActivateFox>(`/sources/fox/${sourceId}/activate`, {
    method: 'POST',
    endpointPrefix: V3_PREFIX,
    validationSchema: activateFoxSchema,
  });
};

export const reloadFox = (sourceId: string) => {
  return request<ReloadFox>(`/sources/fox/${sourceId}/reload`, {
    method: 'POST',
    endpointPrefix: V3_PREFIX,
    validationSchema: reloadFoxSchema,
  });
};

export const upgradeFox = (sourceId: string) => {
  return request<UpgradeFox>(`/sources/fox/${sourceId}/upgrade`, {
    method: 'POST',
    endpointPrefix: V3_PREFIX,
    validationSchema: upgradeFoxSchema,
  });
};

export const activateReceiver = (sourceId: string) => {
  return request<ActivateReceiver>(`/sources/fox/${sourceId}/receiver/activate`, {
    method: 'POST',
    endpointPrefix: V3_PREFIX,
    validationSchema: activateReceiverSchema,
  });
};

export const stopReceiver = (sourceId: string) => {
  return request<StopReceiver>(`/sources/fox/${sourceId}/receiver/stop`, {
    method: 'POST',
    endpointPrefix: V3_PREFIX,
    validationSchema: stopReceiverSchema,
  });
};

export const getFoxSettings = (sourceId: string) => {
  return request<ResponseData<FoxSettings>>(`/sources/fox/${sourceId}/settings`, {
    endpointPrefix: V3_PREFIX,
    validationSchema: getFoxSettingsSchema,
  });
};

export const saveFoxSettings = ({ sourceId, payload }: SaveFoxSettingsParams) => {
  return request<SaveFoxSettings>(`/sources/fox/${sourceId}/settings`, {
    method: 'PATCH',
    body: JSON.stringify(payload),
    endpointPrefix: V3_PREFIX,
    validationSchema: saveFoxSettingsSchema,
  });
};

export const generateFoxWaterfallAuth = (sourceId: string) => {
  return request<ResponseData<FoxWaterfallAuth>>(`/sources/fox/${sourceId}/waterfall/auth`, {
    method: 'POST',
    endpointPrefix: V3_PREFIX,
    validationSchema: generateFoxWaterfallAuthSchema,
  });
};
