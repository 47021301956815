import { useRef } from 'react';
import { App, ModalFuncProps } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { COPIES } from '@/shared/constants';
import { useIsTabletOrMobile } from '@/shared/hooks/mobile';
import styles from './styles.module.css';

interface UseConfirmModalParams extends Pick<ModalFuncProps, 'title' | 'content' | 'okText' | 'cancelButtonProps'> {
  onOk: (value?: unknown) => Promise<void>;
}

const useConfirmModal = ({
  title,
  content,
  onOk,
  okText = COPIES.REMOVE,
  cancelButtonProps: customCancelButtonProps,
}: UseConfirmModalParams) => {
  const { modal } = App.useApp();
  const isTabletOrMobile = useIsTabletOrMobile();
  const isModalOpen = useRef(false);

  const size = isTabletOrMobile ? 'large' : undefined;

  return (value?: unknown, onConfirmSuccess?: VoidFunction) => {
    if (isModalOpen.current) return;

    isModalOpen.current = true;
    const containerElement = document.fullscreenElement || document.body;
    const okButtonProps: ModalFuncProps['okButtonProps'] = { className: styles.modalOkButton, size };
    const cancelButtonProps: ModalFuncProps['cancelButtonProps'] = {
      className: styles.modalCancelButton,
      size,
      ...customCancelButtonProps,
    };

    const { update, destroy } = modal.confirm({
      wrapClassName: styles.modal,
      icon: <ExclamationCircleOutlined />,
      title,
      content,
      getContainer: containerElement as HTMLElement,
      okText,
      cancelText: COPIES.CANCEL,
      okButtonProps,
      cancelButtonProps,
      afterClose() {
        isModalOpen.current = false;
      },
      onOk: async () => {
        try {
          update({
            okButtonProps: { ...okButtonProps, disabled: true },
            cancelButtonProps: { ...cancelButtonProps, disabled: true },
          });
          await onOk(value);
          onConfirmSuccess?.();
        } finally {
          update({
            okButtonProps: { ...okButtonProps, disabled: false },
            cancelButtonProps: { ...cancelButtonProps, disabled: false },
          });
          destroy();
        }
      },
    });
  };
};

export default useConfirmModal;
