import { LoadingWrapper, ScrollUpToRefresh, Table } from '@/shared/components';
import { EditUnitSourceModal } from './components';
import { useUnitSourcesTab } from './hooks';

const UnitSourcesTab = () => {
  const { sourcesList, isLoading, columns, handleScrollUpToReFetch, editUnitSourceId, handleCloseModal } =
    useUnitSourcesTab();

  return (
    <LoadingWrapper
      isLoading={isLoading}
      isFixedOverlay
    >
      <ScrollUpToRefresh
        isScrollUpToRefreshEnabled={sourcesList.length > 0}
        onRefresh={handleScrollUpToReFetch}
      >
        <Table
          rowKey="id"
          columns={columns}
          dataSource={sourcesList}
        />
      </ScrollUpToRefresh>
      {editUnitSourceId && (
        <EditUnitSourceModal
          sourceId={editUnitSourceId}
          onClose={handleCloseModal}
        />
      )}
    </LoadingWrapper>
  );
};

export default UnitSourcesTab;
