import { useEffect, useState } from 'react';
import { Form } from 'antd';
import { SubscriptionType } from '@/entities/subscription';
import { useSubscriptionState } from '@/widgets/SubscriptionDrawer/components/SubscriptionsSettings/components/SubscriptionSettingsControls/hooks';
import { useGetSubscriptions } from '@/widgets/SubscriptionDrawer/hooks';
import { InputTags } from './components';

const KEYWORD = 'Ключовe слово';

const KeywordField = () => {
  const { subscriptions } = useGetSubscriptions();
  const { handleSubscriptionChange } = useSubscriptionState();
  const [keywords, setKeywords] = useState<string[]>([]);

  useEffect(() => {
    if (subscriptions?.models[0]) {
      setKeywords(
        subscriptions.models[0].value.reduce(
          (acc: string[], { type, value }) =>
            type === SubscriptionType.Keyword && value ? [...acc, value as string] : acc,
          []
        )
      );
    }
  }, []);

  const handleChange = (values: string[]) => {
    handleSubscriptionChange(values, SubscriptionType.Keyword);
  };

  return (
    <Form.Item label={KEYWORD}>
      <InputTags
        data={keywords}
        onDataUpdated={handleChange}
        disabled={!subscriptions?.models.length}
      />
    </Form.Item>
  );
};

export default KeywordField;
