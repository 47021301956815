import { Form, SliderSingleProps, Slider, Checkbox, Space } from 'antd';
import classNames from 'classnames';
import { useIsMobile } from '@/shared/hooks';
import styles from './styles.module.css';

const LABEL = 'Підсилення:';
const CHECKBOX_LABEL = 'Автопідсилення';

enum Range {
  Min = -15,
  Medium = 0,
  Max = 45,
}

const marks: SliderSingleProps['marks'] = {
  [Range.Min]: '-15db',
  [Range.Medium]: '0',
  [Range.Max]: '+45db',
};

interface Props {
  amplification: number;
  setAmplification: (value: number) => void;
  autoAmplification: boolean;
  setAutoAmplification: (value: boolean) => void;
  isDisabled?: boolean;
}

const FrequencyAmplification = ({
  autoAmplification,
  amplification,
  setAmplification,
  setAutoAmplification,
  isDisabled = false,
}: Props) => {
  const isMobile = useIsMobile();

  const checkbox = (
    <Checkbox
      value={autoAmplification}
      checked={autoAmplification}
      onChange={() => setAutoAmplification(!autoAmplification)}
      disabled={isDisabled}
    >
      {CHECKBOX_LABEL}
    </Checkbox>
  );

  const slider = !autoAmplification && (
    <Slider
      className={classNames(styles.slider, amplification < 0 ? styles.negativeValue : styles.positiveValue)}
      min={Range.Min}
      max={Range.Max}
      marks={marks}
      value={amplification}
      onChange={setAmplification}
      disabled={isDisabled}
      tooltip={{ placement: isMobile ? 'right' : 'top' }}
    />
  );

  return isMobile ? (
    <Form.Item>
      <Space>
        {LABEL}
        {checkbox}
      </Space>
      {slider}
    </Form.Item>
  ) : (
    <Form.Item label={LABEL}>
      <div className={styles.controls}>
        {checkbox}
        {slider}
      </div>
    </Form.Item>
  );
};

export default FrequencyAmplification;
