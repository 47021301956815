import { SourceTabEntityNavParams } from '@/entities/source/types';
import { useNavigateWithMobileView } from '@/shared/hooks';
import { routingService } from '@/shared/services';

const useNavigateToRecord = () => {
  const navigate = useNavigateWithMobileView();

  return ({ routeParams, options }: SourceTabEntityNavParams) => {
    return navigate({
      pathname: routingService.getFoxRecordUrl(routeParams),
      options,
    });
  };
};

export default useNavigateToRecord;
