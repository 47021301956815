import { useParams, useIsMobile } from '@/shared/hooks';
import { RadioNetwork, RadioNetworkRouteParams } from '@/shared/types';
import { useNavigateToRadioNetwork } from './useNavigateToRadioNetwork';

export const useChooseNetwork = () => {
  const { activeTab, radioNetworkId } = useParams<RadioNetworkRouteParams>();
  const isMobile = useIsMobile();
  const navigate = useNavigateToRadioNetwork();

  const chooseNetwork = (network: RadioNetwork, withMobileView?: boolean) => {
    if (!isMobile && radioNetworkId === network.id) return;
    const routeParams = {
      radioNetworkId: network.id,
      activeTab,
    };

    navigate({ routeParams, options: { withMobileView, clearPreviousQueryParams: true, withAllQueryParams: true } });
  };

  return { chooseNetwork };
};
