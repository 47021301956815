import { useState, RefObject, useCallback, useEffect } from 'react';

interface Params {
  ref: RefObject<HTMLElement>;
}

const useFullScreen = ({ ref }: Params) => {
  const [isFullScreenEnabled, setIsFullScreenEnabled] = useState(false);

  const enterFullscreen = useCallback(() => {
    if (!ref.current) return;

    const elem = ref.current;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    }
  }, [ref]);

  const exitFullscreen = useCallback(() => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }, []);

  const memoToggleFullScreen = useCallback(() => {
    if (!document.fullscreenElement) {
      enterFullscreen();
    } else {
      exitFullscreen();
    }
  }, [enterFullscreen, exitFullscreen]);

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreenEnabled(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  return {
    isFullScreenEnabled,
    memoToggleFullScreen,
  };
};

export default useFullScreen;
