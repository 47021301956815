import { DEFAULT_CLUSTER_TAB } from '@/entities/transcript/constants';
import { useGetTranscriptById } from '@/entities/transcript/hooks';
import { selectActiveClusterTabs } from '@/entities/transcript/slices';
import { TranscriptItemProps } from '@/entities/transcript/types';
import { useAppSelector } from '@/shared/hooks';

interface Params extends Omit<TranscriptItemProps, 'activeItemId'> {}

const useTranscriptCluster = ({ item }: Params) => {
  const isItemCluster = item.type === 'cluster';
  const clusterId = isItemCluster ? item.id : '';
  const clusterMetadata = isItemCluster ? item.metadata : undefined;
  const clusterTranscript = isItemCluster ? item.transcript : item;
  const clusterTranscriptIds = clusterMetadata ? clusterMetadata.sources.map(({ transcriptId }) => transcriptId) : [];

  const activeTab = useAppSelector(selectActiveClusterTabs)[clusterId] ?? DEFAULT_CLUSTER_TAB;
  const tabTranscriptId = activeTab === DEFAULT_CLUSTER_TAB ? '' : activeTab;
  const { transcript: tabTranscript, isTranscriptFetching } = useGetTranscriptById(tabTranscriptId);

  const transcript = tabTranscriptId && tabTranscript ? tabTranscript : clusterTranscript;

  return {
    isCluster: isItemCluster && !tabTranscriptId,
    clusterId,
    clusterMetadata,
    clusterTranscriptIds,
    transcript,
    isTranscriptFetching,
  };
};

export default useTranscriptCluster;
