import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/shared/types';
import { MobileViewState } from './types';

const initialState: MobileViewState = {
  activeMobileDetailsManagerState: null,
};

const mobileViewSlice = createSlice({
  name: 'mobileView',
  initialState,
  reducers: {
    setActiveMobileDetailsManagerState: (
      state: MobileViewState,
      action: PayloadAction<MobileViewState['activeMobileDetailsManagerState']>
    ) => {
      state.activeMobileDetailsManagerState = action.payload;
    },
    resetActiveMobileDetailsManagerState: (state: MobileViewState) => {
      state.activeMobileDetailsManagerState = null;
    },
  },
});

export const { setActiveMobileDetailsManagerState, resetActiveMobileDetailsManagerState } = mobileViewSlice.actions;

export const selectActiveMobileDetailsManagerState = (state: RootState) =>
  state.mobileView.activeMobileDetailsManagerState;

export default mobileViewSlice.reducer;
