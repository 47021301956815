import { EditOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { ERROR_COPIES } from '@/shared/constants';
import { useParams } from '@/shared/hooks';
import { UnitRouteParams } from '@/shared/types';
import { UnitUserActionsProps } from '../types';
import useActivateUnitUser from './useActivateUnitUser';
import useDeactivateUnitUser from './useDeactivateUnitUser';
import useUpdateUnitUserCache from './useUpdateUnitUserCache';

const {
  DEACTIVATE_USER: { buttonText: DEACTIVATE_LABEL },
  ACTIVATE_USER: { buttonText: ACTIVATE_LABEL },
  EDIT_USER: { buttonText: EDIT_LABEL },
} = ERROR_COPIES;

enum ItemKey {
  Deactivate = 'deactivate',
  Activate = 'activate',
  Edit = 'edit',
}

interface Params extends Pick<UnitUserActionsProps, 'userId' | 'isActive' | 'onEditUserClick'> {}

const useUnitUserActions = ({ userId, isActive, onEditUserClick }: Params) => {
  const { unitId = '' } = useParams<UnitRouteParams>();
  const updateUnitUserCache = useUpdateUnitUserCache(unitId);

  const { activateUnitUser } = useActivateUnitUser({
    userId,
    options: {
      onSuccess: () => {
        updateUnitUserCache({
          id: userId,
          isActive: true,
        });
      },
    },
  });

  const { deactivateUnitUser } = useDeactivateUnitUser({
    userId,
    options: {
      onSuccess: () => {
        updateUnitUserCache({
          id: userId,
          isActive: false,
        });
      },
    },
  });

  const handleOpenEditUserModal = () => {
    onEditUserClick(userId);
  };

  const actionItems = [
    isActive
      ? {
          key: ItemKey.Deactivate,
          label: DEACTIVATE_LABEL,
          icon: <LockOutlined />,
          onClick: deactivateUnitUser,
        }
      : {
          key: ItemKey.Activate,
          label: ACTIVATE_LABEL,
          icon: <UnlockOutlined />,
          onClick: activateUnitUser,
        },
    {
      key: ItemKey.Edit,
      label: EDIT_LABEL,
      icon: <EditOutlined />,
      onClick: handleOpenEditUserModal,
    },
  ];

  return {
    actionItems,
  };
};

export default useUnitUserActions;
