import { useCallback } from 'react';
import type { GetComponentProps } from 'rc-table/lib/interface';
import { SourceTabEntityNavParams } from '@/entities/source/types';
import { useParams } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';
import { TableRow } from '../types';
import { isClickOnTooltip } from '../utils';

type NavigateTo = (value: SourceTabEntityNavParams) => void;

const useRow = <T extends TableRow>(navigateTo: NavigateTo) => {
  const { sourceId } = useParams<SourceRouteParams>();

  const handleRow: GetComponentProps<T> = useCallback(
    ({ id }) => ({
      onClick: (event) => {
        if (isClickOnTooltip(event.target)) return;

        navigateTo({
          routeParams: {
            sourceId,
            tabEntityId: id,
          },
          options: { withAllQueryParams: true },
        });
      },
    }),
    [sourceId]
  );

  return handleRow;
};

export default useRow;
