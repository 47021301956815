import { Dispatch, SetStateAction } from 'react';
import { Flex, Form } from 'antd';
import { useRenderPropsContext } from '@/shared/context';
import { useIsMobile } from '@/shared/hooks';
import { SourceRenderProps } from '@/shared/types';
import styles from './styles.module.css';

const LABEL = 'Зона покриття:';

type Props = {
  coverageArea?: string[];
  setCoverageArea: Dispatch<SetStateAction<string[] | undefined>>;
  setIsDrawModeEnabled: Dispatch<SetStateAction<boolean>>;
};

const CoverageArea = ({ coverageArea = [], setCoverageArea, setIsDrawModeEnabled }: Props) => {
  const { renderHexagonMap } = useRenderPropsContext<SourceRenderProps>();
  const isMobile = useIsMobile();

  const map = renderHexagonMap({
    coverageArea,
    onCoverageChange: setCoverageArea,
    onDrawModeChange: setIsDrawModeEnabled,
  });

  return isMobile ? (
    <Form.Item className={styles.wrapper}>
      <Flex
        gap={24}
        vertical
        className={styles.mapWrapper}
      >
        {LABEL}
        {map}
      </Flex>
    </Form.Item>
  ) : (
    <Form.Item
      label={LABEL}
      className={styles.wrapper}
    >
      <div className={styles.mapWrapper}>{map}</div>
    </Form.Item>
  );
};

export default CoverageArea;
