import { CodeKey } from '@/entities/code/constants';

export enum ColumnTitle {
  CodeTitle = 'Код', // impossible to name it as Code because Code was imported above, getting ts error
  Value = 'Значення',
  Notes = 'Нотатки',
}

export enum CodePlaceholder {
  Name = 'Немає коду',
  Value = 'Немає значення',
  Note = 'Немає нотатків',
}

export const CODE_NAME_REQUIRED = `Назва коду є обов'язковою`;
export const CODE_NAME_MIN = `Назва коду має містити не менше ніж 2 символи`;

export const CODENAME_VALIDATION = [
  { required: true, message: CODE_NAME_REQUIRED },
  { min: 2, message: CODE_NAME_MIN },
];

export const CODE_COLUMNS = [
  {
    dataIndex: CodeKey.Name,
    title: ColumnTitle.CodeTitle,
    placeholder: CodePlaceholder.Name,
    formValidationRule: CODENAME_VALIDATION,
    autoFocus: true,
  },
  {
    dataIndex: CodeKey.Value,
    title: ColumnTitle.Value,
    placeholder: CodePlaceholder.Value,
  },
  {
    dataIndex: CodeKey.Note,
    title: ColumnTitle.Notes,
    placeholder: CodePlaceholder.Note,
    isTextArea: true,
  },
];

export const NEW_CODE = 'Новий код';

export const CREATE_CODE_ID = 'create-code';
