import { Form } from 'antd';
import UnitSelect from '@/entities/unit/components/UnitSelect';
import { Input, Modal } from '@/shared/components';
import { COPIES } from '@/shared/constants';
import { normalizeFormItem } from '@/shared/utils';
import { ACCESS_GROUP, COMMAND, MODAL_TITLE, SERIAL_NUMBER, SOURCE_NAME, SOURCE_TYPE } from './constants';
import { useEditUnitSourceModal } from './hooks';
import styles from './styles.module.css';
import { EditUnitSourceModalProps } from './types';

const EditUnitSourceModal = ({ sourceId, onClose }: EditUnitSourceModalProps) => {
  const {
    form,
    isFoxSource,
    isBilkaSource,
    isVuhoSource,
    isUnitSourceUpdating,
    handleFormFinish,
    renderSourceTypeSelect,
    renderAccessGroupSelect,
  } = useEditUnitSourceModal({
    sourceId,
    onClose,
  });

  return (
    <Modal
      title={MODAL_TITLE}
      okText={COPIES.APPLY}
      onOk={form.submit}
      onCancel={onClose}
      confirmLoading={isUnitSourceUpdating}
      destroyOnClose
      open
    >
      <Form
        className={styles.form}
        form={form}
        layout="vertical"
        onFinish={handleFormFinish}
      >
        <Form.Item
          name={SOURCE_NAME.name}
          label={SOURCE_NAME.label}
          extra={SOURCE_NAME.extraLabel}
          required
        >
          <Input disabled />
        </Form.Item>
        {renderSourceTypeSelect?.({
          name: SOURCE_TYPE.name,
          label: SOURCE_TYPE.label,
          required: true,
          disabled: true,
        })}
        {renderAccessGroupSelect?.({
          name: ACCESS_GROUP.name,
          label: ACCESS_GROUP.label,
          required: true,
          disabled: true,
        })}
        <UnitSelect required />
        {isFoxSource && (
          <Form.Item
            name={SERIAL_NUMBER.name}
            label={SERIAL_NUMBER.label}
            rules={SERIAL_NUMBER.rules}
            normalize={normalizeFormItem}
          >
            <Input />
          </Form.Item>
        )}
        {(isBilkaSource || isVuhoSource) && (
          <Form.Item
            name={COMMAND.name}
            label={COMMAND.label}
            rules={COMMAND.rules}
            normalize={normalizeFormItem}
          >
            <Input />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default EditUnitSourceModal;
