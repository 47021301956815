import { useCallback, useEffect, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Key } from 'ts-key-enum';
import { FoxFrequencyStatus } from '@/entities/source/@fox/constants';
import { selectSelectedFrequencies, setLastSelectedFrequency, setSelectedFrequencies } from '@/entities/source/slices';
import { FOX_FREQUENCIES_QUERY_KEY, SPACE_KEY, SPECTROGRAM_DRAWER_CLASSNAME } from '@/shared/constants';
import validations from '@/shared/constants/validation.json';
import {
  useAppDispatch,
  useAppSelector,
  useConfirmModal,
  useParams,
  useOpenFormItemValidationNotification,
} from '@/shared/hooks';
import { hotkeysHelperService } from '@/shared/services';
import { SourceRouteParams } from '@/shared/types';
import useActivateFoxFrequencies from './useActivateFoxFrequencies';
import useDeactivateFoxFrequencies from './useDeactivateFoxFrequencies';
import useDeleteFoxFrequencies from './useDeleteFoxFrequencies';
import useDeleteFoxFrequency from './useDeleteFoxFrequency';
import useDeleteFoxFrequencyFromCache from './useDeleteFoxFrequencyFromCache';
import useFoxFrequenciesQueryParams from './useFoxFrequenciesQueryParams';
import useGetFilteredFoxFrequencies from './useGetFilteredFoxFrequencies';

const DELETE_FREQUENCY_MODAL_TITLE = 'Видалити частоту?';
const DELETE_FREQUENCY_MODAL_CONTENT =
  'Вибрану частоту буде видалено назавжди.\n Отримані перехоплення залишаться в системі.';

const DELETE_FREQUENCIES_MODAL_TITLE = 'Видалити частоти?';
const DELETE_FREQUENCIES_MODAL_CONTENT =
  'Вибрані частоти буде знято з прослуховування.\n Отримані перехоплення залишаться в системі.';

const useScanningHotKeys = () => {
  const queryClient = useQueryClient();
  const openFormItemValidationNotification = useOpenFormItemValidationNotification();
  const dispatch = useAppDispatch();
  const queryParams = useFoxFrequenciesQueryParams();

  const selectedFrequencies = useAppSelector(selectSelectedFrequencies);
  const memoSelectedFrequenciesIds = useMemo(
    () => (selectedFrequencies ? Object.keys(selectedFrequencies) : []),
    [selectedFrequencies]
  );
  const amountOfSelectedFrequencies = memoSelectedFrequenciesIds.length;

  const { sourceId = '' } = useParams<SourceRouteParams>();
  const queryKey = [FOX_FREQUENCIES_QUERY_KEY, sourceId, queryParams];
  const { foxFrequencies } = useGetFilteredFoxFrequencies();

  const deleteFoxFrequencyFromCache = useDeleteFoxFrequencyFromCache(queryKey);

  const memoResetSelectedFrequenciesDictionary = useCallback(() => {
    dispatch(setSelectedFrequencies(null));
    dispatch(setLastSelectedFrequency(null));
  }, [dispatch]);

  const { activateFoxFrequencies } = useActivateFoxFrequencies({
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKey);
    },
  });

  const { deactivateFoxFrequencies } = useDeactivateFoxFrequencies({
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKey);
    },
    onError: (error) => {
      openFormItemValidationNotification({ error, validations, errorPointer: 'value' });
    },
  });

  const { deleteFoxFrequencyAsync } = useDeleteFoxFrequency({
    onSuccess: (_, variables) => {
      deleteFoxFrequencyFromCache(variables.foxFrequencyId);
    },
  });

  const { deleteFoxFrequenciesAsync } = useDeleteFoxFrequencies({
    onSuccess: (_, variables) => {
      if (!('frequency' in variables.queryParams) || !Array.isArray(variables.queryParams.frequency)) return;

      variables.queryParams.frequency.forEach((frequencyId) => {
        if (typeof frequencyId !== 'string') return;

        deleteFoxFrequencyFromCache(frequencyId);
      });
    },
  });

  const deleteFrequency = useConfirmModal({
    title: DELETE_FREQUENCY_MODAL_TITLE,
    content: DELETE_FREQUENCY_MODAL_CONTENT,
    onOk: async (value) => {
      if (typeof value !== 'string') return;

      await deleteFoxFrequencyAsync({ sourceId, foxFrequencyId: value });
    },
  });

  const deleteFrequencies = useConfirmModal({
    title: DELETE_FREQUENCIES_MODAL_TITLE,
    content: DELETE_FREQUENCIES_MODAL_CONTENT,
    onOk: async (value) => {
      if (!Array.isArray(value)) return;

      await deleteFoxFrequenciesAsync({ sourceId, queryParams: { frequency: value } });
    },
  });

  useEffect(() => {
    if (!selectedFrequencies) return;

    const handleKeydown = (e: KeyboardEvent) => {
      if (hotkeysHelperService.getIsInputOrTextarea(e)) return;
      if (
        !hotkeysHelperService.getIsElementInDom(`.${SPECTROGRAM_DRAWER_CLASSNAME}`) &&
        hotkeysHelperService.getIsOverlayedComponentOpen()
      )
        return;

      const { key } = e;

      if (key === Key.Delete || key === Key.Backspace) {
        if (amountOfSelectedFrequencies === 1) {
          deleteFrequency(memoSelectedFrequenciesIds[0], memoResetSelectedFrequenciesDictionary);
        } else {
          deleteFrequencies(memoSelectedFrequenciesIds, memoResetSelectedFrequenciesDictionary);
        }
      }
      if (key === Key.Escape) {
        memoResetSelectedFrequenciesDictionary();
      }
      if (key === SPACE_KEY) {
        const shouldActivateFrequencies = Boolean(
          foxFrequencies.find(
            (frequency) => frequency.id in selectedFrequencies && !(frequency.status === FoxFrequencyStatus.Active)
          )
        );
        if (shouldActivateFrequencies) {
          activateFoxFrequencies({ sourceId, queryParams: { frequency: memoSelectedFrequenciesIds } });
        } else {
          deactivateFoxFrequencies({ sourceId, queryParams: { frequency: memoSelectedFrequenciesIds } });
        }
      }
    };

    document.addEventListener('keydown', handleKeydown);
    return () => document.removeEventListener('keydown', handleKeydown);
  }, [
    amountOfSelectedFrequencies,
    activateFoxFrequencies,
    deactivateFoxFrequencies,
    memoResetSelectedFrequenciesDictionary,
    memoSelectedFrequenciesIds,
    selectedFrequencies,
    foxFrequencies,
  ]);
};

export default useScanningHotKeys;
