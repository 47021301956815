import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/shared/types';
import { SourceState } from './types';

const initialState: SourceState = {
  isCreateSourceModalOpened: false,
  isRecordPlaying: false,
  selectedRecordId: null,
  selectedFrequencies: null,
  lastSelectedFrequency: null,
};

const sourceSlice = createSlice({
  name: 'source',
  initialState,
  reducers: {
    setIsCreateSourceModalOpened: (
      state: SourceState,
      action: PayloadAction<SourceState['isCreateSourceModalOpened']>
    ) => {
      state.isCreateSourceModalOpened = action.payload;
    },
    setIsRecordPlaying: (state: SourceState, action: PayloadAction<SourceState['isRecordPlaying']>) => {
      state.isRecordPlaying = action.payload;
    },
    setSelectedRecordId: (state: SourceState, action: PayloadAction<SourceState['selectedRecordId']>) => {
      state.selectedRecordId = action.payload;
    },
    setSelectedFrequencies: (state: SourceState, action: PayloadAction<SourceState['selectedFrequencies']>) => {
      state.selectedFrequencies = action.payload;
    },
    setLastSelectedFrequency: (state: SourceState, action: PayloadAction<SourceState['lastSelectedFrequency']>) => {
      state.lastSelectedFrequency = action.payload;
    },
  },
});

export const {
  setIsCreateSourceModalOpened,
  setIsRecordPlaying,
  setSelectedRecordId,
  setSelectedFrequencies,
  setLastSelectedFrequency,
} = sourceSlice.actions;

export const selectIsCreateSourceModalOpened = (state: RootState) => state.source.isCreateSourceModalOpened;

export const selectIsRecordPlaying = (state: RootState) => state.source.isRecordPlaying;

export const selectSelectedRecordId = (state: RootState) => state.source.selectedRecordId;

export const selectSelectedFrequencies = (state: RootState) => state.source.selectedFrequencies;

export const selectLastSelectedFrequency = (state: RootState) => state.source.lastSelectedFrequency;

export default sourceSlice.reducer;
