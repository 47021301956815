import { UIEventHandler, useRef, useState } from 'react';
import { useIsMutating } from '@tanstack/react-query';
import AddNewFoxFrequencyButton from '@/entities/source/@fox/components/ScanningTab/components/AddNewFoxFrequencyButton';
import {
  useFoxFrequenciesQueryParams,
  useGetFilteredFoxFrequencies,
} from '@/entities/source/@fox/components/ScanningTab/hooks';
import { useSource, useSourceScroll } from '@/entities/source/hooks';
import { FoxSource } from '@/entities/source/types';
import {
  ACTIVATE_FOX_FREQUENCY_MUTATION_KEY,
  DEACTIVATE_FOX_FREQUENCY_MUTATION_KEY,
  DELETE_FOX_FREQUENCY_MUTATION_KEY,
  UPDATE_FOX_FREQUENCY_MUTATION_KEY,
  FOX_FREQUENCIES_QUERY_KEY,
  CREATE_FOX_FREQUENCY_MUTATION_KEY,
  ACTIVATE_FOX_FREQUENCIES_MUTATION_KEY,
  DEACTIVATE_FOX_FREQUENCIES_MUTATION_KEY,
  FoxScanningQueryParam,
} from '@/shared/constants';
import { useParams, useInvalidateQueryOnTop, useSearchParam } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';
import AddFrequencyRow from '../components/AddFrequencyRow';
import styles from '../styles.module.css';
import useRowSelection from './useRowSelection';

const useFoxFrequenciesTable = () => {
  const source = useSource<FoxSource>();
  const isSourceOnline = source?.status === 'online';
  const [isOnTop, setIsOnTop] = useState(true);
  const frequenciesListWrapperRef = useRef<HTMLDivElement>(null);
  const { sourceId = '' } = useParams<SourceRouteParams>();
  const queryParams = useFoxFrequenciesQueryParams();
  const [search] = useSearchParam(FoxScanningQueryParam.Search);
  const [isReFetchingByScrollUp, setIsReFetchingByScrollUp] = useState(false);

  const [isAddingNewFoxFrequency, setIsAddingNewFoxFrequency] = useState(false);
  const handleAddNewFoxFrequency = () => setIsAddingNewFoxFrequency(true);
  const handleCancelAddNewFoxFrequency = () => setIsAddingNewFoxFrequency(false);

  const { foxFrequencies, isFoxFrequenciesLoading, isFoxFrequenciesFetching, refetchFoxFrequencies } =
    useGetFilteredFoxFrequencies();
  const isFrequenciesListEmpty = foxFrequencies.length === 0;

  const isFoxFrequencyActivating = useIsMutating({ mutationKey: [ACTIVATE_FOX_FREQUENCY_MUTATION_KEY] });
  const isFoxFrequencyDeactivating = useIsMutating({ mutationKey: [DEACTIVATE_FOX_FREQUENCY_MUTATION_KEY] });
  const isFoxFrequenciesActivating = useIsMutating({ mutationKey: [ACTIVATE_FOX_FREQUENCIES_MUTATION_KEY] });
  const isFoxFrequenciesDeactivating = useIsMutating({ mutationKey: [DEACTIVATE_FOX_FREQUENCIES_MUTATION_KEY] });
  const isFoxFrequencyDeleting = useIsMutating({ mutationKey: [DELETE_FOX_FREQUENCY_MUTATION_KEY] });
  const isFoxFrequencyUpdating = useIsMutating({ mutationKey: [UPDATE_FOX_FREQUENCY_MUTATION_KEY] });
  const isFoxFrequencyEditing =
    isFoxFrequenciesLoading ||
    Boolean(isFoxFrequencyActivating) ||
    Boolean(isFoxFrequencyDeactivating) ||
    Boolean(isFoxFrequencyDeleting) ||
    Boolean(isFoxFrequencyUpdating) ||
    Boolean(isFoxFrequenciesActivating) ||
    Boolean(isFoxFrequenciesDeactivating);
  const isFoxFrequencyCreating = useIsMutating({
    mutationKey: [CREATE_FOX_FREQUENCY_MUTATION_KEY, sourceId],
  });
  const isLoadingIndicatorVisible =
    !isReFetchingByScrollUp && (isFoxFrequenciesFetching || isFoxFrequencyEditing || Boolean(isFoxFrequencyCreating));

  const addNewButton = isSourceOnline && (
    <AddNewFoxFrequencyButton
      className={styles.newButton}
      onClick={handleAddNewFoxFrequency}
    />
  );

  const addFrequencyRow = isAddingNewFoxFrequency && (
    <AddFrequencyRow onCancelAddNewFoxFrequency={handleCancelAddNewFoxFrequency} />
  );

  const handleListScroll: UIEventHandler<HTMLDivElement> = (e) => {
    const { scrollTop, scrollHeight } = e.currentTarget;
    if (scrollTop === 0 && !isOnTop) {
      setIsOnTop(true);
    } else if (scrollHeight > window.innerHeight && isOnTop) {
      setIsOnTop(false);
    }
  };

  const handleScrollUpToReFetch = async () => {
    try {
      setIsReFetchingByScrollUp(true);
      await refetchFoxFrequencies();
    } finally {
      setIsReFetchingByScrollUp(false);
    }
  };

  const { handleRowClick } = useRowSelection({ foxFrequencies });

  const { handleScroll, scrollTopPosition } = useSourceScroll();

  useInvalidateQueryOnTop({
    queryKey: [FOX_FREQUENCIES_QUERY_KEY, sourceId, queryParams],
    scrollTopPosition,
    isRefetchEnabled: !isFoxFrequenciesFetching,
  });

  return {
    foxFrequencies,
    isLoadingIndicatorVisible,
    handleRowClick,
    handleScroll,
    handleListScroll,
    isOnTop,
    handleScrollUpToReFetch,
    frequenciesListWrapperRef,
    addNewButton,
    addFrequencyRow,
    isFrequenciesListEmpty,
    search,
  };
};

export default useFoxFrequenciesTable;
