import { AxiosRequestConfig } from 'axios';
import { BACKEND_HOST, BASE_PREFIX } from '@/shared/config';
import { validationService } from '@/shared/services';
import { axiosApiInstance } from './axiosApiInstance';
import { RequestOptions } from './types';
import { optionsContainOnlySchema } from './utils';

// request arguments have such structure to not break compatibility with previous implementation based on fetch api
export const request = async <ResponseType>(url: string, options?: RequestOptions) => {
  const prefix = options?.endpointPrefix ?? BASE_PREFIX;
  const requestUrl = `${BACKEND_HOST}${prefix}${url}`;
  const axiosRequestConfig: AxiosRequestConfig = {
    url: requestUrl,
  };
  let response: ResponseType;
  if (!options || optionsContainOnlySchema(options)) {
    // get request
    response = await axiosApiInstance.request<unknown, ResponseType>(axiosRequestConfig);
  } else if ('body' in options) {
    // request with fetch payload
    const { body, method } = options;
    response = await axiosApiInstance.request<unknown, ResponseType>({
      ...axiosRequestConfig,
      method,
      data: JSON.parse(body),
    });
  } else {
    // fetch delete request or any axios request
    const { endpointPrefix, validationSchema, ...restOptions } = options;
    response = await axiosApiInstance.request<unknown, ResponseType>({
      ...axiosRequestConfig,
      ...restOptions,
    });
  }

  validationService.validateResponseData({
    validationSchema: options?.validationSchema,
    responseData: response,
    url,
  });

  return response;
};
