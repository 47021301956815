import { MobileView, MobileViewQueryParam } from '@/shared/constants';
import { useIsMobile, useNavigate } from '@/shared/hooks';
import { routingService } from '@/shared/services';

const useNetworkLink = (networkId: string) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const mobileViewParam = `${MobileViewQueryParam.Mobile}=${MobileView.SecondaryScreen}`;

  const path = routingService.getRadioNetworkUrl({ radioNetworkId: networkId });
  const queryString = isMobile ? mobileViewParam : '';

  const networkLinkHref = queryString ? `${path}?${queryString}` : path;

  const handleNetworkClick = () => {
    navigate({
      pathname: path,
      options: {
        additionalQueryParams: queryString,
      },
    });
  };

  return {
    networkLinkHref,
    handleNetworkClick,
  };
};

export default useNetworkLink;
