import { useQuery } from '@tanstack/react-query';
import { getSourceById } from '@/entities/source/api';
import { Source } from '@/entities/source/types';
import { SOURCE_BY_ID_QUERY_KEY } from '@/shared/constants';

const useGetSourceById = <T = Source>(sourceId: string) => {
  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: [SOURCE_BY_ID_QUERY_KEY, sourceId],
    queryFn: () => getSourceById<T>(sourceId),
    enabled: Boolean(sourceId),
  });

  return {
    source: data?.data,
    isSourceLoading: isLoading,
    isSourceFetching: isFetching,
    refetchSource: refetch,
  };
};

export default useGetSourceById;
