import { memo } from 'react';
import { HorizontalAxis } from './components';
import { FrequenciesAxesProps as Props } from './types';

const FrequenciesAxes = memo(({ startPoint, endPoint, topPosition, topFrequenciesAxisId }: Props) => {
  const topHorizontalAxisBottomStyle = `calc(${100 - topPosition}% - 45px)`;

  return (
    <>
      <HorizontalAxis
        wrapperId={topFrequenciesAxisId}
        startPoint={startPoint}
        endPoint={endPoint}
        bottom={topHorizontalAxisBottomStyle}
      />
      <HorizontalAxis
        startPoint={startPoint}
        endPoint={endPoint}
        bottom={0}
      />
    </>
  );
});

FrequenciesAxes.displayName = 'FrequenciesAxes';

export default FrequenciesAxes;
