import { createContext, FC, PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import { notification } from 'antd';
import { NotificationContextState, NotificationProps } from './types';

const NotificationContext = createContext<NotificationContextState | null>(null);

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotificationContext should be used inside NotificationContextProvider');
  }
  return context;
};

const NotificationContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();

  const openNotification = useCallback(
    ({ key, type, title, description, duration, closeIcon, className, placement = 'topLeft' }: NotificationProps) => {
      api[type]({
        key,
        message: title,
        description,
        placement,
        duration,
        closeIcon,
        className,
      });
    },
    [api]
  );

  const value = useMemo(
    () => ({
      api,
      openNotification,
    }),
    [api, openNotification]
  );

  return (
    <NotificationContext.Provider value={value}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContextProvider;
