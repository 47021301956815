import { Button, ButtonProps } from '@/shared/components/ui/Button';
import { FilterIconWithBadge } from './components';

type Props = ButtonProps & {
  filtersCount: number;
};

export const FilterButton = ({ filtersCount, ...restProps }: Props) => {
  return (
    <Button
      {...restProps}
      icon={<FilterIconWithBadge count={filtersCount} />}
      data-testid="filter-button"
    />
  );
};
