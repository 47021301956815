import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '@/shared/types';

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = (selector, options) =>
  useSelector(selector, {
    ...options,
    stabilityCheck: 'never',
  });
