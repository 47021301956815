import { useCallback } from 'react';
import { File } from '@/entities/source/@fox/types';
import { getSiblingRecordId } from '../utils';
import useFoxRecordsQueryParams from './useFoxRecordsQueryParams';
import useGetFiles from './useGetFiles';

interface GetRecordIdParams {
  currentRecordId: string;
  isSkipAudioWithoutVoiceEnabled: boolean;
  isSkipNotDecodedFileEnabled: boolean;
}

const useGetSiblingRecordId = () => {
  const queryParams = useFoxRecordsQueryParams();
  const { filesList } = useGetFiles({
    options: {
      enabled: false,
    },
    queryParams,
  });

  const getCurrentRecordIndex = (currentRecordId: string, list: File[]) =>
    list.findIndex(({ id }) => id === currentRecordId);

  const memoGetNextRecordId = useCallback(
    ({
      currentRecordId,
      isSkipAudioWithoutVoiceEnabled,
      isSkipNotDecodedFileEnabled,
    }: GetRecordIdParams): string | null => {
      const currentRecordIndex = getCurrentRecordIndex(currentRecordId, filesList);

      if (!isSkipAudioWithoutVoiceEnabled && !isSkipNotDecodedFileEnabled)
        return filesList[currentRecordIndex - 1]?.id ?? null;

      for (let i = currentRecordIndex - 1; i >= 0; i--) {
        const fileId = getSiblingRecordId({
          isSkipNotDecodedFileEnabled,
          isSkipAudioWithoutVoiceEnabled,
          file: filesList[i],
        });

        if (fileId) return fileId;
      }

      return null;
    },
    [filesList]
  );

  const memoGetPreviousRecordId = useCallback(
    ({
      currentRecordId,
      isSkipAudioWithoutVoiceEnabled,
      isSkipNotDecodedFileEnabled,
    }: GetRecordIdParams): string | null => {
      const currentRecordIndex = getCurrentRecordIndex(currentRecordId, filesList);

      if (!isSkipAudioWithoutVoiceEnabled && !isSkipNotDecodedFileEnabled)
        return filesList[currentRecordIndex + 1]?.id ?? null;

      for (let i = currentRecordIndex + 1; i < filesList.length; i++) {
        const fileId = getSiblingRecordId({
          isSkipNotDecodedFileEnabled,
          isSkipAudioWithoutVoiceEnabled,
          file: filesList[i],
        });

        if (fileId) return fileId;
      }

      return null;
    },
    [filesList]
  );

  return { memoGetNextRecordId, memoGetPreviousRecordId };
};

export default useGetSiblingRecordId;
