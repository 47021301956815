import { useEffect } from 'react';
import { useAppDispatch } from '@/shared/hooks';
import { setServerAvailability, setServerAvailabilityCheckEnabled, setSupportAlert } from '@/shared/slices';
import { useGetSupportData } from './useGetSupportData';

export const useSupportData = (isServerAvailabilityCheckEnabled: boolean) => {
  const { isServerAvailable, supportAlert, refetch } = useGetSupportData();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isServerAvailabilityCheckEnabled) refetch();
  }, [refetch, isServerAvailabilityCheckEnabled]);

  useEffect(() => {
    dispatch(setServerAvailability(isServerAvailable));
    dispatch(setServerAvailabilityCheckEnabled(false));
    if (supportAlert) {
      dispatch(setSupportAlert(supportAlert));
    }
  }, [dispatch, isServerAvailable, supportAlert, isServerAvailabilityCheckEnabled]);
};
