import { FoxFrequencyRangeLimitation } from '@/entities/source/@fox/constants';
import handleRightStep from './handleRightStep';

const handleDraggingRightSlider = (steps: number[], prevRange: number[], value: number) => {
  const maxStep = steps[steps.length - 1];
  const minStep = steps[0];

  // Handle dragging slider to the rigth
  if (value > prevRange[1] && value >= prevRange[0] + maxStep) {
    return [value - maxStep, value];
  }

  // Handle dragging slider to the left
  if (value < prevRange[1]) {
    const leftValue = value - minStep;

    if (leftValue <= FoxFrequencyRangeLimitation.Min) {
      return [FoxFrequencyRangeLimitation.Min, FoxFrequencyRangeLimitation.Min + minStep];
    }

    if (leftValue <= prevRange[0]) {
      return [leftValue, value];
    }
  }

  return handleRightStep(steps, prevRange, value);
};

export default handleDraggingRightSlider;
