import { useCallback } from 'react';
import {
  SelectedFrequenciesDictionary,
  SpectrogramProps,
} from '@/entities/source/@fox/components/ScanningTab/components/Spectrogram/types';

interface Params extends Pick<SpectrogramProps, 'memoSelectedFrequencies' | 'memoOnFrequenciesSelect'> {}

const useSelectedFrequenciesDictionary = ({ memoSelectedFrequencies, memoOnFrequenciesSelect }: Params) => {
  const isAnyFrequencySelected = memoSelectedFrequencies && Object.keys(memoSelectedFrequencies).length > 0;

  const memoResetSelectedFrequenciesDictionary = useCallback(() => {
    if (!isAnyFrequencySelected) return;

    memoOnFrequenciesSelect?.({});
  }, [isAnyFrequencySelected, memoOnFrequenciesSelect]);

  const memoSetSeveralSelectedFrequencies = useCallback(
    (ids: string[]) => {
      if (ids.length === 0) {
        memoResetSelectedFrequenciesDictionary();
        return;
      }

      const frequenciesDictionary: SelectedFrequenciesDictionary = {};
      ids.forEach((id) => {
        frequenciesDictionary[id] = true;
      });

      memoOnFrequenciesSelect?.(frequenciesDictionary);
    },
    [memoResetSelectedFrequenciesDictionary, memoOnFrequenciesSelect]
  );

  return {
    isAnyFrequencySelected,
    memoSetSeveralSelectedFrequencies,
    memoResetSelectedFrequenciesDictionary,
  };
};

export default useSelectedFrequenciesDictionary;
