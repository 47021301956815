import { memo } from 'react';
import { Form } from 'antd';
import { TextArea } from '@/shared/components';
import { useFoxFrequencyNote } from './hooks';
import { FoxFrequencyNoteProps } from './types';

const FoxFrequencyNote = memo(({ className, note, foxFrequencyId, label }: FoxFrequencyNoteProps) => {
  const { textAreaRef, placeholder, noteValidation, foxFrequencyNoteKey, handleBlur, handleKeyDown } =
    useFoxFrequencyNote({
      note,
      foxFrequencyId,
    });

  return (
    <Form.Item
      key={foxFrequencyNoteKey}
      className={className}
      label={label}
      {...noteValidation}
    >
      <TextArea
        ref={textAreaRef}
        key={note}
        defaultValue={note}
        autoSize
        placeholder={placeholder}
        isTransparentOnDesktop
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      />
    </Form.Item>
  );
});

FoxFrequencyNote.displayName = 'FoxFrequencyNote';

export default FoxFrequencyNote;
