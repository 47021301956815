import { useMemo } from 'react';
import { authService } from '@/shared/api';
import useRedirectAfterLogIn from './useRedirectAfterLogIn';

const useAuthRouteGuard = (isAuthRequired: boolean) => {
  const shouldRedirect = useMemo(() => {
    const hasAuthData = Boolean(authService.getAuthDataFromLocalStorage());

    return (isAuthRequired && !hasAuthData) || (!isAuthRequired && hasAuthData);
  }, [isAuthRequired]);

  useRedirectAfterLogIn({ isAuthRequired });

  return shouldRedirect;
};

export default useAuthRouteGuard;
