import dayjs from 'dayjs';
import {
  COPIES,
  DATE,
  DATE_FULL,
  DATE_FULL_TIME,
  DAY,
  TIME,
  TIME_FULL,
  DATE_FULL_MONTH_LONG_TIME_FULL,
} from '@/shared/constants';

export class TimeService {
  getFormattedDate(timestamp: number, withFullTime?: boolean) {
    const isSameDate = dayjs().isSame(timestamp, 'day');
    const isSameYear = dayjs().isSame(timestamp, 'year');
    const dateFormat = isSameYear ? DATE : DATE_FULL;
    const timeFormat = withFullTime ? TIME_FULL : TIME;

    const format = isSameDate ? timeFormat : dateFormat;

    return dayjs(timestamp).format(format);
  }

  getFormattedFullTimeMonthLong(timestamp: number) {
    return dayjs(timestamp).format(DATE_FULL_MONTH_LONG_TIME_FULL);
  }

  getDateRange(startDate?: number | null, endDate?: number | null) {
    if (!startDate && !endDate) return COPIES.NO_DATA;
    if (!endDate) return COPIES.NO_ACTIVITY;

    const dateFirst = dayjs(startDate);
    const dateSecond = dayjs(endDate);

    if (dayjs(startDate).isSame(endDate, 'day')) {
      return dateSecond.format(DATE_FULL);
    }

    if (dayjs(startDate).isSame(endDate, 'month')) {
      return `${dateFirst.format(DAY)} - ${dateSecond.format(DATE_FULL)}`;
    }

    if (dayjs(startDate).isSame(endDate, 'year')) {
      return `${dateFirst.format(DATE)} - ${dateSecond.format(DATE_FULL)}`;
    }

    return `${dateFirst.format(DATE_FULL)} - ${dateSecond.format(DATE_FULL)}`;
  }

  getDateFullTimeRange(startDate?: number | null, endDate?: number | null) {
    if (!startDate || !endDate) return;

    if (dayjs(startDate).isSame(endDate, 'day')) {
      return dayjs(endDate).format(DATE_FULL_TIME);
    }

    return `${dayjs(startDate).format(DATE_FULL_TIME)} - ${dayjs(endDate).format(DATE_FULL_TIME)}`;
  }
}

export const timeService = new TimeService();
