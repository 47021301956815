import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';
import { RootState } from '@/shared/types';
import { TranscriptionDictionaryItem } from '../types';
import { TranscriptsState } from './types';

const initialState: TranscriptsState = {
  transcriptsDictionary: {},
  transcriptEntitiesDictionary: {},
  activeClusterTabs: {},
};

export const transcriptsSlice = createSlice({
  name: 'transcripts',
  initialState,
  reducers: {
    addTranscriptsDictionary: (state: TranscriptsState, action: PayloadAction<TranscriptionDictionaryItem[]>) => {
      state.transcriptsDictionary = { ...state.transcriptsDictionary, ...keyBy(action.payload, 'id') };
    },
    addTranscriptEntitiesDictionary: (
      state: TranscriptsState,
      action: PayloadAction<TranscriptsState['transcriptEntitiesDictionary']>
    ) => {
      state.transcriptEntitiesDictionary = { ...state.transcriptEntitiesDictionary, ...action.payload };
    },
    addActiveClusterTabs: (state: TranscriptsState, action: PayloadAction<TranscriptsState['activeClusterTabs']>) => {
      state.activeClusterTabs = { ...state.activeClusterTabs, ...action.payload };
    },
    resetTranscripts: () => initialState,
  },
});

export const { addTranscriptsDictionary, addTranscriptEntitiesDictionary, addActiveClusterTabs, resetTranscripts } =
  transcriptsSlice.actions;

export const selectTranscriptsDictionary = (state: RootState) => state.transcripts.transcriptsDictionary;
export const selectTranscriptEntitiesDictionary = (state: RootState) => state.transcripts.transcriptEntitiesDictionary;
export const selectActiveClusterTabs = (state: RootState) => state.transcripts.activeClusterTabs;

export default transcriptsSlice.reducer;
