import { useCallback } from 'react';
import { OnPlayPauseParams } from '@/entities/source/@fox/components/RecordsTab/types';
import {
  setIsRecordPlaying,
  setSelectedRecordId,
  selectIsRecordPlaying,
  selectSelectedRecordId,
} from '@/entities/source/slices';
import { useAppSelector, useAppDispatch, useParams } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';
import useAudioPlayerStorageSettings from './useAudioPlayerStorageSettings';
import useAutoplay from './useAutoplay';
import useFile from './useFile';
import useGetRecord from './useGetRecord';
import useNavigateToSiblingRecord from './useNavigateToSiblingRecord';

const useAudioPlayer = () => {
  const dispatch = useAppDispatch();
  const isRecordPlaying = useAppSelector(selectIsRecordPlaying);
  const selectedRecordId = useAppSelector(selectSelectedRecordId);
  const file = useFile();
  const { tabEntityId } = useParams<SourceRouteParams>();
  const isRecordSelected = selectedRecordId === tabEntityId;
  const { memoNavigateToPreviousRecord, memoNavigateToNextRecord } = useNavigateToSiblingRecord();

  const { record, isFetching, isFetched } = useGetRecord(selectedRecordId ?? '');

  const { settings, memoSaveSettings } = useAudioPlayerStorageSettings();

  const { memoPlayNextRecord } = useAutoplay({
    isCurrentRecordFetched: isFetched,
    isSkipSilenceEnabled: !!settings.isSkipSilenceEnabled,
    isAutoplayEnabled: !!settings.autoplay,
  });

  const memoOnPlayPause = useCallback(
    ({ isPlaying, shouldPlaySelectedRecord }: OnPlayPauseParams) => {
      if (tabEntityId && ((!selectedRecordId && isPlaying) || shouldPlaySelectedRecord)) {
        dispatch(setSelectedRecordId(tabEntityId));
      }
      dispatch(setIsRecordPlaying(isPlaying));
    },
    [dispatch, selectedRecordId, tabEntityId]
  );

  return {
    file,
    record,
    isRecordSelected,
    isFetching,
    isPlaying: isRecordPlaying,
    interceptedAt: file?.metadata.interceptedAt,
    settings,
    memoOnPlayPause,
    memoSaveSettings,
    memoPlayNextRecord,
    memoNavigateToNextRecord,
    memoNavigateToPreviousRecord,
  };
};

export default useAudioPlayer;
