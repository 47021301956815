import IMask from 'imask';
import { PHONE_MASK_PATTERN, ROLES_DESCRIPTION } from '@/shared/constants';
import { useAppSelector } from '@/shared/hooks';
import { selectCurrentUser, selectNavigationDrawer } from '@/shared/slices';

const useUserProfileDrawer = () => {
  const navigationDrawer = useAppSelector(selectNavigationDrawer);
  const currentUser = useAppSelector(selectCurrentUser);
  const masked = IMask.createMask({ mask: PHONE_MASK_PATTERN });
  const userRole = currentUser?.role ? ROLES_DESCRIPTION[currentUser.role].label : '';

  if (currentUser?.phone) {
    masked.resolve(currentUser.phone);
  }

  return {
    navigationDrawer,
    user: currentUser,
    userPhone: masked.value,
    userRole,
  };
};

export default useUserProfileDrawer;
