import { Transcription } from '@/entities/transcript/types';

type Params = {
  creatorName?: Transcription['creator_name'];
  sourceName?: Transcription['source_name'];
  tooltipMessage: string | null;
};

export const getTooltipTitle = ({ creatorName, sourceName, tooltipMessage }: Params) => {
  const sourceNameTooltipTitleSufix = `${tooltipMessage ?? ''}\n${sourceName}`;

  const sourceNameTooltipTitle = creatorName
    ? `${creatorName}, ${sourceNameTooltipTitleSufix}`
    : sourceNameTooltipTitleSufix;

  const creatorNameTooltipTitle = creatorName && creatorName.length > 20 ? creatorName : null;

  return sourceName ? sourceNameTooltipTitle : creatorNameTooltipTitle;
};
