import { useEffect, useMemo } from 'react';
import { getLocalStorageValue } from '@/shared/utils';

const OBSERVED_ITEMS: Record<string, string | null> = {};

interface Params<T> {
  key: string;
  memoOnLocalStorageChange: (value: T | null) => void;
}

const useLocalStorageListener = <T>({ key, memoOnLocalStorageChange }: Params<T>) => {
  const getStorageValue = useMemo(() => getLocalStorageValue<T>(key), [key]);
  OBSERVED_ITEMS[key] = localStorage.getItem(key);

  useEffect(() => {
    const handleStorage = () => {
      const rawStorageValue = localStorage.getItem(key);
      if (OBSERVED_ITEMS[key] === rawStorageValue) return;

      const storageValue = getStorageValue();
      OBSERVED_ITEMS[key] = rawStorageValue;
      memoOnLocalStorageChange(storageValue);
    };

    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, [getStorageValue, key, memoOnLocalStorageChange]);
};

export default useLocalStorageListener;
