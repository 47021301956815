import { useRef } from 'react';
import { useAppDispatch, useAppSelector, useContentSize } from '@/shared/hooks';
import { selectIsLayoutSidebarRightInDrawer, setIsLayoutSidebarRightInDrawer } from '@/shared/slices';
import useToggleSidebar, { Params as ToggleSidebarParams } from './useToggleSidebar';

interface Params extends ToggleSidebarParams {}

const useLayoutTwoColumnsSidebarRight = ({ sidebarDefaultWidth }: Params) => {
  const layoutRef = useRef<HTMLDivElement>(null);
  const sidebarWrapperRef = useRef<HTMLDivElement>(null);
  const { contentWidth: sidebarWrapperWidth } = useContentSize(sidebarWrapperRef);
  const isSidebarVisible = sidebarWrapperWidth > 0;
  const { forceResizeSidebarToWidth, toggleSidebarVisibility } = useToggleSidebar({ sidebarDefaultWidth });
  const shouldDrawerRender = useAppSelector(selectIsLayoutSidebarRightInDrawer);
  const dispatch = useAppDispatch();

  const handleOverlayClick = () => dispatch(setIsLayoutSidebarRightInDrawer(false));

  return {
    layoutRef,
    sidebarWrapperRef,
    isSidebarVisible,
    forceResizeSidebarToWidth,
    toggleSidebarVisibility,
    shouldDrawerRender,
    handleOverlayClick,
  };
};

export default useLayoutTwoColumnsSidebarRight;
