import { memo } from 'react';
import { Row, Col, Flex } from 'antd';
import classNames from 'classnames';
import { TranscriptItemProps } from '@/entities/transcript/types';
import { LoadingWrapper } from '@/shared/components';
import {
  TranscriptTime,
  TranscriptSources,
  TranscriptFrequency,
  TranscriptNetworks,
  TranscriptEntities,
  TranscriptMessage,
} from './components';
import styles from './styles.module.css';
import useTranscriptItem from './useTranscriptItem';

export const TranscriptItem = memo((params: TranscriptItemProps) => {
  const {
    radioNetworkId,
    selectionWrapRef,
    isMobile,
    isCluster,
    clusterId,
    clusterMetadata,
    transcript,
    isTranscriptFetching,
    shouldBeHighlighted,
    shouldRenderNetworks,
    renderSelectionBox,
    renderTranscriptControls,
  } = useTranscriptItem(params);

  const messageContent = (
    <LoadingWrapper isLoading={isTranscriptFetching}>
      <Flex
        vertical
        gap={8}
      >
        <TranscriptEntities
          isCluster={isCluster}
          clusterMetadata={clusterMetadata}
          transcript={transcript}
        />
        <TranscriptMessage
          isCluster={isCluster}
          transcriptId={transcript.id}
          networkId={transcript.networkId}
          message={transcript.original_message}
          tags={transcript.tags}
          categories={transcript.categories}
          locations={transcript.locations}
          factions={transcript.factions}
          codes={transcript.codes}
          callSigns={transcript.callsigns}
        />
      </Flex>
    </LoadingWrapper>
  );

  return (
    <div
      ref={selectionWrapRef}
      className={classNames('selection-wrap', styles.transcriptItem, {
        [styles.active]: shouldBeHighlighted,
      })}
      data-testid="transcript-item"
    >
      <Flex
        vertical
        gap={8}
      >
        <Row wrap={false}>
          <Col
            className={styles.metadata}
            flex="none"
          >
            <Flex
              vertical
              gap={8}
            >
              <TranscriptTime timestamp={transcript.timestamp_created} />
              <TranscriptFrequency
                isCluster={isCluster}
                clusterMetadata={clusterMetadata}
                transcript={transcript}
              />
            </Flex>
          </Col>
          <Col flex="auto">
            <Flex
              vertical
              gap={8}
            >
              <Flex justify="space-between">
                <TranscriptSources
                  clusterId={clusterId}
                  clusterMetadata={clusterMetadata}
                  transcript={transcript}
                />
                {!isCluster &&
                  renderTranscriptControls({
                    transcriptId: transcript.id,
                    frequencyId: transcript.networkId ? undefined : transcript.frequencyId,
                  })}
              </Flex>
              {shouldRenderNetworks && (
                <TranscriptNetworks
                  isCluster={isCluster}
                  clusterMetadata={clusterMetadata}
                  transcript={transcript}
                />
              )}
              {!isMobile && messageContent}
            </Flex>
          </Col>
        </Row>
        {isMobile && messageContent}
      </Flex>
      {(!isCluster || radioNetworkId) &&
        renderSelectionBox({
          wrapperRef: selectionWrapRef,
          itemId: transcript.id,
          isFromNetwork: Boolean(transcript.networkId),
          isCluster,
        })}
    </div>
  );
});

TranscriptItem.displayName = 'TranscriptItem';
