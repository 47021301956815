import { PropsWithChildren } from 'react';
import { Drawer, DrawerProps } from 'antd';
import classNames from 'classnames';
import styles from './styles.module.css';

type Props = DrawerProps & {
  placement?: 'left' | 'right';
  defaultWidth?: string | number;
  isOpen?: boolean;
  onClose?: VoidFunction;
};

export const LayoutSidebarDrawer = ({
  placement = 'left',
  defaultWidth = 'auto',
  isOpen = true,
  onClose,
  children,
  ...props
}: PropsWithChildren<Props>) => (
  <Drawer
    {...props}
    width={isOpen ? defaultWidth : 0}
    closeIcon={null}
    placement={placement}
    open={isOpen}
    onClose={onClose}
    footer={false}
    push={false}
    rootClassName={classNames(styles.container, {
      [styles.leftDrawer]: placement === 'left',
    })}
    forceRender
    classNames={{
      header: styles.header,
      body: styles.body,
    }}
  >
    {children}
  </Drawer>
);
