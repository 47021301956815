import { useEffect } from 'react';
import { addTranscriptsDictionary } from '@/entities/transcript/slices';
import { TranscriptionDictionaryItem, TranscriptionList } from '@/entities/transcript/types';
import { useAppDispatch } from '@/shared/hooks';

type Params = {
  memoTranscripts: TranscriptionList;
};

const useSyncTranscriptsDictionary = ({ memoTranscripts }: Params) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const flatTranscripts = memoTranscripts.flatMap<TranscriptionDictionaryItem>((item) => {
      if (item.type === 'cluster') {
        return item.metadata.sources.map((source) => {
          const frequency = item.metadata.frequencies.find(({ transcriptIds }) =>
            transcriptIds.includes(source.transcriptId)
          );

          return {
            id: source.transcriptId,
            frequencyId: frequency?.id,
            frequencyValue: frequency?.value,
            sourceName: source.name,
          };
        });
      }

      return {
        id: item.id,
        frequencyId: item.frequencyId,
        frequencyValue: item.metadata.frequency,
        sourceName: item.source_name,
        categories: item.categories,
      };
    });

    dispatch(addTranscriptsDictionary(flatTranscripts));
  }, [memoTranscripts, dispatch]);
};

export default useSyncTranscriptsDictionary;
