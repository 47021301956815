import { Typography } from 'antd';
import { TranscriptFrequencyProps } from '@/entities/transcript/types';
import { FrequencyLink, ParsedFrequencyValue } from './components';
import styles from './styles.module.css';
import useTranscriptFrequency from './useTranscriptFrequency';

const TranscriptFrequency = (props: TranscriptFrequencyProps) => {
  const { radioNetworkId, isNetwork, frequencyValue, parsedFrequencyValue } = useTranscriptFrequency(props);

  return (
    <Typography.Title
      className={styles.frequency}
      level={5}
      data-testid="frequency"
    >
      {!radioNetworkId || isNetwork ? (
        <FrequencyLink
          frequencyValue={frequencyValue ?? ''}
          parsedFrequencyValue={parsedFrequencyValue}
        />
      ) : (
        <ParsedFrequencyValue frequencyValue={parsedFrequencyValue} />
      )}
    </Typography.Title>
  );
};

export default TranscriptFrequency;
