import { FC } from 'react';
import { useUnmount } from '@reactuses/core';
import {
  getLocationsOptionsGroupedByRegion,
  LocationFormMultiSelect,
  LocationId,
  LOCATION_PLACEHOLDER,
} from '@/entities/location';
import { useModalMapVisibilityController } from '@/entities/map';
import { SubscriptionItemId, SubscriptionType } from '@/entities/subscription';
import { Location } from '@/shared/types';
import { useGetSubscriptions } from '@/widgets/SubscriptionDrawer/hooks';
import { useSubscriptionState } from '../hooks';

type Props = {
  locations: Location[];
  setSearch: (value: string) => void;
  isLoading?: boolean;
};

const SELECT_DROPDOWN_POPUP_CLASSNAME = 'subscription-location-select-dropdown';

const LocationField: FC<Props> = ({ locations, isLoading, setSearch }) => {
  const { hideModalMap, showModalMap } = useModalMapVisibilityController({
    locations,
    selectDropdownClassName: SELECT_DROPDOWN_POPUP_CLASSNAME,
  });
  const { subscriptions } = useGetSubscriptions();
  const { handleSubscriptionChange, getSubscriptionValues } = useSubscriptionState();

  const locationsOptions = getLocationsOptionsGroupedByRegion(locations);

  const handleSearch = (val: string) => {
    setSearch(val);
    hideModalMap();
  };

  const handleChange = (values: SubscriptionItemId[]) => {
    handleSubscriptionChange(values, SubscriptionType.Location);
  };
  const value = getSubscriptionValues(SubscriptionType.Location) as LocationId[];

  useUnmount(hideModalMap);

  return (
    <LocationFormMultiSelect
      popupClassName={SELECT_DROPDOWN_POPUP_CLASSNAME}
      placeholder={LOCATION_PLACEHOLDER}
      options={locationsOptions}
      value={value}
      onChange={handleChange}
      onSearch={handleSearch}
      isLoading={isLoading}
      disabled={!subscriptions?.models.length}
      isAsync
      onOptionMouseEnter={showModalMap}
      onMouseLeave={hideModalMap}
    />
  );
};

export default LocationField;
