import { useEffect } from 'react';
import { MobileDetailsManagerState } from '@/shared/constants';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { selectActiveMobileDetailsManagerState, setActiveMobileDetailsManagerState } from '@/shared/slices';
import { MobileDetailsManagerProps } from '../types';
import { getPreparedDetailItems } from './utils';

const EDIT = 'Редагування';

interface Params<T>
  extends Pick<
    MobileDetailsManagerProps<T>,
    'createTitlePrefix' | 'items' | 'detailItemsOrder' | 'selectedItem' | 'isOpen'
  > {}

export const useMobileDetailsManager = <T>({
  createTitlePrefix,
  items,
  detailItemsOrder,
  selectedItem,
  isOpen,
}: Params<T>) => {
  const dispatch = useAppDispatch();
  const viewType = useAppSelector(selectActiveMobileDetailsManagerState);

  useEffect(() => {
    if (isOpen && !viewType) dispatch(setActiveMobileDetailsManagerState(MobileDetailsManagerState.View));
  }, [isOpen]);

  let primaryItem;
  let detailItems;
  let title = null;
  if (selectedItem && items.length > 0) {
    [primaryItem, ...detailItems] = getPreparedDetailItems(selectedItem, items, detailItemsOrder);
    title = primaryItem.value;
  }

  const isDetails = viewType === MobileDetailsManagerState.View;
  const isEdit = viewType === MobileDetailsManagerState.Edit;
  const isCreate = viewType === MobileDetailsManagerState.Create;

  let titlePrefix;
  if (isCreate) titlePrefix = createTitlePrefix;
  if (isEdit) titlePrefix = EDIT;

  return {
    titlePrefix,
    title,
    detailItems,
    isDetails,
    isEdit,
    isCreate,
    viewType,
  };
};
