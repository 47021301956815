import { SelectedBoundaries, FrequenciesSelectedBoundaries } from '../types';
import getRoundedFloat from './getRoundedFloat';

interface Params {
  canvasWidth: number;
  startFrequency: number;
  endFrequency: number;
  selectedBoundaries: SelectedBoundaries;
}

const getFrequenciesSelectedBoundaries = ({
  canvasWidth,
  selectedBoundaries,
  startFrequency,
  endFrequency,
}: Params): FrequenciesSelectedBoundaries => {
  const canvasOnePercentValue = canvasWidth / 100;
  const startPercent = selectedBoundaries.startPoint / canvasOnePercentValue;
  const endPercent = selectedBoundaries.endPoint / canvasOnePercentValue;

  const frequenciesOnePercentValue = (endFrequency - startFrequency) / 100;
  const startSelectedFrequency = getRoundedFloat(startFrequency + startPercent * frequenciesOnePercentValue);
  const endSelectedFrequency = getRoundedFloat(startFrequency + endPercent * frequenciesOnePercentValue);

  return {
    startSelectedFrequency,
    endSelectedFrequency,
  };
};

export default getFrequenciesSelectedBoundaries;
