import cloneDeep from 'lodash/cloneDeep';
import { AddLayerObject } from 'maplibre-gl';
import {
  cartoDarkLayers,
  cartoLightLayers,
  elevationLayers,
  googleLayers,
  openStreetLayers,
  styleSchema,
} from '@/entities/map/config';
import {
  HAS_CLUSTER,
  HAS_NOT_CLUSTER,
  HEXAGON_COLOR,
  MAP_COLORS,
  MapLayer,
  MapSource,
  MarkerType,
} from '@/entities/map/constants';
import { CustomMarker, MaplibreMap, TileSource } from '@/entities/map/types';
import { getComplexId, getMarkerLayerPrefixes } from '@/entities/map/utils';

type AddHexagonsParams = {
  layerId: MapLayer;
  sourceId: MapSource;
};

class LayerService {
  constructor(private map: MaplibreMap) {}

  static getTileLayers(source: TileSource) {
    switch (source) {
      case MapSource.Elevation:
        return elevationLayers;
      case MapSource.OpenStreet:
        return openStreetLayers;
      case MapSource.CartoLight:
        return cartoLightLayers;
      case MapSource.CartoDark:
        return cartoDarkLayers;
      default:
        return googleLayers;
    }
  }

  setTileLayers(tileSource: TileSource) {
    // to avoid update style before it first loaded
    if (!this.map.isStyleLoaded()) return;

    this.map.setStyle(cloneDeep(styleSchema), {
      transformStyle: (previousStyle, nextStyle) => {
        if (!previousStyle) return nextStyle;

        const tileLayers = LayerService.getTileLayers(tileSource);
        const hasElevationLayer = this.map.getLayer(MapLayer.Elevation);
        const hasCartoLayer = this.map.getLayer(MapLayer.CartoBg);
        let sliceIndex = 1;

        if (hasElevationLayer) {
          sliceIndex = elevationLayers.length;
        }

        if (hasCartoLayer) {
          sliceIndex = cartoLightLayers.length;
        }

        return {
          ...previousStyle,
          layers: [...tileLayers, ...previousStyle.layers.slice(sliceIndex)],
        };
      },
    });
  }

  private addMapLayer(layer: AddLayerObject, beforeLayer?: MapLayer) {
    // to avoid adding a duplicate layer
    if (this.map.getLayer(layer.id)) return;
    this.map.addLayer(layer, beforeLayer);
  }

  addBattleLine(beforeLayer?: MapLayer) {
    this.addMapLayer(
      {
        id: MapLayer.Kml,
        type: 'line',
        source: MapSource.Kml,
        paint: {
          'line-width': 3,
          'line-color': MAP_COLORS.primary,
        },
      },
      beforeLayer
    );
  }

  addMarkers(type: MarkerType, marker: CustomMarker, groupId?: string) {
    const { markerLayer, markerInnerLayer, markerCountLayer } = getMarkerLayerPrefixes(type);
    const filter = type === MarkerType.Cluster ? HAS_CLUSTER : HAS_NOT_CLUSTER;

    this.addMapLayer({
      id: getComplexId(markerLayer, groupId),
      source: getComplexId(MapSource.Markers, groupId),
      filter,
      ...marker.marker,
    });
    this.addMapLayer({
      id: getComplexId(markerInnerLayer, groupId),
      source: getComplexId(MapSource.Markers, groupId),
      filter,
      ...marker.markerInner,
    });
    this.addMapLayer({
      id: getComplexId(markerCountLayer, groupId),
      source: getComplexId(MapSource.Markers, groupId),
      filter,
      ...marker.markerCount,
    });
  }

  addClusterPolygon() {
    this.addMapLayer({
      id: MapLayer.ClusterPolygon,
      type: 'fill',
      source: MapSource.ClusterPolygon,
      paint: {
        'fill-color': MAP_COLORS.clusterPolygon,
        'fill-opacity': 0.5,
      },
    });
    this.addMapLayer({
      id: MapLayer.ClusterPolygonBorder,
      type: 'line',
      source: MapSource.ClusterPolygon,
      paint: {
        'line-color': MAP_COLORS.clusterPolygon,
        'line-width': 2,
      },
    });
  }

  addHexagons({ layerId, sourceId }: AddHexagonsParams) {
    this.addMapLayer({
      id: layerId,
      type: 'fill',
      source: sourceId,
      paint: {
        'fill-outline-color': MAP_COLORS.hexagonBorder,
        'fill-color': HEXAGON_COLOR,
      },
    });
  }
}

export default LayerService;
