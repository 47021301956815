import { Fragment } from 'react';
import { Flex, Typography } from 'antd';
import { TranscriptNetworksProps } from '@/entities/transcript/types';
import { Tooltip } from '@/shared/components';
import { LABEL_SEPARATOR } from '@/shared/constants';
import { TranscriptNetwork } from './components';
import styles from './styles.module.css';
import useTranscriptNetworks from './useTranscriptNetworks';

const TranscriptNetworks = (props: TranscriptNetworksProps) => {
  const { radioNetworkId, isMobile, networks, tooltipTitle } = useTranscriptNetworks(props);

  // not render for RadioNetworks page
  if (radioNetworkId) return null;

  return (
    <Flex>
      <Tooltip
        title={tooltipTitle}
        isDesktopOnly
      >
        <Typography.Title
          className={styles.networks}
          ellipsis={{ rows: isMobile ? 2 : 1 }}
          level={5}
          data-testid="network"
        >
          {networks.map(({ networkId, networkName }, index) => (
            <Fragment key={networkId ?? ''}>
              <TranscriptNetwork
                networkId={networkId}
                networkName={networkName}
              />
              {index + 1 < networks.length && <Typography.Text type="secondary">{LABEL_SEPARATOR}</Typography.Text>}
            </Fragment>
          ))}
        </Typography.Title>
      </Tooltip>
    </Flex>
  );
};

export default TranscriptNetworks;
