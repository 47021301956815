import { useCallback, useEffect } from 'react';
import { useIsTabletOrMobile, useAppSelector, useAppDispatch } from '@/shared/hooks';
import { selectIsNavigationSidebarCollapsed, setIsNavigationSidebarCollapsed } from '@/shared/slices';

const useCollapseSidebar = () => {
  const dispatch = useAppDispatch();
  const isNavigationSidebarCollapsed = useAppSelector(selectIsNavigationSidebarCollapsed);
  const isTabletOrMobile = useIsTabletOrMobile();

  const handleSetIsNavigationSidebarCollapsed = useCallback(
    (value: boolean) => {
      dispatch(setIsNavigationSidebarCollapsed(value));
    },
    [dispatch]
  );

  const handleToggleNavigationSidebar = () => {
    handleSetIsNavigationSidebarCollapsed(!isNavigationSidebarCollapsed);
  };

  useEffect(() => {
    if (isTabletOrMobile) {
      handleSetIsNavigationSidebarCollapsed(true);
    }
  }, [handleSetIsNavigationSidebarCollapsed, isTabletOrMobile]);

  return {
    isNavigationSidebarCollapsed,
    handleToggleNavigationSidebar,
  };
};

export default useCollapseSidebar;
