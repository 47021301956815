import { useMemo } from 'react';
import { convertEncryptionTypesToOptions } from '@/entities/source/@fox/utils';
import { useParams } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';
import useGetFoxFrequenciesFilters from './useGetFoxFrequenciesFilters';

const useOptions = () => {
  const { sourceId = '' } = useParams<SourceRouteParams>();
  const { filters, isFiltersLoading } = useGetFoxFrequenciesFilters({ sourceId });

  const options = useMemo(
    () => ({
      encryptionTypeOptions: convertEncryptionTypesToOptions(filters?.data.encryptionTypes),
    }),
    [filters]
  );

  return {
    options,
    isFiltersLoading,
  };
};

export default useOptions;
