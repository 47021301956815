import { FocusEvent, forwardRef, ReactNode, useCallback, useState } from 'react';
import { TextAreaProps } from 'antd/es/input/TextArea';
import classnames from 'classnames';
import { TextArea } from '@/shared/components';
import styles from './styles.module.css';

interface Props extends TextAreaProps {
  overlay: ReactNode;
}

const TextAreaWithOverlay = forwardRef<HTMLTextAreaElement, Props>((props: Props, ref) => {
  const { overlay, ...textAreaProps } = props;
  const [overlayVisible, setOverlayVisible] = useState(true);

  const handleFocus = useCallback(
    (ev: FocusEvent<HTMLTextAreaElement>) => {
      if (typeof props.onFocus === 'function') props.onFocus(ev);
      setOverlayVisible(false);
    },
    [props.onFocus]
  );
  const handleBlur = useCallback(
    async (ev: FocusEvent<HTMLTextAreaElement>) => {
      if (typeof props.onBlur === 'function') await props.onBlur(ev);
      setOverlayVisible(true);
    },
    [props.onBlur]
  );

  return (
    <div className={styles.wrapper}>
      <div
        className={classnames(styles.field, {
          [styles.field_visible]: !overlayVisible,
        })}
      >
        <TextArea
          {...textAreaProps}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={ref}
          isTransparentOnDesktop
        />
      </div>
      <div
        className={classnames(styles.overlay, {
          [styles.overlay_visible]: overlayVisible,
        })}
      >
        <pre className={styles.fieldImitation}>{overlay}</pre>
      </div>
    </div>
  );
});

TextAreaWithOverlay.displayName = 'TextAreaWithOverlay';

export default TextAreaWithOverlay;
