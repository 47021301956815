import { useQuery } from '@tanstack/react-query';
import { downloadFile as downloadFileApi } from '@/entities/source/@fox/api';
import { DOWNLOAD_RECORD_MUTATION_KEY } from '@/shared/constants';

const CACHING_DURATION = 300000; // 5 min

const useGetRecord = (id: string) => {
  const {
    data: record,
    isFetching,
    isFetched,
  } = useQuery({
    queryKey: [DOWNLOAD_RECORD_MUTATION_KEY, id],
    queryFn: () => downloadFileApi(id),
    enabled: Boolean(id),
    cacheTime: CACHING_DURATION,
    staleTime: CACHING_DURATION,
  });

  return { record, isFetching, isFetched };
};

export default useGetRecord;
