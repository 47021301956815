import { memo } from 'react';
import { useIsAdminOrAnalystRole } from '@/shared/hooks';
import { RadioNetwork } from '@/shared/types';
import { DraggableListItem, StaticListItem } from './components';

interface Props {
  item: RadioNetwork;
  selectedRadioNetworkId?: string;
}

const NetworkListItem = memo(({ ...props }: Props) => {
  const isAdminOrAnalyst = useIsAdminOrAnalystRole();

  return isAdminOrAnalyst ? <DraggableListItem {...props} /> : <StaticListItem {...props} />;
});

NetworkListItem.displayName = 'NetworkListItem';

export default NetworkListItem;
