import { useEffect } from 'react';
import { DrawerProps } from 'antd';
import { Drawer } from '@/shared/components/ui/Drawer';
import { useAppDispatch, useIsMobile } from '@/shared/hooks';
import { setNavigationDrawer } from '@/shared/slices';

const NavigationDrawer = ({ title, open, footer, children }: DrawerProps) => {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();

  const handleClose = () => dispatch(setNavigationDrawer(null));

  useEffect(() => {
    if (isMobile) {
      handleClose();
    }
  }, [isMobile]);

  return (
    <Drawer
      title={title}
      open={open}
      onClose={handleClose}
      footer={footer}
    >
      {children}
    </Drawer>
  );
};

export default NavigationDrawer;
