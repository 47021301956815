import { useMemo, useEffect } from 'react';
import { useTranscriptQueryParams } from '@/entities/transcript/hooks';
import { addTranscriptEntitiesDictionary } from '@/entities/transcript/slices';
import { useAppDispatch } from '@/shared/hooks';
import { transcriptMessageService } from './services';
import TranscriptMessageProps from './types';

export const useTranscriptMessage = ({
  isCluster,
  transcriptId,
  message,
  tags,
  categories,
  locations,
  factions,
  codes,
  callSigns,
}: TranscriptMessageProps) => {
  const { search } = useTranscriptQueryParams();
  const dispatch = useAppDispatch();

  const [parsedMessage, parsedEntities] = useMemo(() => {
    return transcriptMessageService.parse(message, {
      aliases: {
        categories,
        tags,
        locations,
        factions,
        callSigns,
        codes,
      },
      search,
      isCluster,
    });
  }, [message, categories, tags, locations, factions, callSigns, codes, search]);

  useEffect(() => {
    if (Object.keys(parsedEntities).length > 0) {
      dispatch(addTranscriptEntitiesDictionary({ [transcriptId]: parsedEntities }));
    }
  }, [transcriptId, parsedEntities, dispatch]);

  return { parsedMessage };
};
