import { GeoJsonFeatureCollection } from '@/entities/map/types';
import { request, ResponseData } from '@/shared/api';
import { queryParamsService } from '@/shared/services';
import { GetInterceptionsMapPointsQueryParams } from './types';
import { getAllInterceptionsMapPointsSchema, getMapKMLDataSchema } from './validationSchemas';

export const getAllInterceptionsMapPoints = ({
  transcripts,
  locations,
  factions,
  categories,
  frequencies,
  groups,
  sources,
  dateFrom,
  dateTo,
}: GetInterceptionsMapPointsQueryParams) => {
  const params = new URLSearchParams();

  queryParamsService.setQueryParamsArrayParameter(params, 'transcript[]', transcripts);
  queryParamsService.setQueryParamsArrayParameter(params, 'location[]', locations);
  queryParamsService.setQueryParamsArrayParameter(params, 'faction[]', factions);
  queryParamsService.setQueryParamsArrayParameter(params, 'category[]', categories);
  queryParamsService.setQueryParamsArrayParameter(params, 'frequency[]', frequencies);
  queryParamsService.setQueryParamsArrayParameter(params, 'group[]', groups);
  queryParamsService.setQueryParamsArrayParameter(params, 'source[]', sources);

  if (dateFrom) {
    params.set('date_from', dateFrom);
  }
  if (dateTo) {
    params.set('date_to', dateTo);
  }

  const queryParams = params.toString().length > 0 ? `?${params.toString()}` : '';

  return request<ResponseData<GeoJsonFeatureCollection>>(`/transcripts/map${queryParams}`, {
    validationSchema: getAllInterceptionsMapPointsSchema,
  });
};

export const getMapKMLData = async () => {
  return request<ResponseData<string>>('/map/layers/battle-line', {
    validationSchema: getMapKMLDataSchema,
  });
};
