import { Typography } from 'antd';
import { FoxKey } from '@/entities/source/@fox/types';

interface Props {
  groupId: FoxKey['groupId'];
}

const FoxKeyGroupId = ({ groupId }: Props) => {
  return <Typography.Text ellipsis={{ tooltip: groupId }}>{groupId}</Typography.Text>;
};

export default FoxKeyGroupId;
