import { useMutation, MutateOptions } from '@tanstack/react-query';
import { deleteFoxFrequency as deleteFoxFrequencyApi, DeleteFoxFrequencyParams } from '@/entities/source/@fox/api';
import { DELETE_FOX_FREQUENCY_MUTATION_KEY } from '@/shared/constants';

const useDeleteFoxFrequency = (options: MutateOptions<unknown, unknown, DeleteFoxFrequencyParams> = {}) => {
  const {
    mutate: deleteFoxFrequency,
    mutateAsync: deleteFoxFrequencyAsync,
    isLoading: isFoxFrequencyDeleting,
  } = useMutation({
    ...options,
    mutationKey: [DELETE_FOX_FREQUENCY_MUTATION_KEY],
    mutationFn: deleteFoxFrequencyApi,
  });

  return {
    deleteFoxFrequency,
    deleteFoxFrequencyAsync,
    isFoxFrequencyDeleting,
  };
};

export default useDeleteFoxFrequency;
