import { CSSProperties, useMemo } from 'react';
import { HorizontalAxisProps } from './types';
import { getMarks } from './utils';

const SHORT_RANGE_LIMIT = 5;

interface Params extends Pick<HorizontalAxisProps, 'bottom' | 'startPoint' | 'endPoint'> {}

const useHorizontalAxis = ({ bottom, startPoint, endPoint }: Params) => {
  const wrapperStyle: CSSProperties = { bottom };
  const isShortAxis = endPoint - startPoint <= SHORT_RANGE_LIMIT;

  const marks = useMemo(() => getMarks({ startPoint, endPoint }), [startPoint, endPoint]);

  return {
    isShortAxis,
    wrapperStyle,
    marks,
  };
};

export default useHorizontalAxis;
