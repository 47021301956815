import { forwardRef, useState, FocusEvent } from 'react';
import { Input, Typography } from 'antd';
import { TextAreaProps as AntdTextAreaProps, TextAreaRef as AntdTextAreaRef } from 'antd/es/input/TextArea';
import classNames from 'classnames';
import { useIsMobile, useIsTabletOrMobile } from '@/shared/hooks';
import commonInputStyles from '../commonInput.styles.module.css';
import styles from './styles.module.css';

export type TextAreaProps = AntdTextAreaProps & {
  isTransparentOnDesktop?: boolean;
  overflowEllipsisRows?: number;
};
export type TextAreaRef = AntdTextAreaRef;

export const TextArea = forwardRef<TextAreaRef, TextAreaProps>(
  (
    { className, isTransparentOnDesktop, overflowEllipsisRows, onFocus, onBlur, defaultValue, value, ...restProps },
    ref
  ) => {
    const isMobile = useIsMobile();
    const isTabletOrMobile = useIsTabletOrMobile();
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = (e: FocusEvent<HTMLTextAreaElement, Element>) => {
      setIsFocused(true);
      onFocus?.(e);
    };

    const handleBlur = (e: FocusEvent<HTMLTextAreaElement, Element>) => {
      setIsFocused(false);
      onBlur?.(e);
    };

    const handleClick = () => {
      if (ref && 'current' in ref) {
        ref.current?.focus();
      }
    };

    const pseudoElementValue = value || defaultValue;

    const showPseudoElement = overflowEllipsisRows && pseudoElementValue;

    return (
      <>
        <Input.TextArea
          ref={ref}
          size={isTabletOrMobile ? 'large' : 'middle'}
          classNames={{
            textarea: classNames({
              [styles.hiddenTextArea]: showPseudoElement && !isFocused,
              [commonInputStyles.transparentInput]: isTransparentOnDesktop && !isMobile,
              className,
            }),
          }}
          defaultValue={defaultValue}
          value={value}
          {...restProps}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
        {showPseudoElement && (
          <div className={classNames({ [styles.hiddenElement]: isFocused }, styles.pseudoElement)}>
            <Typography.Paragraph
              className={styles.pseudoElementContent}
              onClick={handleClick}
              onKeyDown={handleClick}
              ellipsis={{
                rows: overflowEllipsisRows,
                tooltip: {
                  overlayClassName: styles.tooltip,
                  mouseLeaveDelay: 0,
                },
              }}
            >
              {String(pseudoElementValue)}
            </Typography.Paragraph>
          </div>
        )}
      </>
    );
  }
);

TextArea.displayName = 'TextArea';
