import { useCallback, useState } from 'react';
import { FOX_SIDEBAR_MIN_WIDTH } from '@/entities/source/@fox/constants';
import { useFiltersDrawerManager, useSource } from '@/entities/source/hooks';
import { FoxSource } from '@/entities/source/types';
import { useIsMobile } from '@/shared/hooks';
import useScanningHotKeys from './useScanningHotKeys';
import useScrollToSpectrogramFrequency from './useScrollToSpectrogramFrequency';
import useSyncSelectedFrequenciesWithAllFrequencies from './useSyncSelectedFrequenciesWithAllFrequencies';

const SOURCE_SCANNING_SIDEBAR_STORAGE_KEY = 'source-scanning';
const SOURCE_IF_OFFLINE = 'Пристрій не в мережі';

const useScanningTab = () => {
  const source = useSource<FoxSource>();
  const sourceName = source?.name;
  const isSourceOnline = source?.status === 'online';
  const isSdrOnline = source?.deviceStatus === 'sdr_online';
  const isSpectrogramShown = isSdrOnline && isSourceOnline;
  const isMobile = useIsMobile();

  const { isFiltersOpen, handleOpenFilters, handleCloseFilters } = useFiltersDrawerManager();

  const [isMobileSpectrogramOpen, setIsMobileSpectrogramOpen] = useState(false);
  const handleOpenMobileSpectrogram = () => setIsMobileSpectrogramOpen(true);
  const handleCloseMobileSpectrogram = useCallback(() => setIsMobileSpectrogramOpen(false), []);

  const scrollToSpectrogramFrequencyData = useScrollToSpectrogramFrequency({
    memoCloseSpectrogram: handleCloseMobileSpectrogram,
  });

  useSyncSelectedFrequenciesWithAllFrequencies();

  useScanningHotKeys();

  return {
    isMobile,
    sourceName,
    isSpectrogramShown,
    sourceIsOffline: SOURCE_IF_OFFLINE,
    sidebarStorageKey: SOURCE_SCANNING_SIDEBAR_STORAGE_KEY,
    sidebarMinWidth: FOX_SIDEBAR_MIN_WIDTH,
    isFiltersOpen,
    handleOpenFilters,
    handleCloseFilters,
    isMobileSpectrogramOpen,
    handleOpenMobileSpectrogram,
    handleCloseMobileSpectrogram,
    ...scrollToSpectrogramFrequencyData,
  };
};

export default useScanningTab;
