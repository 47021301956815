import { memo } from 'react';
import { NetworkPreview } from '@/entities/network';
import { LoadingWrapper, ScrollUpToRefresh, VirtualList } from '@/shared/components';
import { NetworkListItem } from './components';
import { useNetworkList } from './hooks';
import styles from './styles.module.css';

export const NetworksList = memo(() => {
  const {
    networks,
    isSpinnerVisible,
    networkListWrapperRef,
    isOnTopOfNetworkList,
    selectedRadioNetworkId,
    scrollToRowIndex,
    forceFetchNextPage,
    handleListScroll,
    handleMouseMove,
    stopNetworkListScrolling,
    handleScrollUpToReFetch,
  } = useNetworkList();

  return (
    <>
      <div className={styles.networkWrap}>
        <LoadingWrapper
          isLoading={isSpinnerVisible}
          isEmpty={!networks.length}
          isFixedOverlay
          emptyClassName={styles.empty}
        >
          <div
            className={styles.networkListWrapper}
            onScroll={handleListScroll}
            onMouseMove={handleMouseMove}
            onMouseLeave={stopNetworkListScrolling}
          >
            <ScrollUpToRefresh
              className={styles.scrollUpToRefresh}
              isScrollUpToRefreshEnabled={networks.length > 0}
              onRefresh={handleScrollUpToReFetch}
              childrenWrapperClassName={styles.list}
              loadingComponentWrapperClassName={styles.loadingComponentWrapperClassName}
              isRefreshTriggerDisabled={!isOnTopOfNetworkList}
            >
              <VirtualList
                listRef={networkListWrapperRef}
                data={networks}
                onScroll={handleListScroll}
                defaultRowHeight={50}
                loadMore={forceFetchNextPage}
                scrollToRowIndex={scrollToRowIndex}
                renderRow={(item) => (
                  <NetworkListItem
                    item={item}
                    selectedRadioNetworkId={selectedRadioNetworkId}
                  />
                )}
              />
            </ScrollUpToRefresh>
          </div>
        </LoadingWrapper>
      </div>
      <NetworkPreview />
    </>
  );
});

NetworksList.displayName = 'NetworksList';
