import { memo } from 'react';
import { Col, Row } from 'antd';
import FoxFrequencyEncryptionType from '@/entities/source/@fox/components/ScanningTab/components/FoxFrequencyEncryptionType';
import FoxFrequencyValue from '@/entities/source/@fox/components/ScanningTab/components/FoxFrequencyValue';
import SignalTypeIcon from '@/entities/source/@fox/components/SignalTypeIcon';
import { FoxFrequency } from '@/entities/source/@fox/types';
import { jsonService } from '@/shared/services';
import { Stringified } from '@/shared/types';

interface Props {
  frequency: FoxFrequency['value'];
  isDigital: FoxFrequency['isDigital'];
  stringifiedEncryptionTypes: Stringified<FoxFrequency['encryptionTypes']> | null;
  wrapperClassName: string;
  topColumnClassName: string;
  search: string;
}

const FrequencyValueCell = memo(
  ({ frequency, isDigital, stringifiedEncryptionTypes, wrapperClassName, topColumnClassName, search }: Props) => {
    const encryptionTypes = stringifiedEncryptionTypes ? jsonService.parse(stringifiedEncryptionTypes) : null;

    return (
      <Row className={wrapperClassName}>
        <Col className={topColumnClassName}>
          <FoxFrequencyValue
            value={frequency}
            search={search}
          />
          <SignalTypeIcon isDigital={isDigital} />
        </Col>
        <Col flex="auto">
          {Array.isArray(encryptionTypes) && <FoxFrequencyEncryptionType encryptionTypes={encryptionTypes} />}
        </Col>
      </Row>
    );
  }
);

FrequencyValueCell.displayName = 'FrequencyValueCell';

export default FrequencyValueCell;
