import { Form, Space } from 'antd';
import { useIsMobile } from '@/shared/hooks';

const LABEL = 'Приймач:';

interface Props {
  receiverName: string;
}

const ReceiverName = ({ receiverName }: Props) => {
  const isMobile = useIsMobile();

  return isMobile ? (
    <Form.Item>
      <Space>
        {LABEL}
        {receiverName}
      </Space>
    </Form.Item>
  ) : (
    <Form.Item label={LABEL}>{receiverName}</Form.Item>
  );
};

export default ReceiverName;
