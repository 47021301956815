import { Empty as EmptyComponent, EmptyProps } from 'antd';
import classNames from 'classnames';
import { EmptyDarkIcon } from '@/shared/assets';
import { useAppSelector } from '@/shared/hooks';
import { selectIsDarkMode } from '@/shared/slices';
import styles from './styles.module.css';

export const Empty = ({ className, ...rest }: EmptyProps) => {
  const isDarkMode = useAppSelector(selectIsDarkMode);

  return (
    <EmptyComponent
      {...rest}
      image={isDarkMode ? <EmptyDarkIcon /> : EmptyComponent.PRESENTED_IMAGE_DEFAULT}
      className={classNames(className, styles.icon)}
      data-testid="empty-content"
    />
  );
};
