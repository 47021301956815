import { HorizontalAxisProps } from '../types';

const LONG_RANGE_LIMIT = 10;

interface Params extends Pick<HorizontalAxisProps, 'startPoint' | 'endPoint'> {}

const getMarks = ({ startPoint, endPoint }: Params) => {
  const roundedStart = Math.ceil(startPoint);
  const roundedEnd = Math.floor(endPoint);
  const step = roundedEnd - roundedStart < LONG_RANGE_LIMIT ? 1 : 2;
  const arr: number[] = [];
  for (let i = roundedStart; i <= roundedEnd; i += 1) {
    if (i !== startPoint && i !== endPoint && i % step === 0) {
      arr.push(i);
    }
  }
  return arr;
};

export default getMarks;
