import { VirtualItem } from '@tanstack/react-virtual';

export const getDuplicatedRowsTotalSize = (virtualItems: VirtualItem[]) => {
  const uniqItemKeys: unknown[] = [];

  return virtualItems.reduce((acc, item) => {
    if (uniqItemKeys.includes(item.key)) return acc + item.size;
    uniqItemKeys.push(item.key);
    return acc;
  }, 0);
};
