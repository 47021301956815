import Details from '@/entities/source/@fox/components/Details';
import { useFoxKeyDetails } from '@/entities/source/@fox/components/KeysTab/hooks';
import styles from './styles.module.css';

const FoxKeyDetails = () => {
  const details = useFoxKeyDetails();

  return (
    <Details
      details={details}
      wrapperClassname={styles.wrapper}
      withTitle={false}
    />
  );
};

export default FoxKeyDetails;
