import { useUnitRouteInfo, useTableItemModalManager } from '@/entities/unit/hooks';
import { UnitSource } from '@/entities/unit/types';
import { UNIT_SOURCES_QUERY_KEY } from '@/shared/constants';
import { useScrollUpToReFetch } from '@/shared/hooks';
import useGetUnitSources from './useGetUnitSources';
import useUnitSourcesTableColumns from './useUnitSourcesTableColumns';

const useUnitSourcesTab = () => {
  const { routeUnitId } = useUnitRouteInfo();
  const { itemId, handleOpenModal, handleCloseModal } = useTableItemModalManager<UnitSource['id']>();

  const { unitSourcesList, getUnitSourcesRef, isUnitSourcesFetching } = useGetUnitSources({ unitId: routeUnitId });

  const columns = useUnitSourcesTableColumns({ getUnitSourcesRef, handleOpenModal });

  const { handleScrollUpToReFetch } = useScrollUpToReFetch([UNIT_SOURCES_QUERY_KEY, routeUnitId]);

  return {
    sourcesList: unitSourcesList,
    isLoading: isUnitSourcesFetching,
    columns,
    handleScrollUpToReFetch,
    editUnitSourceId: itemId,
    handleCloseModal,
  };
};

export default useUnitSourcesTab;
