import { useEffect, useMemo, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { NOTIFICATION_TYPES } from '@/shared/constants';
import validations from '@/shared/constants/validation.json';
import { useNotificationContext, useRenderPropsContext } from '@/shared/context';
import { useParams, useOpenFormItemValidationNotification } from '@/shared/hooks';
import { UnitRenderProps, UnitRouteParams } from '@/shared/types';
import useGetUnitDetails from './useGetUnitDetails';
import useUpdateUnitArea from './useUpdateUnitArea';

const { SUCCESS } = NOTIFICATION_TYPES;
const SETTINGS_SAVED = 'Налаштування збережено';

const useSettingsTab = () => {
  const { renderHexagonMap } = useRenderPropsContext<UnitRenderProps>();
  const { openNotification } = useNotificationContext();
  const openFormItemValidationNotification = useOpenFormItemValidationNotification();
  const { unitId = '' } = useParams<UnitRouteParams>();
  const { details, isDetailsLoading, refetchDetails } = useGetUnitDetails(unitId);
  const initialCoverageArea = useMemo(() => details?.area, [details]);
  const [coverageArea, setCoverageArea] = useState<string[]>();
  const [isDrawModeEnabled, setIsDrawModeEnabled] = useState(false);

  const isCoverageUpdated = !isEqual(initialCoverageArea, coverageArea);

  const { updateUnitArea, isUnitAreaUpdating } = useUpdateUnitArea({
    onSuccess: () => {
      refetchDetails();
      openNotification({ type: SUCCESS, title: SETTINGS_SAVED });
    },
    onError: (error) => {
      openFormItemValidationNotification({
        error,
        validations,
        errorPointer: 'area',
      });
    },
  });

  const handleFormReset = () => {
    setCoverageArea(initialCoverageArea);
  };

  const handleFormSave = () => {
    if (!isCoverageUpdated) return;

    updateUnitArea({
      unitId,
      payload: {
        area: coverageArea ?? [],
      },
    });
  };

  useEffect(() => {
    handleFormReset();
  }, [initialCoverageArea]);

  return {
    isFormChanged: isCoverageUpdated,
    isFormSaving: isUnitAreaUpdating,
    handleFormReset,
    handleFormSave,
    coverageArea,
    setCoverageArea,
    isDrawModeEnabled,
    setIsDrawModeEnabled,
    isTabContentLoading: isDetailsLoading,
    renderHexagonMap,
  };
};

export default useSettingsTab;
