import { PropsWithChildren } from 'react';
import { PageNotFoundIcon, AccessDeniedPageIcon } from '@/shared/assets';
import { useAppDispatch } from '@/shared/hooks';
import { setShouldRedirectToPageNotFound, setShouldRedirectToPageAccessDenied } from '@/shared/slices';
import { CodeErrorPage } from './components';
import { useAccessRouteGuard } from './hooks';

const ERRORS = {
  '404': {
    TITLE: 'Сторінку не знайдено',
    MESSAGE: 'Сторінку яку ви шукаєте було переміщено або видалено.',
  },
  '403': {
    TITLE: 'Перегляд заборонено',
    MESSAGE: 'У вас недостатньо прав для перегляду даної сторінки. Зверніться до адміністратора свого підрозділу.',
  },
};

const AccessRouteGuard = ({ children }: PropsWithChildren) => {
  const { shouldRedirectToPageNotFound, shouldRedirectToPageAccessDenied } = useAccessRouteGuard();
  const dispatch = useAppDispatch();

  if (shouldRedirectToPageNotFound) {
    return (
      <CodeErrorPage
        title={ERRORS['404'].TITLE}
        message={ERRORS['404'].MESSAGE}
        onButtonClick={() => dispatch(setShouldRedirectToPageNotFound(false))}
        icon={<PageNotFoundIcon />}
      />
    );
  }

  if (shouldRedirectToPageAccessDenied) {
    return (
      <CodeErrorPage
        title={ERRORS['403'].TITLE}
        message={ERRORS['403'].MESSAGE}
        onButtonClick={() => dispatch(setShouldRedirectToPageAccessDenied(false))}
        icon={<AccessDeniedPageIcon />}
      />
    );
  }

  return children;
};

export default AccessRouteGuard;
