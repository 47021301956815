import useFiltersManager from './useFiltersManager';
import useOptions from './useOptions';
import useSaveRecordsFilter from './useSaveRecordsFilter';

const useFiltersDrawer = () => {
  const {
    memoEncryptionType,
    memoFrequency,
    memoSourceAddress,
    memoGroupAddress,
    memoDateRange,
    digitalSignal,
    analogSignal,
    decoded,
    notDecoded,
    ...restFiltersData
  } = useFiltersManager();

  useSaveRecordsFilter({
    memoEncryptionType,
    memoFrequency,
    memoSourceAddress,
    memoGroupAddress,
    digitalSignal,
    analogSignal,
    decoded,
    notDecoded,
    memoDateRange,
  });

  const { isLoading, options } = useOptions();

  return {
    memoEncryptionType,
    memoFrequency,
    memoSourceAddress,
    memoGroupAddress,
    memoDateRange,
    isLoading,
    ...options,
    ...restFiltersData,
  };
};

export default useFiltersDrawer;
