import { File } from '@/entities/source/@fox/types';

interface Params {
  isSkipAudioWithoutVoiceEnabled: boolean;
  isSkipNotDecodedFileEnabled: boolean;
  file: File;
}

const getSiblingRecordId = ({
  isSkipAudioWithoutVoiceEnabled,
  isSkipNotDecodedFileEnabled,
  file,
}: Params): File['id'] | undefined => {
  if (isSkipAudioWithoutVoiceEnabled && isSkipNotDecodedFileEnabled) {
    if (file.metadata.isDecoded && file.metadata.hasVoice) return file.id;
  } else if (isSkipAudioWithoutVoiceEnabled) {
    if (file.metadata.hasVoice) return file.id;
  } else if (isSkipNotDecodedFileEnabled) {
    if (file.metadata.isDecoded) return file.id;
  }
};

export default getSiblingRecordId;
