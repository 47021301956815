import { UnlockFilled } from '@ant-design/icons';
import SignalTypeIcon from '@/entities/source/@fox/components/SignalTypeIcon';
import { FoxRecordSignalType } from '@/entities/source/@fox/constants';
import { DetailsItem } from '@/entities/source/@fox/types';
import { getDetailValue } from '@/entities/source/@fox/utils';
import { EMPTY_ENCRYPTION_TYPE } from '../constants';
import styles from '../styles.module.css';
import { getSK42Value, getWGS84Value } from '../utils';
import useFile from './useFile';

const SIGNAL_TYPE = 'Тип сигналу';
const DIGITAL_SIGNAL = 'Цифровий сигнал';
const ANALOG_SIGNAL = 'Аналоговий сигнал';
const ENCRYPTION_TITLE = 'Тип шифрування';
const COLOR_CODE_TITLE = 'Color code';
const WGS84_TITLE = 'WGS84';
const SK42_TITLE = 'СК-42';
const FEATURE_SET_TITLE = 'Feature Set';
const SOURCE_ADDRESS_TITLE = 'Source address';
const GROUP_ADDRESS_TITLE = 'Group address';
const KEY_ID_TITLE = 'Key ID';

const useRecordDetails = (): undefined | Record<string, Pick<DetailsItem, 'value' | 'icon' | 'canBeCopied'>> => {
  const file = useFile();
  if (!file) return;

  const { encryptionType, colorCode, lat, lng, featureSet, sourceAddress, groupAddress, keyId, signalType } =
    file.metadata;
  const isDigital = signalType === FoxRecordSignalType.Digital;

  if (!isDigital) {
    return {
      [SIGNAL_TYPE]: {
        icon: (
          <SignalTypeIcon
            isDigital={false}
            className={styles.signalIcon}
          />
        ),
        value: ANALOG_SIGNAL,
      },
    };
  }

  return {
    [SIGNAL_TYPE]: {
      icon: (
        <SignalTypeIcon
          isDigital
          className={styles.signalIcon}
        />
      ),
      value: DIGITAL_SIGNAL,
    },
    [ENCRYPTION_TITLE]: {
      icon: <UnlockFilled className={styles.unlockIcon} />,
      value: encryptionType ?? EMPTY_ENCRYPTION_TYPE,
    },
    [COLOR_CODE_TITLE]: {
      value: getDetailValue(colorCode),
    },
    [WGS84_TITLE]: {
      value: getWGS84Value([lat, lng]),
      canBeCopied: Boolean(lat) && Boolean(lng),
    },
    [SK42_TITLE]: {
      value: getSK42Value([lat, lng]),
      canBeCopied: Boolean(lat) && Boolean(lng),
    },
    [FEATURE_SET_TITLE]: {
      value: getDetailValue(featureSet),
    },
    [SOURCE_ADDRESS_TITLE]: {
      value: getDetailValue(sourceAddress),
    },
    [GROUP_ADDRESS_TITLE]: {
      value: getDetailValue(groupAddress),
    },
    [KEY_ID_TITLE]: {
      value: getDetailValue(keyId),
    },
  };
};

export default useRecordDetails;
