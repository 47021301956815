import { useConfirmModal } from '@/shared/hooks';
import styles from '../styles.module.css';

const OK_TEXT = 'Активувати';
const MODAL_TITLE = 'Активувати джерело?';
const MODAL_CONTENT = (
  <>
    <p className={styles.modalContent}>
      Перш ніж активувати джерело переконайтесь що даний пристрій НЕ знаходиться в руках ворога, інакше ворог отримає
      доступ до всієї інформації на носії в тому числі перехоплень, ключів шифрування та ключів доступу.
    </p>
    <p className={styles.modalContent}>Всі дії фіксуються, будьте уважними.</p>
  </>
);

interface Params {
  onActivateFox: () => Promise<void>;
}

const useOpenActivateFoxConfirmModal = ({ onActivateFox }: Params) => {
  return useConfirmModal({
    title: MODAL_TITLE,
    content: MODAL_CONTENT,
    okText: OK_TEXT,
    onOk: onActivateFox,
  });
};

export default useOpenActivateFoxConfirmModal;
