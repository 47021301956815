import FactionTag from '@/entities/faction/components/FactionTag';
import { FactionTagsProps } from '@/shared/types';

export const FactionTags = ({ className, factionsLabels }: FactionTagsProps) => {
  return (
    <div className={className}>
      {factionsLabels?.map((factionLabel) => (
        <FactionTag
          label={factionLabel}
          key={factionLabel}
        />
      ))}
    </div>
  );
};
