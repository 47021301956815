import { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { useIsMobile } from '@/shared/hooks';
import { LayoutDesktopSidebar, LayoutDesktopSidebarProps } from './components';
import styles from './styles.module.css';

export type Props = LayoutDesktopSidebarProps & {
  isMobileViewCollapsed?: boolean;
};

export const LayoutSidebar = ({ children, isMobileViewCollapsed, ...props }: PropsWithChildren<Props>) => {
  const isMobile = useIsMobile();

  return isMobile ? (
    <div className={classNames(styles.mobileSidebar, isMobileViewCollapsed ? styles.collapsed : styles.expanded)}>
      {children}
    </div>
  ) : (
    <LayoutDesktopSidebar {...props}>{children}</LayoutDesktopSidebar>
  );
};
