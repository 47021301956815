import { PropsWithChildren } from 'react';
import { Typography } from 'antd';
import styles from './styles.module.css';

export const HeaderTitle = ({ children }: PropsWithChildren) => {
  return (
    <Typography.Title
      level={3}
      className={styles.title}
    >
      {children}
    </Typography.Title>
  );
};
