import { Table as AntdTable, TableProps } from 'antd';
import { AnyObject } from 'antd/es/_util/type';
import classNames from 'classnames';
import styles from './styles.module.css';

export const Table = <T extends AnyObject>({ className, ...restProps }: TableProps<T>) => {
  return (
    <AntdTable
      className={classNames(styles.table, className)}
      pagination={false}
      {...restProps}
    />
  );
};
