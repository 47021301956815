import { useDebounceFn } from '@reactuses/core';
import {
  selectCallingElementPosition,
  setCallingElementPosition,
  setPointCoordinates,
  resetModalMapState,
} from '@/entities/map/slices';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Location } from '@/shared/types';

interface Params {
  locations: Location[];
  selectDropdownClassName: string;
}

const DEBOUNCE_SHOW_MODAL_MAP = 1000;

const useModalMapVisibilityController = ({ locations, selectDropdownClassName }: Params) => {
  const dispatch = useAppDispatch();
  const modalMapCallingElementPosition = useAppSelector(selectCallingElementPosition);

  const { run: showModalMap, cancel: cancelShowModalMap } = useDebounceFn((optionValue: string) => {
    const selectDropdownDomRect = document.querySelector(`.${selectDropdownClassName}`)?.getBoundingClientRect();
    const hoveredOption = locations.find((location) => location.id === optionValue);
    if (!selectDropdownDomRect || !hoveredOption?.coordinates) return;

    dispatch(
      setCallingElementPosition({
        top: selectDropdownDomRect.top,
        left: selectDropdownDomRect.left,
        right: selectDropdownDomRect.right,
      })
    );
    dispatch(setPointCoordinates(hoveredOption.coordinates));
  }, DEBOUNCE_SHOW_MODAL_MAP);

  const hideModalMap = () => {
    cancelShowModalMap();
    if (!modalMapCallingElementPosition) return;

    dispatch(resetModalMapState());
  };

  return {
    showModalMap,
    hideModalMap,
  };
};

export default useModalMapVisibilityController;
