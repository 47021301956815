import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Faction, RootState } from '@/shared/types';
import { FactionsState } from './types';

const initialState: FactionsState = {
  factionModal: null,
  factionsDictionary: {},
};

const factionsSlice = createSlice({
  name: 'factions',
  initialState,
  reducers: {
    setFactionModal: (state: FactionsState, action: PayloadAction<FactionsState['factionModal']>) => {
      state.factionModal = action.payload;
    },
    setFactionsDictionary: (state: FactionsState, action: PayloadAction<FactionsState['factionsDictionary']>) => {
      state.factionsDictionary = action.payload;
    },
  },
});

export const { setFactionModal, setFactionsDictionary } = factionsSlice.actions;

export const selectFactionModal = (state: RootState) => state.factions.factionModal;
export const selectFactionsDictionary = (state: RootState) => state.factions.factionsDictionary;

const selectId = (_: unknown, id: string) => id;
const selectById = createSelector(
  selectFactionsDictionary,
  selectId,
  (factionsDictionary: FactionsState['factionsDictionary'], id: string): Faction | undefined => factionsDictionary[id]
);

export const selectFactionById = (id: string) => (state: RootState) => selectById(state, id);

export default factionsSlice.reducer;
