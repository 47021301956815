import { useEffect, useState } from 'react';
import { SpectrumInstance } from '../SpectrumService';
import { SpectrogramProps } from '../types';

const RESET_IS_SPECTROGRAM_DRAWING_TIMEOUT = 15 * 1000;

interface Params extends Pick<SpectrogramProps, 'decibelPower'>, Partial<Pick<SpectrumInstance, 'addData'>> {}

const useDrawSpectrogram = ({ addData, decibelPower }: Params) => {
  const [isSpectrogramDrawing, setIsSpectrogramDrawing] = useState(false);

  useEffect(() => {
    if (decibelPower.length === 0) return;

    addData?.(decibelPower);

    setIsSpectrogramDrawing(true);
    const resetIsSpectrogramDrawingTimeoutId = setTimeout(() => {
      setIsSpectrogramDrawing(false);
    }, RESET_IS_SPECTROGRAM_DRAWING_TIMEOUT);

    return () => {
      clearTimeout(resetIsSpectrogramDrawingTimeoutId);
    };
  }, [decibelPower]);

  return {
    isSpectrogramDrawing,
  };
};

export default useDrawSpectrogram;
