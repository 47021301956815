import { useEffect, Dispatch, SetStateAction, useState } from 'react';
import { usePrevious, useDebounceFn, useElementSize } from '@reactuses/core';
import { DEBOUNCE_DEFAULT } from '@/shared/constants';
import { LayoutDesktopSidebarProps } from '../types';

const LAYOUT_CONTENT_MIN_WIDTH = 550;

interface Params extends Pick<LayoutDesktopSidebarProps, 'layoutRef' | 'resizerPosition'> {
  sidebarMinWidth: number;
  sidebarMaxWidth: number;
  sidebarWidth: string | number;
  setSidebarWidth: Dispatch<SetStateAction<string | number>>;
}

const useResizeRightSidebarOnWindowSizeChange = ({
  layoutRef,
  sidebarMinWidth,
  sidebarMaxWidth,
  sidebarWidth,
  setSidebarWidth,
  resizerPosition,
}: Params) => {
  const [layoutWidth] = useElementSize(layoutRef);
  const prevLayoutWidth = usePrevious(layoutWidth);
  const [forcedSidebarMaxWidth, setForcedSidebarMaxWidth] = useState(sidebarMaxWidth);
  const [isInFullScreen, setIsInFullScreen] = useState(false);

  const { run: handleFullScreenChange } = useDebounceFn(() => setIsInFullScreen((state) => !state), DEBOUNCE_DEFAULT);

  useEffect(() => {
    if (resizerPosition === 'right') return; // do not do anything for sidebars from the left side

    const freeSpaceForSidebar = layoutWidth - LAYOUT_CONTENT_MIN_WIDTH;

    if (sidebarMaxWidth <= freeSpaceForSidebar) {
      setForcedSidebarMaxWidth(sidebarMaxWidth);
    } else {
      setForcedSidebarMaxWidth(Math.floor(freeSpaceForSidebar));
    }
  }, [layoutWidth, sidebarMaxWidth, resizerPosition]);

  useEffect(() => {
    if (isInFullScreen || (!isInFullScreen && document.fullscreenElement)) return; // if user was in full screen mode and pressed Esc, it triggers window resize event, this "if" omits such resize event
    if (resizerPosition === 'right') return; // do not do anything for sidebars from the left side
    if (!layoutRef?.current || layoutRef.current.scrollWidth === layoutRef.current.clientWidth) return;
    if (typeof sidebarWidth === 'string' || sidebarWidth <= 0) return;

    if (prevLayoutWidth && prevLayoutWidth > layoutWidth) {
      if (sidebarWidth <= 0) return;

      setSidebarWidth((prevSidebarWidth) => {
        if (typeof prevSidebarWidth === 'string') return prevSidebarWidth;
        if (prevSidebarWidth < sidebarMinWidth) return 0;

        const newSidebarWidth = prevSidebarWidth - (prevLayoutWidth - layoutWidth);
        return newSidebarWidth < 0 ? 0 : Math.floor(newSidebarWidth);
      });
    }
  }, [
    isInFullScreen,
    layoutRef,
    layoutWidth,
    prevLayoutWidth,
    sidebarMinWidth,
    sidebarWidth,
    setSidebarWidth,
    resizerPosition,
  ]);

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, [handleFullScreenChange]);

  return {
    forcedSidebarMaxWidth,
  };
};

export default useResizeRightSidebarOnWindowSizeChange;
