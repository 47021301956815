import { createContext, useContext, useState, useMemo, PropsWithChildren } from 'react';
import { UserToEdit } from '@/entities/user/types';
import { EditUserModalState } from './types';

const EditUserModalContext = createContext<EditUserModalState | null>(null);

export const useEditUserModalContext = () => {
  const context = useContext(EditUserModalContext);
  if (!context) {
    throw new Error('useEditUserModalContext should be used inside the EditUserModalContextProvider');
  }
  return context;
};

const EditUserModalContextProvider = ({ children }: PropsWithChildren) => {
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState<UserToEdit | null>(null);

  const openModal = (user: UserToEdit) => {
    setIsEditUserModalOpen(true);
    setUserToEdit(user);
  };

  const closeModal = () => {
    setIsEditUserModalOpen(false);
  };

  const value = useMemo(
    () => ({
      isEditUserModalOpen,
      userToEdit,
      openModal,
      closeModal,
    }),
    [isEditUserModalOpen, userToEdit]
  );

  return <EditUserModalContext.Provider value={value}>{children}</EditUserModalContext.Provider>;
};

export default EditUserModalContextProvider;
