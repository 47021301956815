import { useEffect } from 'react';
import { useUnitRouteInfo } from '@/entities/unit/hooks';
import { UnitTab } from '@/shared/constants';
import { useParams } from '@/shared/hooks';
import { UnitRouteParams } from '@/shared/types';

type Params = {
  navigateToTab: (tab: UnitTab) => void;
};

const useTabRouteGuard = ({ navigateToTab }: Params) => {
  const { activeTab } = useParams<UnitRouteParams>();
  const { isFreeSourcesRoute, isFreeUsersRoute } = useUnitRouteInfo();

  useEffect(() => {
    if (!activeTab) return;

    if (isFreeSourcesRoute || isFreeUsersRoute || !Object.values(UnitTab).includes(activeTab)) {
      navigateToTab(UnitTab.Users);
    }
  }, [isFreeSourcesRoute, isFreeUsersRoute, activeTab]);
};

export default useTabRouteGuard;
