import { Flex, Typography } from 'antd';
import classNames from 'classnames';
import SourceActivity from '@/entities/source/@fox/components/SourceActivity';
import { FoxFrequencyStatus } from '@/entities/source/@fox/constants';
import { FoxFrequency } from '@/entities/source/@fox/types';
import styles from './styles.module.css';

type Props = {
  frequency: FoxFrequency;
};

const FoxFrequencyDetailsTitle = ({ frequency: { status, value, lastActiveAt } }: Props) => {
  return (
    <Flex
      className={classNames(styles.wrapper, {
        [styles.inactive]: status === FoxFrequencyStatus.Inactive,
      })}
    >
      <Typography.Text
        className={styles.title}
        strong
      >
        {value}
      </Typography.Text>
      <SourceActivity activity={lastActiveAt} />
    </Flex>
  );
};

export default FoxFrequencyDetailsTitle;
