import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Note } from '@/entities/note/types';
import { RootState } from '@/shared/types';
import { AppliedDates } from './types';

const initialState: AppliedDates = {};

export const noteAppliedDatesSlice = createSlice({
  name: 'noteAppliedDates',
  initialState,
  reducers: {
    setAppliedDates: (_, action: PayloadAction<Note[]>) => {
      const notesList = action.payload;
      const entries = notesList.map(({ id, applied_at }) => [id, applied_at]);

      return Object.fromEntries(entries);
    },
    addOrUpdateDate: (state: AppliedDates, action: PayloadAction<Note>) => {
      state[action.payload.id] = action.payload.applied_at;
    },
    deleteDate: (state: AppliedDates, action: PayloadAction<Note['id']>) => {
      delete state[action.payload];
    },
  },
});

export const { setAppliedDates, addOrUpdateDate, deleteDate } = noteAppliedDatesSlice.actions;

export const selectAppliedDates = (state: RootState) => Object.values(state.noteAppliedDates);

export default noteAppliedDatesSlice.reducer;
