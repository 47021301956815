import { useEffect, useMemo } from 'react';
import { Form } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import pick from 'lodash/pick';
import { useGetUnitSources } from '@/entities/unit/components/UnitContent/components/UnitSourcesTab/hooks';
import { useUnitRouteInfo } from '@/entities/unit/hooks';
import { UNIT_SOURCES_QUERY_KEY } from '@/shared/constants';
import validations from '@/shared/constants/validation.json';
import { useRenderPropsContext } from '@/shared/context';
import { validationService } from '@/shared/services';
import { UnitRenderProps } from '@/shared/types';
import { EditUnitSourceModalProps, UnitSourceForm } from '../types';
import useUpdateUnitSource from './useUpdateUnitSource';

interface Params extends EditUnitSourceModalProps {}

const useEditUnitSourceModal = ({ sourceId, onClose }: Params) => {
  const { renderSourceTypeSelect, renderAccessGroupSelect } = useRenderPropsContext<UnitRenderProps>();
  const [form] = Form.useForm<UnitSourceForm>();
  const { routeUnitId } = useUnitRouteInfo();
  const queryClient = useQueryClient();

  const { unitSourcesList } = useGetUnitSources({
    unitId: routeUnitId,
    options: { enabled: false },
  });

  const source = useMemo(() => unitSourcesList.find(({ id }) => id === sourceId), [unitSourcesList, sourceId]);
  const isFoxSource = source?.type === 'fox';
  const isBilkaSource = source?.type === 'bilka';
  const isVuhoSource = source?.type === 'vuho';

  const { updateUnitSource, isUnitSourceUpdating } = useUpdateUnitSource({
    options: {
      onSuccess: async () => {
        onClose();
        await queryClient.invalidateQueries([UNIT_SOURCES_QUERY_KEY, routeUnitId]);
      },
      onError: (error) => {
        validationService.validateForm({ form, error, validations });
      },
    },
  });

  const handleFormFinish = (formData: UnitSourceForm) => {
    updateUnitSource({
      sourceId,
      payload: formData,
    });
  };

  useEffect(() => {
    if (!source) return;

    form.setFieldsValue(pick(source, ['name', 'type', 'groupId', 'unitId', 'externalId']));
  }, [source, form]);

  return {
    form,
    isFoxSource,
    isBilkaSource,
    isVuhoSource,
    isUnitSourceUpdating,
    handleFormFinish,
    renderSourceTypeSelect,
    renderAccessGroupSelect,
  };
};

export default useEditUnitSourceModal;
