import { useSearchParams } from 'react-router-dom';
import {
  NETWORKS_TRANSCRIPTS_QUERY_PARAMS_KEY,
  TranscriptQueryParam,
  NoteQueryParam,
  MobileViewQueryParam,
  MobileView,
} from '@/shared/constants';
import { useNavigate, useLocalStorageForLimitedDays, useIsMobile } from '@/shared/hooks';
import { routingService, queryParamsService } from '@/shared/services';
import { RadioNetworkRouteParams, FilterLocalStorageParams, QueryParamsOptions } from '@/shared/types';

type Params = {
  routeParams: RadioNetworkRouteParams;
  options?: {
    withMobileView?: boolean;
    clearPreviousQueryParams?: boolean;
  } & QueryParamsOptions;
};

export const useNavigateToRadioNetwork = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const { getStorageValue } = useLocalStorageForLimitedDays<Record<string, FilterLocalStorageParams>>(
    NETWORKS_TRANSCRIPTS_QUERY_PARAMS_KEY
  );
  const [, setSearchParams] = useSearchParams();

  return ({ routeParams, options = {} }: Params) => {
    const filterList = getStorageValue() || {};
    const { withMobileView, clearPreviousQueryParams, ...navigateParams } = options;
    const { radioNetworkId } = routeParams;

    navigate({
      pathname: routingService.getRadioNetworkUrl(routeParams),
      options: navigateParams,
    });

    // clear all related tabs query parameters when change the radio network item and set mobile view
    setSearchParams(
      (searchParams) => {
        if (clearPreviousQueryParams) {
          Object.values(TranscriptQueryParam).forEach((paramName) => {
            searchParams.delete(paramName);
          });
          Object.values(NoteQueryParam).forEach((paramName) => {
            searchParams.delete(paramName);
          });
        }

        // we can not use useNavigateWithMobileView hook here because current setSearchParams contradicts it
        if (isMobile && withMobileView) searchParams.set(MobileViewQueryParam.Mobile, MobileView.SecondaryScreen);

        if (radioNetworkId && filterList[radioNetworkId]) {
          return queryParamsService.getUpdatedFilterQueryParam(
            TranscriptQueryParam,
            filterList[radioNetworkId],
            searchParams
          )();
        }

        return searchParams;
      },
      { replace: true }
    );
  };
};
