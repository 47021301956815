import { PropsWithChildren, ReactNode } from 'react';
import SupportNotification from '@/shared/components/SupportNotification';
import { MobileContainer } from '@/shared/components/mobile/MobileContainer';
import { MobileView } from '@/shared/constants';
import { useIsMobile, useMobileActiveViewQueryParam } from '@/shared/hooks';
import styles from './styles.module.css';

type Props = {
  viewType: MobileView;
  title?: ReactNode;
  isLoading?: boolean;
};

export const LayoutMobileView = ({ viewType, title, isLoading = false, children }: PropsWithChildren<Props>) => {
  const { mobileActiveView, setMobileActiveView } = useMobileActiveViewQueryParam();
  const isMobile = useIsMobile();

  if (!isMobile) return children;

  const activeView = mobileActiveView ?? MobileView.PrimaryScreen;
  const isMenu = viewType === MobileView.Menu;
  const isPrimaryScreen = viewType === MobileView.PrimaryScreen;
  const isSecondaryScreen = viewType === MobileView.SecondaryScreen;

  const handleClose = () => {
    if (isMenu || isSecondaryScreen) {
      setMobileActiveView?.(MobileView.PrimaryScreen, false);
      return;
    }
    if (isPrimaryScreen) {
      setMobileActiveView?.(MobileView.Menu, false);
    }
  };

  return (
    <MobileContainer
      title={title}
      viewType={activeView}
      isOpen={viewType === activeView}
      isLoading={isLoading}
      onClose={handleClose}
      hasMotion={false}
      bodyClassName={styles.containerBody}
      shouldDestroyOnClose={!isPrimaryScreen}
    >
      {!isMenu && <SupportNotification />}
      {children}
    </MobileContainer>
  );
};
