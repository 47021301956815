import { CreateFoxFrequencyPayload } from '@/entities/source/@fox/types';

export const FORM_INITIAL_VALUES: Partial<CreateFoxFrequencyPayload> = {
  value: '',
  note: '',
  isDigital: true,
};

export const NEW_FREQUENCY = 'Нове сканування';

export const NO_FREQUENCY = 'немає частоти';
export const NO_NOTES = 'немає нотатків';
