/**
 * WaveSurfer uses canvas for drawing audio visualization.
 * It provides the possibility to customize default audio waveforms.
 * Here is a simple audio timeline drawing on canvas.
 */

export const renderFunction = (channelData: (Float32Array | number[])[], ctx: CanvasRenderingContext2D) => {
  const topChannel = channelData[0];
  const { length } = topChannel;
  const { width } = ctx.canvas;
  const pixelRatio = window.devicePixelRatio || 1;
  const barWidth = 1;
  const barGap = 0;
  const barIndexScale = width / (barWidth + barGap) / length;

  let prevX = 0;
  for (let i = 0; i <= length; i += 1) {
    const x = Math.round(i * barIndexScale);

    if (x > prevX) {
      ctx.rect(prevX, 8 * pixelRatio, 1, 2 * pixelRatio);
      prevX = x;
    }
  }

  ctx.fill();
  ctx.closePath();
};

export default renderFunction;
