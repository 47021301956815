import { useQuery } from '@tanstack/react-query';
import { featureCollection } from '@turf/turf';
import { getAllInterceptionsMapPoints } from '@/entities/map/api';
import { GetInterceptionsMapPointsQueryParams } from '@/entities/map/api/types';
import { GeoJsonFeatureCollection } from '@/entities/map/types';
import { ALL_INTERCEPTIONS_MAP_KEY } from '@/shared/constants';

const EMPTY_RESPONSE: GeoJsonFeatureCollection = featureCollection([]);

const useGetMapPoints = (transcriptsIds: GetInterceptionsMapPointsQueryParams['transcripts']) => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: [ALL_INTERCEPTIONS_MAP_KEY, transcriptsIds],
    queryFn: () => {
      // getAllInterceptionsMapPoints returns 400 error if we try to query it without transcriptsIds
      // in this case, we won't make request when we don't have transcriptsIds
      if (transcriptsIds.length === 0) return { data: EMPTY_RESPONSE };

      return getAllInterceptionsMapPoints({ transcripts: transcriptsIds });
    },
    // disable by default, make request using only refetch method
    enabled: false,
  });

  return {
    geoJsonFeatures: data?.data?.features,
    isMapPointsFetching: isFetching,
    refetch,
  };
};

export default useGetMapPoints;
