import { FoxFrequencyEncryptionType } from '@/entities/source/@fox/constants';
import { exhaustiveGuard } from '@/shared/utils';
import { PossibilityOfDecryption } from '../types';

interface EncryptionTypeName {
  shortName: string;
  fullName: string;
}

export class FrequencyEncryptionTypeService {
  getEncryptionTypeName(encryptionType: FoxFrequencyEncryptionType): EncryptionTypeName {
    switch (encryptionType) {
      case FoxFrequencyEncryptionType.AES256:
        return {
          shortName: 'AES256',
          fullName: 'AES 256',
        };
      case FoxFrequencyEncryptionType.AES128:
        return {
          shortName: 'AES128',
          fullName: 'AES 128',
        };
      case FoxFrequencyEncryptionType.TYT380Basic:
        return {
          shortName: 'TYT',
          fullName: 'TYT 380 Basic',
        };
      case FoxFrequencyEncryptionType.TYT380Enhanced:
        return {
          shortName: 'TYT',
          fullName: 'TYT 380 Enhanced',
        };
      case FoxFrequencyEncryptionType.Hytera40:
      case FoxFrequencyEncryptionType.Hytera128:
      case FoxFrequencyEncryptionType.Hytera256:
        return {
          shortName: 'Hytera',
          fullName: 'Hytera 40bit / 128bit / 256bit',
        };
      case FoxFrequencyEncryptionType.HyteraFull:
        return {
          shortName: 'HyteraFull',
          fullName: 'Hytera Full',
        };
      case FoxFrequencyEncryptionType.BASIC:
        return {
          shortName: 'BASIC',
          fullName: 'BASIC',
        };
      case FoxFrequencyEncryptionType.RC4:
        return {
          shortName: 'RC4',
          fullName: 'RC4 - 40bit',
        };
      case FoxFrequencyEncryptionType.UNENCRYPTED:
        return {
          shortName: 'UE',
          fullName: 'Unencrypted',
        };
      case FoxFrequencyEncryptionType.UNKNOWN:
        return {
          shortName: 'UN',
          fullName: 'Unknown',
        };
      default:
        exhaustiveGuard(encryptionType);
        return {
          shortName: 'UN',
          fullName: 'Unknown',
        };
    }
  }

  getPossibilityOfDecryption(encryptionType: FoxFrequencyEncryptionType): PossibilityOfDecryption {
    switch (encryptionType) {
      case FoxFrequencyEncryptionType.RC4:
      case FoxFrequencyEncryptionType.UNENCRYPTED:
      case FoxFrequencyEncryptionType.TYT380Enhanced:
      case FoxFrequencyEncryptionType.Hytera40:
      case FoxFrequencyEncryptionType.Hytera128:
      case FoxFrequencyEncryptionType.Hytera256:
        return 'supported';
      case FoxFrequencyEncryptionType.BASIC:
        return 'partially supported';
      case FoxFrequencyEncryptionType.AES256:
        return 'supported with key';
      case FoxFrequencyEncryptionType.AES128:
      case FoxFrequencyEncryptionType.TYT380Basic:
      case FoxFrequencyEncryptionType.HyteraFull:
      case FoxFrequencyEncryptionType.UNKNOWN:
        return 'not supported';
      default:
        exhaustiveGuard(encryptionType);
        return 'not supported';
    }
  }

  getPossibilityOfDecryptionTranslation(possibilityOfDecryption: PossibilityOfDecryption) {
    switch (possibilityOfDecryption) {
      case 'supported':
        return 'підтримується';
      case 'not supported':
        return 'не підтримується';
      case 'supported with key':
        return 'підтримується з ключем';
      case 'partially supported':
        return 'частково підтримується';
      default:
        exhaustiveGuard(possibilityOfDecryption);
    }
  }

  getTagClassName({
    possibilityOfDecryption,
    greenClassName,
    orangeClassName,
    redClassName,
  }: {
    possibilityOfDecryption: PossibilityOfDecryption;
    greenClassName: string;
    orangeClassName: string;
    redClassName: string;
  }) {
    switch (possibilityOfDecryption) {
      case 'supported':
        return greenClassName;
      case 'partially supported':
        return orangeClassName;
      case 'not supported':
      case 'supported with key':
        return redClassName;
      default:
        exhaustiveGuard(possibilityOfDecryption);
    }
  }
}

export const frequencyEncryptionTypeService = new FrequencyEncryptionTypeService();
