import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  useCreateFoxFrequency,
  useDeleteFoxFrequency,
  useDeleteFoxFrequencyFromCache,
  useFoxFrequenciesQueryParams,
} from '@/entities/source/@fox/components/ScanningTab/hooks';
import { setLastSelectedFrequency, setSelectedFrequencies } from '@/entities/source/slices';
import { FOX_FREQUENCIES_QUERY_KEY } from '@/shared/constants';
import validations from '@/shared/constants/validation.json';
import { useParams, useAppDispatch, useOpenFormItemValidationNotification } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';
import { CreateFoxFrequencyPayload } from '../types';

const useSpectrogramHandlers = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const queryParams = useFoxFrequenciesQueryParams();
  const openFormItemValidationNotification = useOpenFormItemValidationNotification();
  const { sourceId = '' } = useParams<SourceRouteParams>();
  const queryKey = [FOX_FREQUENCIES_QUERY_KEY, sourceId, queryParams];

  const deleteFoxFrequencyFromCache = useDeleteFoxFrequencyFromCache(queryKey);

  const { createFoxFrequencyAsync } = useCreateFoxFrequency({
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKey);
    },
    onError: (error) => {
      openFormItemValidationNotification({
        error,
        validations,
        errorPointer: 'value',
      });
    },
  });

  const { deleteFoxFrequencyAsync } = useDeleteFoxFrequency({
    onSuccess: (_, variables) => {
      deleteFoxFrequencyFromCache(variables.foxFrequencyId);
    },
  });

  const handleDeleteFoxFrequency = useCallback(
    async (foxFrequencyId: string) => {
      await deleteFoxFrequencyAsync({ sourceId, foxFrequencyId });
    },
    [deleteFoxFrequencyAsync, sourceId]
  );

  const handleCreateFoxFrequency = async ({ value, isDigital }: CreateFoxFrequencyPayload) => {
    await createFoxFrequencyAsync({ value: value.toString(), note: '', isDigital });
  };

  const memoHandleFrequenciesSelect = useCallback(
    (frequencies: Record<string, boolean>) => {
      dispatch(setSelectedFrequencies(frequencies));
      dispatch(setLastSelectedFrequency(null));
    },
    [dispatch]
  );

  return {
    handleCreateFoxFrequency,
    handleDeleteFoxFrequency,
    memoHandleFrequenciesSelect,
  };
};

export default useSpectrogramHandlers;
