import { useRef, useEffect } from 'react';

export function useScrollToTop(dependency: unknown) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.scrollTo(0, 0);
  }, [dependency]);

  return ref;
}
