import { FormFieldData } from '@/shared/types';

export const MODAL_TITLE = 'Редагувати джерело';

export const SOURCE_NAME: FormFieldData = {
  name: 'name',
  label: 'Назва джерела',
  extraLabel: 'Назва посту або позивний місця де встановлено пристрій',
};

export const SOURCE_TYPE: FormFieldData = {
  name: 'type',
  label: 'Тип джерела',
};

export const ACCESS_GROUP: FormFieldData = {
  name: 'groupId',
  label: 'Група доступу',
};

export const SERIAL_NUMBER: FormFieldData = {
  name: 'externalId',
  label: 'Серійний номер пристрою',
  rules: [{ required: true, message: 'Введіть серійний номер пристрою' }],
};

export const COMMAND: FormFieldData = {
  name: 'externalId',
  label: 'Команда',
  rules: [{ required: true, message: 'Введіть команду пристрою' }],
};
