import { Form } from 'antd';
import FoxFrequencyCreateButtons from '@/entities/source/@fox/components/ScanningTab/components/FoxFrequencyCreateButtons';
import IsDigitalToggle from '@/entities/source/@fox/components/ScanningTab/components/IsDigitalToggle';
import { FORM_INITIAL_VALUES, NO_FREQUENCY, NO_NOTES } from '@/entities/source/@fox/components/ScanningTab/constants';
import { getValueValidationRules } from '@/entities/source/@fox/components/ScanningTab/utils';
import { Input, TextArea } from '@/shared/components';
import useCreateFoxFrequencyForm from './useCreateFoxFrequencyForm';

const FREQUENCY_LABEL = 'Частота';
const NOTES_LABEL = 'Нотатки';

type Props = {
  onCloseForm: VoidFunction;
};

const CreateFoxFrequencyForm = ({ onCloseForm }: Props) => {
  const { form, frequencyRange, isFoxFrequencyCreating, handleFinish, handleCancel } = useCreateFoxFrequencyForm({
    onCloseForm,
  });

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      initialValues={FORM_INITIAL_VALUES}
    >
      <Form.Item
        name="value"
        label={FREQUENCY_LABEL}
        rules={getValueValidationRules(frequencyRange)}
      >
        <Input
          autoFocus
          placeholder={NO_FREQUENCY}
          maxLength={8}
        />
      </Form.Item>
      <Form.Item
        name="note"
        label={NOTES_LABEL}
      >
        <TextArea
          autoSize
          placeholder={NO_NOTES}
        />
      </Form.Item>
      <IsDigitalToggle formItemName="isDigital" />
      <FoxFrequencyCreateButtons
        isSubmitting={isFoxFrequencyCreating}
        onCancelClick={handleCancel}
        onSubmitClick={form.submit}
      />
    </Form>
  );
};

export default CreateFoxFrequencyForm;
