import { InfoCircleOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { Button, ButtonProps } from '@/shared/components';
import { useToggleDetailsButton } from './hooks';
import styles from './styles.module.css';

interface Props extends Omit<ButtonProps, 'className' | 'onClick'> {
  isActive: boolean;
  onClick: (value: boolean) => void;
}

const ToggleDetailsButton = ({ isActive, onClick, icon = <InfoCircleOutlined />, ...buttonProps }: Props) => {
  const { handleClick } = useToggleDetailsButton({ isActive, onClick });
  return (
    <Button
      {...buttonProps}
      icon={icon}
      onClick={handleClick}
      className={classnames(styles.button, {
        [styles.activeButton]: isActive,
      })}
    />
  );
};

export default ToggleDetailsButton;
