import { ReactElement, ReactNode } from 'react';
import { Row } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import ToggleDetailsButton from '../ToggleDetailsButton';
import styles from './styles.module.css';

interface Props {
  isSidebarVisible: boolean;
  onToggleSidebarClick: (isSidebarVisible: boolean) => void;
  toggleIcon?: ReactNode;
  additionalElement?: ReactElement;
}

const TabHeader = ({
  isSidebarVisible,
  onToggleSidebarClick,
  toggleIcon = <InfoCircleOutlined />,
  additionalElement,
}: Props) => {
  return (
    <Row className={styles.wrapper}>
      {additionalElement}
      <ToggleDetailsButton
        onClick={onToggleSidebarClick}
        isActive={isSidebarVisible}
        icon={toggleIcon}
      />
    </Row>
  );
};

export default TabHeader;
