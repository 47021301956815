import { TimeRangePickerProps } from 'antd';
import dayjs from 'dayjs';

export const DATE_RANGE_FORMAT = 'DD/MM/YYYY HH:mm';
export const DATE_RANGE_FORMAT_SHORT = 'DD/MM/YY HH:mm';

export const PREVIOUS_MONTH = dayjs().add(-30, 'd').startOf('day');

export const RANGE_PRESETS: TimeRangePickerProps['presets'] = [
  { label: 'Минулі 30 днів', value: [dayjs().add(-30, 'd').startOf('day'), dayjs().endOf('day')] },
  { label: 'Минулі 90 днів', value: [dayjs().add(-90, 'd').startOf('day'), dayjs().endOf('day')] },
  { label: 'Поточний рік', value: [dayjs().startOf('year'), dayjs().endOf('day')] },
  { label: 'Минулі 365 днів', value: [dayjs().add(-365, 'd').startOf('day'), dayjs().endOf('day')] },
];
