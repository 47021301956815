import { theme } from 'antd';
import { EChartsOption } from '../types';

export const useDefaultOption = (): EChartsOption => {
  const { token } = theme.useToken();
  return {
    tooltip: {
      trigger: 'item',
      backgroundColor: token.colorBorderBg,
      textStyle: {
        color: token.colorText,
      },
    },
    legend: {
      left: 0,
      bottom: 0,
      selectedMode: false,
      itemWidth: 14,
      textStyle: {
        color: token.colorText,
      },
    },
    grid: {
      left: 0,
      right: 0,
      bottom: 40,
      containLabel: true,
    },
    xAxis: {
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        interval: 3,
        color: token.colorText,
      },
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: false,
      },
      axisLabel: {
        color: token.colorTextSecondary,
      },
    },
    animation: false,
  };
};
