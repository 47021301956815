import { transcriptionSchema } from '@/entities/transcript';
import { responseDataSchema } from '@/shared/validationSchemas';

export const attachCategoryToTranscriptManuallySchema = responseDataSchema(transcriptionSchema);
export const unAttachCategoryFromTranscriptManuallySchema = responseDataSchema(transcriptionSchema);
export const attachTagToTranscriptSchema = responseDataSchema(transcriptionSchema);
export const attachCallSignToTranscriptSchema = responseDataSchema(transcriptionSchema);
export const createCallSignFromTranscriptSchema = responseDataSchema(transcriptionSchema);
export const attachLocationToTranscriptSchema = responseDataSchema(transcriptionSchema);
export const attachLocationManuallySchema = responseDataSchema(transcriptionSchema);
export const attachFactionToTranscriptSchema = responseDataSchema(transcriptionSchema);
export const attachFactionManuallySchema = responseDataSchema(transcriptionSchema);
