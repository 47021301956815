import { useMemo } from 'react';
import { Tabs as AntdTabs, TabsProps } from 'antd';
import classNames from 'classnames';
import DeferredTabContent from '@/shared/components/DeferredTabContent';
import { useIsTabletOrMobile } from '@/shared/hooks';
import styles from './styles.module.css';

const Tabs = ({ children, className, items, ...props }: TabsProps) => {
  const isTabletOrMobile = useIsTabletOrMobile();

  const deferredItems = useMemo(() => {
    return items?.map((item) => ({
      ...item,
      children: item.children ? <DeferredTabContent>{item.children}</DeferredTabContent> : undefined,
    }));
  }, [items]);

  return (
    <AntdTabs
      {...props}
      className={classNames(styles.styledTabs, className)}
      data-testid="tabs"
      tabBarStyle={{
        marginLeft: isTabletOrMobile ? 16 : 32,
        marginRight: isTabletOrMobile ? 16 : 32,
        marginBottom: 0,
      }}
      items={deferredItems}
    >
      {children}
    </AntdTabs>
  );
};

export default Tabs;
