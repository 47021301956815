import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/shared/types';
import { CallSignsState } from './types';

const initialState: CallSignsState = {
  radioNetworkId: '',
  isAddingNewCallSign: false,
  newCallSign: null,
};

export const callSignsSlice = createSlice({
  name: 'callSigns',
  initialState,
  reducers: {
    setCallSignsRadioNetworkId: (state: CallSignsState, action: PayloadAction<CallSignsState['radioNetworkId']>) => {
      state.radioNetworkId = action.payload;
    },
    setCallSignsSearchText: (state: CallSignsState, action: PayloadAction<CallSignsState['searchText']>) => {
      state.searchText = action.payload;
    },
    startAddingNewCallSign: (state: CallSignsState) => {
      state.isAddingNewCallSign = true;
      state.newCallSign = null;
    },
    stopAddingNewCallSign: (state: CallSignsState) => {
      state.isAddingNewCallSign = false;
      state.newCallSign = null;
    },
    setNewCallSignProperty: (state: CallSignsState, action: PayloadAction<CallSignsState['newCallSign']>) => {
      state.newCallSign = { ...state.newCallSign, ...action.payload };
    },
    resetState: () => initialState,
  },
});

export const {
  setCallSignsRadioNetworkId,
  setCallSignsSearchText,
  startAddingNewCallSign,
  stopAddingNewCallSign,
  setNewCallSignProperty,
  resetState,
} = callSignsSlice.actions;

export const selectCallSignsRadioNetworkId = (state: RootState) => {
  const {
    callSigns: { radioNetworkId },
  } = state;
  return radioNetworkId;
};

export const selectCallSignsQueryParams = (state: RootState) => {
  const {
    callSigns: { pageSize, searchText },
  } = state;
  return { pageSize, searchText };
};

export const selectIsAddingNewCallSign = (state: RootState) => {
  const {
    callSigns: { isAddingNewCallSign },
  } = state;
  return isAddingNewCallSign;
};

export const selectNewCallSign = (state: RootState) => {
  const {
    callSigns: { newCallSign },
  } = state;
  return newCallSign;
};

export default callSignsSlice.reducer;
