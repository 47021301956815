import { useState, useCallback } from 'react';
import { AudioPlayerProps } from '../type';

interface Params extends Pick<AudioPlayerProps, 'memoSettings' | 'memoOnSettingsUpdate'> {
  memoPlay: VoidFunction;
}

const useAutoplayControl = ({ memoSettings, memoPlay, memoOnSettingsUpdate }: Params) => {
  const isAutoplayOn = Boolean(memoSettings.autoplay);
  const [hasPlayed, setHasPlayed] = useState(false);

  const memoToggleAutoplay = useCallback(() => {
    if (!isAutoplayOn && !hasPlayed) {
      memoPlay();
      setHasPlayed(true);
    }

    memoOnSettingsUpdate?.({ autoplay: !isAutoplayOn });
  }, [isAutoplayOn, hasPlayed, memoOnSettingsUpdate, memoPlay]);

  return { isAutoplayOn, memoToggleAutoplay };
};

export default useAutoplayControl;
