import { CallingElementPosition } from '@/entities/map/slices';

interface Params {
  callingElementPosition: CallingElementPosition | null;
  modalMapWidth: number;
  spaceBetweenMapAndCallingElement: number;
}

const getModalMapPosition = ({ callingElementPosition, modalMapWidth, spaceBetweenMapAndCallingElement }: Params) => {
  if (!callingElementPosition) return;

  const { top, left, right } = callingElementPosition;
  if (left - spaceBetweenMapAndCallingElement < modalMapWidth) {
    return {
      top,
      left: right + spaceBetweenMapAndCallingElement,
    };
  }
  return {
    top,
    left: left - modalMapWidth - spaceBetweenMapAndCallingElement,
  };
};

export default getModalMapPosition;
