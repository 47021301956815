import styles from './styles.module.css';
import { getImagePath } from './utils';

interface Props {
  id: number;
  name?: string;
}

export const CategoryTagIcon = ({ id, name }: Props) => {
  return (
    <img
      src={getImagePath(id)}
      alt={name}
      className={styles.img}
    />
  );
};
