import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { updateUnitSource as updateUnitSourceApi } from '@/entities/unit/api';
import { UpdateUnitSourceParams, UnitSource } from '@/entities/unit/types';
import { ResponseData } from '@/shared/api';
import { UPDATE_UNIT_SOURCE_MUTATION_KEY } from '@/shared/constants';

interface Params {
  options?: UseMutationOptions<ResponseData<UnitSource>, unknown, UpdateUnitSourceParams>;
}

const useUpdateUnitSource = ({ options = {} }: Params = {}) => {
  const { mutate: updateUnitSource, isLoading: isUnitSourceUpdating } = useMutation({
    ...options,
    mutationKey: [UPDATE_UNIT_SOURCE_MUTATION_KEY],
    mutationFn: updateUnitSourceApi,
  });

  return {
    updateUnitSource,
    isUnitSourceUpdating,
  };
};

export default useUpdateUnitSource;
