import { z } from 'zod';
import { baseCategorySchema, paginatedSchema, responseDataSchema } from '@/shared/validationSchemas';

const categoryCountersSchema = z.object({
  countTags: z.number(),
});

export const subcategorySchema = baseCategorySchema.merge(categoryCountersSchema);

export const categorySchema = z
  .object({
    subCategories: z.array(subcategorySchema).optional(),
  })
  .merge(baseCategorySchema)
  .merge(categoryCountersSchema);

export const recommendedCategorySchema = z
  .object({
    children: z.array(baseCategorySchema).optional(),
  })
  .merge(baseCategorySchema);

export const tagSchema = z.object({
  id: z.string(),
  name: z.string(),
  categoryId: z.number(),
  countTranscripts: z.string(),
});

export const categoryCreateResponsePayloadSchema = categorySchema.pick({ id: true });

export const categoryUpdateResponsePayloadSchema = categorySchema.pick({ id: true, name: true });

export const getCategoriesSchema = responseDataSchema(z.array(categorySchema));

export const getRecommendedCategoriesSchema = responseDataSchema(z.array(recommendedCategorySchema));

export const createCategorySchema = responseDataSchema(categoryCreateResponsePayloadSchema);

export const updateCategorySchema = responseDataSchema(categoryUpdateResponsePayloadSchema);

export const getCategoryTagsSchema = paginatedSchema(tagSchema);
