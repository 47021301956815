import { CSSProperties } from 'react';
import getRoundedFloat from './getRoundedFloat';

interface Params {
  startZoomPercent?: number;
  endZoomPercent?: number;
  canvasWidth: number;
}

const getCanvasZoomStyle = ({ startZoomPercent, endZoomPercent, canvasWidth }: Params): CSSProperties => {
  if (typeof startZoomPercent === 'undefined' || typeof endZoomPercent === 'undefined') return {};

  const scaleX = getRoundedFloat(((canvasWidth / (endZoomPercent - startZoomPercent)) * 100) / canvasWidth);
  const translateX = -startZoomPercent;

  return {
    transform: `scaleX(${scaleX}) translateX(${translateX}%)`,
  };
};

export default getCanvasZoomStyle;
