import { Rule } from 'antd/es/form';
import { FoxSource } from '@/entities/source/types';
import validateRange from './validateRange';

const getValueValidationRules = (frequencyRange?: FoxSource['frequencyRange']): Rule[] => {
  const fromStringified = frequencyRange?.from?.toFixed(4) ?? '100.0000';
  const toStringified = frequencyRange?.to?.toFixed(4) ?? '500.0000';

  return [{ required: true, message: 'Введіть частоту' }, { validator: validateRange(fromStringified, toStringified) }];
};

export default getValueValidationRules;
