import { Badge } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import styles from './styles.module.css';

type Props = {
  count: number;
};

export const FilterIconWithBadge = ({ count }: Props) => {
  return (
    <Badge
      className={styles.badge}
      count={count}
      size="small"
    >
      <FilterOutlined />
    </Badge>
  );
};
