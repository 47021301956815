import { useMemo } from 'react';
import { UnitsDictionary } from '@/entities/unit/types';
import { getUnitsDictionary } from '../utils';
import useGetUnits from './useGetUnits';

const useUnitsDictionary = (): UnitsDictionary => {
  const { units } = useGetUnits({ options: { enabled: false } });

  return useMemo(() => getUnitsDictionary(units), [units]);
};

export default useUnitsDictionary;
