import { PropsWithChildren } from 'react';
import { Layout } from 'antd';
import classNames from 'classnames';
import { useLayoutDesktopSidebar } from './hooks';
import styles from './styles.module.css';
import { LayoutDesktopSidebarProps } from './types';

export type { LayoutDesktopSidebarProps } from './types';

export const LayoutDesktopSidebar = ({
  layoutSidebarClassName,
  resizerClassName,
  resizerPosition = 'right',
  children,
  ...restProps
}: PropsWithChildren<LayoutDesktopSidebarProps>) => {
  const { sidebarRef, sidebarWidth, startResizing } = useLayoutDesktopSidebar({ resizerPosition, ...restProps });

  return (
    <Layout.Sider
      ref={sidebarRef}
      width={sidebarWidth}
      className={classNames(
        styles.sidebarWrapper,
        {
          [styles.resizerLeftWrapper]: resizerPosition === 'left',
          [styles.collapsed]: Number(sidebarWidth) === 0,
        },
        layoutSidebarClassName
      )}
    >
      <div className={classNames(resizerClassName, styles.resizerWrapper)}>
        <div className={styles.contentWrapper}>{children}</div>
        <div
          className={classNames(styles.resizer, {
            [styles.resizerLeft]: resizerPosition === 'left',
          })}
          onMouseDown={startResizing}
          onTouchStart={startResizing}
        />
      </div>
    </Layout.Sider>
  );
};
