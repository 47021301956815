import {
  ACCESS_GROUPS_PATH_BASE,
  AccessGroupTab,
  CATEGORIES_PATH_BASE,
  FACTIONS_PATH_BASE,
  RADIO_NETWORKS_PATH_BASE,
  RadioNetworkTab,
  SOURCES_PATH_BASE,
  SourceTab,
  UNIT_FREE_SOURCES_ROUTE,
  UNIT_FREE_USERS_ROUTE,
  UNITS_PATH_BASE,
  UnitTab,
} from '@/shared/constants';
import {
  AccessGroupRouteParams,
  CategoryRouteParams,
  FactionRouteParams,
  RadioNetworkRouteParams,
  SourceRouteParams,
  UnitRouteParams,
} from '@/shared/types';

export class RoutingService {
  getAccessGroupUrl({ activeTab = AccessGroupTab.Units, accessGroupId }: AccessGroupRouteParams) {
    const pathSuffix = accessGroupId ? `/${accessGroupId}` : '';
    return `${ACCESS_GROUPS_PATH_BASE}/${activeTab}${pathSuffix}`;
  }

  getUnitUrl({ unitId, activeTab = UnitTab.Users }: UnitRouteParams) {
    if (unitId === UNIT_FREE_SOURCES_ROUTE) return `${UNITS_PATH_BASE}/${UNIT_FREE_SOURCES_ROUTE}`;
    if (unitId === UNIT_FREE_USERS_ROUTE) return `${UNITS_PATH_BASE}/${UNIT_FREE_USERS_ROUTE}`;

    const pathSuffix = unitId ? `/${unitId}/${activeTab}` : '';
    return `${UNITS_PATH_BASE}${pathSuffix}`;
  }

  getCategoryUrl({ categoryId }: CategoryRouteParams) {
    const pathSuffix = categoryId ? `/${categoryId}` : '';
    return `${CATEGORIES_PATH_BASE}${pathSuffix}`;
  }

  getFactionUrl({ factionId }: FactionRouteParams) {
    const pathSuffix = factionId ? `/${factionId}` : '';
    return `${FACTIONS_PATH_BASE}${pathSuffix}`;
  }

  getRadioNetworkUrl({ radioNetworkId, activeTab = RadioNetworkTab.Interceptions }: RadioNetworkRouteParams) {
    const pathSuffix = radioNetworkId ? `/${radioNetworkId}/${activeTab}` : '';
    return `${RADIO_NETWORKS_PATH_BASE}${pathSuffix}`;
  }

  getSourceUrl({ sourceId, activeTab = SourceTab.Scanning }: SourceRouteParams) {
    const pathSuffix = sourceId ? `/${sourceId}/${activeTab}` : '';
    return `${SOURCES_PATH_BASE}${pathSuffix}`;
  }

  getFoxKeyUrl({ sourceId, tabEntityId }: SourceRouteParams) {
    const pathSuffix = tabEntityId ? `/${tabEntityId}` : '';
    return `${SOURCES_PATH_BASE}/${sourceId}/${SourceTab.Keys}${pathSuffix}`;
  }

  getFoxRecordUrl({ sourceId, tabEntityId }: SourceRouteParams) {
    const pathSuffix = tabEntityId ? `/${tabEntityId}` : '';
    return `${SOURCES_PATH_BASE}/${sourceId}/${SourceTab.Records}${pathSuffix}`;
  }
}

export const routingService = new RoutingService();
