import { useSearchParams } from 'react-router-dom';

export const useSearchParam = <T extends string>(searchParam: string): [T, (val: T, replace?: boolean) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamValue = searchParams.get(searchParam) ?? '';
  const setSearchParamValue = (value: T, replace: boolean = true) => {
    if (value) searchParams.set(searchParam, value);
    else searchParams.delete(searchParam);
    setSearchParams(searchParams, { replace });
  };
  return [searchParamValue as T, setSearchParamValue];
};
