import classNames from 'classnames';
import { MobileBackIcon, MobileCloseIcon, MobileMenuIcon, MobileOpenIcon } from '@/shared/assets';
import styles from './styles.module.css';

const navigationClass = classNames(styles.button, styles.navigationButton);

export const MobileBackButtonIcon = () => <MobileBackIcon className={navigationClass} />;
export const MobileCloseButtonIcon = () => <MobileCloseIcon className={navigationClass} />;
export const MobileOpenButtonIcon = () => <MobileOpenIcon className={classNames(styles.button, styles.openButton)} />;
export const MobileMenuButtonIcon = () => <MobileMenuIcon className={classNames(styles.button, styles.menu)} />;
