import { ColumnTitle, ColumnPlaceholder, ALIAS_DETAIL_ITEM, CallSignKey } from '@/entities/callSign';
import { FactionTags } from '@/entities/faction';
import { CallSign, ResponsiveColumnType } from '@/shared/types';

export const DETAIL_VIEW_ITEMS: ResponsiveColumnType<CallSign>[] = [
  {
    dataIndex: CallSignKey.Name,
    title: ColumnTitle.CallSignTitle,
    modifyFn: (name) => (name as CallSign['name']).toUpperCase(),
  },
  ALIAS_DETAIL_ITEM,
  {
    dataIndex: CallSignKey.Position,
    title: ColumnTitle.Position,
    placeholder: ColumnPlaceholder.Position,
  },
  {
    dataIndex: CallSignKey.Factions,
    title: ColumnTitle.Faction,
    placeholder: ColumnPlaceholder.Faction,
    modifyFn: (factions) => {
      const callSignFactions = factions as CallSign[CallSignKey.Factions];
      if (!callSignFactions?.length) return null;
      const factionsLabels = callSignFactions?.map(({ full_short_name }) => full_short_name);
      return <FactionTags factionsLabels={factionsLabels} />;
    },
  },
  {
    dataIndex: CallSignKey.Note,
    title: ColumnTitle.Notes,
    placeholder: ColumnPlaceholder.Notes,
  },
];
