import { useCallback, useEffect, useRef, useState } from 'react';
import { useInterval } from '@reactuses/core';
import { useContentSize } from '@/shared/hooks';
import { getLayoutSidebarLocalStorageKey } from '@/shared/utils';
import { TranscriptsAndMapProps } from '../types';

interface Params extends Pick<TranscriptsAndMapProps, 'sidebarStorageKey'> {}

const TRANSCRIPTS_MAP_MIN_WIDTH = 220;
const TRANSCRIPTS_FULL_WIDTH = '100%';
const ANIMATION_DELAY = 500;

const useResizeSidebar = ({ sidebarStorageKey }: Params) => {
  const [isMapVisible, setIsMapVisible] = useState(() => {
    const persistedTranscriptsColumnWidth = localStorage.getItem(getLayoutSidebarLocalStorageKey(sidebarStorageKey));
    if (persistedTranscriptsColumnWidth) {
      const { value } = JSON.parse(persistedTranscriptsColumnWidth);
      return value !== TRANSCRIPTS_FULL_WIDTH;
    }
    return true;
  });
  const [forceResizeSidebarToWidth, setForceResizeSidebarToWidth] = useState<string | number | null>(null);
  const [recalculateFrequency, setRecalculateFrequency] = useState<null | number>(null);
  const transcriptsAndMapWrapperRef = useRef<HTMLDivElement>(null);
  const layoutSidebarWrapperRef = useRef<HTMLDivElement>(null);
  const { contentWidth: transcriptsAndMapWrapperWidth } = useContentSize(transcriptsAndMapWrapperRef);
  const sidebarDefaultWidth = transcriptsAndMapWrapperWidth / 2;

  const syncIsMapVisible = useCallback(() => {
    const transcriptsSection = layoutSidebarWrapperRef.current?.firstChild as HTMLDivElement | undefined;
    const transcriptsSectionWidth = transcriptsSection?.clientWidth;
    if (
      transcriptsSectionWidth &&
      transcriptsAndMapWrapperWidth - transcriptsSectionWidth <= TRANSCRIPTS_MAP_MIN_WIDTH
    ) {
      setIsMapVisible(false);
      setForceResizeSidebarToWidth(TRANSCRIPTS_FULL_WIDTH);
    } else if (
      !isMapVisible &&
      transcriptsSectionWidth &&
      transcriptsAndMapWrapperWidth - transcriptsSectionWidth > TRANSCRIPTS_MAP_MIN_WIDTH
    ) {
      setIsMapVisible(true);
    }
  }, [isMapVisible, transcriptsAndMapWrapperWidth]);

  const handleResizeStart = () => setRecalculateFrequency(500);

  const handleResizeFinish = useCallback(() => {
    syncIsMapVisible();
    setRecalculateFrequency(null);
  }, [syncIsMapVisible]);

  const handleToggleMapVisibilityButtonClick = () => {
    if (isMapVisible) {
      setIsMapVisible(false);
      setForceResizeSidebarToWidth(TRANSCRIPTS_FULL_WIDTH);
    } else {
      setForceResizeSidebarToWidth(sidebarDefaultWidth);
      // we need to wait for the sidebar resizing animation and recalculate the map content after the transition finished
      setTimeout(() => {
        setIsMapVisible(true);
      }, ANIMATION_DELAY);
    }
  };

  useInterval(() => {
    if (!isMapVisible) {
      setIsMapVisible(true);
    }
  }, recalculateFrequency);

  useEffect(() => {
    syncIsMapVisible();
  }, [transcriptsAndMapWrapperWidth]);

  useEffect(() => {
    if (forceResizeSidebarToWidth) setForceResizeSidebarToWidth(null);
  }, [forceResizeSidebarToWidth]);

  return {
    sidebarDefaultWidth,
    forceResizeSidebarToWidth,
    handleResizeFinish,
    handleResizeStart,
    transcriptsAndMapWrapperRef,
    transcriptsAndMapWrapperWidth,
    layoutSidebarWrapperRef,
    isMapVisible,
    handleToggleMapVisibilityButtonClick,
  };
};

export default useResizeSidebar;
