import { PropsWithChildren, ReactNode } from 'react';
import { MobileContainer } from '@/shared/components/mobile/MobileContainer';
import { MobileDetailsManagerState, MobileView } from '@/shared/constants';
import { useAppDispatch } from '@/shared/hooks';
import { setActiveMobileDetailsManagerState, resetActiveMobileDetailsManagerState } from '@/shared/slices';

type Props = {
  viewType: MobileDetailsManagerState | null;
  title?: ReactNode;
  isLoading?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
};

export const MobileDetailsContainer = ({
  viewType,
  title,
  isLoading = false,
  isOpen = false,
  onClose,
  children,
}: PropsWithChildren<Props>) => {
  const dispatch = useAppDispatch();

  const isDetails = viewType === MobileDetailsManagerState.View;
  const isEdit = viewType === MobileDetailsManagerState.Edit;
  const isCreate = viewType === MobileDetailsManagerState.Create;

  const handleClose = () => {
    if (isEdit) {
      dispatch(setActiveMobileDetailsManagerState(MobileDetailsManagerState.View));
    }
    if (isDetails || isCreate) {
      dispatch(resetActiveMobileDetailsManagerState());
    }
    onClose?.();
  };

  return (
    <MobileContainer
      title={title}
      viewType={MobileView.SecondaryScreen}
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={handleClose}
      hasMotion={false}
      shouldSynchronizeWithModalQueryParam
    >
      {children}
    </MobileContainer>
  );
};
