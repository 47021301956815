import { Space, Switch, Typography } from 'antd';
import { NOTIFY_ME } from '@/widgets/SubscriptionDrawer/constants';
import {
  useCreateSubscription,
  useDeactivateSubscription,
  useActivateSubscription,
  useGetSubscriptions,
} from '@/widgets/SubscriptionDrawer/hooks';
import styles from './styles.module.css';

const SIGNAL_SENDER_PHONE_NUMBER = '+38 (063) 306 1075';
const NOTE = `Повідомлення приходитимуть в програму Signal від номеру ${SIGNAL_SENDER_PHONE_NUMBER} на ваш номер`;

const NotifyMeSection = () => {
  const { subscriptions } = useGetSubscriptions();
  const { createSubscription } = useCreateSubscription();
  const { deactivateSubscription, isDeactivating } = useDeactivateSubscription();
  const { activateSubscription, isActivating } = useActivateSubscription();

  const handleSubscriptionToggle = async (wantToBeSubscribed: boolean) => {
    if (wantToBeSubscribed) {
      if (subscriptions?.models.length) {
        return activateSubscription(subscriptions.models[0].id);
      }
      return createSubscription([]);
    }
    if (subscriptions) {
      deactivateSubscription(subscriptions.models[0].id);
    }
  };
  return (
    <Space
      direction="vertical"
      size="small"
      className={styles.notifyMe}
    >
      <Space direction="vertical">
        <Space>
          <Switch
            checked={subscriptions?.models ? Boolean(subscriptions?.models[0]?.is_active) : false}
            onChange={handleSubscriptionToggle}
            disabled={isDeactivating || isActivating}
          />
          <Typography.Text strong>{NOTIFY_ME}</Typography.Text>
        </Space>
      </Space>
      <Typography.Text type="secondary">{NOTE}</Typography.Text>
    </Space>
  );
};

export default NotifyMeSection;
