import {
  FOX_SIDEBAR_DEFAULT_WIDTH,
  FOX_SIDEBAR_MAX_WIDTH,
  FOX_SIDEBAR_MIN_WIDTH,
} from '@/entities/source/@fox/constants';
import { LayoutTwoColumns } from '@/shared/components';
import { useIsMobile } from '@/shared/hooks';
import Details from '../Details';
import TabHeader from '../TabHeader';
import { MobileFoxKeysList, FoxKeysTable } from './components';
import { useFoxKeyDetails } from './hooks';

const SOURCE_KEYS_SIDEBAR_STORAGE_KEY = 'source-keys';

const KeysTab = () => {
  const details = useFoxKeyDetails();
  const isMobile = useIsMobile();

  return (
    <LayoutTwoColumns
      isSidebarRight
      sidebarMinWidth={FOX_SIDEBAR_MIN_WIDTH}
      sidebarDefaultWidth={FOX_SIDEBAR_DEFAULT_WIDTH}
      sidebarMaxWidth={FOX_SIDEBAR_MAX_WIDTH}
      storageKey={SOURCE_KEYS_SIDEBAR_STORAGE_KEY}
      panel={<Details details={details} />}
      isMobileViewCollapsed
    >
      {({ isSidebarVisible, toggleSidebarVisibility }) =>
        isMobile ? (
          <MobileFoxKeysList />
        ) : (
          <>
            <TabHeader
              isSidebarVisible={isSidebarVisible}
              onToggleSidebarClick={toggleSidebarVisibility}
            />
            <FoxKeysTable />
          </>
        )
      }
    </LayoutTwoColumns>
  );
};

export default KeysTab;
