import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import isToday from 'dayjs/plugin/isToday';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

export default function configureDayjs() {
  dayjs.extend(updateLocale);
  dayjs.extend(isToday);
  dayjs.extend(relativeTime, {
    thresholds: [
      { l: 's', r: 1 },
      { l: 'ss', r: 59, d: 'second' },
      { l: 'm', r: 1 },
      { l: 'mm', r: 59, d: 'minute' },
      { l: 'h', r: 1 },
      { l: 'hh', r: 23, d: 'hour' },
      { l: 'd', r: 1 },
      { l: 'dd', r: 29, d: 'day' },
      { l: 'M', r: 1 },
      { l: 'MM', r: 11, d: 'month' },
      { l: 'y', r: 1 },
      { l: 'yy', d: 'year' },
    ],
  });

  dayjs.locale('uk');
  dayjs.updateLocale('uk', {
    relativeTime: {
      s: '1 с',
      ss: '%d с',
      m: '1 хв',
      mm: '%d хв',
      h: '1 г',
      hh: '%d г',
      d: '1 д',
      dd: '%d д',
      M: '1 м',
      MM: '%d м',
      y: '1 р',
      yy: '%d р',
    },
  });
}
