import { FoxFrequency } from '@/entities/source/@fox/types';
import { ResponseData } from '@/shared/api';

interface Params {
  staleData: ResponseData<FoxFrequency[]>;
  foxFrequencyId: FoxFrequency['id'];
  note: FoxFrequency['note'];
}

const getFoxFrequenciesCacheWithUpdatedNote = ({ staleData, foxFrequencyId, note }: Params) => {
  const { data } = staleData;

  const updatedData = data.map((foxFrequency) => {
    if (foxFrequency.id !== foxFrequencyId) return foxFrequency;
    return { ...foxFrequency, note };
  });

  return {
    data: updatedData,
  };
};

export default getFoxFrequenciesCacheWithUpdatedNote;
