import { ReactNode } from 'react';
import { Badge } from 'antd';
import { Faction } from '@/shared/types';

export const getFactionLabel = (faction: Faction | undefined): ReactNode => {
  if (!faction) return;
  const factionsNames = faction.full_short_name.split(', ');
  const mainName = factionsNames.shift();
  const factionSpans = factionsNames.map((factionName) => {
    return (
      <span key={factionName}>
        <Badge
          style={{ marginLeft: '.25rem' }}
          status="default"
          text={factionName}
        />
      </span>
    );
  });
  return (
    <>
      <span style={{ marginLeft: '0.25rem', textDecoration: 'bald' }}>{mainName}</span>
      {factionSpans}
    </>
  );
};
