import { PresetStatusColorType } from 'antd/es/_util/colors';
import { ConnectionStatus, SubscriptionStatus } from '../types';

interface Params {
  socketConnectionStatus: ConnectionStatus;
  spectrumSubscriptionStatus: SubscriptionStatus;
  scannerSubscriptionStatus: SubscriptionStatus;
}

const getSpectrogramStatus = ({
  socketConnectionStatus,
  spectrumSubscriptionStatus,
  scannerSubscriptionStatus,
}: Params): PresetStatusColorType => {
  if (
    socketConnectionStatus === 'failed' ||
    spectrumSubscriptionStatus === 'failed' ||
    scannerSubscriptionStatus === 'failed'
  )
    return 'error';
  if (
    socketConnectionStatus === 'connecting' ||
    spectrumSubscriptionStatus === 'subscribing' ||
    scannerSubscriptionStatus === 'subscribing'
  )
    return 'processing';
  if (
    socketConnectionStatus === 'disconnected' ||
    spectrumSubscriptionStatus === 'unsubscribed' ||
    scannerSubscriptionStatus === 'unsubscribed'
  )
    return 'warning';
  if (
    socketConnectionStatus === 'connected' &&
    spectrumSubscriptionStatus === 'subscribed' &&
    scannerSubscriptionStatus === 'subscribed'
  )
    return 'success';

  return 'default';
};

export default getSpectrogramStatus;
