import { PropsWithChildren } from 'react';
import { Spinner } from '@/shared/components';
import styles from './styles.module.css';
import useUserDataGuard from './useUserDataGuard';

const UserDataGuard = ({ children }: PropsWithChildren) => {
  const { isUserDataExists } = useUserDataGuard();

  return isUserDataExists ? children : <Spinner className={styles.spinner} />;
};

export default UserDataGuard;
