import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getUnits } from '@/entities/unit/api';
import { Unit } from '@/entities/unit/types';
import { ResponseData } from '@/shared/api';
import { UNITS_QUERY_KEY } from '@/shared/constants';

type Params = {
  search?: string;
  options?: UseQueryOptions<unknown, unknown, ResponseData<Unit[]>, string[]>;
};

const useGetUnits = ({ search = '', options = {} }: Params = {}) => {
  const { data, isFetching, isLoading, refetch } = useQuery({
    queryKey: [UNITS_QUERY_KEY, search],
    queryFn: () => {
      return getUnits({
        queryParams: {
          search: search || null,
        },
      });
    },
    ...options,
  });

  return {
    units: data?.data ?? [],
    isUnitsFetching: isFetching,
    isUnitsLoading: isLoading,
    refetchUnits: refetch,
  };
};

export default useGetUnits;
