import { useEffect, RefObject } from 'react';
import { InputRef } from 'antd';
import { FrequencyProps } from '../types';

interface Params extends Pick<FrequencyProps, 'isAutoFocusEnabled'> {
  showInput: () => void;
  inputRef: RefObject<InputRef>;
}

const useFrequencyAutoFocus = ({ isAutoFocusEnabled, showInput, inputRef }: Params) => {
  useEffect(() => {
    if (!isAutoFocusEnabled) return;

    showInput();
    // showInput takes time for showing hidden input, it is impossible to focus hidden input that's why setTimeout is used.
    setTimeout(() => {
      inputRef.current?.focus();
    }, 500);
  }, [isAutoFocusEnabled]);
};

export default useFrequencyAutoFocus;
