import { useIsTabletOrMobile } from '@/shared/hooks';
import { withStopPropagation as withStopPropagationDecorator } from '@/shared/utils';
import { ButtonProps } from '../types';

interface Params extends ButtonProps {}

const useButton = (params: Params) => {
  const isTabletOrMobile = useIsTabletOrMobile();

  const { className, withStopPropagation, ...restButtonProps } = params;
  const { icon, children, onTouchStart, onTouchEnd, onMouseDown, onMouseUp, onClick } = restButtonProps;
  const isIconButton = icon && !children;
  const size: ButtonProps['size'] = isTabletOrMobile && !isIconButton ? 'large' : 'middle';

  const handleTouchStart = withStopPropagation ? withStopPropagationDecorator(onTouchStart) : onTouchStart;
  const handleTouchEnd = withStopPropagation ? withStopPropagationDecorator(onTouchEnd) : onTouchEnd;
  const handleMouseDown = withStopPropagation ? withStopPropagationDecorator(onMouseDown) : onMouseDown;
  const handleMouseUp = withStopPropagation ? withStopPropagationDecorator(onMouseUp) : onMouseUp;
  const handleClick = withStopPropagation ? withStopPropagationDecorator(onClick) : onClick;

  return {
    className,
    isIconButton,
    size,
    handleTouchStart,
    handleTouchEnd,
    handleMouseDown,
    handleMouseUp,
    handleClick,
    restButtonProps,
  };
};

export default useButton;
