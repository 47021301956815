import { useRef } from 'react';

interface Params {
  onClick: () => void;
}

const useBlurButtonOnClick = ({ onClick }: Params) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const blurButtonOnClick = () => {
    onClick();
    buttonRef.current?.blur();
  };

  return {
    buttonRef,
    blurButtonOnClick,
  };
};

export default useBlurButtonOnClick;
