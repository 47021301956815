import { useLocation } from 'react-router-dom';
import { MobileView, MobileViewQueryParam } from '@/shared/constants';
import { useIsMobile } from '@/shared/hooks/mobile';

type Params<T> = {
  routeQueryParams: T;
  routePath?: string;
};

const useGetFilteredQueryParams = <T extends Record<string, string>>({ routeQueryParams, routePath }: Params<T>) => {
  const { pathname, search } = useLocation();
  const isMobile = useIsMobile();
  const allQueryParams = new URLSearchParams(search);
  // keep all params if pathname is the same
  const filteredParams = new URLSearchParams(pathname === routePath ? search : '');

  Object.values(routeQueryParams).forEach((queryKey) => {
    const queryValue = allQueryParams.get(queryKey);

    if (queryValue) {
      filteredParams.set(queryKey, queryValue);
    }
  });

  if (isMobile) {
    filteredParams.set(MobileViewQueryParam.Mobile, MobileView.SecondaryScreen);
  }

  return filteredParams.toString();
};

export default useGetFilteredQueryParams;
