import { useEffect } from 'react';
import { useDebounceFn } from '@reactuses/core';
import { DEBOUNCE_DEFAULT } from '@/shared/constants';

interface Params {
  onResize: () => void;
  canvasWidth: number;
  canvasHeight: number;
}

const useSpectrogramResize = ({ canvasWidth, canvasHeight, onResize }: Params) => {
  const { run: handleResize } = useDebounceFn(() => onResize(), DEBOUNCE_DEFAULT);

  useEffect(() => {
    if (!canvasWidth || !canvasHeight) return;

    handleResize();
  }, [canvasWidth, canvasHeight, handleResize]);
};

export default useSpectrogramResize;
