import { useQuery } from '@tanstack/react-query';
import { getUnitDetails } from '@/entities/unit/api';
import { UNIT_DETAILS_QUERY_KEY } from '@/shared/constants';

const useGetUnitDetails = (unitId: string) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: [UNIT_DETAILS_QUERY_KEY, unitId],
    queryFn: () => getUnitDetails(unitId),
    enabled: Boolean(unitId),
  });

  return {
    details: data?.data,
    isDetailsLoading: isLoading,
    refetchDetails: refetch,
  };
};

export default useGetUnitDetails;
