import getFixedRange from './getFixedRange';
import isRoundedNumbersEqual from './isRoundedNumbersEqual';

const handleRightStep = (steps: number[], prevRange: number[], value: number) => {
  const equalStep = steps.find((step) => isRoundedNumbersEqual(value - step, prevRange[0]));

  return equalStep ? getFixedRange([prevRange[0], prevRange[0] + equalStep]) : prevRange;
};

export default handleRightStep;
