import { convex, featureCollection } from '@turf/turf';
import { Feature } from 'geojson';
import { MapGeoJSONFeature, Offset } from 'maplibre-gl';
import { MAP_CLUSTER_RADIUS, MAP_CLUSTER_RADIUS_SCALE, MapSource, POINT, SETTLEMENT } from '@/entities/map/constants';
import { ClusterProperties, MaplibreMap, NormalizedGeoJsonFeature, OpenTooltipFn } from '@/entities/map/types';
import { normalizeGeoJsonFeature } from '@/entities/map/utils';

type ShowPointTooltipParams = {
  feature: MapGeoJSONFeature;
  onOpen?: OpenTooltipFn;
  offset?: Offset;
};

class ClusterService {
  constructor(private map: MaplibreMap) {}

  getClusterFromFeature(feature: MapGeoJSONFeature): NormalizedGeoJsonFeature<ClusterProperties> {
    const cluster = normalizeGeoJsonFeature(feature);

    return {
      ...cluster,
      properties: {
        cluster: feature.properties.cluster,
        clusterId: feature.properties.cluster_id,
        pointCount: feature.properties.point_count,
        pointCountAbbreviated: feature.properties.point_count_abbreviated,
        locationFeatures: feature.properties.location_features,
        pointFeatures: feature.properties.point_features,
      },
    };
  }

  getClusterChildren(cluster: NormalizedGeoJsonFeature<ClusterProperties>, callback?: (features: Feature[]) => void) {
    const {
      properties: { clusterId },
      sourceId = MapSource.Markers,
    } = cluster;

    const source = this.map.getSource(sourceId);
    source?.getClusterChildren(clusterId, (err, features) => {
      if (err || !features) return;

      callback?.(features);
    });
  }

  getClusterPoints(cluster: NormalizedGeoJsonFeature<ClusterProperties>) {
    const {
      properties: { clusterId, pointCount },
      sourceId = MapSource.Markers,
    } = cluster;
    const source = this.map.getSource(sourceId);

    return new Promise<Feature[]>((resolve) => {
      if (!source) {
        resolve([]);
        return;
      }

      source.getClusterLeaves(clusterId, pointCount, 0, (err, features) => {
        if (err || !features) return resolve([]);

        resolve(features);
      });
    });
  }

  zoomToClusterBounds(cluster: NormalizedGeoJsonFeature<ClusterProperties>) {
    const {
      properties: { clusterId },
      geometry: { coordinates },
      sourceId = MapSource.Markers,
    } = cluster;

    const source = this.map.getSource(sourceId);
    source?.getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) return;

      this.map.easeTo({
        center: coordinates,
        zoom: zoom ?? undefined,
      });
    });
  }

  calculateClusterRadius() {
    const { sources } = this.map.getStyle();
    const isHighZoomLevel = this.map.getMaxZoom() - this.map.getZoom() <= 1;
    const clusterRadius = isHighZoomLevel ? 1 : MAP_CLUSTER_RADIUS;

    Object.keys(sources).forEach((sourceId) => {
      if (sourceId.startsWith(MapSource.Markers)) {
        const source = this.map.getSource(sourceId);
        source?.setClusterOptions({
          cluster: true,
          clusterRadius: clusterRadius * MAP_CLUSTER_RADIUS_SCALE,
        });
      }
    });
  }

  showClusterPolygon(cluster: NormalizedGeoJsonFeature<ClusterProperties>) {
    this.getClusterPoints(cluster).then((features) => {
      const pointsGeoJson = features.map(normalizeGeoJsonFeature);
      const polygonGeoJson = convex(featureCollection(pointsGeoJson));

      const polygon = this.map.getSource(MapSource.ClusterPolygon);
      polygon?.setData(polygonGeoJson as Feature);
    });
  }

  showPointTooltip({ feature, onOpen, offset }: ShowPointTooltipParams) {
    const {
      properties: { type },
      geometry: {
        coordinates: [lng, lat],
      },
    } = normalizeGeoJsonFeature(feature);

    const isLocation = type === 'location';
    const label = `${isLocation ? SETTLEMENT : POINT}: ${Number(lat.toFixed(7))} - ${Number(lng.toFixed(7))}`;

    onOpen?.({
      label,
      coords: [lng, lat],
      offset,
    });
  }
}

export default ClusterService;
