import { useEffect } from 'react';
import { getWebglStatus } from '@/entities/map/components/MapContainer/utils';
import { NOTIFICATION_TYPES } from '@/shared/constants';
import { useNotificationContext } from '@/shared/context';
import styles from './styles.module.css';

const NOTIFICATION_TITLE = 'WebGL не підтримується вашим браузером або вимкнений';
const NOTIFICATION_DESCRIPTION =
  'Завдяки WebGL досягається графічне прискорення відеокарт, необхідне для візуалізації мапи.\n' +
  'Оновіть ваш браузер або перевірте налаштування та увімкніть WebGL щоб виправити цю помилку';
const WEB_GL_STATUS = getWebglStatus();

const useWebGlStatus = () => {
  const { openNotification } = useNotificationContext();

  useEffect(() => {
    if (WEB_GL_STATUS !== 'supported') {
      openNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: NOTIFICATION_TITLE,
        description: NOTIFICATION_DESCRIPTION,
        duration: 0,
        className: styles.notification,
      });
    }
  }, [openNotification]);

  return WEB_GL_STATUS;
};

export default useWebGlStatus;
