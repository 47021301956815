import { useEffect } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { AudioPlayerProps } from '../type';

interface Params extends Pick<AudioPlayerProps, 'memoPlayNextRecord'> {
  wavesurfer: WaveSurfer | null;
  isAutoplayOn: boolean;
  memoStop: () => void;
}

const useWsFinishEventListener = ({ wavesurfer, isAutoplayOn, memoStop, memoPlayNextRecord }: Params) => {
  useEffect(() => {
    if (!wavesurfer) return;

    const handlerAudioFinish = () => {
      memoStop();
      if (isAutoplayOn && memoPlayNextRecord) {
        memoPlayNextRecord();
      }
    };

    const unsubscribeFinish = wavesurfer.on('finish', () => {
      handlerAudioFinish();
    });
    const unsubscribeAudioprocess = wavesurfer.on('audioprocess', (audioprocess) => {
      const audioDuration = wavesurfer.getDuration(); // sometimes getDuration method returns 0.001 if audio is short, we should skip such scenario because it is not a valid value.
      if (audioDuration > 0.001 && audioprocess > audioDuration) {
        handlerAudioFinish();
      }
    });

    return () => {
      unsubscribeFinish();
      unsubscribeAudioprocess();
    };
  }, [wavesurfer, isAutoplayOn, memoPlayNextRecord, memoStop]);
};

export default useWsFinishEventListener;
