import { useCopyToClipboard } from '@/shared/hooks';

const useDetailItem = () => {
  const { copyToClipboard } = useCopyToClipboard();

  const handleCopyToClipboardClick = (value: string | number) => async () => {
    await copyToClipboard(String(value));
  };

  return {
    handleCopyToClipboardClick,
  };
};

export default useDetailItem;
